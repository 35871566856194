import {Component, OnInit} from '@angular/core';
import {
    INavigationLateralMenu,
    INavigationLateralSection,
} from '@shared/navigation/navigation.interfaces';
import {combineLatest, Observable, of} from 'rxjs';
import {map,} from 'rxjs/operators';
import {AppNavigationLateralComponent} from '@shared/navigation/lateral/navigation-lateral.component';

@Component({selector: 'layout-diffusions', standalone: false, templateUrl: 'layout-diffusions.component.html'})
export class AppLayoutDiffusionsComponent implements OnInit {
    static readonly menus: Record<string, INavigationLateralMenu> = {
        PASSERELLES: {
            code: AppNavigationLateralComponent.menuCodes.PASSERELLES,
            label: 'Passerelles',
            route: '/app/diffusions/passerelles',
        }
    };

    private _section$!: Observable<INavigationLateralSection>;

    get section$(): Observable<INavigationLateralSection> {
        return this._section$;
    }

    ngOnInit(): void {
        this._section$ = combineLatest([
            of(AppLayoutDiffusionsComponent.menus.PASSERELLES),
        ]).pipe(
            map(menus => menus.filter(menu => !!menu)),
            map(menus => ({menus} as INavigationLateralSection))
        );
    }
}
