import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import {
    IDossierFileActions, IDossierFileDropdownData, IDossierFileDropdownDataOptions
} from '@features/dossiers/dossiers.interfaces';

@Component({selector: 'dossier-file-dropdown', standalone: false, templateUrl: 'dossier-file.dropdown.component.html'})
export class DossierFileDropdownComponent implements IDynamicComponent {
    static readonly actions = {
        DELETE: 'dossier_file_delete',
        READ: 'dossier_file_read',
        RENAME: 'dossier_file_rename',
        SHARE: 'dossier_file_share',
    };
    static readonly initDossierFileActions: IDossierFileActions = {read: true, rename: true, share: true, delete: true};
    private _dropdownService = inject(DropdownService);
    private _dossierFile!: DossierFile;
    private _options: IDossierFileDropdownDataOptions = {...{enabledActions: DossierFileDropdownComponent.initDossierFileActions}};

    @Input()
    set data(value: IDossierFileDropdownData) {
        this._dossierFile = value.dossierFile;
        this._options = {...{enabledActions: DossierFileDropdownComponent.initDossierFileActions}, ...value.options};
    }

    get options(): IDossierFileDropdownDataOptions {
        return this._options;
    }

    delete(): void {
        this._dropdownService.clicked(DossierFileDropdownComponent.actions.DELETE, this._dossierFile);
    }

    read(): void {
        this._dropdownService.clicked(DossierFileDropdownComponent.actions.READ, this._dossierFile);
    }

    rename(): void {
        this._dropdownService.clicked(DossierFileDropdownComponent.actions.RENAME, this._dossierFile);
    }

    share(): void {
        this._dropdownService.clicked(DossierFileDropdownComponent.actions.SHARE, this._dossierFile);
    }
}
