import {Component, inject, Input} from '@angular/core';
import Bien from '@models/bien/bien.model';
import {BienPerformanceEnergetiqueService} from '@models/bien/performance-energetique/bien-performance-energetique.service';
import {IGraphesOptions} from '@shared/diagnostic/diagnostic.interfaces';
import {AppDiagnosticGrapheCELegacyComponent} from '@shared/diagnostic/graphes/v1/diagnostic-graphe-ce.component';
import {AppDiagnosticGrapheGESLegacyComponent} from '@shared/diagnostic/graphes/v1/diagnostic-graphe-ges.component';
import {LowerCasePipe} from '@angular/common';
import {AppDiagGraphesV2Component} from '@shared/diagnostic/graphes/v2/diagnostic-graphes-v2.component';

@Component({
    imports: [
        AppDiagGraphesV2Component,
        AppDiagnosticGrapheCELegacyComponent,
        AppDiagnosticGrapheGESLegacyComponent,
        LowerCasePipe,
    ],
    selector: 'app-diag-graphes',
    templateUrl: 'diagnostic-graphes.component.html',
})
export class AppDiagGraphesComponent {
    static readonly initGraphesOptions: IGraphesOptions = {estimationCoutsVisibility: true, grapheEmissionsVisibility: true};
    // @todo Cette injection n'est pas normale, ce qu'il y a dans `src/shared` ne doit pas utiliser du code de `src/core`
    private _bienPerformanceEnergetiqueService = inject(BienPerformanceEnergetiqueService);
    private _bien!: Bien;
    private _options: IGraphesOptions = {...AppDiagGraphesComponent.initGraphesOptions};

    get bien(): Bien {
        return this._bien;
    }

    @Input()
    set bien(value: Bien) {
        this._bien = value;
    }

    get options(): IGraphesOptions {
        return this._options;
    }

    @Input()
    set options(value: IGraphesOptions) {
        this._options = {...AppDiagGraphesComponent.initGraphesOptions, ...value};
    }

    isDiagV1(): boolean {
        return this._bienPerformanceEnergetiqueService.isDiagV1(this._bien);
    }

    isDiagV2(): boolean {
        return this._bienPerformanceEnergetiqueService.isDiagV2(this._bien);
    }
}
