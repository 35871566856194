import {Component, Input, output, ViewChild} from '@angular/core';
import {FormsModule, NgModel} from '@angular/forms';
import {IFormSelectDateOptions} from '@shared/form/form.interfaces';
import DateFormat from '@shared/date/date.format';
import {NgClass} from '@angular/common';
import {ProvideParentFormDirective} from '@shared/form/provide-parent-form.directive';

@Component({
    imports: [FormsModule, NgClass, ProvideParentFormDirective],
    exportAs: 'formSelectDate',
    selector: 'app-form-select-date',
    templateUrl: 'form.select-date.component.html',
})
export class AppFormSelectDateComponent {
    static readonly initFormSelectDateOptions: IFormSelectDateOptions = {
        inputClass: 'tw-input-date',
        name: 'formSelectDate',
        required: false,
        toApi: false,
    };
    readonly selected = output<string>();
    private _formInput!: NgModel;
    private _modelDate!: string;
    private _modelNumber!: number;
    private _modelNumberMax!: number;
    private _modelNumberMin!: number;
    private _options: IFormSelectDateOptions = {...AppFormSelectDateComponent.initFormSelectDateOptions};

    get formInput(): NgModel {
        return this._formInput;
    }

    @ViewChild('formDate')
    set formInput(value: NgModel) {
        setTimeout(_ => this._formInput = value, 1);
    }

    @Input()
    set model(value: string | Date) {
        this._modelDate = DateFormat.getDateString(value);
        this.setModelNumber();
    }

    get modelDate(): string {
        return this._modelDate;
    }

    set modelDate(value: string) {
        this._modelDate = value;
        this.setModelNumber();
    }

    get modelNumber(): number {
        return this._modelNumber;
    }

    get modelNumberMax(): number {
        return this._modelNumberMax;
    }

    get modelNumberMin(): number {
        return this._modelNumberMin;
    }

    get options(): IFormSelectDateOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectDateOptions) {
        this._options = {...AppFormSelectDateComponent.initFormSelectDateOptions, ...value};
        this._options.max = DateFormat.dateFromDatetime(this._options.max);
        this._options.min = DateFormat.dateFromDatetime(this._options.min);
        this._modelNumberMax = this._options.max ? DateFormat.toDate(this._options.max).getTime() : null!;
        this._modelNumberMin = this._options.min ? DateFormat.toDate(this._options.min).getTime() : null!;
    }

    emitDate(): void {
        if (this._options.toApi) {
            this.selected.emit(DateFormat.toAPI(this._modelDate));
        } else {
            this.selected.emit(this._modelDate);
        }
    }

    setModelNumber(): void {
        if (this._modelDate) {
            this._modelNumber = DateFormat.toDate(this._modelDate)?.getTime();
        } else {
            this._modelNumber = null!;
        }
    }
}
