import {Component, HostListener, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {IBienOverviewPhotosClicked} from '@features/biens/biens.interfaces';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import BrowserScreenSizeService from '@shared/browser/screen-size/browser.screen-size.service';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {DossierBienOverviewService} from '@features/dossiers/dossier/bien/overview/dossier-bien-overview.service';
import {
    DossierBienOverviewInfosService
} from '@features/dossiers/dossier/bien/overview/infos/dossier-bien-overview.infos.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import {
    DossierBienOverviewLogementsService
} from '@features/dossiers/dossier/bien/overview/logements/dossier-bien-overview.logements.service';
import {
    DossierBienOverviewSurfacesService
} from '@features/dossiers/dossier/bien/overview/surfaces/dossier-bien-overview.surfaces.service';
import {
    IDossierBienOverviewMainInfosOptions
} from '@features/dossiers/dossier/bien/overview/dossier-bien-overview.interfaces';

@Component({
    selector: 'app-dossier-bien-overview',
    standalone: false,
    templateUrl: 'dossier-bien-overview.component.html',
})
export class AppDossierBienOverviewComponent implements OnDestroy, OnInit {
    static readonly initDossierBienOverviewMainInfosOptions: IDossierBienOverviewMainInfosOptions = {
        showReference: true,
    };
    private _browserScreenSizeService = inject(BrowserScreenSizeService);
    private _dossierBienOverviewInfosService = inject(DossierBienOverviewInfosService);
    private _dossierBienOverviewLogementsService = inject(DossierBienOverviewLogementsService);
    private _dossierBienOverviewService = inject(DossierBienOverviewService);
    private _dossierBienOverviewSurfacesService = inject(DossierBienOverviewSurfacesService);
    private _modalService = inject(ModalService);
    private _toasterService = inject(ToasterService);
    private _dossierBien!: ADossierBien;
    private _infosConfidentialWanted$!: Observable<void>;
    private _isInfosConfidentialVisible = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IDossierBienOverviewMainInfosOptions = {...AppDossierBienOverviewComponent.initDossierBienOverviewMainInfosOptions};
    private _screenSizeSource = new ReplaySubject<string>(1);

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get infosConfidentialWanted$(): Observable<void> {
        return this._infosConfidentialWanted$;
    }

    @Input()
    set infosConfidentialWanted$(value: Observable<void>) {
        this._infosConfidentialWanted$ = value;
    }

    get isInfosConfidentialVisible(): boolean {
        return this._isInfosConfidentialVisible;
    }

    get isPartInfosVisible(): boolean {
        return this._dossierBienOverviewInfosService.isPartVisible;
    }

    get isPartLogementsVisible(): boolean {
        return this._dossierBienOverviewLogementsService.isPartVisible;
    }

    get isPartSurfacesVisible(): boolean {
        return this._dossierBienOverviewSurfacesService.isPartVisible;
    }

    get options(): IDossierBienOverviewMainInfosOptions {
        return this._options;
    }

    @Input()
    set options(value: IDossierBienOverviewMainInfosOptions) {
        this._options = {...AppDossierBienOverviewComponent.initDossierBienOverviewMainInfosOptions, ...value};
    }

    ngOnInit(): void {
        this.setScreenSize();
        this._screenSizeSource.asObservable().pipe(
            map(size => {
                if (size === BrowserScreenSizeService.sizes['2XL']) {
                    return 7;
                } else if (size === BrowserScreenSizeService.sizes.XL) {
                    return 6;
                } else if (size === BrowserScreenSizeService.sizes.LG) {
                    return 5;
                } else if (size === BrowserScreenSizeService.sizes.MD) {
                    return 3;
                } else if (size === BrowserScreenSizeService.sizes.SM) {
                    return 3;
                }

                return 2;
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(mainInfosLimit => this._dossierBienOverviewService.init(this.dossierBien, mainInfosLimit));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickedBienPhoto(bienOverviewPhotosClicked: IBienOverviewPhotosClicked): void {
        let currentIndex = 0;

        if (bienOverviewPhotosClicked.bienPhoto) {
            currentIndex = bienOverviewPhotosClicked.cBienPhotos.results.findIndex(bienPhoto => bienPhoto.uuid === bienOverviewPhotosClicked.bienPhoto!.uuid);
        }

        this._modalService.ngOpen$('BienModalDisplayPhotos', {
            resolve: {bienPhotos: () => bienOverviewPhotosClicked.cBienPhotos.results, currentIndex},
        }).subscribe();
    }

    setIsInfosConfidentialVisible(value: boolean): void {
        this._isInfosConfidentialVisible = value;
        if (this.isInfosConfidentialVisible) {
            const labelPrice = this.dossierBien.isEstimation() ? 'de la valeur' : (this.dossierBien.isLocation() ? 'du loyer' : 'du prix');

            this._toasterService.info('Voir les informations confidentielles', 'Les photos privées et le détail ' + labelPrice + ' sont maintenant visibles.');
        }
    }

    @HostListener('window:resize')
    setScreenSize(): void {
        this._screenSizeSource.next(this._browserScreenSizeService.getSize());
    }
}
