import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject, switchMap} from 'rxjs';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    VentePasserelleDropdownComponent
} from '@features/ventes/vente/passerelles/passerelle/dropdown/vente-passerelle.dropdown.component';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import {
    CVentePasserellesService
} from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.service';
import CVentePasserelles from '@models/ventes/vente/passerelles/collection/vente-passerelles.collection.model';
import {ICollectionSelectionListOptions} from '@shared/collection/selection/collection-selection.interfaces';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';

@Component({
    selector: 'app-ventes-passerelles-list',
    standalone: false,
    templateUrl: 'ventes-passerelles-list.component.html',
})
export class AppVentesPasserellesListComponent implements OnDestroy, OnInit {
    static readonly initVentePasserellesListOptions: ICollectionSelectionListOptions = {
        initSelectAll: false,
        nameSelection: 'vente-passerelles-list-selection',
    };
    readonly clicked = output<IDropdownClicked>();
    readonly ventePasserellesSelected = output<VentePasserelle[]>();
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _cVentePasserellesService = inject(CVentePasserellesService);
    private _dropdownService = inject(DropdownService);
    private _cVentePasserelles$!: Observable<CVentePasserelles>;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: ICollectionSelectionListOptions = {...AppVentesPasserellesListComponent.initVentePasserellesListOptions};
    private _pendingGetMore = false;

    get cVentePasserelles$(): Observable<CVentePasserelles> {
        return this._cVentePasserelles$;
    }

    @Input({required: true})
    set cVentePasserelles$(value$: Observable<CVentePasserelles>) {
        this._cVentePasserelles$ = value$;
    }

    get options(): ICollectionSelectionListOptions {
        return this._options;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.clicked.emit(dropdownClicked));

        this._collectionSelectionService.clear(this._options.nameSelection!);
        this._collectionSelectionService.isInit$(this._options.nameSelection!).pipe(
            filter(isInit => isInit),
            switchMap(_ => this._collectionSelectionService.getListSelected$(this._options.nameSelection!)),
            map(listSelected => listSelected as Set<VentePasserelle>),
            map(listSelected => Array.from(listSelected)),
            takeUntil(this._onDestroy$),
        ).subscribe(listSelected => this.ventePasserellesSelected.emit(listSelected));
    }

    ngOnDestroy(): void {
        this._collectionSelectionService.delete(this._options.nameSelection!);
        this._onDestroy$.next();
    }

    clickOnVentePasserelle(htmlButtonElement: HTMLButtonElement, ventePasserelle: VentePasserelle): void {
        this._dropdownService.open(htmlButtonElement, {
            component: VentePasserelleDropdownComponent,
            data: {ventePasserelle},
        });
    }

    getMore(cVentePasserelles: CVentePasserelles): void {
        this._pendingGetMore = true;
        this._cVentePasserellesService.addNext$(cVentePasserelles).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }
}
