import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {VenteService} from '@models/ventes/vente/vente.service';
import Vente from '@models/ventes/vente/vente.model';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {filter, map, takeUntil} from 'rxjs/operators';
import {
    VenteOverviewActionsMainComponent
} from '@features/ventes/vente/actions/overview/vente-overview.actions-main.component';
import {Router} from '@angular/router';
import {ToasterService} from '@shared/toaster/toaster.service';
import {VenteOverviewService} from '@models/ventes/vente/vente-overview.service';

@Component({selector: 'layout-vente-overview', standalone: false, templateUrl: 'layout-vente-overview.component.html'})
export class AppLayoutVenteOverviewComponent implements OnDestroy, OnInit {
    private _callToActionService = inject(CallToActionService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _venteOverviewService = inject(VenteOverviewService);
    private _venteService = inject(VenteService);
    private _infosConfidentialWantedSource = new Subject<void>();
    private _infosConfidentialWanted$ = this._infosConfidentialWantedSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();

    get infosConfidentialWanted$(): Observable<void> {
        return this._infosConfidentialWanted$;
    }

    get vente$(): Observable<Vente> {
        return this._venteService.current$.pipe(filter(vente => !!vente));
    }

    ngOnInit(): void {
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.ADVANCED_PRINT) {
                    return this._callToActionService.actionExec$(this.advancedPrint$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.PRINT_AFFICHETTES) {
                    return this._callToActionService.actionExec$(this.printAffichettes$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.RESTORE) {
                    return this._callToActionService.actionExec$(this.restore$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.UNLOCK) {
                    return this._callToActionService.actionExec$(this.unlock$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    advancedPrint$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.advancedPrint$(vente)));
    }

    print$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.print$(vente)));
    }

    printAffichettes$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.printAffichettes$(vente)));
    }

    restore$(): Observable<string> {
        return this.vente$.pipe(
            switchMap(vente => this._venteOverviewService.restore$(vente)),
            map(clonedVente => {
                if (clonedVente) {
                    this._toasterService.success('Bien en vente restauré', 'Le bien en vente "' + clonedVente.reference + '" a été créé.');

                    return '/app/ventes/' + clonedVente.id.toString();
                }

                return undefined!;
            }),
        );
    }

    unlock$(): Observable<void> {
        this._infosConfidentialWantedSource.next();

        return of(undefined);
    }
}
