import {inject, Injectable} from '@angular/core';
import Etude from '@models/etudes/etude/etude.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {EtudesApiService} from '@models/etudes/etudes.api.service';
import {TemplateFactory} from '@models/templates/template/template.factory';
import {IEtudeApi} from '@models/etudes/etude/etude.interfaces';
import {EtudeSettingsNetworkFactory} from '@models/etudes/etude/settings/network/etude-settings-network.factory';
import {ILinksApi} from '@models/links/links.interfaces';

@Injectable({providedIn: 'root'})
export class EtudeFactory {
    private _etudesApiService = inject(EtudesApiService);
    private _etudeSettingsNetworkFactory = inject(EtudeSettingsNetworkFactory);
    private _templateFactory = inject(TemplateFactory);

    create(etudeApi: IEtudeApi): Etude {
        const etude = this.createVirgin(etudeApi.uuid, etudeApi.id);

        etude.crpcen = etudeApi.crpcen;
        etude.imprimerAffichetteConfrere = etudeApi.imprimerAffichetteConfrere;
        etude.nbNotaires = etudeApi.nbNotaires;
        etude.previsite = etudeApi.previsite;
        etude.raisonSociale = etudeApi.raisonSociale;
        etude.sendingAddressesBiensToConfreres = etudeApi.sendingAddressesBiensToConfreres;

        if (etudeApi._embedded) {
            etude.defaultTemplates = (etudeApi._embedded.defaultTemplates || []).filter(templateApi => templateApi)
                // En attente d'un retour complet de l'API
                .map(templateApi => ({...templateApi, ...{id: templateApi['templateId'] as number}}))
                // En attente d'un retour complet de l'API
                .map(templateApi => {
                    if (!templateApi._links) {
                        templateApi._links = {self: {href: '/templates/' + templateApi.id.toString()}} as ILinksApi;
                    }

                    return templateApi;
                })
                .map(templateApi => this._templateFactory.create(templateApi));
        }

        if (etudeApi._links) {
            if (etudeApi._links.banner) {
                etude.linkBanner = etudeApi._links.banner.href;
            }

            if (etudeApi._links.logo) {
                etude.linkLogo = etudeApi._links.logo.href;
            }

            if (etudeApi._links.self) {
                etude.linkSelf = etudeApi._links.self.href;
            }

            if (etudeApi._links.sites) {
                etude.linkSites = etudeApi._links.sites.href;
            }

            if (etudeApi._links.users) {
                etude.linkUsers = etudeApi._links.users.href;
            }

            if (etudeApi._links.ventes) {
                etude.linkVentes = etudeApi._links.ventes.href;
            }
        }

        return etude;
    }

    createVirgin(uuid: string, id: number): Etude {
        const etude = new Etude(uuid, id);

        etude.settingsNetwork = this._etudeSettingsNetworkFactory.createVirgin();

        return etude;
    }

    get$(uuid: string): Observable<Etude> {
        return this._etudesApiService.get$(uuid).pipe(map(etudeApi => this.create(etudeApi)));
    }

    getByLink$(link: string): Observable<Etude> {
        return this._etudesApiService.getByLink$(link).pipe(map(etudeApi => this.create(etudeApi)));
    }

    getCurrent$(): Observable<Etude> {
        return this._etudesApiService.getCurrent$().pipe(map(etudeApi => this.create(etudeApi)));
    }
}
