import {Injectable} from '@angular/core';
import {v4 as uuidV4, validate} from 'uuid';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {Gender} from '@shared/texts/texts.interfaces';

@Injectable({providedIn: 'root'})
export class TextsService {
    static capitalize(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    static priceDifference(oldPrice: number, newPrice: number, gender: Gender = 'M'): string {
        const oldPriceText = oldPrice || oldPrice === 0 ? TextCurrencyPipe.transformation(oldPrice) : `Non renseigné${gender === 'M' ? '' : 'e'}`;
        const newPriceText = newPrice || newPrice === 0 ? TextCurrencyPipe.transformation(newPrice) : `Non renseigné${gender === 'M' ? '' : 'e'}`;

        return `${oldPriceText} => ${newPriceText}`;
    }

    uuid(): string {
        return (uuidV4 as () => string)();
    }

    uuidValidate(uuid: string): boolean {
        return validate(uuid);
    }
}
