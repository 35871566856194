import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {
    DossierBienOverviewMainInfosService
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewTerrainService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _dossierBienOverviewMainInfosService = inject(DossierBienOverviewMainInfosService);
    private _isCloturesVisible!: boolean;
    private _isCommentsTerrainVisible!: boolean;
    private _isDecliviteVisible!: boolean;
    private _isLongueurFacadeVisible!: boolean;
    private _isPanneauxPhotovoltaiquesVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isPointEauVilleVisible!: boolean;
    private _isPointEauCampagneVisible!: boolean;
    private _isServitudeVisible!: boolean;
    private _isSurfaceCadastraleVisible!: boolean;
    private _isSurfaceBatieVisible!: boolean;
    private _isSurfaceNonBatieVisible!: boolean;
    private _isSurfacePlancherVisible!: boolean;
    private _isZoneInondableVisible!: boolean;

    get isCloturesVisible(): boolean {
        return this._isCloturesVisible;
    }

    get isCommentsTerrainVisible(): boolean {
        return this._isCommentsTerrainVisible;
    }

    get isDecliviteVisible(): boolean {
        return this._isDecliviteVisible;
    }

    get isLongueurFacadeVisible(): boolean {
        return this._isLongueurFacadeVisible;
    }

    get isPanneauxPhotovoltaiquesVisible(): boolean {
        return this._isPanneauxPhotovoltaiquesVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isPointEauCampagneVisible(): boolean {
        return this._isPointEauCampagneVisible;
    }

    get isPointEauVilleVisible(): boolean {
        return this._isPointEauVilleVisible;
    }

    get isServitudeVisible(): boolean {
        return this._isServitudeVisible;
    }

    get isSurfaceCadastraleVisible(): boolean {
        return this._isSurfaceCadastraleVisible;
    }

    get isSurfaceBatieVisible(): boolean {
        return this._isSurfaceBatieVisible;
    }

    get isSurfaceNonBatieVisible(): boolean {
        return this._isSurfaceNonBatieVisible;
    }

    get isSurfacePlancherVisible(): boolean {
        return this._isSurfacePlancherVisible;
    }

    get isZoneInondableVisible(): boolean {
        return this._isZoneInondableVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isCloturesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.clotures);
        this._isCommentsTerrainVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsTerrain);
        this._isDecliviteVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.declivite);
        this._isLongueurFacadeVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.longueurFacade)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.longueurFacade);
        this._isPanneauxPhotovoltaiquesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.panneauxPhotovoltaiques);
        this._isPointEauCampagneVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.pointEauCampagne);
        this._isPointEauVilleVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.pointEauVille);
        this._isServitudeVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.servitude);
        this._isSurfaceCadastraleVisible = !this._dossierBienOverviewMainInfosService.getByCode(DossierBienFieldsConst.surfaceCadastrale)
            && this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.surfaceCadastrale);
        this._isSurfaceBatieVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.surfaceBatie);
        this._isSurfaceNonBatieVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.surfaceNonBatie);
        this._isSurfacePlancherVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.surfacePlancher);
        this._isZoneInondableVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.zoneInondable);

        this._isPartVisible = this.isCommentsTerrainVisible || this.isCloturesVisible || this.isDecliviteVisible
            || this.isLongueurFacadeVisible || this.isPanneauxPhotovoltaiquesVisible || this.isPointEauCampagneVisible
            || this.isPointEauVilleVisible || this.isServitudeVisible || this.isSurfaceCadastraleVisible
            || this.isSurfaceBatieVisible || this.isSurfaceNonBatieVisible || this.isSurfacePlancherVisible
            || this.isZoneInondableVisible;
    }
}
