import {Component, DestroyRef, ElementRef, inject, OnInit, Renderer2} from '@angular/core';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    imports: [FaIconComponent],
    selector: 'app-browser-online-indicator',
    templateUrl: 'browser.online-indicator.component.html',
})
export class BrowserOnlineIndicatorComponent implements OnInit {
    static readonly CLASS_INDICATOR_OFFLINE = 'tw-browser-indicator-offline';
    private _browserOnlineService = inject(BrowserOnlineService);
    private _destroyRef = inject(DestroyRef);
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _renderer2 = inject(Renderer2);

    ngOnInit(): void {
        this._browserOnlineService.isOn$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(isOn => {
            if (isOn) {
                this._renderer2.removeClass(this._elementRef.nativeElement.parentElement, BrowserOnlineIndicatorComponent.CLASS_INDICATOR_OFFLINE);
            } else {
                this._renderer2.addClass(this._elementRef.nativeElement.parentElement, BrowserOnlineIndicatorComponent.CLASS_INDICATOR_OFFLINE);
            }
        });
    }

    openMoreInfos(): void {
        this._browserOnlineService.openInfosOffLine();
    }
}
