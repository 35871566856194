import {Directive, inject} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {PhoneNumberService} from '@shared/texts/phones/phone/number/phone-number.service';

@Directive({
    providers: [{ provide: NG_VALIDATORS, useExisting: FormPhoneValidatorDirective, multi: true }],
    selector: '[appFormPhoneValidator]',
})
export class FormPhoneValidatorDirective implements Validator {
    // @todo Cette injection n'est pas normale, ce qu'il y a dans `src/shared` ne doit pas utiliser du code de `src/core`
    private _phoneNumberService = inject(PhoneNumberService);

    validate(control: AbstractControl<string>): ValidationErrors | null {
        return !control.value || this._phoneNumberService.isValid(control.value) ? null : {phone: true};
    }
}
