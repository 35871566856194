import {NgModule} from '@angular/core';
import {AppPersonEditSimpleComponent} from '@features/contacts/person-edit-simple/person.edit-simple.component';
import {
    AppContactConvivialNameComponent
} from '@features/contacts/contact/convivial-name/contact.convivial-name.component';
import {AppContactCardComponent} from '@features/contacts/contact/card/contact.card.component';
import {AppContactInitialesComponent} from '@features/contacts/contact/initiales/contact.initiales.component';
import {AppContactsSourceComponent} from '@features/contacts/source/contacts-source.component';

@NgModule({
    exports: [
        AppContactCardComponent,
        AppContactConvivialNameComponent,
        AppContactsSourceComponent,
        AppPersonEditSimpleComponent,
    ],
    imports: [
        AppContactCardComponent,
        AppContactConvivialNameComponent,
        AppContactInitialesComponent,
        AppContactsSourceComponent,
        AppPersonEditSimpleComponent,
    ],
})
export class ContactsModule {
}
