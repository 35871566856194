import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {TemplateHeaderFactory} from '@models/templates/headers/header/template-header.factory';
import {ICTemplateHeadersApi} from '@models/templates/headers/collection/template-headers.collection.interfaces';
import CTemplateHeaders from '@models/templates/headers/collection/template-headers.collection.model';
import {TemplateHeadersApiService} from '@models/templates/headers/template-headers.api.service';

@Injectable({providedIn: 'root'})
export class CTemplateHeadersFactory {
    private _templateHeaderFactory = inject(TemplateHeaderFactory);
    private _templateHeadersApiService = inject(TemplateHeadersApiService);

    create(cTemplateHeadersApi: ICTemplateHeadersApi): CTemplateHeaders {
        const cTemplateHeaders = new CTemplateHeaders();

        cTemplateHeaders.links = new Links(cTemplateHeadersApi._links);
        cTemplateHeaders.page = cTemplateHeadersApi.page;
        cTemplateHeaders.pages = cTemplateHeadersApi.pages;
        cTemplateHeaders.perPage = cTemplateHeadersApi.limit;
        cTemplateHeaders.total = cTemplateHeadersApi.total;

        if (cTemplateHeadersApi._embedded) {
            cTemplateHeaders.results = (cTemplateHeadersApi._embedded.items || []).filter(templateHeaderApi => templateHeaderApi)
                .map(templateHeaderApi => this._templateHeaderFactory.create(templateHeaderApi));
        }

        return cTemplateHeaders;
    }

    get$(): Observable<CTemplateHeaders> {
        return this._templateHeadersApiService.getHeaders$().pipe(map(cTemplateHeadersApi => this.create(cTemplateHeadersApi)));
    }
}
