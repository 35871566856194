import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgBienDetails]'})
export class NgBienDetailsDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskBienDetailsCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgBienDetailsDirective],
    selector: 'app-ng-bien-details',
    template: '<div appNgBienDetails></div>',
})
export class NgBienDetailsComponent {
}
