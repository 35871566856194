import {NgModule} from '@angular/core';
import {AppCollectionForFilterPipe} from '@shared/collection/for-filter/collection.for-filter.pipe';
import {AppCollectionSortableDirective} from '@shared/collection/sortable/collection.sortable.directive';
import {AppCollectionItemErrorDirective} from '@shared/collection/item-error/collection.item-error.directive';
import {
    AppCollectionStackedImagesComponent
} from '@shared/collection/stacked-images/collection.stacked-images.component';
import {AppCollectionColumnSortDirective} from '@shared/collection/column-sort/collection-column.sort.directive';
import {AppCollectionNavigationComponent} from '@shared/collection/navigation/collection-navigation.component';
import {AppCollectionSelectionDirective} from '@shared/collection/selection/collection-selection.directive';
import {CollectionSelectionBarComponent} from '@shared/collection/selection/bar/collection-selection-bar.component';

@NgModule({
    exports: [
        AppCollectionColumnSortDirective,
        AppCollectionForFilterPipe,
        AppCollectionItemErrorDirective,
        AppCollectionNavigationComponent,
        AppCollectionSelectionDirective,
        AppCollectionSortableDirective,
        AppCollectionStackedImagesComponent,
        CollectionSelectionBarComponent,
    ],
    imports: [
        AppCollectionColumnSortDirective,
        AppCollectionForFilterPipe,
        AppCollectionItemErrorDirective,
        AppCollectionNavigationComponent,
        AppCollectionSelectionDirective,
        AppCollectionSortableDirective,
        AppCollectionStackedImagesComponent,
        CollectionSelectionBarComponent,
    ],
})
export class CollectionModule {
}
