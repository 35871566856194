import {Component, inject, Input, output} from '@angular/core';
import {IImageLoadError, IImagePickerOptions} from '@shared/image/image.interfaces';
import ImageService from '@shared/image/image.service';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IMG_MAX_SIZE} from '@shared/image/image.constants';
import {AppFormFilePickerComponent} from '@shared/form/file/picker/form-file-picker.component';

@Component({
    imports: [AppFormFilePickerComponent],
    selector: 'app-image-picker',
    templateUrl: 'image-picker.component.html',
})
export class AppImagePickerComponent {
    static readonly initImagePickerOptions: IImagePickerOptions = {
        allowedTypes: [
            ImageService.acceptedMimeTypes.JPEG,
            ImageService.acceptedMimeTypes.JPG,
            ImageService.acceptedMimeTypes.PNG,
        ].join(','),
        label: 'Sélectionner une image',
        labelImageType: 'image',
        maxSize: IMG_MAX_SIZE,
        messageWithValidation: false,
        minHeight: 300,
        minWidth: 300,
    };
    readonly selected = output<File>();
    private _imageService = inject(ImageService);
    private _imageSelectedError!: IImageLoadError;
    private _options: IImagePickerOptions = {...AppImagePickerComponent.initImagePickerOptions};
    private _openFilePicker$!: Observable<void>;

    get imageSelectedError(): IImageLoadError {
        return this._imageSelectedError;
    }

    get openFilePicker$(): Observable<void> {
        return this._openFilePicker$;
    }

    @Input()
    set openFilePicker$(value: Observable<void>) {
        this._openFilePicker$ = value;
    }

    get options(): IImagePickerOptions {
        return this._options;
    }

    @Input()
    set options(value: IImagePickerOptions) {
        this._options = {...AppImagePickerComponent.initImagePickerOptions, ...value};
    }

    onSelect(file: File): void {
        this._imageSelectedError = undefined!;
        this._imageService.isAccepted$(file, this._options).pipe(take(1)).subscribe({
            next: isAccepted => {
                if (isAccepted) {
                    this.selected.emit(file);
                }
            },
            error: imageSelectedError => this._imageSelectedError = imageSelectedError,
        });
    }
}
