import {NgModule} from '@angular/core';
import {NgEskimmoFooterComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo.footer.component';
import {NgEskimmoDossierSuiviComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo.dossier-suivi.component';
import {NgEskimmoContactComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo.contact.component';
import {NgEskimmoButtonSaveComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo.button-save.component';
import {NgEskimmoUploadFilesInfoComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo.upload-files-info.component';
import {NgEskimmoBrowserComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-browser.component';
import {
    NgEskimmoConfigurationPasserellesComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-configuration-passerelles.component';
import {NgEskimmoDashboardComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dashboard.component';
import {
    NgEskimmoDocumentsAdvertisementComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-documents-advertisement.component';
import {NgEskimmoDocumentsBlankComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-documents-blank.component';
import {
    NgEskimmoDossierSuiviEmailComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-email.component';
import {
    NgEskimmoDossierSuiviReportComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-report.component';
import {NgEskimmoMyAccountComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo-my-account.component';
import {
    NgEskimmoNegociationRevenusComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-negociation-revenus.component';
import {NgEskimmoTaskRunnerCompComponent} from '@shared/angularJS/up-ng/eskimmo/eskimmo.task-runner.components';

const components = [
    NgEskimmoBrowserComponent,
    NgEskimmoButtonSaveComponent,
    NgEskimmoConfigurationPasserellesComponent,
    NgEskimmoContactComponent,
    NgEskimmoDashboardComponent,
    NgEskimmoDocumentsAdvertisementComponent,
    NgEskimmoDocumentsBlankComponent,
    NgEskimmoDossierSuiviComponent,
    NgEskimmoDossierSuiviEmailComponent,
    NgEskimmoDossierSuiviReportComponent,
    NgEskimmoFooterComponent,
    NgEskimmoMyAccountComponent,
    NgEskimmoNegociationRevenusComponent,
    NgEskimmoTaskRunnerCompComponent,
    NgEskimmoUploadFilesInfoComponent,
];

@NgModule({exports: components, imports: components})
export class EskimmoAppComponentsModule {
}
