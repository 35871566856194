import {Component, ElementRef, inject, Input} from '@angular/core';
import Site from '@models/sites/site/site.model';
import Etude from '@models/etudes/etude/etude.model';
import {switchMap, take} from 'rxjs/operators';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {Observable, ReplaySubject} from 'rxjs';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import BrowserIntersectionService from '@shared/browser/intersection/browser.intersection.service';
import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {RouterLink} from '@angular/router';
import {AppImagePreviewComponent} from '@shared/image/preview/image-preview.component';
import {
    AppCommuneConvivialNameComponent
} from '@features/communes/commune/convivial-name/commune.convivial-name.component';
import {AppUsersStackedImagesComponent} from '@features/users/stacked-images/users.stacked-images.component';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {TextPhoneComponent} from '@shared/texts/phones/phone/text-phone.component';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [
        AppCommuneConvivialNameComponent,
        AppImagePreviewComponent,
        AppLoaderComponent,
        AppUsersStackedImagesComponent,
        AsyncPipe,
        FaIconComponent,
        NgOptimizedImage,
        RouterLink,
        TextPhoneComponent,
    ],
    selector: 'app-etude-site-card',
    templateUrl: 'etude-site.card.component.html',
})
export class AppEtudeSiteCardComponent {
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _emailEditService = inject(EmailEditService);
    private _etudeFactory = inject(EtudeFactory);
    private _browserIntersectionService = inject(BrowserIntersectionService);
    private _etudeSource = new ReplaySubject<Etude>(1);
    private _etude$ = this._etudeSource.asObservable();
    private _site!: Site;

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    get site(): Site {
        return this._site;
    }

    @Input()
    set site(value: Site) {
        this._site = value;
        this._browserIntersectionService.observe(this._elementRef.nativeElement, () => this._etudeFactory.getByLink$(this._site.linkEtude)
            .pipe(take(1))
            .subscribe(etude => this._etudeSource.next(etude)));
    }

    sendMail(): void {
        this._etude$.pipe(
            switchMap(etude => this._emailEditService.fromEtudeSite$(etude, this.site)),
            take(1),
        ).subscribe();
    }
}
