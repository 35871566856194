import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {Observable, ReplaySubject, Subject, switchMap} from 'rxjs';
import {filter, take, takeUntil, tap} from 'rxjs/operators';
import {CDemandeursFactory} from '@models/demandeurs/collection/demandeurs.collection.factory';
import CDemandeurs from '@models/demandeurs/collection/demandeurs.collection.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {CDemandeursService} from '@models/demandeurs/collection/demandeurs.collection.service';
import {ModalService} from '@shared/modal/modal.service';

@Component({selector: 'dc-demandeur-select', standalone: false, templateUrl: 'demandeur-select.component.html'})
export class DCDemandeurSelectComponent implements IDynamicComponent, OnDestroy, OnInit {
    private _cDemandeursFactory = inject(CDemandeursFactory);
    private _cDemandeursService = inject(CDemandeursService);
    private _modalService = inject(ModalService);
    private _displaySearchLoader = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _cDemandeursSource = new ReplaySubject<CDemandeurs>(1);
    private _cDemandeurs$ = this._cDemandeursSource.asObservable();
    private _pendingGetMore = false;
    private _searchText!: string;
    private _searchSource = new ReplaySubject<string>(1);
    private _search$ = this._searchSource.asObservable();

    get cDemandeurs$(): Observable<CDemandeurs> {
        return this._cDemandeurs$;
    }

    set data(dynamicComponentData: IDynamicComponentData) {
    }

    get displaySearchLoader(): boolean {
        return this._displaySearchLoader;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    get searchText(): string {
        return this._searchText;
    }

    ngOnInit(): void {
        this._search$.pipe(
            tap(_ => this._cDemandeursSource.next(undefined!)),
            filter(search => !!search),
            tap(_ => this._displaySearchLoader = true),
            switchMap(search => this._cDemandeursFactory.get$({keywords: search, limit: 5})),
            takeUntil(this._onDestroy$),
        ).subscribe(cDemandeurs => {
            this._cDemandeursSource.next(cDemandeurs);
            this._displaySearchLoader = false;
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    add(demandeur: Demandeur): void {
        this._modalService.setResponse(demandeur);
    }

    getMore(cDemandeurs: CDemandeurs): void {
        this._pendingGetMore = true;
        this._cDemandeursService.addNext$(cDemandeurs).pipe(take(1)).subscribe({complete: () => this._pendingGetMore = false});
    }

    searchDemandeurs(search: string): void {
        this._searchSource.next(search);
        this._searchText = search;
    }
}
