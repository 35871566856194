import {Component, Input} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {IDemandeurCardOptions} from '@features/demandeurs/demandeurs.interfaces';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';

@Component({selector: 'app-demandeur-card', standalone: false, templateUrl: 'demandeur.card.component.html'})
export class AppDemandeurCardComponent {
    static readonly initDemandeurCardOptions: IDemandeurCardOptions = {};
    private _demandeurSource = new BehaviorSubject<Demandeur>(undefined!);
    private _demandeur$ = this._demandeurSource.asObservable();
    private _options: IDemandeurCardOptions = {...AppDemandeurCardComponent.initDemandeurCardOptions};

    get demandeur$(): Observable<Demandeur> {
        return this._demandeur$;
    }

    @Input()
    set demandeur(value: Demandeur) {
        this._demandeurSource.next(value);
    }

    get options(): IDemandeurCardOptions {
        return this._options;
    }

    @Input()
    set options(value: IDemandeurCardOptions) {
        this._options = {...AppDemandeurCardComponent.initDemandeurCardOptions, ...value};
    }
}
