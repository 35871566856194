import {Component, inject, OnInit} from '@angular/core';
import {Observable, ReplaySubject, take} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {map, tap} from 'rxjs/operators';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {UrlService} from '@shared/texts/url/url.service';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';
import {IDCEstimationOnsaleReferenceData} from '@features/estimations/estimations.interfaces';

@Component({
    selector: 'dc-estimation-onsale-reference-slide-over',
    standalone: false,
    templateUrl: 'estimation.onsale-reference.slide-over.component.html',
})
export class DCEstimationOnsaleReferenceSlideOverComponent implements IDynamicComponent, OnInit {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _urlService = inject(UrlService);
    private _estimationOnsaleReferenceSource = new ReplaySubject<EstimationOnsaleReference>(1);
    private _estimationOnsaleReference$ = this._estimationOnsaleReferenceSource.asObservable();
    private _hostname!: string;
    private _nature!: DictionaryItem;
    private _superficieLabel!: string;

    set data(dynamicComponentData: IDCEstimationOnsaleReferenceData) {
        this._estimationOnsaleReferenceSource.next(dynamicComponentData.estimationOnsaleReference!);
    }

    get estimationOnsaleReference$(): Observable<EstimationOnsaleReference> {
        return this._estimationOnsaleReference$;
    }

    get hostname(): string {
        return this._hostname;
    }

    get nature(): DictionaryItem {
        return this._nature;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._estimationOnsaleReference$.pipe(
            tap(estimationOnsaleReference => this._hostname = this._urlService.getHostname(estimationOnsaleReference.url)),
            map(estimationOnsaleReference => this._dictionaryItemService.getById(Dictionary.names.NATURES, estimationOnsaleReference.natureId)),
            tap(nature => this._nature = nature),
            map(nature => this._dictionaryItemService.getOneSuperficieLabel(nature)),
            take(1),
        ).subscribe(superficieLabel => this._superficieLabel = superficieLabel);
    }
}
