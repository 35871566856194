import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewPerformanceEnergetiqueService
} from '@features/dossiers/dossier/bien/overview/performance-energetique/dossier-bien-overview.performance-energetique.service';

@Component({
    selector: 'app-dossier-bien-overview-performance-energetique',
    standalone: false,
    templateUrl: 'dossier-bien-overview.performance-energetique.component.html',
})
export class AppDossierBienOverviewPerformanceEnergetiqueComponent {
    private _dossierBienOverviewPerformanceEnergetiqueService = inject(DossierBienOverviewPerformanceEnergetiqueService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isPerformanceEnergetiqueVisible(): boolean {
        return this._dossierBienOverviewPerformanceEnergetiqueService.isPerformanceEnergetiqueVisible;
    }
}
