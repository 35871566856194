import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskEstimationsReferences]'})
export class NgEstimationsReferencesDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationsReferences', elementRef, injector);
    }
}

@Component({
    imports: [NgEstimationsReferencesDirective],
    selector: 'app-ng-estimations-references',
    template: '<div appNgEskEstimationsReferences></div>',
})
export class NgEstimationsReferencesComponent {
}
