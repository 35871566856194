import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgContactsWalletCurrent]'})
export class NgContactsWalletCurrentDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskContactsWalletCurrentCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgContactsWalletCurrentDirective],
    selector: 'app-ng-contacts-wallet-current',
    template: '<div appNgContactsWalletCurrent></div>',
})
export class NgContactsWalletCurrentComponent {
}
