import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewDiagnosticsService
} from '@features/dossiers/dossier/bien/overview/diagnostics/dossier-bien-overview.diagnostics.service';

@Component({
    selector: 'app-dossier-bien-overview-diagnostics',
    standalone: false,
    templateUrl: 'dossier-bien-overview.diagnostics.component.html',
})
export class AppDossierBienOverviewDiagnosticsComponent {
    private _dossierBienOverviewDiagnosticsService = inject(DossierBienOverviewDiagnosticsService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isDiagnosticsRealisesVisible(): boolean {
        return this._dossierBienOverviewDiagnosticsService.isDiagnosticsRealisesVisible;
    }

    get isPerformanceEnergetiqueCommentsVisible(): boolean {
        return this._dossierBienOverviewDiagnosticsService.isPerformanceEnergetiqueCommentsVisible;
    }
}
