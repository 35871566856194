import {inject, Injectable} from '@angular/core';
import {ActualiteFactory} from '@models/actualites/actualite/actualite.factory';
import {IActualiteApi} from '@models/actualites/actualite/actualite.interfaces';
import Actualite from '@models/actualites/actualite/actualite.model';

@Injectable({providedIn: 'root'})
export class ActualitesFactory {
    private _actualiteFactory = inject(ActualiteFactory);

    create(actualiteApis: IActualiteApi[] = []): Actualite[] {
        return (actualiteApis || []).filter(actualiteApi => actualiteApi)
            .map(actualiteApi => this._actualiteFactory.create(actualiteApi));
    }
}
