import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {
    IFormFieldInput, IFormRadioInputYnn, IFormRadioOptions, IFormRadioYnnOptions
} from '@shared/form/form.interfaces';
import {NgModel} from '@angular/forms';
import {AppFormRadioComponent} from '@shared/form/radio/form.radio.component';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [AppFormRadioComponent, AppLoaderComponent],
    exportAs: 'formRadioYnn',
    selector: 'app-form-radio-ynn',
    templateUrl: 'form.radio-ynn.component.html',
})
export class AppFormRadioYnnComponent {
    @Output() selected = new EventEmitter<boolean>();
    // Impossible de passer avec la ligne ci-dessous, une erreur dans la console apparait
    // Readonly selected = output<boolean>();
    static readonly initFormRadioYnnOptions: IFormRadioYnnOptions = {
        accord: 'singular',
        genre: 'male',
        name: 'formRadioInputYnn',
    };
    static readonly itemLabelNull = 'Non renseigné';
    static readonly itemNull: IFormRadioInputYnn = {
        code: 'null',
        label: AppFormRadioYnnComponent.itemLabelNull,
        value: null!,
    };
    static readonly list: IFormRadioInputYnn[] = [
        {code: 'true', label: 'Oui', value: true},
        {code: 'false', label: 'Non', value: false},
        AppFormRadioYnnComponent.itemNull,
    ];
    private _formRadioInputYnn!: IFormRadioInputYnn;
    private _formInput!: NgModel;
    private _formRadioOptions: IFormRadioOptions = {name: AppFormRadioYnnComponent.initFormRadioYnnOptions.name};
    private _list = JSON.parse(JSON.stringify(AppFormRadioYnnComponent.list)) as IFormRadioInputYnn[];

    get formInput(): NgModel {
        return this._formInput;
    }

    get formRadioInputYnn(): IFormRadioInputYnn {
        return this._formRadioInputYnn;
    }

    get formRadioOptions(): IFormRadioOptions {
        return this._formRadioOptions;
    }

    @ViewChild('formRadioYnn')
    set formRadioYnn(value: AppFormRadioComponent) {
        setTimeout(_ => this._formInput = value?.formInput, 1);
    }

    get list(): IFormRadioInputYnn[] {
        return this._list;
    }

    @Input()
    set model(value: boolean) {
        setTimeout(() => this.onSelect(this._list.filter(item => item.value === value)[0] || AppFormRadioYnnComponent.itemNull));
    }

    @Input()
    set options(value: IFormRadioYnnOptions) {
        const options = {...AppFormRadioYnnComponent.initFormRadioYnnOptions, ...value} as IFormRadioYnnOptions;
        const itemValueNull = this._list.filter(item => item.value === null)[0];

        itemValueNull.label = AppFormRadioYnnComponent.itemLabelNull;
        if (options.genre === 'female') {
            itemValueNull.label += 'e';
        }

        if (options.accord === 'plural') {
            itemValueNull.label += 's';
        }

        this._formRadioOptions = {name: options.name};
    }

    onSelect(formFieldInput: IFormFieldInput): void {
        this._formRadioInputYnn = formFieldInput as IFormRadioInputYnn;
        this.selected.emit(this._formRadioInputYnn.value);
    }
}
