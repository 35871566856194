import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager, NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {
    NgDemandeurManager, NgEmailManager, NgFilterManager, NgMediaManager, NgReportManager
} from '@legacy/app/managers/managers';
import {NgEmail, NgEmailDossier, NgFilter, NgReport} from '@legacy/app/managers/ressources';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {ReportFactory} from '@models/reports/report/report.factory';
import {EmailFactory} from '@models/emails/email/email.factory';
import Filter from '@models/filters/filter/filter.model';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {take} from 'rxjs/operators';
import {ReportsService} from '@models/reports/reports.service';
import {firstValueFrom} from 'rxjs';

export default function getEskDossierSuivi(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-dossier-suivi></esk-dossier-suivi>
         */
        module.component('eskDossierSuivi', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/dossier/suivi.html',
        });

        /**
         * Dossier suivi
         *
         * @param BienDossierCurrent
         * @param DemandeurManager
         * @param Ng2RouterStateForNgService
         * @param FilterManager
         * @param ReportManager
         * @param SoqModalesManager
         * @param EmailManager
         * @param $translate
         * @param SoqSweetAlert
         * @param Ng2ReportFactory
         * @param Ng2EmailFactory
         * @param Ng2ReportsService
         * @param MediaManager
         */
        Controller.$inject = ['BienDossierCurrent', 'DemandeurManager', 'Ng2RouterStateForNgService', 'FilterManager', 'ReportManager', 'SoqModalesManager', 'EmailManager', '$translate', 'SoqSweetAlert', 'Ng2ReportFactory', 'Ng2EmailFactory', 'Ng2ReportsService', 'MediaManager'];
        function Controller(this: any,
                            bienDossierCurrent: NgBienDossierCurrent,
                            demandeurManager: NgDemandeurManager,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            filterManager: NgFilterManager,
                            reportManager: NgReportManager,
                            soqModalesManager: NgSoqModalesManager,
                            emailManager: NgEmailManager,
                            $translate: angular.translate.ITranslateService,
                            soqSweetAlert: NgSoqSweetAlert,
                            ng2ReportFactory: ReportFactory,
                            ng2EmailFactory: EmailFactory,
                            ng2ReportsService: ReportsService,
                            mediaManager: NgMediaManager) {
            const $ctrl = this;
            var tabId;

            $ctrl.ng2RouterStateForNgService = ng2RouterStateForNgService;
            $ctrl.$onDestroy = $onDestroy;
            $ctrl.$onInit = $onInit;
            $ctrl.onChangeTab = onChangeTab;
            $ctrl.addReport = addReport;
            $ctrl.onSelectReport = onSelectReport;
            $ctrl.sendReports = sendReports;
            $ctrl.printReports = printReports;
            $ctrl.onSelectEmail = onSelectEmail;
            $ctrl.sendEmail = sendEmail;
            $ctrl.tabs = [
                {id: 'reports', value: 'Comptes-rendus'},
                {id: 'emails', value: 'Emails'}
            ];
            $ctrl.loader = {reports: {}, emails: {}};
            // @todo Utiliser DossierService.getTypeFromUrl()
            if ($ctrl.ng2RouterStateForNgService.current.name!.startsWith('/app/demandeurs')) {
                $ctrl.dossier = demandeurManager.current;
            } else {
                $ctrl.dossier = bienDossierCurrent.get();
            }

            tabId = ng2RouterStateForNgService.current.name!.split('/')[5];

            if (!angular.isString(tabId) || tabId === '') tabId = $ctrl.tabs[0].id;

            let currentUrl!: string;
            const interval = setInterval(() => {
                if (currentUrl !== ng2RouterStateForNgService.current.url) {
                    const splittedCurrentUrl = currentUrl.split('/');
                    const idCurrentUrl = +splittedCurrentUrl[splittedCurrentUrl.length - 1];

                    currentUrl = ng2RouterStateForNgService.current.url;
                    if (!idCurrentUrl) {
                        return;
                    }

                    const splittedNewUrl = ng2RouterStateForNgService.current.url.split('/');
                    const idNewUrl = +splittedNewUrl[splittedNewUrl.length - 1];

                    if (idCurrentUrl > 0 && idNewUrl > 0) {
                        return;
                    }

                    $ctrl.$onInit();
                }
            }, 500);

            function $onInit() {
                currentUrl = ng2RouterStateForNgService.current.url;
                $ctrl.onChangeTab($ctrl.tabs.find(tab => tab.id === tabId));
            }

            function $onDestroy() {
                clearInterval(interval);
            }

            /**
             * Change current tab
             *
             * @param tab
             */
            function onChangeTab(tab: { id: unknown }) {
                $ctrl.currentTab = tab;
                if ($ctrl.currentTab.id === 'reports') {
                    getReports();
                } else if ($ctrl.currentTab.id === 'emails') {
                    getEmails();
                }
            }

            /**
             * Open a modal to create a new report
             */
            function addReport() {
                soqModalesManager.open('EskModalReport', {
                    resolve: {
                        report: function () {
                            return reportManager.create({
                                dossiers: [{
                                    type: $ctrl.dossier._esk.typeDossier,
                                    typeId: $ctrl.dossier.id,
                                    dossier: $ctrl.dossier
                                }]
                            });
                        },
                        currentTab: function () {
                            return 'general';
                        },
                        dossier: $ctrl.dossier
                    },
                    backdrop: 'static'
                }).then(getReports);
            }

            /**
             * Go to selected report
             *
             * @param report
             */
            function onSelectReport(report: NgReport) {
                ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/suivi/reports/' + report.id.toString());
            }

            /**
             * Send reports list
             */
            function sendReports() {
                if ($ctrl.reports.length <= 0) {
                    soqSweetAlert.warningMessage($translate.instant('global.title.SEND', {data: 'report'}), $translate.instant('global.NO_ONE_SELECTED', {action: 'send'}) + '<br>' + $translate.instant('global.init.ENTERING', {data: 'report'}));

                    return;
                }

                const reports = $ctrl.reports.map(ngReport => ng2ReportFactory.ngCreate(ngReport));

                $ctrl.loader.reports.operating = 'SEND';
                firstValueFrom(ng2ReportsService.getMedia$(reports)).then(
                    media => mediaManager.createFromNg2(media)
                ).then(media => openEmailModale({
                    attachments: [{media}],
                    subject: $translate.instant('mail.reports.TITLE'),
                    message: $translate.instant('mail.reports.MESSAGE'),
                } as NgEmail)).finally(() => $ctrl.loader.reports.operating = undefined!);
            }

            /**
             * Print reports list
             */
            function printReports() {
                if ($ctrl.reports.length <= 0) {
                    soqSweetAlert.warningMessage($translate.instant('global.title.PRINT', {data: 'report'}), $translate.instant('global.NO_ONE_SELECTED', {action: 'print'}) + '<br>' + $translate.instant('global.init.ENTERING', {data: 'report'}));

                    return;
                }

                const reports = $ctrl.reports.map(ngReport => ng2ReportFactory.ngCreate(ngReport));

                $ctrl.loader.reports.operating = 'PRINT';
                ng2ReportsService.read$(reports).pipe(take(1)).subscribe(() => $ctrl.loader.reports.operating = undefined);
            }

            /**
             * Go to selected emails
             *
             * @param email
             */
            function onSelectEmail(email: NgEmail) {
                ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.dossier._esk.typeDossier + 's/' + $ctrl.dossier.id.toString() + '/suivi/emails/' + email.id.toString());
            }

            /**
             * Send reports list
             */
            function sendEmail() {
                $ctrl.loader.emails.operating = 'SEND';
                openEmailModale({} as NgEmail).then(getEmails).finally(function () {
                    $ctrl.loader.emails.operating = undefined;
                });
            }

            /**
             * Get reports
             */
            function getReports() {
                $ctrl.loader.reports.getting = true;
                $ctrl.reports = [];
                filterManager.getActiveByRouteCode(Filter.routes.GET_ETUDE_REPORTS, Filter.codes.WALLET_CURRENT).then(
                    filter => filter as NgFilter
                ).then(filter => {
                    switch ($ctrl.dossier._esk.typeDossier) {
                        case DossierTypesConst.DEMANDEUR :
                            filter.queryParameters.dossiersDemandeur.values = [$ctrl.dossier.id];
                            break;
                        case DossierTypesConst.VENTE :
                            filter.queryParameters.dossiersVente.values = [$ctrl.dossier.id];
                            break;
                        case DossierTypesConst.LOCATION :
                            filter.queryParameters.dossiersLocation.values = [$ctrl.dossier.id];
                            break;
                        case DossierTypesConst.ESTIMATION :
                            filter.queryParameters.dossiersEstimation.values = [$ctrl.dossier.id];
                            break;
                    }

                    return reportManager.getAllEtudeFiltered(filter).then(function (reports) {
                        angular.forEach(reports.collection, reportData => {
                            $ctrl.reports.push(ng2ReportFactory.ngCreate(reportData));
                        });
                    });
                }).finally(function () {
                    $ctrl.loader.reports.getting = false;
                });
            }

            /**
             * Get emails
             */
            function getEmails() {
                $ctrl.loader.emails.getting = true;
                $ctrl.emails = [];
                emailManager.getFromDossier($ctrl.dossier).then(function (emails) {
                    angular.forEach(emails.collection, emailData => $ctrl.emails.push(ng2EmailFactory.ngCreate(emailData)));
                }).finally(() => $ctrl.loader.emails.getting = false);
            }

            /**
             * Open email modale
             *
             * @param dataEmail
             * @returns {*}
             */
            function openEmailModale(dataEmail: NgEmail) {
                if (!angular.isString(dataEmail.message)) dataEmail.message = "";

                dataEmail.recipients = $ctrl.dossier._esk.typeDossier === DossierTypesConst.DEMANDEUR ? $ctrl.dossier.contactsGroup.getRecipients() : $ctrl.dossier.bien.contactsGroup.getRecipients();
                dataEmail.dossiers = [{
                    typeId: $ctrl.dossier.id,
                    type: $ctrl.dossier._esk.typeDossier,
                    dossier: $ctrl.dossier,
                } as unknown as NgEmailDossier];
                dataEmail.message += "<br><br>" + emailManager.getCurrentUserSignature();

                return soqModalesManager.open('EskModalEmail', {
                    resolve: {email: emailManager.create(dataEmail), currentDossier: $ctrl.dossier},
                });
            }
        }
    })(angularJS);
}
