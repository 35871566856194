@let isOnLine = isOnLine$ | async;

@if (currentEtude$ | async; as currentEtude) {
  <div appProvideParentForm>
    <div class="row">
      <!-- Responsable de dossier -->
      <div class="col-xs-12 col-sm-6 col-md-3">
        @if (isOnLine) {
          <div class="form-group" [appFormError]="bienResponsableDossier.formInput">
            <label>Responsable dossier</label>

            <app-users-select #bienResponsableDossier="usersSelect" [etudes]="[currentEtude]"
                              [options]="{name: 'responsableDossier', required: true}"
                              (selected)="onResponsableDossierSelected($event)" [user]="responsableDossier"/>
          </div>
        } @else {
          <div class="form-group">
            <label>Responsable dossier</label>

            <p class="form-control-static">{{responsableDossier.convivialName}}</p>

            <app-browser-online-indicator/>
          </div>
        }
      </div>

      <!-- Notaire responsable -->
      <div class="col-xs-12 col-sm-6 col-md-3">
        <div class="form-group">
          <label>Notaire</label>

          @if (isOnLine) {
            <app-users-select [etudes]="[currentEtude]" [roles]="[roleNotaire]" (selected)="onNotaireSelected($event)"
                              [user]="notaire"/>
          } @else {
            <p class="form-control-static">{{notaire ? notaire.convivialName : 'Aucun notaire saisi'}}</p>
          }

          <app-browser-online-indicator/>
        </div>
      </div>

      <!-- Cause de la vente -->
      @if (showFieldForBien(FIELDS.causeVente)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label for="bien-cause-vente">Cause de la vente</label>

          <input type="text" id="bien-cause-vente" class="form-control"
                 [(ngModel)]="ngBienDossier.bien.option.causeVente" maxlength="255" name="{{FIELDS.causeVente}}">
        </div>
      }

      <!-- Motif estimation -->
      @if (showFieldForBien(FIELDS.motif)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Motif</label>

          <app-dictionary-select-items [model]="motif" [name]="ESTIMATION_MOTIFS" [options]="{name: 'motif'}"
                                       (selected)="onMotifSelected($event)"/>
        </div>
      }
    </div>

    <div class="row">
      <!-- Référence interne -->
      <div class="form-group col-xs-12 col-sm-6 col-md-3">
        <label for="bien-reference-interne">Référence interne</label>

        <input type="text" id="bien-reference-interne" class="form-control" name="referenceInterne"
               [(ngModel)]="ngBienDossier.bien.referenceInterne">
      </div>

      <!-- Référence publique -->
      @if (showFieldForBien(FIELDS.referencePublique)) {
        <div class="col-xs-12 col-sm-6 col-md-3 ref-public">
          <div class="form-group" [appFormError]="referencePublique">
            <label for="bien-reference-publique">Référence publique</label>

            <soq-popover-template [display]="'default'" [placement]="'right'" [trigger]="'outsideClick'"
                                  [template]="'src/app/legacy/templates/biens/controllers/item/edit/reference-publique.help.html'"/>

            <input type="text" id="bien-reference-publique" class="form-control" name="{{FIELDS.referencePublique}}"
                   #referencePublique="ngModel" pattern="[A-Za-z0-9_/.-]+$"
                   [(ngModel)]="ngVente.referencePublique">
          </div>
        </div>
      }

      <!-- Référence Noty -->
      @if (!!ngBienDossier.bien.reference) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Référence Noty</label> <p class="form-control-static">{{ngBienDossier.bien.reference}}</p>
        </div>
      }

      <!-- Ancienne référence -->
      @if (!!ngBienDossier.bien.ancienneReference) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label>Ancienne référence</label> <p class="form-control-static">{{ngBienDossier.bien.ancienneReference}}</p>
        </div>
      }
    </div>

    <div class="row">
      <!-- Visite virtuelle -->
      @if (!currentEtude.previsite) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label for="bien-virtual-visite">URL visite virtuelle</label>

          @if (!!ngBienDossier.bien.option.urlVisiteVirtuelle) {
            <a target="_blank" class="margin-left-5" [href]="ngBienDossier.bien.getSanitizeUrlVisiteVirtuelle()">
              <fa-icon [icon]="'external-link-alt'"/>
            </a>
          }

          <input type="text" [(ngModel)]="ngBienDossier.bien.option.urlVisiteVirtuelle" id="bien-virtual-visite"
                 class="form-control" name="urlVisiteVirtuelle">
        </div>
      }

      <!-- Clés pour visite -->
      <div class="form-group col-xs-12 col-sm-6 col-md-3">
        <label for="bien-cle-visite">Clés pour visite</label>

        <input type="text" [(ngModel)]="ngBienDossier.bien.metadata.confreres.clesVisite" id="bien-cle-visite"
               class="form-control" name="clesVisite">
      </div>

      <!-- Numéro de clé -->
      <div class="form-group col-xs-12 col-sm-6 col-md-3">
        <label for="bien-num-cle">N° de clés</label>

        <input type="text" [(ngModel)]="ngBienDossier.bien.metadata.confidentielles.numeroCle" id="bien-num-cle"
               class="form-control" name="numeroCle">
      </div>

      <!-- Panneaux -->
      @if (showFieldForBien(FIELDS.panneaux)) {
        <div class="form-group col-xs-12 col-sm-6 col-md-3">
          <label for="bien-panneaux">Panneaux</label>

          <input type="text" [(ngModel)]="ngBienDossier.bien.metadata.confidentielles.panneaux" id="bien-panneaux"
                 class="form-control" name="panneaux">
        </div>
      }
    </div>

    <!-- Commentaires pour les confrères -->
    @if (showFieldForBien(FIELDS.commentsConfreres) && currentEtude.hasNetwork()) {
      <div class="form-group">
        <label for="bien-comments-confreres">Renseignements destinés aux confrères</label>

        <soq-popover-text [text]="'Ces informations ne sont visibles que par vos confrères et à l\'impression de la fiche confidentielle.'"
                          [display]="'default'" [placement]="'right'" [trigger]="'outsideClick'"/>

        <textarea id="bien-comments-confreres" class="form-control" name="confreresComments"
                  [(ngModel)]="ngBienDossier.bien.metadata.confreres.comments" [minHeight]="100"></textarea>
      </div>
    }

    <!-- Commentaires confidentiels -->
    <div class="form-group">
      <label for="bien-comments-confidentiels">Renseignements confidentiels</label>

      <soq-popover-text [text]="'Ces informations ne sont visibles que par votre étude et à l\'impression de la fiche confidentielle.'"
                        [display]="'default'" [placement]="'right'" [trigger]="'outsideClick'"/>

      <textarea id="bien-comments-confidentiels" class="form-control" name="confidentiellesComments"
                [(ngModel)]="ngBienDossier.bien.metadata.confidentielles.comments" [minHeight]="100"></textarea>
    </div>
  </div>
}
