import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, UrlTree} from '@angular/router';
import {UserService} from '@models/users/user/user.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GuardsService} from '@core/routing/guards/guards.service';

// @todo À ranger dans core/models/users/user
@Injectable({providedIn: 'root'})
export class HasRolesGuard {
    private _guardsService = inject(GuardsService);
    private _userService = inject(UserService);

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const roles = activatedRouteSnapshot.data?.roles as string[];

        if (!roles) {
            return of(this._guardsService.redirect(activatedRouteSnapshot));
        }

        if (roles.length <= 0) {
            return of(true);
        }

        return this._userService.last$.pipe(
            map(currentUser => currentUser.hasSomeRole(roles)),
            map(hasRole => hasRole ? true : this._guardsService.redirect(activatedRouteSnapshot)),
        );
    }
}
