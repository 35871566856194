import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import Quartier from '@models/quartiers/quartier/quartier.model';
import {switchMap, take, tap} from 'rxjs/operators';
import {IFormFieldCheckbox} from '@shared/form/form.interfaces';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import CArrondissements from '@models/arrondissements/collection/arrondissements.collection.model';
import Commune from '@models/communes/commune/commune.model';
import {CArrondissementsService} from '@models/arrondissements/collection/arrondissements.collection.service';
import Arrondissement from '@models/arrondissements/arrondissement/arrondissement.model';

@Component({selector: 'app-quartiers-selection', standalone: false, templateUrl: 'quartiers-selection.component.html'})
export class AppQuartiersSelectionComponent {
    @Output() selected = new EventEmitter<Quartier[]>();
    // Impossible de passer avec la ligne ci-dessous, une erreur dans la console apparait
    // Readonly selected = output<Quartier[]>();

    static readonly initQuartiersSelectionOptions = {name: 'quartiersSelection'};
    private _cArrondissementsService = inject(CArrondissementsService);
    private _cArrondissementsSource = new ReplaySubject<CArrondissements>(1);
    private _checkedArrondissements = new Map<string, Arrondissement>();
    private _indeterminateArrondissements = new Map<string, Arrondissement>();
    private _mapSelectedQuartiers = new Map<string, Quartier>();
    private _modelSource = new BehaviorSubject<void>(undefined);
    private _cArrondissements$ = this._modelSource.asObservable().pipe(
        switchMap(_ => this._cArrondissementsSource.asObservable()),
        tap(_ => this._checkedArrondissements.clear()),
        tap(_ => this._indeterminateArrondissements.clear()),
        tap(cArrondissements => this.setArrondissementsStatus(cArrondissements)),
    );
    private _options = {...AppQuartiersSelectionComponent.initQuartiersSelectionOptions};
    private _selectedQuartiers: Quartier[] = [];

    get cArrondissements$(): Observable<CArrondissements> {
        return this._cArrondissements$;
    }

    get checkedArrondissements(): Map<string, Arrondissement> {
        return this._checkedArrondissements;
    }

    @Input()
    set commune(value: Commune) {
        this._cArrondissementsSource.next(undefined!);
        this._cArrondissementsService.getOne$(value).pipe(take(1))
            .subscribe(cArrondissements => this._cArrondissementsSource.next(cArrondissements));
    }

    get indeterminateArrondissements(): Map<string, Arrondissement> {
        return this._indeterminateArrondissements;
    }

    @Input()
    set model(quartiers: Quartier[]) {
        this._mapSelectedQuartiers.clear();
        (quartiers || []).forEach(quartier => this._mapSelectedQuartiers.set(quartier.uuid, quartier));
        this._selectedQuartiers = Array.from(this._mapSelectedQuartiers.values());
        this._modelSource.next();
    }

    get options(): { name: string } {
        return this._options;
    }

    get selectedQuartiers(): Quartier[] {
        return this._selectedQuartiers;
    }

    onChangeQuartiers(arrondissement: Arrondissement, rawSelectedQuartiers: IFormFieldCheckbox[] = []): void {
        const arrondissementSelectedQuartiers = rawSelectedQuartiers.filter(data => data) as Quartier[];
        const arrondissementSelectedQuartiersIds = arrondissementSelectedQuartiers.map(arrondissementSelectedQuartier => arrondissementSelectedQuartier.id);

        this.setCheckedArrondissements(arrondissement, arrondissementSelectedQuartiers);
        this.setIndeterminateArrondissements(arrondissement, arrondissementSelectedQuartiers);
        arrondissement.quartiers.forEach(arrondissementQuartier => {
            if (arrondissementSelectedQuartiersIds.includes(arrondissementQuartier.id)) {
                this._mapSelectedQuartiers.set(arrondissementQuartier.uuid, arrondissementQuartier);
            } else {
                this._mapSelectedQuartiers.delete(arrondissementQuartier.uuid);
            }
        });
        this._selectedQuartiers = Array.from(this._mapSelectedQuartiers.values());
        this.selected.emit(this.selectedQuartiers);
    }

    setArrondissementsStatus(cArrondissements: CArrondissements): void {
        cArrondissements?.results.forEach(arrondissement => {
            const arrondissementQuartiersIds = arrondissement.quartiers.map(arrondissementQuartier => arrondissementQuartier.id);
            const arrondissementSelectedQuartiers = this.selectedQuartiers.filter(selectedQuartier => arrondissementQuartiersIds.includes(selectedQuartier.id));

            this.setCheckedArrondissements(arrondissement, arrondissementSelectedQuartiers);
            this.setIndeterminateArrondissements(arrondissement, arrondissementSelectedQuartiers);
        });
    }

    setCheckedArrondissements(arrondissement: Arrondissement, arrondissementSelectedQuartiers: Quartier[] = []): void {
        if (arrondissementSelectedQuartiers.length === arrondissement.quartiers.length) {
            this._checkedArrondissements.set(arrondissement.uuid, arrondissement);
        } else {
            this._checkedArrondissements.delete(arrondissement.uuid);
        }
    }

    setIndeterminateArrondissements(arrondissement: Arrondissement, arrondissementSelectedQuartiers: Quartier[] = []): void {
        if (0 < arrondissementSelectedQuartiers.length && arrondissementSelectedQuartiers.length < arrondissement.quartiers.length) {
            this._indeterminateArrondissements.set(arrondissement.uuid, arrondissement);
        } else {
            this._indeterminateArrondissements.delete(arrondissement.uuid);
        }
    }
}
