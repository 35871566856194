import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import environment from '@env/environment';
import {BrowserWindowService} from '@shared/browser/window/browser.window.service';

@Injectable({providedIn: 'root'})
export class VirtualVisitService {
    private _browserWindowService = inject(BrowserWindowService);

    open$(crpcen: string, reference: string): Observable<Window> {
        const windowName = reference + ' : Visite virtuelle';
        let userPrevisite = crpcen;
        let referencePrevisite = reference;

        if (environment.previsite!.prefix) {
            userPrevisite = environment.previsite!.prefix + ':' + crpcen;
            referencePrevisite = environment.previsite!.prefix + ':' + reference;
        }

        return this._browserWindowService.openFromOrigin$('/assets/previsite.html', {
            partner: environment.previsite!.partnerToken,
            ref: referencePrevisite,
            url: environment.previsite!.url,
            user: userPrevisite,
        }, windowName);
    }
}
