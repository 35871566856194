import Collection from '@models/collection/collection.model';
import VenteMatching from '@models/ventes/vente/matchings/matching/vente-matching.model';

export default class CVenteMatchings extends Collection<VenteMatching> {
    add(venteMatching: VenteMatching, firstPosition = false): void {
        if (firstPosition) {
            this.results.unshift(venteMatching);
        } else {
            this.results.push(venteMatching);
        }

        this.total++;
    }

    getByLinkEtude(linkEtude: string): VenteMatching {
        return this.results.find(venteMatching => venteMatching.hasLinkEtude(linkEtude))!;
    }

    hasOnlyOneByLinkEtude(linkEtude: string): boolean {
        return !!this.getByLinkEtude(linkEtude) && this.total === 1;
    }
}
