import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgBiensWalletDiffusion]'})
export class NgBiensWalletDiffusionDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDossierBiensWalletDiffusionCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgBiensWalletDiffusionDirective],
    selector: 'app-ng-biens-wallet-diffusion',
    template: '<div appNgBiensWalletDiffusion></div>',
})
export class NgBiensWalletDiffusionComponent {
}
