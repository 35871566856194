import {Component, inject, Input, OnInit} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {NgVente} from '@legacy/app/managers/ressources';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import VentePrice from '@models/ventes/vente/price/vente-price.model';
import {TextCurrencyPipe} from '@shared/texts/currency/text-currency.pipe';
import {IVentePriceOptions} from '@features/ventes/ventes.interfaces';

@Component({
    providers: [TextCurrencyPipe],
    selector: 'app-vente-price',
    standalone: false,
    templateUrl: 'vente-price.component.html',
})
export class AppVentePriceComponent implements OnInit {
    static readonly initVentePriceOptions: IVentePriceOptions = {
        enabledNV: true,
        enabledPriceM2: true,
        inlineNV: false,
    };
    private _dictionaryItemService = inject(DictionaryItemService);
    private _textCurrencyPipe = inject(TextCurrencyPipe);
    private _venteFactory = inject(VenteFactory);
    private _ventePriceFactory = inject(VentePriceFactory);
    private _options = {...AppVentePriceComponent.initVentePriceOptions};
    private _priceHNITitle!: string;
    private _priceNVTitle!: string;
    private _vente!: Vente;
    private _ventePrice!: VentePrice;

    @Input()
    set ngVente(value: NgVente) {
        this._vente = this._venteFactory.ngCreate(value);
    }

    get options(): IVentePriceOptions {
        return this._options;
    }

    @Input()
    set options(value: IVentePriceOptions) {
        this._options = {...AppVentePriceComponent.initVentePriceOptions, ...value};
    }

    get priceHNITitle(): string {
        return this._priceHNITitle;
    }

    get priceNVTitle(): string {
        return this._priceNVTitle;
    }

    @Input()
    set vente(value: Vente) {
        this._vente = value;
    }

    get vente(): Vente {
        return this._vente;
    }

    get ventePrice(): VentePrice {
        return this._ventePrice;
    }

    ngOnInit(): void {
        const dicItemPriceHNI = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_PRIX_CHARGES_TYPES, Vente.chargesType.HONO_NEGO_INCLUS);
        const dicItemPriceNV = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_PRIX_CHARGES_TYPES, Vente.chargesType.NET_VENDEUR);
        let labelPrice = this.vente.labelPrice.replace('la', '').replace('le', '').trim();

        // @todo Est-ce que ce ne serait pas mieux d'avoir des champs directement dans Vente ?
        this._ventePrice = this._ventePriceFactory.createFromVente(this.vente);
        labelPrice = labelPrice[0].toUpperCase() + labelPrice.slice(1);
        this._priceHNITitle = labelPrice + ' ' + dicItemPriceHNI.shortLabel.toLowerCase();
        this._priceNVTitle = labelPrice + ' ' + dicItemPriceNV.shortLabel.toLowerCase();
        if (!this.vente.isInteractive() && this.ventePrice.isTypeChargesNV()) {
            this._priceHNITitle += '\n' + labelPrice + ' ' + dicItemPriceNV.shortLabel.toLowerCase() + ' : ' + this._textCurrencyPipe.transform(this.ventePrice.priceNV);
        }

        const priceM2 = this._venteFactory.getPriceM2(this.vente);

        if (priceM2 > 0 && this.options.enabledPriceM2) {
            this._priceHNITitle += '\nSoit ' + this._textCurrencyPipe.transform(priceM2) + ' du m²';
            this._priceNVTitle += '\nSoit ' + this._textCurrencyPipe.transform(priceM2) + ' du m²';
        }
    }
}
