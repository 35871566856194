import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {takeUntil} from 'rxjs/operators';
import {OffreachatDropdownComponent} from '@features/offreachats/item/dropdown/offreachat.dropdown.component';
import {
    IOffreachatActionsMainData, IOffreachatActionsMainDataOptions, IOffreachatDropdownDataOptions
} from '@features/offreachats/offreachats.interfaces';

@Component({
    selector: 'offreachat-resume-actions-main',
    standalone: false,
    templateUrl: 'offreachat-resume.actions-main.component.html',
})
export class OffreachatResumeActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = OffreachatDropdownComponent.actions;
    private _callToActionService = inject(CallToActionService);
    private _dropdownService = inject(DropdownService);
    private _offreachat!: Offreachat;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IOffreachatActionsMainDataOptions = {};

    set data(value: IOffreachatActionsMainData) {
        this._offreachat = value.offreachat;
        this._options = value.options!;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        const options: IOffreachatDropdownDataOptions = {enabledActions: {print: true}};

        this._dropdownService.open(htmlButtonElement, {
            component: OffreachatDropdownComponent,
            data: {offreachat: this._offreachat, options},
        });
    }

    send(): void {
        this._callToActionService.clicked(OffreachatResumeActionsMainComponent.actions.SEND, this._offreachat);
    }
}
