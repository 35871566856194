import {NgModule, Type} from '@angular/core';
import {NgDemandeurResultsComponent} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results.component';
import {
    NgDemandeurEditCriteriaComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-edit-criteria.component';
import {
    NgDemandeurEditGeneralComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-edit-general.component';
import {NgDemandeurLifetimeComponent} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-lifetime.component';
import {
    NgDemandeurResultsDetailsComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results-details.component';
import {
    NgDemandeurResultsHistoricalComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results-historical.component';
import {
    NgDemandeursWalletCurrentComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-wallet-current.component';

const components: Type<unknown>[] = [
    NgDemandeurEditCriteriaComponent,
    NgDemandeurEditGeneralComponent,
    NgDemandeurLifetimeComponent,
    NgDemandeurResultsComponent,
    NgDemandeurResultsDetailsComponent,
    NgDemandeurResultsHistoricalComponent,
    NgDemandeursWalletCurrentComponent,
];

@NgModule({exports: components, imports: components})
export class DemandeursComponentsModule {
}
