import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {IFormRangeSliderInput, IFormRangeSliderOptions} from '@shared/form/form.interfaces';
import {NgxSliderModule} from '@angular-slider/ngx-slider';

@Component({
    /* eslint-disable */
    encapsulation: ViewEncapsulation.None,
    /* eslint-enable */
    imports: [NgxSliderModule],
    selector: 'app-form-range-slider',
    styleUrls: ['form.range-slider.component.scss'],
    templateUrl: 'form.range-slider.component.html',
})
export class AppFormRangeSliderComponent implements OnInit {
    @Output() rangeUpdated = new EventEmitter<IFormRangeSliderInput>();
    // Impossible de passer avec la ligne ci-dessous, une erreur dans la console apparait
    // Readonly rangeUpdated = output<IFormRangeSliderInput>();

    static readonly initFormRangeSliderOptions: IFormRangeSliderOptions = {bornesNull: false};
    private _highValue!: number;
    private _initOptions = {
        ...AppFormRangeSliderComponent.initFormRangeSliderOptions,
        ...{
            translate: (value: number): string => {
                if (value === this._options.floor) {
                    return '';
                } else if (value === this._options.ceil) {
                    return value.toString() + ' et +';
                }

                return value.toString();
            },
        },
    };
    private _options: IFormRangeSliderOptions = {...this._initOptions};
    private _range!: IFormRangeSliderInput;
    private _value!: number;

    get highValue(): number {
        return this._highValue;
    }

    set highValue(value: number) {
        this._highValue = value;
    }

    get options(): IFormRangeSliderOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormRangeSliderOptions) {
        this._options = {...this._initOptions, ...value};
    }

    @Input()
    set range(value: IFormRangeSliderInput) {
        this._range = value;
    }

    get value(): number {
        return this._value;
    }

    set value(value: number) {
        this._value = value;
    }

    ngOnInit(): void {
        this._highValue = this._range?.max ?? this.options.ceil ?? 0;
        this._value = this._range?.min ?? this.options.floor ?? 0;
    }

    onChange(): void {
        let max = this.highValue;
        let min = this.value;

        if (this.options.bornesNull) {
            if (this.value === this.options.floor) {
                min = null!;
            }

            if (this.highValue === this.options.ceil) {
                max = null!;
            }
        }

        this.rangeUpdated.emit({min, max});
    }
}
