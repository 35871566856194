import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgBiensLastCriteria]'})
export class NgBiensLastCriteriaDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDossierBiensLastCriteriaCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgBiensLastCriteriaDirective],
    selector: 'app-ng-biens-last-criteria',
    template: '<div appNgBiensLastCriteria></div>',
})
export class NgBiensLastCriteriaComponent {
}
