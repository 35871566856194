import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';

@Component({
    selector: 'configuration-actions-main-dropdown',
    standalone: false,
    templateUrl: 'configuration-actions-main.dropdown.component.html',
})
export class ConfigurationActionsMainDropdownComponent implements IDynamicComponent {
    static readonly actions = {
        ETUDE_PROFIL: 'etude_profil',
        SAVE: 'save',
    };
    private _dropdownService = inject(DropdownService);
    private _value!: IDynamicComponent;

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    etudeProfil(): void {
        this._dropdownService.clicked(ConfigurationActionsMainDropdownComponent.actions.ETUDE_PROFIL);
    }
}
