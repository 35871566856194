import {Component, inject, Input} from '@angular/core';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

@Component({selector: 'app-demandeur-statut', standalone: false, templateUrl: 'demandeur.statut.component.html'})
export class AppDemandeurStatutComponent {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _demandeur!: Demandeur;
    private _statut!: DictionaryItem;

    get demandeur(): Demandeur {
        return this._demandeur;
    }

    @Input()
    set demandeur(value: Demandeur) {
        this._demandeur = value;
        this._statut = this._dictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_STATUTS, this.demandeur.statut);
    }

    get statut(): DictionaryItem {
        return this._statut;
    }
}
