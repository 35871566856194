import {
    ChangeDetectionStrategy, Component, inject, Input, OnInit, output, Signal, signal, ViewChild
} from '@angular/core';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {NgItemDictionary} from '@legacy/app/managers/ressources';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import {DictionarySelected, IDictionarySelectOptions} from '@features/dictionaries/dictionaries.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'dictionarySelect',
    // @todo Supprimer l'import de *Module pour préférer seulement *Component quand ceux-ci seront standalone
    imports: [AppFormSelectInputComponent],
    selector: 'app-dictionary-select-items',
    templateUrl: 'dictionary-select-items.component.html',
})
export class AppDictionarySelectItemsComponent implements OnInit {
    static readonly initDictionarySelectOptions: IDictionarySelectOptions = {
        acceptNoResult: false,
        name: 'dictionarySelect',
        notFoundText: 'Aucun élément',
    };
    readonly selected = output<DictionarySelected>();
    private _dictionaryFactory = inject(DictionaryFactory);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dictionarySelected = signal<DictionarySelected>(undefined!);
    private _formInput!: NgModel;
    private _id!: number;
    private _initialList: DictionaryItem[] = [];
    private _list: DictionaryItem[] = [];
    private _name!: string;
    private _options: IDictionarySelectOptions = {...AppDictionarySelectItemsComponent.initDictionarySelectOptions};

    get dictionarySelected(): Signal<DictionarySelected> {
        return this._dictionarySelected;
    }

    @ViewChild('dictionarySelectInput')
    set dictionarySelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    @Input()
    set id(value: number) {
        this._id = value;
    }

    get list(): DictionaryItem[] {
        return this._list;
    }

    @Input()
    set model(value: DictionarySelected) {
        // @todo Faire dans le OnInit afin de pouvoir accepter `string` en type de value
        // @todo Supprimer les deprecated
        this._dictionarySelected.set(value);
    }

    @Input({required: true})
    set name(value: string) {
        this._name = value;
    }

    /**
     * @deprecated, utiliser set model()
     */
    @Input()
    set ngItem(value: NgItemDictionary) {
        if (value) {
            this.model = this._dictionaryItemFactory.ngCreate(value);
        } else {
            this.model = undefined!;
        }
    }

    get options(): IDictionarySelectOptions {
        return this._options;
    }

    @Input()
    set options(value: IDictionarySelectOptions) {
        this._options = {...AppDictionarySelectItemsComponent.initDictionarySelectOptions, ...value};
    }

    ngOnInit(): void {
        this._initialList = this._dictionaryFactory.getByName(this._name).items;
        this._list = [...this._initialList];
        if (!this._dictionarySelected() && this._id) {
            this._dictionarySelected.set(this._dictionaryItemService.getById(this._name, this._id));
        }
    }

    onSelect(dictionarySelection: unknown): void {
        this.selected.emit(dictionarySelection as DictionarySelected);
    }

    search(search: string): void {
        const sanitizeSearch = search.toLowerCase();

        this._list = this._initialList.filter(item => item.label.toLowerCase().includes(sanitizeSearch));
        if (this._list.length <= 0 && this._options.acceptNoResult) {
            this._list.push(this._dictionaryItemFactory.createForOtherLabel(search));
        }
    }
}
