import {Component, inject, Input, OnInit} from '@angular/core';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    CBonvisiteLocationsFactory
} from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.factory';
import {
    CBonvisiteVentesFactory
} from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.factory';
import CBonvisiteLocations
    from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.model';
import CBonvisiteVentes from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.model';

@Component({
    selector: 'app-bonvisite-dossierbiens-number',
    standalone: false,
    templateUrl: 'bonvisite.dossierbiens-number.component.html',
})
export class AppBonvisiteDossierbiensNumberComponent implements OnInit {
    private _cBonvisiteLocationsFactory = inject(CBonvisiteLocationsFactory);
    private _cBonvisiteVentesFactory = inject(CBonvisiteVentesFactory);
    private _bonvisite!: Bonvisite;
    private _dossierbiens$!: Observable<{ cBonvisiteLocations: CBonvisiteLocations; cBonvisiteVentes: CBonvisiteVentes }>;

    @Input()
    set bonvisite(value: Bonvisite) {
        this._bonvisite = value;
    }

    get dossierbiens$(): Observable<{ cBonvisiteLocations: CBonvisiteLocations; cBonvisiteVentes: CBonvisiteVentes }> {
        return this._dossierbiens$;
    }

    ngOnInit(): void {
        this._dossierbiens$ = combineLatest([
            this._cBonvisiteLocationsFactory.getByLink$(this._bonvisite.linkBVLocations),
            this._cBonvisiteVentesFactory.getByLink$(this._bonvisite.linkBVVentes),
        ]).pipe(map(([cBonvisiteLocations, cBonvisiteVentes]) => ({cBonvisiteLocations, cBonvisiteVentes})));
    }
}
