import {NgModule, Type} from '@angular/core';
import {NgLocationLabelPriceComponent} from '@shared/angularJS/up-ng/locations/location-label-price.component';
import {NgLocationDiffusionComponent} from '@shared/angularJS/up-ng/locations/locations-item-diffusion.component';
import {NgLocationGestionComponent} from '@shared/angularJS/up-ng/locations/locations-item-gestion.component';
import {NgLocationLifetimeComponent} from '@shared/angularJS/up-ng/locations/locations-item-lifetime.component';
import {NgLocationMandatsComponent} from '@shared/angularJS/up-ng/locations/locations-item-mandats.component';

const components: Type<unknown>[] = [
    NgLocationDiffusionComponent,
    NgLocationGestionComponent,
    NgLocationLabelPriceComponent,
    NgLocationLifetimeComponent,
    NgLocationMandatsComponent,
];

@NgModule({exports: components, imports: components})
export class LocationsComponentsModule {
}
