import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {
    ICEtudeConstraintsApi
} from '@models/etudes/etude/constraints/collection/etude-constraints.collection.interfaces';
import CEtudeConstraints from '@models/etudes/etude/constraints/collection/etude-constraints.collection.model';
import {EtudeConstraintsApiService} from '@models/etudes/etude/constraints/etude-constraints.api.service';
import {Observable} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {map} from 'rxjs/operators';
import {EtudeConstraintsFactory} from '@models/etudes/etude/constraints/etude-constraints.factory';

@Injectable({providedIn: 'root'})
export class CEtudeConstraintsFactory {
    private _etudeConstraintsFactory = inject(EtudeConstraintsFactory);
    private _etudeConstraintsApiService = inject(EtudeConstraintsApiService);

    create(cEtudeConstraintsApi: ICEtudeConstraintsApi): CEtudeConstraints {
        const cEtudeConstraints = new CEtudeConstraints();

        cEtudeConstraints.links = new Links(cEtudeConstraintsApi._links);
        cEtudeConstraints.page = cEtudeConstraintsApi.page;
        cEtudeConstraints.pages = cEtudeConstraintsApi.pages;
        cEtudeConstraints.perPage = cEtudeConstraintsApi.limit;
        cEtudeConstraints.total = cEtudeConstraintsApi.total;
        if (cEtudeConstraintsApi._embedded) {
            cEtudeConstraints.results = this._etudeConstraintsFactory.create(cEtudeConstraintsApi._embedded.items);
        }

        return cEtudeConstraints;
    }

    get$(etude: Etude): Observable<CEtudeConstraints> {
        return this._etudeConstraintsApiService.getCollection$(etude.id.toString()).pipe(map(cEtudeConstraintsApi => this.create(cEtudeConstraintsApi)));
    }
}
