import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NotestimBiensApiService} from '@models/notestim/biens/notestim-biens.api.service';
import CNotestimBiens from '@models/notestim/biens/collection/notestim-biens.collection.model';
import {
    ICNotestimBiensQueryParameters, ICNotestimBiensApi
} from '@models/notestim/biens/collection/notestim-biens.collection.interfaces';
import {NotestimBienFactory} from '@models/notestim/biens/bien/notestim-bien.factory';

@Injectable({providedIn: 'root'})
export class CNotestimBiensFactory {
    private _notestimBienFactory = inject(NotestimBienFactory);
    private _notestimBiensApiService = inject(NotestimBiensApiService);

    create(cNotestimBiensApi: ICNotestimBiensApi): CNotestimBiens {
        const cNotestimBiens = new CNotestimBiens();

        cNotestimBiens.links = new Links(cNotestimBiensApi._links);
        cNotestimBiens.page = cNotestimBiensApi.page;
        cNotestimBiens.pages = cNotestimBiensApi.pages;
        cNotestimBiens.perPage = cNotestimBiensApi.limit;
        cNotestimBiens.total = cNotestimBiensApi.total;
        if (cNotestimBiensApi._embedded) {
            cNotestimBiens.results = (cNotestimBiensApi._embedded.items || []).filter(notestimBienApi => notestimBienApi)
                .map(notestimBienApi => this._notestimBienFactory.create(notestimBienApi));
        }

        return cNotestimBiens;
    }

    get$(queryParameters?: ICNotestimBiensQueryParameters): Observable<CNotestimBiens> {
        return this._notestimBiensApiService.getCollection$(queryParameters).pipe(map(cNotestimBiensApi => this.create(cNotestimBiensApi)));
    }
}
