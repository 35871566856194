import {Component, Input} from '@angular/core';
import {ILoaderOptions} from '@shared/load/load.interfaces';
import {NgClass} from '@angular/common';

@Component({imports: [NgClass], selector: 'app-loader', templateUrl: 'loader.component.html'})
export class AppLoaderComponent {
    static readonly initLoaderOptions: ILoaderOptions = {};
    private _classHeight = 'tw-h-4';
    private _options: ILoaderOptions = {...AppLoaderComponent.initLoaderOptions};

    get classHeight(): string {
        return this._classHeight;
    }

    @Input()
    set classHeight(value: string) {
        this._classHeight = value;
    }

    get options(): ILoaderOptions {
        return this._options;
    }

    @Input()
    set options(value: ILoaderOptions) {
        this._options = {...AppLoaderComponent.initLoaderOptions, ...value};
    }
}
