import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

@Component({selector: 'app-dossier-bien-statut', standalone: false, templateUrl: 'dossier-bien.statut.component.html'})
export class AppDossierBienStatutComponent {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dossierBien!: ADossierBien;
    private _statut!: DictionaryItem;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input()
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
        if (this.dossierBien.isDemandeur()) {
            this._statut = this._dictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_STATUTS, this.dossierBien.statut);
        } else if (this.dossierBien.isEstimation()) {
            this._statut = this._dictionaryItemService.getByCode(Dictionary.names.ESTIMATION_STATUTS, this.dossierBien.statut);
        } else if (this.dossierBien.isLocation()) {
            this._statut = this._dictionaryItemService.getByCode(Dictionary.names.LOCATION_STATUTS, this.dossierBien.statut);
        } else if (this.dossierBien.isVente()) {
            this._statut = this._dictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, this.dossierBien.statut);
        } else {
            this._statut = {label: this.dossierBien.labelStatut} as DictionaryItem;
        }
    }

    get statut(): DictionaryItem {
        return this._statut;
    }
}
