import {Component, inject, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {filter} from 'rxjs/operators';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {AsyncPipe} from '@angular/common';

@Component({
    imports: [AppLoaderComponent, AsyncPipe],
    selector: 'app-etude-raison-sociale',
    templateUrl: 'etude-raison-sociale.component.html',
})
export class AppEtudeRaisonSocialeComponent implements OnInit {
    private _etudeFactory = inject(EtudeFactory);
    private _linkSubject = new BehaviorSubject<string>(undefined!);
    private _etude$!: Observable<Etude>;

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    @Input()
    set link(value: string) {
        this._linkSubject.next(value);
    }

    ngOnInit(): void {
        this._etude$ = this._linkSubject.asObservable().pipe(
            filter(link => !!link),
            switchMap(link => this._etudeFactory.getByLink$(link)),
        );
    }
}
