import {Component, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {ILocationSlideOverData} from '@features/locations/locations.interfaces';
import {LocationFactory} from '@models/locations/location/location.factory';
import Location from '@models/locations/location/location.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';

@Component({selector: 'dc-location-slide-over', standalone: false, templateUrl: 'location.slide-over.component.html'})
export class DCLocationSlideOverComponent implements IDynamicComponent {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _locationFactory = inject(LocationFactory);
    private _location$!: Observable<Location>;
    private _superficieLabel!: string;

    set data(dynamicComponentData: ILocationSlideOverData) {
        this._location$ = this._locationFactory.getByLink$(dynamicComponentData.link).pipe(
            tap(location => this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(location.bien.nature)),
            take(1)
        );
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    get location$(): Observable<Location> {
        return this._location$;
    }

    redirectToDetails(location: Location): void {
        this._locationFactory.openDetailsInNewTab$(location).pipe(take(1)).subscribe();
    }
}
