import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgSoqModalesManager} from '@legacy/app/soqrate/soqrate';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {ModalService} from '@shared/modal/modal.service';
import {ReferenceFactory} from '@models/references/reference/reference.factory';
import {switchMap, take, tap} from 'rxjs/operators';
import Reference from '@models/references/reference/reference.model';
import {EstimationFactory} from '@models/estimations/estimation/estimation.factory';
import CReferences from '@models/references/collection/references.collection.model';
import {
    DCEstimationReferenceEditComponent
} from '@features/estimations/estimation/references/reference/edit/estimation.reference-edit.component';
import {
    EstimationReferenceFactory
} from '@models/estimations/estimation/references/reference/estimation-reference.factory';
import {DCReferenceEditComponent} from '@features/references/reference/edit/reference.edit.component';
import {combineLatest, firstValueFrom, of} from 'rxjs';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';
import {
    CEstimationReferencesFactory
} from '@models/estimations/estimation/references/collection/estimation-references.collection.factory';
import {
    AppEstimationReferencesListComponent
} from '@features/estimations/estimation/references/list/estimation-references-list.component';
import {
    EstimationReferenceSourceConst
} from '@models/estimations/estimation/references/estimation-references.constants';
import {
    EstimationReferenceService
} from '@models/estimations/estimation/references/reference/estimation-reference.service';

export default function getEstimationReferencesCrud(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-estimation-references-crud></esk-estimation-references-crud>
         * @param options : {refresh(bool)}
         */
        module.component('eskEstimationReferencesCrud', {
            bindings: {estimation: '=', onChange: '=?', options: '=?',},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/estimations/components/item/references/crud.html'
        });

        /**
         * Controller for estimation references
         *
         * @param SoqModalesManager
         * @param $translate
         * @param Ng2RouterStateForNgService
         * @param Ng2ModalService
         * @param Ng2ReferenceFactory
         * @param Ng2EstimationFactory
         * @param Ng2EstimationReferenceFactory
         * @param Ng2CEstimationReferencesFactory
         * @param Ng2EstimationReferenceService
         */
        Controller.$inject = ['SoqModalesManager', '$translate', 'Ng2RouterStateForNgService', 'Ng2ModalService', 'Ng2ReferenceFactory', 'Ng2EstimationFactory', 'Ng2EstimationReferenceFactory', 'Ng2CEstimationReferencesFactory', 'Ng2EstimationReferenceService'];
        function Controller(this: any,
                            soqModalesManager: NgSoqModalesManager,
                            $translate: angular.translate.ITranslateService,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            ng2ModalService: ModalService,
                            ng2ReferenceFactory: ReferenceFactory,
                            ng2EstimationFactory: EstimationFactory,
                            ng2EstimationReferenceFactory: EstimationReferenceFactory,
                            ng2CEstimationReferencesFactory: CEstimationReferencesFactory,
                            ng2EstimationReferenceService: EstimationReferenceService) {
            const $ctrl = this;

            $ctrl.$onInit = $onInit;
            $ctrl.editReference = editReference;
            $ctrl.importReferences = importReferences;
            $ctrl.referenceAction = referenceAction;
            $ctrl.searchReferences = searchReferences;

            /**
             * Initialization method
             */
            function $onInit() {
                $ctrl.loader = {updating: true};
                if (!angular.isObject($ctrl.options)) $ctrl.options = {};

                $ctrl.options.mainNature = ng2EstimationFactory.ngCreate($ctrl.estimation).bien.nature;
                if (!angular.isFunction($ctrl.onChange)) $ctrl.onChange = function () {
                };

                updateReferences(!$ctrl.options.refresh);
            }

            function referenceAction({action, value}: {action: string, value: { estimationReference: EstimationReference, idx: number }}) {
                if (action === AppEstimationReferencesListComponent.actions.EDIT) {
                    editReference(value.estimationReference);
                } else if (action === AppEstimationReferencesListComponent.actions.REMOVE) {
                    removeReference(value.estimationReference);
                } else if (action === AppEstimationReferencesListComponent.actions.UPDATE_RANK) {
                    updateRankReference(value.estimationReference, value.idx + 1);
                }
            }

            function updateReferences(init = false) {
                $ctrl.loader.updating = true;

                return firstValueFrom(ng2CEstimationReferencesFactory.get$(ng2EstimationFactory.ngCreate($ctrl.estimation)).pipe(
                    tap(cEstimationReferences => $ctrl.cEstimationReferences = cEstimationReferences),
                    tap(cEstimationReferences => $ctrl.cEstimationReferences$ = of(cEstimationReferences)),
                    tap(_ => $ctrl.onChange($ctrl.cEstimationReferences, init)),
                    tap(_ => $ctrl.loader.updating = false),
                    take(1),
                ));
            }

            function editReference(estimationReference: EstimationReference) {
                if (estimationReference) {
                    return ng2ModalService.open$<EstimationReference>(DCEstimationReferenceEditComponent, {estimationReference}, {align: 'top'}).pipe(
                        switchMap(estimationReference => {
                            if (!estimationReference) {
                                return of(undefined!);
                            }

                            return ng2EstimationReferenceFactory.save$(ng2EstimationFactory.ngCreate($ctrl.estimation), estimationReference);
                        }),
                        take(1),
                    ).subscribe(estimationReference => {
                        if (estimationReference) {
                            updateReferences();
                        }
                    });
                }

                return ng2ModalService.open$<Reference>(DCReferenceEditComponent, {reference: ng2ReferenceFactory.createVirgin()}, {align: 'top'}).pipe(
                    switchMap(reference => {
                        if (!reference) {
                            return of(undefined!);
                        }

                        return ng2ReferenceFactory.save$(reference).pipe(switchMap(reference => linkReference$(reference)));
                    }),
                    take(1),
                ).subscribe(reference => {
                    if (reference) {
                        updateReferences();
                    }
                });
            }

            /**
             * Import references from file
             */
            function importReferences() {
                soqModalesManager.open<CReferences>('EstimationModalReferencesImport', {resolve: {nature: $ctrl.estimation.bien.nature}}).then(cReferences => {
                    if (!cReferences || cReferences.total <= 0) return;

                    firstValueFrom(combineLatest(cReferences.results.map(reference => linkReference$(reference))))
                        .then(_ => updateReferences());
                });
            }

            /**
             * Link reference to estimation
             *
             * @param reference
             * @return {Object}
             */
            function linkReference$(reference: Reference) {
                return ng2EstimationReferenceService.linkReference$(ng2EstimationFactory.ngCreate($ctrl.estimation), reference, EstimationReferenceSourceConst.REFERENCE);
            }

            function removeReference(estimationReference: EstimationReference) {
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: $translate.instant('estimation.reference.remove.confirm.CONFIRMATION'),
                    question: $translate.instant('estimation.reference.remove.confirm.MESSAGE', {type: estimationReference.sourceType}),
                    title: $translate.instant('estimation.reference.remove.confirm.TITLE'),
                    status: ModalService.statuts.DANGER,
                }).pipe(
                    switchMap(response => {
                        if (!response) {
                            return of(null);
                        }

                        return ng2EstimationReferenceFactory.delete$(ng2EstimationFactory.ngCreate($ctrl.estimation), estimationReference).pipe(
                            tap(() => updateReferences()),
                        );
                    }),
                    take(1),
                ).subscribe();
            }

            /**
             * Search references
             */
            function searchReferences() {
                ng2RouterStateForNgService.navigateByUrl('/app/estimations/' + $ctrl.estimation.id + '/evaluation/references/criteres');
            }

            function updateRankReference(estimationReference: EstimationReference, rank: number) {
                ng2EstimationReferenceFactory.updateRank$(ng2EstimationFactory.ngCreate($ctrl.estimation), estimationReference, rank)
                    .pipe(take(1)).subscribe(() => updateReferences());
            }
        }
    })(angularJS);
}
