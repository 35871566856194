import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskDossierSuivi]'})
export class NgEskimmoDossierSuiviDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDossierSuivi', elementRef, injector);
    }
}

@Component({
    imports: [NgEskimmoDossierSuiviDirective],
    selector: 'esk-dossier-suivi',
    template: '<div appNgEskDossierSuivi></div>',
})
export class NgEskimmoDossierSuiviComponent {
}
