import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {
    DossierFileDropdownComponent
} from '@features/dossiers/dossier/files/file/dropdown/dossier-file.dropdown.component';
import {
    DossierFilesActionsSelectionComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-selection.component';
import {
    DossierFilesActionsSelectionDropdownComponent
} from '@features/dossiers/dossier/files/actions/dropdown/dossier-files.actions-selection-dropdown.component';
import {
    DossierFilesActionsMainComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-main.component';
import {
    AppDossierFileContactsNumberComponent
} from '@features/dossiers/dossier/files/file/contacts/number/dossier-file-contacts.number.component';
import {ContactsModule} from '@features/contacts/contacts.module';
import {
    AppDossierContactsGroupEditComponent
} from '@features/dossiers/dossier/contacts-group/edit/dossier-contacts-group-edit.component';
import {DictionariesModule} from '@features/dictionaries/dictionaries.module';
import {
    DossierContactsGroupEditMemberDropdownComponent
} from '@features/dossiers/dossier/contacts-group/edit/member-dropdown/dossier-contacts-group-edit.member-dropdown.component';
import {
    AppDossierInotExportsBoxComponent
} from '@features/dossiers/dossier/inot/exports/box/dossier-inot-exports.box.component';
import {
    DossierInotExportActionsMainComponent
} from '@features/dossiers/dossier/inot/exports/export/actions/dossier-inot-export.actions-main.component';
import {
    DCDossierMembresSelectComponent
} from '@features/dossiers/dossier/membres/select/dynamic-components/dossier-membres-select.component';
import {AppDossierFilesListComponent} from '@features/dossiers/dossier/files/list/dossier-files-list.component';
import {MediasModule} from '@features/medias/medias.module';
import {
    AppDossierMembersListComponent
} from '@features/dossiers/dossier/contacts-group/members/list/dossier-members-list.component';
import {EtudeDossiersModule} from '@features/etude-dossiers/etude-dossiers.module';
import {UsersModule} from '@features/users/users.module';
import {
    AppDossierInotExportStatutComponent
} from '@features/dossiers/dossier/inot/exports/export/statut/dossier-inot-export.statut.component';
import {InotModule} from '@features/inot/inot.module';
import {AppDossiersViewChoiceComponent} from '@features/dossiers/view-choice/dossiers-view-choice.component';
import {BiensModule} from '@features/biens/biens.module';
import {EskimmoComponentsModule} from '@shared/angularJS/up-ng/eskimmo-components.module';
import {AppDossierBienCardComponent} from '@features/dossiers/dossier/bien/card/dossier-bien.card.component';
import {VentesModule} from '@features/ventes/ventes.module';
import {
    AppDossiersBiensStackedImagesComponent
} from '@features/dossiers/biens/stacked-images/dossiers-biens.stacked-images.component';
import {AppDossierBienHeaderComponent} from '@features/dossiers/dossier/bien/header/dossier-bien.header.component';
import {AppDossierBienStatutComponent} from '@features/dossiers/dossier/bien/statut/dossier-bien.statut.component';
import {DossierBienOverviewModule} from '@features/dossiers/dossier/bien/overview/dossier-bien-overview.module';
import {DossierModule} from '@features/dossiers/dossier/dossier.module';
import {BrowserOnlineIndicatorComponent} from '@shared/browser/online/indicator/browser.online-indicator.component';

@NgModule({
    declarations: [
        AppDossierBienCardComponent,
        AppDossierBienHeaderComponent,
        AppDossierBienStatutComponent,
        AppDossierContactsGroupEditComponent,
        AppDossierFileContactsNumberComponent,
        AppDossierFilesListComponent,
        AppDossierInotExportsBoxComponent,
        AppDossierInotExportStatutComponent,
        AppDossierMembersListComponent,
        AppDossiersBiensStackedImagesComponent,
        AppDossiersViewChoiceComponent,
        DCDossierMembresSelectComponent,
        DossierContactsGroupEditMemberDropdownComponent,
        DossierFileDropdownComponent,
        DossierFilesActionsMainComponent,
        DossierFilesActionsSelectionComponent,
        DossierFilesActionsSelectionDropdownComponent,
        DossierInotExportActionsMainComponent,
    ],
    exports: [
        AppDossierBienCardComponent,
        AppDossierBienHeaderComponent,
        AppDossierContactsGroupEditComponent,
        AppDossierFileContactsNumberComponent,
        AppDossierFilesListComponent,
        AppDossierInotExportsBoxComponent,
        AppDossierInotExportStatutComponent,
        AppDossierMembersListComponent,
        AppDossiersBiensStackedImagesComponent,
        AppDossiersViewChoiceComponent,
        DossierBienOverviewModule,
        DossierModule,
    ],
    imports: [
        BiensModule,
        CommonModule,
        ContactsModule,
        DossierBienOverviewModule,
        DossierModule,
        DictionariesModule,
        EskimmoComponentsModule,
        EtudeDossiersModule,
        InotModule,
        MediasModule,
        SharedModule,
        UsersModule,
        VentesModule,
        BrowserOnlineIndicatorComponent,
    ],
})
export class DossiersModule {
}
