import {
    Component, ElementRef, inject, Input, NgZone, OnDestroy, output, Renderer2, ViewEncapsulation
} from '@angular/core';
import {IMapBox, IMapInitOptions, IMapData} from '@shared/map/map.interfaces';
import {
    divIcon, featureGroup, LatLng, latLng, LatLngBounds, Layer, LeafletEvent, Map, MapOptions as LeafletOptions,
    Marker, marker, MarkerClusterGroupOptions, tileLayer
} from 'leaflet';
import 'leaflet.markercluster';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LeafletModule} from '@bluehalo/ngx-leaflet';
import {LeafletMarkerClusterModule} from '@bluehalo/ngx-leaflet-markercluster';

@Component({
    /* eslint-disable */
    encapsulation: ViewEncapsulation.None,
    /* eslint-enable */
    imports: [LeafletMarkerClusterModule, LeafletModule],
    selector: 'app-map',
    styleUrls: ['map.component.scss'],
    templateUrl: 'map.component.html',
})
export class MapComponent implements OnDestroy {
    static readonly initLeafletMarkerClusterOptions: MarkerClusterGroupOptions = {
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: false,
        zoomToBoundsOnClick: true,
    };
    static readonly initLeafletOptions: LeafletOptions = {
        // Carte sur Noteo
        center: latLng(47.49799, -0.48944),
        layers: [
            tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            })
        ],
        zoom: 12,
    };
    static readonly initOptions: IMapInitOptions = {
        withControls: true,
        useCluster: false,
    };
    readonly boxChanged = output<IMapBox>();
    readonly markerIdClicked = output<string>();
    private _elementRef = inject(ElementRef<HTMLElement>);
    private _ngZone = inject(NgZone);
    private _renderer2 = inject(Renderer2);
    private _actualMapBox!: IMapBox;
    private _leafletCenter!: LatLng;
    private _leafletFitBounds!: LatLngBounds;
    private _leafletLayers!: Layer[];
    private _leafletZoom = MapComponent.initLeafletOptions.zoom!;
    private _leafletMarkerClusterLayers!: Layer[];
    private _leafletOptions: LeafletOptions = {...MapComponent.initLeafletOptions};
    private _leafletMarkerClusterOptions: MarkerClusterGroupOptions = {...MapComponent.initLeafletMarkerClusterOptions};
    private readonly _onDestroy$ = new Subject<void>();
    private _userAction = false;
    private _useCluster: boolean = MapComponent.initOptions.useCluster!;

    get leafletCenter(): LatLng {
        return this._leafletCenter;
    }

    get leafletFitBounds(): LatLngBounds {
        return this._leafletFitBounds;
    }

    get leafletLayers(): Layer[] {
        return this._leafletLayers;
    }

    get leafletZoom(): number {
        return this._leafletZoom;
    }

    get leafletMarkerClusterLayers(): Layer[] {
        return this._leafletMarkerClusterLayers;
    }

    get leafletMarkerClusterOptions(): MarkerClusterGroupOptions {
        return this._leafletMarkerClusterOptions;
    }

    get leafletOptions(): LeafletOptions {
        return this._leafletOptions;
    }

    @Input({required: true})
    set mapBox(value: IMapBox) {
        if (this.hasMapBoxChanged(value)) {
            this._userAction = value.source !== 'leaflet';
            if(!this._userAction) {
                this._actualMapBox = value;
            }

            this._leafletCenter = latLng(value.center.latitude, value.center.longitude);
            this._leafletZoom = value.zoom ?? MapComponent.initLeafletOptions.zoom!;
        }
    }

    @Input()
    set initOptions(value: IMapInitOptions) {
        this._leafletOptions.doubleClickZoom = value.withControls ?? MapComponent.initOptions.withControls;
        this._leafletOptions.dragging = value.withControls ?? MapComponent.initOptions.withControls;
        this._leafletOptions.keyboard = value.withControls ?? MapComponent.initOptions.withControls;
        this._leafletOptions.scrollWheelZoom = value.withControls ?? MapComponent.initOptions.withControls;
        this._leafletOptions.zoomControl = value.withControls ?? MapComponent.initOptions.withControls;
        this._useCluster = value.useCluster ?? MapComponent.initOptions.useCluster!;
    }

    @Input()
    set data(value: IMapData) {
        this._onDestroy$.next();

        if (value) {
            this.addMarkers(value);

            this.setActiveMarker(value);
        }
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    addMarkers(mapData: IMapData): void {
        this._leafletFitBounds = undefined!;
        this._leafletMarkerClusterLayers = undefined!;

        const markers: Marker[] = [];
        mapData.markers?.forEach(newMarker => markers.push(
            marker(latLng(newMarker.latitude, newMarker.longitude), {
                icon: divIcon(
                    {
                        className: 'map-icon',
                        html: `<div id='marker-${newMarker.id!}' class='marker-pin'></div>`,
                        iconSize: [24, 40],
                        iconAnchor: [24, 40],
                    }
                )
            }).on('click', () => {
                this._ngZone.run(() => this.markerIdClicked.emit(newMarker.id!));
            })
        ));

        if (markers.length > 0) {
            const featureGroups = featureGroup(markers);
            if (mapData.useAutoZoom) {
                this._leafletFitBounds = featureGroups.getBounds().pad(0.25);
            }

            if (this._useCluster) {
                this._leafletMarkerClusterLayers = markers;
            } else {
                this._leafletLayers = [featureGroups];
            }
        }
    }

    emit(map: Map): void {
        const bounds = map.getBounds();
        const center = bounds.getCenter();

        const mapBox = {
            center: {latitude: center.lat, longitude: center.lng},
            nordEst: {latitude: bounds.getNorthEast().lat, longitude: bounds.getNorthEast().lng},
            sudOuest: {latitude: bounds.getSouthWest().lat, longitude: bounds.getSouthWest().lng},
            zoom: map.getZoom(),
            source: 'leaflet'
        };

        if (this.hasMapBoxChanged(mapBox)) {
            this.boxChanged.emit(mapBox);
        }
    }

    hasMapBoxChanged(mapBox: IMapBox): boolean {
        if (!this._actualMapBox) {
            return true;
        }

        return this._actualMapBox.zoom !== mapBox.zoom
            || this._actualMapBox.center.latitude !== mapBox.center.latitude
            || this._actualMapBox.center.longitude !== mapBox.center.longitude;
    }

    mapReady(map: Map): void {
        const center = map.getBounds().getCenter();

        this._actualMapBox = {
            center: {
                latitude: center.lat,
                longitude: center.lng,
            },
            zoom: map.getZoom(),
            source: 'mapReady'
        };
    }

    mouseDown(): void {
        this._userAction = true;
    }

    moveEnd(event: LeafletEvent): void {
        if (this._userAction) {
            this.emit(event.target as Map);
            this._userAction = false;
        }
    }

    setActiveMarker(mapData: IMapData): void {
        mapData.markerIdActivated$?.pipe(takeUntil(this._onDestroy$)).subscribe(id => {
            const markerToActivate = this._elementRef.nativeElement.querySelector('#marker-' + id);
            const markersActived = Array.from(this._elementRef.nativeElement.querySelectorAll('.marker-actived'));

            markersActived.forEach(markerActived => {
                this._renderer2.removeClass(markerActived, 'marker-actived');
            });

            if (markerToActivate) {
                this._renderer2.addClass(markerToActivate, 'marker-actived');
            }
        });
    }

    zoomEnd(): void {
        this._userAction = true;
    }
}
