import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {ICEtudesApi, ICEtudesQueryParameters} from '@models/etudes/collection/etudes.collection.interfaces';
import CEtudes from '@models/etudes/collection/etudes.collection.model';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {Observable} from 'rxjs';
import {EtudesApiService} from '@models/etudes/etudes.api.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CEtudesFactory {
    private _etudeFactory = inject(EtudeFactory);
    private _etudesApiService = inject(EtudesApiService);

    create(cEtudesApi: ICEtudesApi): CEtudes {
        const cEtudes = new CEtudes();

        cEtudes.links = new Links(cEtudesApi._links);
        cEtudes.page = cEtudesApi.page;
        cEtudes.pages = cEtudesApi.pages;
        cEtudes.perPage = cEtudesApi.limit;
        cEtudes.total = cEtudesApi.total;
        if (cEtudesApi._embedded) {
            cEtudes.results = (cEtudesApi._embedded.items || []).filter(etudeApi => etudeApi)
                .map(etudeApi => this._etudeFactory.create(etudeApi));
        }

        return cEtudes;
    }

    get$(queryParameters?: ICEtudesQueryParameters): Observable<CEtudes> {
        return this._etudesApiService.getCollection$(queryParameters).pipe(map(cEtudesApi => this.create(cEtudesApi)));
    }

    getNext$(cEtudes: CEtudes): Observable<CEtudes> {
        return this._etudesApiService.getCollectionByLink$(cEtudes.links.next).pipe(map(cEtudesApi => this.create(cEtudesApi)));
    }
}
