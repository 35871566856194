import angularJS from '@shared/angularJS/global.ng';
import {map} from "lodash";
import {ConditionConst, SortConst} from '@shared/constants';
import {IModule, IPromise, IQService} from 'angular';
import {NgDictionariesManager, NgManager, NgSortManager} from '@legacy/app/managers/managers';
import {NgFilter} from '@legacy/app/managers/ressources';
import {NgFiltersCollection} from '@legacy/app/managers/collections';
import {filter} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import Ng2Filter from '@models/filters/filter/filter.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import {combineLatest, firstValueFrom} from 'rxjs';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import DateFormat from '@shared/date/date.format';
import Vente from '@models/ventes/vente/vente.model';
import Location from '@models/locations/location/location.model';
import Estimation from '@models/estimations/estimation/estimation.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import DemandeurRechercheResultat
    from '@models/demandeurs/demandeur/recherches/recherche/resultats/resultat/demandeur-recherche-resultat.model';

export default function getManagersFilter(module: IModule): void {
    (function (angular) {
        'use strict';

        module.factory('FilterManager', manager);

        /**
         * Manager filter
         *
         * @param BaseManager
         * @param $q
         * @param DictionariesManager
         * @param SortManager
         * @param Ng2EtudeService
         * @param Ng2UserService
         * @param Ng2DictionaryItemService
         * @param Ng2DictionaryFactory
         */
        manager.$inject = ['BaseManager', '$q', 'DictionariesManager', 'SortManager', 'Ng2EtudeService', 'Ng2UserService', 'Ng2DictionaryItemService', 'Ng2DictionaryFactory'];
        function manager(baseManager: NgManager,
                         $q: IQService,
                         dictionariesManager: NgDictionariesManager,
                         sortManager: NgSortManager,
                         ng2EtudeService: EtudeService,
                         ng2UserService: UserService,
                         ng2DictionaryItemService: DictionaryItemService,
                         ng2DictionaryFactory: DictionaryFactory) {
            let classResource = baseManager.getClass<NgFilter>();
            let currentEtude: Etude;

            ng2EtudeService.current$.pipe(filter(etude => !!etude)).subscribe(etude => currentEtude = etude);

            /**
             * Routing
             */
            baseManager.addRoute('filters.cget', {path: '/user/filters', method: 'GET'});
            baseManager.addRoute('filters.get', {path: '/user/filters/{id}', method: 'GET'});
            baseManager.addRoute('filters.insert', {path: '/user/filters', method: 'POST'});
            baseManager.addRoute('filters.edit', {path: '/user/filters/{id}', method: 'PUT'});
            baseManager.addRoute('filters.update-rank', {path: '/user/filters/{id}/rank', method: 'PATCH'});

            /**
             * FilterManager object
             *
             * @constructor
             */
            angular.extend(FilterManager.prototype, baseManager.__proto__);
            FilterManager.prototype.eskManager = {prefixRoute: 'filters', collectionName: 'items'};
            function FilterManager() {
            }

            /**
             * Returns the promise to GET filters
             *
             * @param route
             * @param code
             * @param all
             */
            FilterManager.prototype.getActiveByRouteCode = function (route: string, code: string, all: boolean): IPromise<NgFilter> {
                const self = this;

                return firstValueFrom(combineLatest([ng2UserService.last$, ng2UserService.getCurrentCommune$()])).then(([currentUser, commune]) => self.getAll({
                    route: {condition: ConditionConst.IN, values: [route]},
                    code: {condition: ConditionConst.IN, values: [code]}
                }).then(function (filters: NgFiltersCollection) {
                    if (all === true) return filters;

                    if (filters.totalItems > 0) {
                        return filters.collection[0];
                    }

                    return $q.reject();
                }).catch(function () {
                    const filter = self.create({route: route, code: code});

                    switch (route + ':' + code) {
                        case Ng2Filter.routes.GET_LOCATIONS + ':box_new_locations':
                        case Ng2Filter.routes.GET_VENTES + ':box_new_ventes':
                        case Ng2Filter.routes.GET_VENTES + ':box_updated_ventes':
                            if (commune) {
                                filter.queryParameters.departements.values.push(commune.departement);
                            }

                            break;

                        case Ng2Filter.routes.GET_ETUDE_VENTES + ':' + Ng2Filter.codes.WALLET_CURRENT:
                        case Ng2Filter.routes.GET_ETUDE_LOCATIONS + ':' + Ng2Filter.codes.WALLET_CURRENT:
                        case Ng2Filter.routes.GET_ETUDE_ESTIMATIONS + ':' + Ng2Filter.codes.WALLET_CURRENT:
                        case Ng2Filter.routes.GET_ETUDE_DEMANDEURS + ':' + Ng2Filter.codes.WALLET_CURRENT:
                            filter.queryParameters.responsableDossier.values.push(currentUser.id);

                            break;

                        case Ng2Filter.routes.GET_ETUDE_DEMANDEURS + ':correspondants':
                            filter.queryParameters.recherches.status.values.push(DemandeurRecherche.activeStatuts.UP_TO_DATE);
                            filter.queryParameters.recherches.status.values.push('pending_updated_match');
                            filter.queryParameters.recherches.status.values.push('pending_match');

                            break;

                        case Ng2Filter.routes.GET_ETUDE_REPORTS + ':' + Ng2Filter.codes.WALLET_CURRENT:
                            filter.queryParameters.sorts.push({code: 'createdAt', direction: SortConst.DESCENDING});

                            break;
                    }

                    return filter;
                }).then(function (filter: NgFilter) {
                    if (route === Ng2Filter.routes.GET_VENTES && ['box_new_ventes', 'box_updated_ventes'].includes(code) ||
                        route === Ng2Filter.routes.GET_LOCATIONS && code === 'box_new_locations') {
                        if (angular.isObject(filter.queryParameters.departements) && angular.isArray(filter.queryParameters.departements.values) && filter.queryParameters.departements.values?.length > 0) {
                            filter.queryParameters.areas = {
                                condition: filter.queryParameters.departements.condition,
                                values: map(filter.queryParameters.departements.values, 'id')
                            };
                            delete filter.queryParameters.departements;
                        }
                        if (angular.isArray(filter.queryParameters.sorts) && filter.queryParameters.sorts.length > 0) {
                            filter.queryParameters.tris = {};
                            angular.forEach(filter.queryParameters.sorts, function (sort) {
                                filter.queryParameters.tris[sort.code] = sort.direction;
                            });
                        }
                        // Ajout de toutes les natures pour éviter qu'en modifiant le filtre, la maison ne se mette par défaut, cf. eskSearchCriteria si pas de natures
                        if (!angular.isObject(filter.queryParameters.nature) || angular.isArray(filter.queryParameters.nature.values) || filter.queryParameters.departements.nature.values?.length <= 0) {
                            filter.queryParameters.nature = {
                                condition: ConditionConst.IN,
                                values: ng2DictionaryFactory.getByName(Dictionary.names.NATURES).items.map(dictionaryItem => dictionaryItem.code),
                            };
                        }
                    }

                    return filter;
                }));
            };

            /**
             * Create a Filter object
             *
             * @param data
             * @returns Filter object || Array of manager.Filter objects
             */
            FilterManager.prototype.create = function (data: unknown) {
                if (angular.isArray(data)) {
                    var filters = [];

                    for (var i = 0; i < data.length; i++) {
                        // @ts-ignore
                        filters.push(new Filter(data[i]));
                    }

                    return filters;
                }

                // @ts-ignore
                return new Filter(data);
            };

            /**
             * Filter object
             *
             * @param data
             * @constructor
             */
            angular.extend(Filter.prototype, classResource.prototype);
            Filter.prototype.eskManager = {prefixRoute: 'filters'};
            function Filter(this: NgFilter, data: { route: string }) {
                this._esk = {};
                this.queryParameters = {};
                this.extend(data);
                this.defaultValues(data.route);
                this._esk.defaultRouteParams = {id: this.id};

                if (!angular.isDefined(this.nom)) {
                    if (angular.isString(this.code) && this.code !== '') {
                        this.nom = this.code;
                    } else {
                        this.nom = this.route;
                    }
                }
            }

            /**
             * Extend the existing Filter with new data
             *
             * @param data
             * @returns {Filter}
             */
            Filter.prototype.extend = function (data: unknown) {
                classResource.prototype.extend.call(this, data);

                if (angular.isObject(this.queryParameters.sort)) {
                    this.queryParameters.sort = sortManager.getOneById(this.queryParameters.sort.id);
                }

                return this;
            };

            /**
             * Sanitize object Estimation before send to API
             *
             * @returns {Object}
             */
            Filter.prototype.sanitize = function () {
                var sanitizeObject = classResource.prototype.sanitize.call(this);

                delete sanitizeObject.queryParameters.keywords;

                return sanitizeObject;
            };

            /**
             * RAZ
             *
             * @returns {Filter}
             */
            Filter.prototype.raz = function () {
                this.queryParameters = {};

                return this.defaultValues(this.route);
            };

            /**
             * Add default filter value
             *
             * @param route
             * @returns {Filter}
             */
            Filter.prototype.defaultValues = function (route: string): NgFilter {
                this.route = route;
                switch (this.route) {
                    case Ng2Filter.routes.GET_ETUDE_VENTES :
                        if (!angular.isObject(this.queryParameters.statut)) {
                            this.queryParameters.statut = {
                                choice: false,
                                values: [
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, Vente.statuts.BROUILLON)).code,
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, Vente.statuts.MANDAT)).code,
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, Vente.statuts.COMPROMIS)).code,
                                ],
                                selection: {},
                                condition: ConditionConst.IN,
                            };

                            const venteStatusDiffuse = ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_STATUTS, Vente.statuts.DIFFUSE);

                            if (currentEtude?.hasNetwork() && venteStatusDiffuse) {
                                this.queryParameters.statut.values.push(venteStatusDiffuse.code);
                            }
                        }

                        if (!angular.isObject(this.queryParameters.typeVente)) {
                            this.queryParameters.typeVente = {
                                values: [dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.VENTE_TYPES, Vente.types.SIMPLE)).code],
                                condition: ConditionConst.IN,
                            };
                        }

                        if (!angular.isObject(this.queryParameters.nature)) {
                            this.queryParameters.nature = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.mandat)) {
                            this.queryParameters.mandat = {type: {values: [], condition: ConditionConst.IN}};
                        }

                        if (!angular.isObject(this.queryParameters.responsableDossier)) {
                            this.queryParameters.responsableDossier = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.notaire)) {
                            this.queryParameters.notaire = {values: [], condition: ConditionConst.IN};
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_LOCATIONS :
                        if (!angular.isObject(this.queryParameters.statut)) {
                            this.queryParameters.statut = {
                                values: [
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_STATUTS, Location.statuts.BROUILLON)).code,
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_STATUTS, Location.statuts.DISPONIBLE)).code,
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_STATUTS, Location.statuts.SUSPENDU)).code,
                                ],
                                condition: ConditionConst.IN,
                            };

                            if (currentEtude?.hasNetwork()) {
                                this.queryParameters.statut.values.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.LOCATION_STATUTS, Location.statuts.DIFFUSE)).code);
                            }
                        }

                        if (!angular.isObject(this.queryParameters.nature)) {
                            this.queryParameters.nature = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.responsableDossier)) {
                            this.queryParameters.responsableDossier = {values: [], condition: ConditionConst.IN};
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_ESTIMATIONS :
                        if (!angular.isObject(this.queryParameters.statut)) {
                            this.queryParameters.statut = {
                                values: [
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.ESTIMATION_STATUTS, Estimation.statuts.BROUILLON)).code,
                                    dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.ESTIMATION_STATUTS, Estimation.statuts.REMIS)).code,
                                ],
                                condition: ConditionConst.IN,
                            };
                        }

                        if (!angular.isObject(this.queryParameters.typeEstimation)) {
                            this.queryParameters.typeEstimation = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.nature)) {
                            this.queryParameters.nature = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.responsableDossier)) {
                            this.queryParameters.responsableDossier = {values: [], condition: ConditionConst.IN};
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_DEMANDEURS :
                        if (!angular.isObject(this.queryParameters.recherches)) {
                            this.queryParameters.recherches = {};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.type)) {
                            this.queryParameters.recherches.type = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.prixVenteMin)) {
                            this.queryParameters.recherches.prixVenteMin = {min: null, max: null};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.prixVenteMax)) {
                            this.queryParameters.recherches.prixVenteMax = {min: null, max: null};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.nature)) {
                            this.queryParameters.recherches.nature = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.resultats)) {
                            this.queryParameters.recherches.resultats = {};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.resultats.vente)) {
                            this.queryParameters.recherches.resultats.vente = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.status)) {
                            this.queryParameters.recherches.status = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.statut)) {
                            this.queryParameters.statut = {values: [null], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.responsableDossier)) {
                            this.queryParameters.responsableDossier = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.dateCreation)) {
                            this.queryParameters.dateCreation = {value: null};
                        }

                        if (!angular.isObject(this.queryParameters.recherches.dateModification)) {
                            this.queryParameters.recherches.dateModification = {value: null};
                        }

                        if (!angular.isObject(this.queryParameters.typeDemandeur)) {
                            this.queryParameters.typeDemandeur = {values: [], condition: ConditionConst.IN};
                        }

                        break;

                    case Ng2Filter.routes.GET_VENTES:
                        if (!angular.isArray(this.queryParameters.sorts)) {
                            this.queryParameters.sorts = [];
                        }

                        if (!angular.isObject(this.queryParameters.statut)) {
                            this.queryParameters.statut = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.departements)) {
                            this.queryParameters.departements = {values: [], condition: ConditionConst.IN};
                        }

                        switch (this.code) {
                            case 'box_new_ventes':
                                this.queryParameters.statut.values = [Vente.statuts.DIFFUSE, Vente.statuts.MANDAT];
                                this.queryParameters.sorts = [
                                    {code: "dateDiffusion", direction: SortConst.DESCENDING},
                                    {code: "mandatDate", direction: SortConst.DESCENDING}
                                ];

                                break;

                            case 'box_updated_ventes':
                                if (!angular.isObject(this.queryParameters.areas)) {
                                    this.queryParameters.areas = {values: [], condition: ConditionConst.IN};
                                }

                                if (!angular.isObject(this.queryParameters.commune)) {
                                    this.queryParameters.commune = {values: [], condition: ConditionConst.IN};
                                }

                                if (!angular.isObject(this.queryParameters.dateModification)) {
                                    this.queryParameters.dateModification = {};
                                }

                                this.queryParameters.statut.values = [Vente.statuts.ARCHIVE, Vente.statuts.COMPROMIS, Vente.statuts.DIFFUSE, Vente.statuts.MANDAT, Vente.statuts.SUSPENDU];
                                this.queryParameters.dateModification.archive = this.queryParameters.dateModification.archive !== false;
                                this.queryParameters.dateModification.compromis = this.queryParameters.dateModification.compromis !== false;
                                this.queryParameters.dateModification.prix = this.queryParameters.dateModification.prix !== false;
                                this.queryParameters.sorts = [
                                    {code: "dateModification", direction: SortConst.DESCENDING}
                                ];

                                break;
                        }

                        break;

                    case Ng2Filter.routes.GET_RECHERCHE_RESULTATS:
                        if (!angular.isArray(this.queryParameters.sorts)) {
                            this.queryParameters.sorts = [{code: "status", direction: SortConst.ASCENDING}];
                        }

                        switch (this.code) {
                            case Ng2Filter.codes.WALLET_CURRENT :
                                this.queryParameters.interest = {
                                    condition: ConditionConst.NOT_IN,
                                    values: [
                                        DemandeurRechercheResultat.interests.DISINTERESTED,
                                        DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE,
                                    ],
                                };
                                this.queryParameters.status = {values: [], condition: ConditionConst.IN};

                                break;
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_REPORTS:
                        if (!angular.isObject(this.queryParameters.dossiersDemandeur)) {
                            this.queryParameters.dossiersDemandeur = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.dossiersVente)) {
                            this.queryParameters.dossiersVente = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.dossiersLocation)) {
                            this.queryParameters.dossiersLocation = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.dossiersEstimation)) {
                            this.queryParameters.dossiersEstimation = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isArray(this.queryParameters.sorts)) {
                            this.queryParameters.sorts = [];
                        }

                        break;

                    case Ng2Filter.routes.GET_LOCATIONS:
                        if (!angular.isArray(this.queryParameters.sorts)) {
                            this.queryParameters.sorts = [];
                        }

                        if (!angular.isObject(this.queryParameters.statut)) {
                            this.queryParameters.statut = {values: [], condition: ConditionConst.IN};
                        }

                        if (!angular.isObject(this.queryParameters.departements)) {
                            this.queryParameters.departements = {values: [], condition: ConditionConst.IN};
                        }

                        switch (this.code) {
                            case 'box_new_locations':
                                this.queryParameters.statut.values = [Location.statuts.DIFFUSE, Location.statuts.DISPONIBLE];
                                this.queryParameters.sorts = [{code: "dateDiffusion", direction: SortConst.DESCENDING}];

                                break;
                        }

                        break;
                }

                return this;
            };

            /**
             * Extend the existing Filter with new data
             *
             * @returns {Object}
             */
            Filter.prototype.getParamsRequest = function (): any {
                const queryParams = {};
                const self = this;
                const statutValues: string[] = [];

                // Nettoyage des statuts
                if (angular.isObject(self.queryParameters.statut) && angular.isArray(self.queryParameters.statut.values)) {
                    angular.forEach(self.queryParameters.statut.values, function (statut) {
                        if (angular.isString(statut) && statut !== '') statutValues.push(statut);
                    });
                    self.queryParameters.statut.values = statutValues;
                }

                switch (self.route) {
                    case Ng2Filter.routes.GET_ETUDE_VENTES:
                        if (self.queryParameters.statut.values?.length > 0) {
                            // @ts-ignore
                            queryParams.statut = {
                                condition: self.queryParameters.statut.condition,
                                values: self.queryParameters.statut.values
                            };
                        }

                        if (self.queryParameters.typeVente.values?.length > 0) {
                            // @ts-ignore
                            queryParams.type = {
                                condition: self.queryParameters.typeVente.condition,
                                values: self.queryParameters.typeVente.values
                            };
                        }

                        if (self.queryParameters.nature.values?.length > 0) {
                            // @ts-ignore
                            queryParams.nature = {
                                condition: self.queryParameters.nature.condition,
                                values: self.queryParameters.nature.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.mandat)) {
                            if (angular.isObject(self.queryParameters.mandat.type) && self.queryParameters.mandat.type.values?.length > 0) {
                                // @ts-ignore
                                if (!angular.isObject(queryParams.mandat)) queryParams.mandat = {};

                                // @ts-ignore
                                queryParams.mandat.type = {
                                    condition: self.queryParameters.mandat.type.condition,
                                    values: self.queryParameters.mandat.type.values
                                };
                            }

                            if (angular.isObject(self.queryParameters.mandat.echeance)) {
                                // @ts-ignore
                                if (!angular.isObject(queryParams.mandat)) queryParams.mandat = {};

                                const dateMax = DateFormat.toDate(DateFormat.addDaysToDateString(DateFormat.dateFromNow(), self.queryParameters.mandat.echeance.code));
                                const dateMin = DateFormat.toDate(DateFormat.dateFromNow());

                                DateFormat.razTime(dateMax);
                                DateFormat.razTime(dateMin);
                                // @ts-ignore
                                queryParams.mandat.dateFin = {
                                    max: DateFormat.toJSON(DateFormat.toDate(DateFormat.addSecondsToDateString(dateMax, -1))),
                                    min: self.queryParameters.mandat.echeance.code === 0 ? undefined : DateFormat.toJSON(dateMin),
                                };
                            }
                        }

                        if (self.queryParameters.responsableDossier.values?.length > 0) {
                            // @ts-ignore
                            queryParams.responsableDossier = {
                                condition: self.queryParameters.responsableDossier.condition,
                                values: self.queryParameters.responsableDossier.values
                            };
                        }

                        if (self.queryParameters.notaire.values?.length > 0) {
                            // @ts-ignore
                            queryParams.notaire = {
                                condition: self.queryParameters.notaire.condition,
                                values: self.queryParameters.notaire.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.sort)) {
                            // @ts-ignore
                            queryParams.tris = {};
                            // @ts-ignore
                            queryParams.tris[self.queryParameters.sort.code] = self.queryParameters.sort.direction;
                        }

                        if (angular.isString(self.queryParameters.keywords)) {
                            // @ts-ignore
                            queryParams.keywords = self.queryParameters.keywords;
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_LOCATIONS:
                        if (self.queryParameters.statut.values?.length > 0) {
                            // @ts-ignore
                            queryParams.statut = {
                                condition: self.queryParameters.statut.condition,
                                values: self.queryParameters.statut.values
                            };
                        }

                        if (self.queryParameters.nature.values?.length > 0) {
                            // @ts-ignore
                            queryParams.nature = {
                                condition: self.queryParameters.nature.condition,
                                values: self.queryParameters.nature.values
                            };
                        }

                        if (self.queryParameters.responsableDossier.values?.length > 0) {
                            // @ts-ignore
                            queryParams.responsableDossier = {
                                condition: self.queryParameters.responsableDossier.condition,
                                values: self.queryParameters.responsableDossier.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.sort)) {
                            // @ts-ignore
                            queryParams.tris = {};
                            // @ts-ignore
                            queryParams.tris[self.queryParameters.sort.code] = self.queryParameters.sort.direction;
                        }

                        if (angular.isString(self.queryParameters.keywords)) {
                            // @ts-ignore
                            queryParams.keywords = self.queryParameters.keywords;
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_ESTIMATIONS:
                        if (self.queryParameters.statut.values?.length > 0) {
                            // @ts-ignore
                            queryParams.statuts = self.queryParameters.statut.values.join(',');
                        }

                        if (self.queryParameters.typeEstimation.values?.length > 0) {
                            // @ts-ignore
                            queryParams.type = {
                                condition: self.queryParameters.typeEstimation.condition,
                                values: self.queryParameters.typeEstimation.values
                            };
                        }

                        if (self.queryParameters.nature.values?.length > 0) {
                            // @ts-ignore
                            queryParams.natures = [];
                            angular.forEach(self.queryParameters.nature.values, function (natureCode) {
                                // @ts-ignore
                                queryParams.natures.push(dictionariesManager.createFromNg2(ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, natureCode)).id);
                            });
                            // @ts-ignore
                            queryParams.natures = queryParams.natures.join(',');
                        }

                        if (self.queryParameters.responsableDossier.values?.length > 0) {
                            // @ts-ignore
                            queryParams.responsablesDossier = self.queryParameters.responsableDossier.values.join(',');
                        }

                        if (angular.isObject(self.queryParameters.sort)) {
                            // @ts-ignore
                            queryParams.tris = {};
                            // @ts-ignore
                            queryParams.tris[self.queryParameters.sort.code] = self.queryParameters.sort.direction;
                        }

                        if (angular.isString(self.queryParameters.keywords)) {
                            // @ts-ignore
                            queryParams.keywords = self.queryParameters.keywords;
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_DEMANDEURS:
                        const date3Months = DateFormat.toDate(DateFormat.addMonthsToDateString(DateFormat.dateFromNow(), -3));
                        const date6Months = DateFormat.toDate(DateFormat.addMonthsToDateString(DateFormat.dateFromNow(), -6));

                        DateFormat.razTime(date3Months);
                        DateFormat.razTime(date6Months);

                        // @ts-ignore
                        if (!angular.isObject(queryParams.recherches)) {
                            // @ts-ignore
                            queryParams.recherches = {};
                        }

                        if (self.queryParameters.recherches.type.values?.length > 0) {
                            // @ts-ignore
                            queryParams.recherches.type = {
                                condition: self.queryParameters.recherches.type.condition,
                                values: self.queryParameters.recherches.type.values
                            };
                        }

                        if (self.queryParameters.recherches.prixVenteMax.min > 0) {
                            // @ts-ignore
                            queryParams.recherches.prixVenteMax = {
                                min: self.queryParameters.recherches.prixVenteMax.min,
                            };
                        }

                        if (self.queryParameters.recherches.prixVenteMin.max > 0) {
                            // @ts-ignore
                            queryParams.recherches.prixVenteMin = {
                                max: self.queryParameters.recherches.prixVenteMin.max,
                            };
                        }

                        if (self.queryParameters.recherches.nature.values?.length > 0) {
                            // @ts-ignore
                            queryParams.recherches.nature = {
                                condition: self.queryParameters.recherches.nature.condition,
                                values: self.queryParameters.recherches.nature.values.map(nature => nature.id),
                            };
                        }

                        if (self.queryParameters.recherches.resultats.vente.values?.length > 0) {
                            // @ts-ignore
                            queryParams.recherches.resultats = {
                                vente: {
                                    condition: self.queryParameters.recherches.resultats.vente.condition,
                                    values: self.queryParameters.recherches.resultats.vente.values
                                }
                            };
                        }

                        if (self.queryParameters.recherches.status.values?.length > 0) {
                            // @ts-ignore
                            queryParams.recherches.status = {
                                condition: self.queryParameters.recherches.status.condition,
                                values: self.queryParameters.recherches.status.values
                            };
                        }

                        if (self.queryParameters.statut.values?.length > 0) {
                            // @ts-ignore
                            queryParams.statut = {
                                condition: self.queryParameters.statut.condition,
                                values: self.queryParameters.statut.values
                            };
                        }
                        // @ts-ignore
                        if (!angular.isObject(queryParams.statut) || !angular.isArray(queryParams.statut.values) || queryParams.statut.values?.length <= 0) {
                            // @ts-ignore
                            queryParams.statut = {
                                condition: ConditionConst.NOT_IN,
                                values: [Demandeur.statuts.ARCHIVE],
                            };
                        }

                        if (self.queryParameters.responsableDossier.values?.length > 0) {
                            // @ts-ignore
                            queryParams.responsableDossier = {
                                condition: self.queryParameters.responsableDossier.condition,
                                values: self.queryParameters.responsableDossier.values
                            };
                        }

                        if (![null, undefined].includes(self.queryParameters.dateCreation.value)) {
                            // @ts-ignore
                            if (!angular.isObject(queryParams.dateCreation)) {
                                // @ts-ignore
                                queryParams.dateCreation = {};
                            }

                            switch (self.queryParameters.dateCreation.value) {
                                case 0:
                                    // @ts-ignore
                                    queryParams.dateCreation.min = DateFormat.toAPI(date3Months);
                                    break;
                                case 1:
                                    // @ts-ignore
                                    queryParams.dateCreation.max = DateFormat.toAPI(date3Months);
                                    // @ts-ignore
                                    queryParams.dateCreation.min = DateFormat.toAPI(date6Months);
                                    break;
                                case 2:
                                    // @ts-ignore
                                    queryParams.dateCreation.max = DateFormat.toAPI(date6Months);
                                    break;
                            }
                        }

                        if (![null, undefined].includes(self.queryParameters.recherches.dateModification.value)) {
                            // @ts-ignore
                            if (!angular.isObject(queryParams.recherches.dateModification)) {
                                // @ts-ignore
                                queryParams.recherches.dateModification = {};
                            }

                            switch (self.queryParameters.recherches.dateModification.value) {
                                case 0:
                                    // @ts-ignore
                                    queryParams.recherches.dateModification.min = DateFormat.toAPI(date3Months);
                                    break;
                                case 1:
                                    // @ts-ignore
                                    queryParams.recherches.dateModification.max = DateFormat.toAPI(date3Months);
                                    // @ts-ignore
                                    queryParams.recherches.dateModification.min = DateFormat.toAPI(date6Months);
                                    break;
                                case 2:
                                    // @ts-ignore
                                    queryParams.recherches.dateModification.max = DateFormat.toAPI(date6Months);
                                    break;
                            }
                        }

                        if (self.queryParameters.typeDemandeur.values?.length > 0) {
                            // @ts-ignore
                            queryParams.typeDemandeur = {
                                condition: self.queryParameters.typeDemandeur.condition,
                                values: map(self.queryParameters.typeDemandeur.values, 'id')
                            };
                        }

                        if (angular.isObject(self.queryParameters.sort)) {
                            // @ts-ignore
                            queryParams.tris = {};
                            // @ts-ignore
                            queryParams.tris[self.queryParameters.sort.code] = self.queryParameters.sort.direction;
                        }

                        if (angular.isString(self.queryParameters.keywords)) {
                            // @ts-ignore
                            queryParams.keywords = self.queryParameters.keywords;
                        }

                        if (self.code === 'correspondants') {
                            // @ts-ignore
                            if (queryParams.recherches && queryParams.recherches.status && queryParams.recherches.resultats) {
                                // @ts-ignore
                                queryParams.recherches.status = undefined;
                                // @ts-ignore
                                queryParams.recherches.resultats.status = {
                                    condition: ConditionConst.IN,
                                    values: ['match', 'match_updated']
                                };
                                // @ts-ignore
                                queryParams.recherches.resultats.interest = {
                                    condition: ConditionConst.NOT_IN,
                                    values: [
                                        DemandeurRechercheResultat.interests.DISINTERESTED,
                                        DemandeurRechercheResultat.interests.DISINTERESTED_EXCEPT_PRICE,
                                    ],
                                };
                            }
                        }

                        break;

                    case Ng2Filter.routes.GET_VENTES:
                        if (angular.isObject(self.queryParameters.statut) && self.queryParameters.statut.values?.length > 0) {
                            // @ts-ignore
                            queryParams.statut = {
                                condition: self.queryParameters.statut.condition,
                                values: self.queryParameters.statut.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.departements)) {
                            angular.forEach(self.queryParameters.departements.values, function (value) {
                                if (angular.isObject(value)) {
                                    // @ts-ignore
                                    queryParams.areas = {
                                        condition: self.queryParameters.departements.condition,
                                        values: map(self.queryParameters.departements.values, 'id')
                                    };
                                }
                            });
                        }

                        if (angular.isObject(self.queryParameters.areas) && self.queryParameters.areas.values?.length > 0) {
                            // @ts-ignore
                            queryParams.areas = {
                                condition: self.queryParameters.areas.condition,
                                values: self.queryParameters.areas.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.commune) && self.queryParameters.commune.values?.length > 0) {
                            // @ts-ignore
                            queryParams.commune = {
                                condition: self.queryParameters.commune.condition,
                                values: self.queryParameters.commune.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.dateModification)) {
                            // @ts-ignore
                            queryParams.dateModification = {};
                            if (self.queryParameters.dateModification.prix) {
                                // @ts-ignore
                                queryParams.dateModification.prix = true;
                            }

                            if (self.queryParameters.dateModification.compromis) {
                                // @ts-ignore
                                queryParams.dateModification.compromis = true;
                            }

                            if (self.queryParameters.dateModification.archive) {
                                // @ts-ignore
                                queryParams.dateModification.archive = true;
                            }
                        }

                        if (self.queryParameters.sorts.length > 0) {
                            // @ts-ignore
                            queryParams.tris = {};
                            angular.forEach(self.queryParameters.sorts, function (sort) {
                                // @ts-ignore
                                queryParams.tris[sort.code] = sort.direction;
                            });
                        }

                        break;

                    case Ng2Filter.routes.GET_RECHERCHE_RESULTATS:
                        if (self.queryParameters.interest.values?.length > 0) {
                            // @ts-ignore
                            queryParams.interest = {
                                condition: self.queryParameters.interest.condition,
                                values: self.queryParameters.interest.values
                            };
                        }

                        if (self.queryParameters.status.values?.length > 0) {
                            // @ts-ignore
                            queryParams.status = {
                                condition: self.queryParameters.status.condition,
                                values: self.queryParameters.status.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.sort)) {
                            // @ts-ignore
                            queryParams.tris = {};
                            if (self.queryParameters.sort.code === 'status') {
                                // @ts-ignore
                                queryParams.tris.interest = self.queryParameters.sort.direction;
                                // @ts-ignore
                                queryParams.tris.status = self.queryParameters.sort.direction;
                                // @ts-ignore
                                queryParams.tris.currentEtude = self.queryParameters.sort.direction;
                            } else {
                                // @ts-ignore
                                queryParams.tris[self.queryParameters.sort.code] = self.queryParameters.sort.direction;
                            }
                        }

                        if (angular.isString(self.queryParameters.keywords)) {
                            // @ts-ignore
                            queryParams.keywords = self.queryParameters.keywords;
                        }

                        break;

                    case Ng2Filter.routes.GET_ETUDE_REPORTS:
                        if (self.queryParameters.dossiersDemandeur.values?.length > 0) {
                            // @ts-ignore
                            queryParams.dossiersDemandeur = {
                                condition: self.queryParameters.dossiersDemandeur.condition,
                                values: self.queryParameters.dossiersDemandeur.values
                            };
                        }

                        if (self.queryParameters.dossiersVente.values?.length > 0) {
                            // @ts-ignore
                            queryParams.dossiersVente = {
                                condition: self.queryParameters.dossiersVente.condition,
                                values: self.queryParameters.dossiersVente.values
                            };
                        }

                        if (self.queryParameters.dossiersLocation.values?.length > 0) {
                            // @ts-ignore
                            queryParams.dossiersLocation = {
                                condition: self.queryParameters.dossiersLocation.condition,
                                values: self.queryParameters.dossiersLocation.values
                            };
                        }

                        if (self.queryParameters.dossiersEstimation.values?.length > 0) {
                            // @ts-ignore
                            queryParams.dossiersEstimation = {
                                condition: self.queryParameters.dossiersEstimation.condition,
                                values: self.queryParameters.dossiersEstimation.values
                            };
                        }

                        if (self.queryParameters.sorts.length > 0) {
                            // @ts-ignore
                            queryParams.tris = {};
                            angular.forEach(self.queryParameters.sorts, function (sort) {
                                // @ts-ignore
                                queryParams.tris[sort.code] = sort.direction;
                            });
                        }

                        break;

                    case Ng2Filter.routes.GET_LOCATIONS:
                        if (angular.isObject(self.queryParameters.statut) && self.queryParameters.statut.values?.length > 0) {
                            // @ts-ignore
                            queryParams.statut = {
                                condition: self.queryParameters.statut.condition,
                                values: self.queryParameters.statut.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.departements)) {
                            angular.forEach(self.queryParameters.departements.values, function (value) {
                                if (angular.isObject(value)) {
                                    // @ts-ignore
                                    queryParams.areas = {
                                        condition: self.queryParameters.departements.condition,
                                        values: map(self.queryParameters.departements.values, 'id')
                                    };
                                }
                            });
                        }

                        if (angular.isObject(self.queryParameters.areas) && self.queryParameters.areas.values?.length > 0) {
                            // @ts-ignore
                            queryParams.areas = {
                                condition: self.queryParameters.areas.condition,
                                values: self.queryParameters.areas.values
                            };
                        }

                        if (angular.isObject(self.queryParameters.commune) && self.queryParameters.commune.values?.length > 0) {
                            // @ts-ignore
                            queryParams.commune = {
                                condition: self.queryParameters.commune.condition,
                                values: self.queryParameters.commune.values
                            };
                        }

                        if (self.queryParameters.sorts.length > 0) {
                            // @ts-ignore
                            queryParams.tris = {};
                            angular.forEach(self.queryParameters.sorts, function (sort) {
                                // @ts-ignore
                                queryParams.tris[sort.code] = sort.direction;
                            });
                        }

                        break;
                }

                return queryParams;
            };

            // @ts-ignore
            return new FilterManager();
        }
    })(angularJS);
}
