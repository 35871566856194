import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewCoutsService
} from '@features/dossiers/dossier/bien/overview/couts/dossier-bien-overview.couts.service';

@Component({
    selector: 'app-dossier-bien-overview-couts',
    standalone: false,
    templateUrl: 'dossier-bien-overview.couts.component.html',
})
export class AppDossierBienOverviewCoutsComponent {
    private _dossierBienOverviewCoutsService = inject(DossierBienOverviewCoutsService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isChargesVisible(): boolean {
        return this._dossierBienOverviewCoutsService.isChargesVisible;
    }

    get isCommentsCoutsVisible(): boolean {
        return this._dossierBienOverviewCoutsService.isCommentsCoutsVisible;
    }
}
