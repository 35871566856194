import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject, switchMap} from 'rxjs';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import CDossierFiles from '@models/dossiers/dossier/files/collection/dossier-files.collection.model';
import {filter, map, take, takeUntil} from 'rxjs/operators';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import {
    DossierFileDropdownComponent
} from '@features/dossiers/dossier/files/file/dropdown/dossier-file.dropdown.component';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {IDossierFilesListOptions} from '@features/dossiers/dossiers.interfaces';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';

@Component({selector: 'app-dossier-files-list', standalone: false, templateUrl: 'dossier-files-list.component.html'})
export class AppDossierFilesListComponent implements OnDestroy, OnInit {
    static readonly initDossierFilesListOptions: IDossierFilesListOptions = {
        displayColumnAction: true,
        displayColumnShare: true,
        nameSelection: 'dossier-files-list-selection',
        enabledSelection: true,
    };
    readonly clicked = output<IDropdownClicked>();
    readonly dossierFilesSelected = output<DossierFile[]>();
    private _cDossierFilesService = inject(CDossierFilesService);
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _dropdownService = inject(DropdownService);
    private _cDossierFiles$!: Observable<CDossierFiles>;
    private _hideDossierFileUuidContacts: string[] = [];
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IDossierFilesListOptions = {...AppDossierFilesListComponent.initDossierFilesListOptions};
    private _pendingGetMore = false;

    get cDossierFiles$(): Observable<CDossierFiles> {
        return this._cDossierFiles$;
    }

    @Input()
    set cDossierFiles$(value$: Observable<CDossierFiles>) {
        this._cDossierFiles$ = value$;
    }

    get hideDossierFileUuidContacts(): string[] {
        return this._hideDossierFileUuidContacts;
    }

    get options(): IDossierFilesListOptions {
        return this._options;
    }

    @Input()
    set options(value: IDossierFilesListOptions) {
        this._options = {...AppDossierFilesListComponent.initDossierFilesListOptions, ...value};
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    @Input()
    set sharingDossierFileUuids(value: string[]) {
        this._hideDossierFileUuidContacts = value;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.clicked.emit(dropdownClicked));
        this._collectionSelectionService.clear(this._options.nameSelection!);
        this._collectionSelectionService.isInit$(this._options.nameSelection!).pipe(
            filter(isInit => isInit),
            switchMap(_ => this._collectionSelectionService.getListSelected$(this._options.nameSelection!)),
            map(listSelected => listSelected as Set<DossierFile>),
            map(listSelected => Array.from(listSelected)),
            takeUntil(this._onDestroy$),
        ).subscribe(listSelected => this.dossierFilesSelected.emit(listSelected));
    }

    ngOnDestroy(): void {
        this._collectionSelectionService.delete(this._options.nameSelection!);
        this._onDestroy$.next();
    }

    clickOnDossierFile(htmlButtonElement: HTMLButtonElement, dossierFile: DossierFile): void {
        this._dropdownService.open(htmlButtonElement, {
            component: DossierFileDropdownComponent,
            data: {dossierFile, options: this.options.dropdownOptions},
        });
    }

    getMore(cDossierFiles: CDossierFiles): void {
        this._pendingGetMore = true;
        this._cDossierFilesService.addNext$(cDossierFiles).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }

    isEnabled(dossierFile: DossierFile): boolean {
        if (!this.options.filterEnabled) {
            return true;
        }

        return this.options.filterEnabled(dossierFile);
    }
}
