import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEstimationsReferencesCriteria]'})
export class NgEstimationsReferencesCriteriaDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationsReferencesCriteriaCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgEstimationsReferencesCriteriaDirective],
    selector: 'app-ng-estimations-references-criteria',
    template: '<div appNgEstimationsReferencesCriteria></div>',
})
export class NgEstimationsReferencesCriteriaComponent {
}
