import {Component, Input, OnDestroy} from '@angular/core';
import {PDFDocumentProxy, PdfViewerModule} from 'ng2-pdf-viewer';
import {BehaviorSubject, delay, Observable, of, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {AsyncPipe, NgClass, NgTemplateOutlet} from '@angular/common';
import {LoadModule} from '@shared/load/load.module';

// Voir https://github.com/VadimDez/ng2-pdf-viewer/issues/624 concernant l'entrée dans angular.json "allowedCommonJsDependencies"
@Component({
    // @todo Supprimer l'import de *Module pour préférer seulement *Component quand ceux-ci seront standalone
    imports: [AsyncPipe, LoadModule, NgClass, NgTemplateOutlet, PdfViewerModule],
    selector: 'app-pdf',
    styleUrls: ['pdf.component.scss'],
    templateUrl: 'pdf.component.html',
})
export class AppPdfComponent implements OnDestroy {
    private readonly _onDestroy$ = new Subject<void>();
    private _pdfNbPages!: number;
    private _pdfPathSource = new BehaviorSubject<string>(undefined!);
    private _pdfPath$ = this._pdfPathSource.asObservable();

    get pdfNbPages(): number {
        return this._pdfNbPages;
    }

    @Input()
    set pdfPath(value: string) {
        this.pdfPath$ = of(value);
    }

    get pdfPath$(): Observable<string> {
        return this._pdfPath$;
    }

    @Input()
    set pdfPath$(value: Observable<string>) {
        value.pipe(
            tap(_ => this._pdfNbPages = 0),
            tap(_ => this._pdfPathSource.next(undefined!)),
            delay(1),
            takeUntil(this._onDestroy$),
        ).subscribe(path => this._pdfPathSource.next(path));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    getPdfDocument(pdfDocument: PDFDocumentProxy): void {
        this._pdfNbPages = pdfDocument.numPages > 0 ? pdfDocument.numPages : 1;
    }
}
