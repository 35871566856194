import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgVenteDiffusion]'})
export class NgVenteDiffusionDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVenteDiffusionCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgVenteDiffusionDirective],
    selector: 'app-ng-vente-diffusion',
    template: '<div appNgVenteDiffusion></div>',
})
export class NgVenteDiffusionComponent {
}
