import AModel from '@models/model.abstract';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {DossierType} from '@models/dossiers/dossiers.interfaces';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {NoteoStatutColorConst, NoteoStatutColorType} from '@shared/noteo/noteo.constants';

export default abstract class ADossier extends AModel {
    static readonly abstractStatuts = {
        ARCHIVE: 'archive',
        BROUILLON: 'brouillon',
        COMPROMIS: 'compromis',
        DIFFUSE: 'diffuse',
        DISPONIBLE: 'disponible',
        EN_RECHERCHE: 'en_recherche',
        EN_SUSPENS: 'en_suspens',
        MANDAT: 'mandat',
        NEW: AModel.STATUT_NEW,
        REMIS: 'remis',
        SUSPENDU: 'suspendu',
    };
    readonly dossierType: DossierType = null!;
    protected _interne = false;
    private _colorStatut!: NoteoStatutColorType;
    private _dateCreation!: string;
    private readonly _id: number;
    private _labelStatut!: string;
    private _linkContactsGroup!: string;
    private _reference: string;
    private _statut!: string;

    constructor(uuid: string, id?: number, reference?: string) {
        super(uuid);
        this._id = id!;
        this._reference = reference!;
    }

    get colorStatut(): NoteoStatutColorType {
        return this._colorStatut;
    }

    get convivialName(): string {
        return (this.contactsGroup || {nom: ContactsGroup.NO_NAME}).nom + (this.reference ? (' (' + this.reference + ')') : '');
    }

    get dateCreation(): string {
        return this._dateCreation;
    }

    set dateCreation(value: string) {
        this._dateCreation = value;
    }

    get id(): number {
        return this._id;
    }

    get interne(): boolean {
        return this._interne;
    }

    get labelStatut(): string {
        return this._labelStatut;
    }

    get linkContactsGroup(): string {
        return this._linkContactsGroup;
    }

    set linkContactsGroup(value: string) {
        this._linkContactsGroup = value;
    }

    get statut(): string {
        return this._statut;
    }

    set statut(value: string) {
        this._statut = value;
        switch (this._statut) {
            case ADossier.abstractStatuts.ARCHIVE :
                this._colorStatut = NoteoStatutColorConst.STONE;
                this._labelStatut = this.isDemandeur() ? 'archivé' : 'archivée';
                break;
            case ADossier.abstractStatuts.BROUILLON :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'brouillon';
                break;
            case ADossier.abstractStatuts.COMPROMIS :
                this._colorStatut = NoteoStatutColorConst.ORANGE;
                this._labelStatut = 'compromis';
                break;
            case ADossier.abstractStatuts.DIFFUSE :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = this.isDemandeur() ? 'diffusé' : 'diffusée';
                break;
            case ADossier.abstractStatuts.DISPONIBLE :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'disponible';
                break;
            case ADossier.abstractStatuts.EN_RECHERCHE :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = 'en recherche';
                break;
            case ADossier.abstractStatuts.EN_SUSPENS :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'en suspens';
                break;
            case ADossier.abstractStatuts.MANDAT :
                this._colorStatut = NoteoStatutColorConst.BLUE;
                this._labelStatut = 'mandat';
                break;
            case ADossier.abstractStatuts.NEW :
                this._colorStatut = NoteoStatutColorConst.ROSE;
                this._labelStatut = 'nouveau';
                break;
            case ADossier.abstractStatuts.REMIS :
                this._colorStatut = NoteoStatutColorConst.GREEN;
                this._labelStatut = this.isDemandeur() ? 'remis' : 'remise';
                break;
            case ADossier.abstractStatuts.SUSPENDU :
                this._colorStatut = NoteoStatutColorConst.ORANGE;
                this._labelStatut = this.isDemandeur() ? 'suspendu' : 'suspendue';
                break;
            default:
                this._labelStatut = undefined!;
        }
    }

    get reference(): string {
        return this._reference;
    }

    isArchived(): boolean {
        return this.statut === ADossier.abstractStatuts.ARCHIVE;
    }

    isBrouillon(): boolean {
        return this.statut === ADossier.abstractStatuts.BROUILLON;
    }

    isCompromis(): boolean {
        return this.statut === ADossier.abstractStatuts.COMPROMIS;
    }

    isDemandeur(): boolean {
        return this.dossierType === DossierTypesConst.DEMANDEUR;
    }

    isDiffused(): boolean {
        return this.statut === ADossier.abstractStatuts.DIFFUSE;
    }

    isEstimation(): boolean {
        return this.dossierType === DossierTypesConst.ESTIMATION;
    }

    isLocation(): boolean {
        return this.dossierType === DossierTypesConst.LOCATION;
    }

    isMandat(): boolean {
        return this.statut === ADossier.abstractStatuts.MANDAT;
    }

    isNew(): boolean {
        return this.uuid === ADossier.abstractStatuts.NEW;
    }

    isNewOrBrouillon(): boolean {
        return this.isBrouillon() || this.isNew();
    }

    isNewOrIdNullish(): boolean {
        return this.isNew() || !this.id;
    }

    isSuspended(): boolean {
        return this.statut === ADossier.abstractStatuts.SUSPENDU;
    }

    isVente(): boolean {
        return this.dossierType === DossierTypesConst.VENTE;
    }

    setInterne(value: boolean): void {
        this._interne = value;
    }

    abstract get contactsGroup(): ContactsGroup;

    abstract get responsableId(): number;
}
