import {Component, inject, Input} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {VentePriceFactory} from '@models/ventes/vente/price/vente-price.factory';
import VentePrice from '@models/ventes/vente/price/vente-price.model';
import {IVentePricingOptions} from '@features/ventes/ventes.interfaces';

@Component({selector: 'app-vente-pricing', standalone: false, templateUrl: 'vente-pricing.component.html'})
export class AppVentePricingComponent {
    static readonly initVentePricingOptions: IVentePricingOptions = {details: 'simple'};
    private _ventePriceFactory = inject(VentePriceFactory);
    private _options: IVentePricingOptions = {...AppVentePricingComponent.initVentePricingOptions};
    private _isPrive = false;
    private _isPublic = false;
    private _vente!: Vente;
    private _ventePrice!: VentePrice;

    get isPrive(): boolean {
        return this._isPrive;
    }

    get isPublic(): boolean {
        return this._isPublic;
    }

    get options(): IVentePricingOptions {
        return this._options;
    }

    @Input()
    set options(value: IVentePricingOptions) {
        this._options = {...AppVentePricingComponent.initVentePricingOptions, ...value};
        this._isPrive = this._options.details === 'prive';
        this._isPublic = this._options.details === 'public' || this._options.details === 'prive';
    }

    @Input({required: true})
    set vente(value: Vente) {
        this._vente = value;
        // @todo Est-ce que ce ne serait pas mieux d'avoir des champs directement dans Vente ?
        this._ventePrice = this._ventePriceFactory.createFromVente(this.vente);
    }

    get vente(): Vente {
        return this._vente;
    }

    get ventePrice(): VentePrice {
        return this._ventePrice;
    }
}
