import {inject, Injectable} from '@angular/core';
import {UsersApiService} from '@models/users/users.api.service';
import CUsers from '@models/users/collection/users.collection.model';
import {Observable} from 'rxjs';
import {ICUsersQueryParameters, ICUsersApi} from '@models/users/collection/users.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import {ConditionConst} from '@shared/constants';
import {IFilterQueryParametersApi} from '@models/filters/filter/filter.interfaces';
import {UsersFactory} from '@models/users/users.factory';

@Injectable({providedIn: 'root'})
export class CUsersFactory {
    private _usersFactory = inject(UsersFactory);
    private _usersApiService = inject(UsersApiService);

    create(cUsersApi: ICUsersApi): CUsers {
        const cUsers = new CUsers();

        cUsers.links = new Links(cUsersApi._links);
        cUsers.page = cUsersApi.page;
        cUsers.pages = cUsersApi.pages;
        cUsers.perPage = cUsersApi.limit;
        cUsers.total = cUsersApi.total;
        if (cUsersApi._embedded) {
            cUsers.results = this._usersFactory.create(cUsersApi._embedded.items);
        }

        return cUsers;
    }

    createQueryParameters(filterQueryParametersApi: IFilterQueryParametersApi): ICUsersQueryParameters {
        const queryParameters = this.createVirginQueryParameters();

        queryParameters.commune = filterQueryParametersApi.commune ?? queryParameters.commune;
        queryParameters.etude = filterQueryParametersApi.etude ?? queryParameters.etude;
        queryParameters.role = filterQueryParametersApi.role ?? queryParameters.role;

        return queryParameters;
    }

    createVirginQueryParameters(): ICUsersQueryParameters {
        return {
            commune: {condition: ConditionConst.IN, values: []},
            etude: {condition: ConditionConst.IN, values: []},
            role: {condition: ConditionConst.IN, values: []},
        };
    }

    get$(queryParameters?: ICUsersQueryParameters): Observable<CUsers> {
        return this._usersApiService.getCollection$(queryParameters).pipe(map(cUsersApi => this.create(cUsersApi)));
    }

    getByLink$(link: string): Observable<CUsers> {
        return this._usersApiService.getCollectionByLink$(link).pipe(map(cUsersApi => this.create(cUsersApi)));
    }

    getEtude$(etude: Etude, queryParameters?: ICUsersQueryParameters): Observable<CUsers> {
        return this._usersApiService.getEtudeCollection$(etude.id.toString(), queryParameters).pipe(map(cUsersApi => this.create(cUsersApi)));
    }

    getNext$(cUsers: CUsers): Observable<CUsers> {
        return this._usersApiService.getCollectionByLink$(cUsers.links.next).pipe(map(cUsersApi => this.create(cUsersApi)));
    }
}
