import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {
    ICReferencesApi, ICReferencesQueryParameters
} from '@models/references/collection/references.collection.interfaces';
import CReferences from '@models/references/collection/references.collection.model';
import {ReferenceFactory} from '@models/references/reference/reference.factory';
import {Observable} from 'rxjs';
import {ReferencesApiService} from '@models/references/references.api.service';
import {map} from 'rxjs/operators';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Transfer from '@models/transfers/transfer/transfer.model';
import {TransferFactory} from '@models/transfers/transfer/transfer.factory';
import {HttpOptions} from '@core/api/api.interfaces';
import {IQueryParameters} from '@shared/texts/texts.interfaces';
import SearchCriteria from '@models/search/criteria/search-criteria.model';

@Injectable({providedIn: 'root'})
export class CReferencesFactory {
    private _referenceFactory = inject(ReferenceFactory);
    private _referencesApiService = inject(ReferencesApiService);
    private _transferFactory = inject(TransferFactory);

    create(cReferencesApi: ICReferencesApi): CReferences {
        const cReferences = this.createVirgin();

        cReferences.links = new Links(cReferencesApi._links);
        cReferences.page = cReferencesApi.page;
        cReferences.pages = cReferencesApi.pages;
        cReferences.perPage = cReferencesApi.limit;
        cReferences.total = cReferencesApi.total;
        if (cReferencesApi._embedded) {
            cReferences.results = (cReferencesApi._embedded.items || []).filter(referenceApi => referenceApi)
                .map(referenceApi => this._referenceFactory.create(referenceApi));
        }

        return cReferences;
    }

    createVirgin(): CReferences {
        const cReferences = new CReferences();

        cReferences.links = new Links();

        return cReferences;
    }

    get$(queryParameters?: IQueryParameters, httpOptions?: HttpOptions): Observable<CReferences> {
        return this._referencesApiService.getCollection$(queryParameters, httpOptions).pipe(map(cReferencesApi => this.create(cReferencesApi)));
    }

    getNext$(cReferences: CReferences): Observable<CReferences> {
        return this._referencesApiService.getCollectionByLink$(cReferences.links.next).pipe(map(cReferencesApi => this.create(cReferencesApi)));
    }

    getQueryParametersFromSearchCriteria(searchCriteria: SearchCriteria): ICReferencesQueryParameters {
        const queryParameters = {
            source: searchCriteria.getCReferencesQueryParametersSource(),
            tris: searchCriteria.tris,
        } as ICReferencesQueryParameters;

        if (searchCriteria.archiveDateVenteMax || searchCriteria.archiveDateVenteMin) {
            queryParameters.dateVente = {
                max: searchCriteria.archiveDateVenteMax || CReferences.QUERY_PARAMETERS_DEFAULT.dateVente.max,
                min: searchCriteria.archiveDateVenteMin || CReferences.QUERY_PARAMETERS_DEFAULT.dateVente.min,
            };
        }

        if (searchCriteria.loyerMax || searchCriteria.loyerMin) {
            queryParameters.loyer = {};
            if (searchCriteria.loyerMax) {
                queryParameters.loyer.max = searchCriteria.loyerMax;
            }

            if (searchCriteria.loyerMin) {
                queryParameters.loyer.min = searchCriteria.loyerMin;
            }
        }

        if (searchCriteria.nombreChambresMax > 0 || searchCriteria.nombreChambresMin > 0) {
            queryParameters.nombreChambres = {};
            if (searchCriteria.nombreChambresMax > 0) {
                queryParameters.nombreChambres.max = searchCriteria.nombreChambresMax;
            }

            if (searchCriteria.nombreChambresMin > 0) {
                queryParameters.nombreChambres.min = searchCriteria.nombreChambresMin;
            }
        }

        if (searchCriteria.nombreEtagesMax > 0 || searchCriteria.nombreEtagesMin > 0) {
            queryParameters.nombreEtages = {};
            if (searchCriteria.nombreEtagesMax > 0) {
                queryParameters.nombreEtages.max = searchCriteria.nombreEtagesMax;
            }

            if (searchCriteria.nombreEtagesMin > 0) {
                queryParameters.nombreEtages.min = searchCriteria.nombreEtagesMin;
            }
        }

        if (searchCriteria.nombrePiecesMax > 0 || searchCriteria.nombrePiecesMin > 0) {
            queryParameters.nombrePieces = {};
            if (searchCriteria.nombrePiecesMax > 0) {
                queryParameters.nombrePieces.max = searchCriteria.nombrePiecesMax;
            }

            if (searchCriteria.nombrePiecesMin > 0) {
                queryParameters.nombrePieces.min = searchCriteria.nombrePiecesMin;
            }
        }

        if (searchCriteria.archivePrixCessionMax > 0 || searchCriteria.archivePrixCessionMin > 0) {
            queryParameters.prixVente = {};
            if (searchCriteria.archivePrixCessionMax > 0) {
                queryParameters.prixVente.max = searchCriteria.archivePrixCessionMax;
            }

            if (searchCriteria.archivePrixCessionMin > 0) {
                queryParameters.prixVente.min = searchCriteria.archivePrixCessionMin;
            }
        }

        if (searchCriteria.superficieMax > 0 || searchCriteria.superficieMin > 0) {
            queryParameters.superficie = {};
            if (searchCriteria.superficieMax > 0) {
                queryParameters.superficie.max = searchCriteria.superficieMax;
            }

            if (searchCriteria.superficieMin > 0) {
                queryParameters.superficie.min = searchCriteria.superficieMin;
            }
        }

        if (searchCriteria.surfaceCadastraleMax > 0 || searchCriteria.surfaceCadastraleMin > 0) {
            queryParameters.surfaceCadastrale = {};
            if (searchCriteria.surfaceCadastraleMax > 0) {
                queryParameters.surfaceCadastrale.max = searchCriteria.surfaceCadastraleMax;
            }

            if (searchCriteria.surfaceCadastraleMin > 0) {
                queryParameters.surfaceCadastrale.min = searchCriteria.surfaceCadastraleMin;
            }
        }

        if (searchCriteria.communes.length > 0) {
            queryParameters.commune = searchCriteria.communes.map(commune => commune.id);
        }

        if (searchCriteria.departements.length > 0) {
            queryParameters.departement = searchCriteria.departements.map(departement => departement.id);
        }

        if (searchCriteria.epoques.length > 0) {
            queryParameters.epoque = searchCriteria.epoques.map(epoque => epoque.id);
        }

        if (searchCriteria.natures.length > 0) {
            queryParameters.nature = searchCriteria.natures.map(nature => nature.id);
        }

        if (searchCriteria.voie) {
            queryParameters.adresse = searchCriteria.voie.nom;
        } else if (searchCriteria.nomVoie) {
            queryParameters.adresse = searchCriteria.nomVoie;
        }

        return queryParameters;
    }

    import(file: File, nature: DictionaryItem): Transfer<ICReferencesApi, CReferences> {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('nature', nature.id.toString());

        const creation = (cReferencesApi: ICReferencesApi) => {
            cReferencesApi.page = 1;
            cReferencesApi.pages = 1;
            cReferencesApi.limit = cReferencesApi._embedded.items.length;
            cReferencesApi.total = cReferencesApi._embedded.items.length;

            return this.create.call(this, cReferencesApi);
        };
        const transfer = this._transferFactory.create<ICReferencesApi, CReferences>(this._referencesApiService.import$(formData), creation);

        transfer.start();

        return transfer;
    }
}
