import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {IFormSearchInputOptions} from '@shared/form/form.interfaces';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {ProvideParentFormDirective} from '@shared/form/provide-parent-form.directive';
import {FormsModule} from '@angular/forms';

@Component({
    imports: [FaIconComponent, FormsModule, ProvideParentFormDirective],
    selector: 'app-form-search-input',
    styleUrls: ['form-search-input.component.scss'],
    templateUrl: 'form-search-input.component.html',
})
export class AppFormSearchInputComponent implements AfterViewInit, OnDestroy, OnInit {
    readonly searchedText = output<string>();
    @ViewChild('formSearchInput') formSearchInput!: ElementRef<HTMLInputElement>;

    static readonly initFormSearchInputOptions: IFormSearchInputOptions = {hasFocus: false, placeholder: 'Rechercher'};
    private readonly _onDestroy$ = new Subject<void>();
    private _loading = false;
    private _model!: string;
    private _options: IFormSearchInputOptions = {...AppFormSearchInputComponent.initFormSearchInputOptions};
    private _searchSource = new Subject<string>();

    get loading(): boolean {
        return this._loading;
    }

    @Input()
    set loading(value: boolean) {
        this._loading = value;
    }

    get model(): string {
        return this._model;
    }

    @Input()
    set model(value: string) {
        this._model = value;
    }

    get options(): IFormSearchInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSearchInputOptions) {
        this._options = {...AppFormSearchInputComponent.initFormSearchInputOptions, ...value};
    }

    ngOnInit(): void {
        this._searchSource.asObservable().pipe(
            debounceTime(200),
            distinctUntilChanged(),
            takeUntil(this._onDestroy$),
        ).subscribe(search => this.searchedText.emit(search));
    }

    ngAfterViewInit(): void {
        if (this._options.hasFocus) {
            this.formSearchInput.nativeElement.focus();
        }
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    onInput(): void {
        this._searchSource.next(this._model);
    }
}
