import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskDemandeurResults]'})
export class NgDemandeurResultsDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeurResults', elementRef, injector);
    }
}

@Component({
    imports: [NgDemandeurResultsDirective],
    selector: 'app-ng-demandeur-results',
    template: '<div appNgEskDemandeurResults></div>',
})
export class NgDemandeurResultsComponent {
}
