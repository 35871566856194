import {inject, Injectable} from '@angular/core';
import {VentePrixHistoryFactory} from '@models/ventes/vente/prix-histories/prix-history/vente-prix-history.factory';
import {IVentePrixHistoryApi} from '@models/ventes/vente/prix-histories/prix-history/vente-prix-history.interfaces';
import VentePrixHistory from '@models/ventes/vente/prix-histories/prix-history/vente-prix-history.model';

@Injectable({providedIn: 'root'})
export class VentePrixHistoriesFactory {
    private _ventePrixHistoryFactory = inject(VentePrixHistoryFactory);

    create(ventePasserelleApis: IVentePrixHistoryApi[] = []): VentePrixHistory[] {
        return (ventePasserelleApis || []).filter(ventePasserelleApi => ventePasserelleApi)
            .map(ventePasserelleApi => this._ventePrixHistoryFactory.create(ventePasserelleApi));
    }
}
