import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';

@Component({selector: 'bien-edit-sanitaires', standalone: false, templateUrl: 'bien.edit-sanitaires.component.html'})
export class DCBienEditSanitairesComponent implements IDynamicComponent {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _modalService = inject(ModalService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._dossierBien = dynamicComponentData.dossierBien as ADossierBien;
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        this._modalService.setResponse(this._dossierBien);
    }

    showFieldForBien(field: string): boolean {
        return this._dossierBienFieldsService.isEditionVisible(this._dossierBien, field);
    }
}
