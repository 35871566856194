import {inject, Injectable} from '@angular/core';
import {EtudeConstraintFactory} from '@models/etudes/etude/constraints/constraint/etude-constraint.factory';
import {IEtudeConstraintApi} from '@models/etudes/etude/constraints/constraint/etude-constraint.interfaces';
import EtudeConstraint from '@models/etudes/etude/constraints/constraint/etude-constraint.model';

@Injectable({providedIn: 'root'})
export class EtudeConstraintsFactory {
    private _etudeConstraintFactory = inject(EtudeConstraintFactory);

    create(etudeConstraintApis: IEtudeConstraintApi[] = []): EtudeConstraint[] {
        return (etudeConstraintApis || []).filter(etudeConstraintApi => etudeConstraintApi)
            .map(etudeConstraintApi => this._etudeConstraintFactory.create(etudeConstraintApi));
    }
}
