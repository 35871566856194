import {Component, inject, OnInit} from '@angular/core';
import {Observable, ReplaySubject, take} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {map, tap} from 'rxjs/operators';
import CityscanOnsaleProperty from '@models/cityscan-onsale-properties/onsale-property/cityscan-onsale-property.model';
import {IDCCityscanOnsalePropertyData} from '@features/cityscan/cityscan.interfaces';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {UrlService} from '@shared/texts/url/url.service';

@Component({
    selector: 'dc-cityscan-onsale-property-slide-over',
    standalone: false,
    templateUrl: 'cityscan-onsale-property.slide-over.component.html',
})
export class DCCityscanOnsalePropertySlideOverComponent implements IDynamicComponent, OnInit {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _urlService = inject(UrlService);
    private _cityscanOnsalePropertySource = new ReplaySubject<CityscanOnsaleProperty>(1);
    private _cityscanOnsaleProperty$ = this._cityscanOnsalePropertySource.asObservable();
    private _hostname!: string;
    private _nature!: DictionaryItem;
    private _superficieLabel!: string;

    set data(dynamicComponentData: IDCCityscanOnsalePropertyData) {
        this._cityscanOnsalePropertySource.next(dynamicComponentData.cityscanOnsaleProperty!);
    }

    get cityscanOnsaleProperty$(): Observable<CityscanOnsaleProperty> {
        return this._cityscanOnsaleProperty$;
    }

    get hostname(): string {
        return this._hostname;
    }

    get nature(): DictionaryItem {
        return this._nature;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._cityscanOnsaleProperty$.pipe(
            tap(cityscanOnsaleProperty => this._hostname = this._urlService.getHostname(cityscanOnsaleProperty.url)),
            map(cityscanOnsaleProperty => this._dictionaryItemService.getById(Dictionary.names.NATURES, cityscanOnsaleProperty.natureId)),
            tap(nature => this._nature = nature),
            map(nature => this._dictionaryItemService.getOneSuperficieLabel(nature)),
            take(1),
        ).subscribe(superficieLabel => this._superficieLabel = superficieLabel);
    }
}
