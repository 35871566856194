import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgFilter} from '@legacy/app/managers/ressources';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {UserService} from '@models/users/user/user.service';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {UserFactory} from '@models/users/user/user.factory';
import {take, tap} from 'rxjs/operators';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionaryFactory} from '@models/dictionaries/dictionary/dictionary.factory';
import {ConditionConst} from '@shared/constants';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import {ModalService} from '@shared/modal/modal.service';
import {FilterFactory} from '@models/filters/filter/filter.factory';

export default function getDemandeursModalFiltersWalletCurrentCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('DemandeursModalFiltersWalletCurrentCtrl', Controller);

        /**
         * Controller to manage filters on wallet current
         *
         * @param filter
         * @param Ng2EtudeService
         * @param $uibModalInstance
         * @param SoqSweetAlert
         * @param $translate
         * @param DictionariesManager
         * @param Ng2UserService
         * @param Ng2UserFactory
         * @param Ng2DictionaryItemService
         * @param Ng2DictionaryFactory
         * @param Ng2ModalService
         * @param Ng2FilterFactory
         */
        Controller.$inject = ['filter', 'Ng2EtudeService', '$uibModalInstance', 'SoqSweetAlert', '$translate', 'DictionariesManager', 'Ng2UserService', 'Ng2UserFactory', 'Ng2DictionaryItemService', 'Ng2DictionaryFactory', 'Ng2ModalService', 'Ng2FilterFactory'];
        function Controller(this: any,
                            filter: NgFilter,
                            ng2EtudeService: EtudeService,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            soqSweetAlert: NgSoqSweetAlert,
                            $translate: angular.translate.ITranslateService,
                            dictionariesManager: NgDictionariesManager,
                            ng2UserService: UserService,
                            ng2UserFactory: UserFactory,
                            ng2DictionaryItemService: DictionaryItemService,
                            ng2DictionaryFactory: DictionaryFactory,
                            ng2ModalService: ModalService,
                            ng2FilterFactory: FilterFactory) {
            const $ctrl = this;

            $ctrl.onSelectDemandeursRecherchesStatuts = onSelectDemandeursRecherchesStatuts;
            $ctrl.onSelectNatures = onSelectNatures;
            $ctrl.onSelectResponsables = onSelectResponsables;
            $ctrl.onSelectTransactionTypes = onSelectTransactionTypes;
            $ctrl.remove = remove;
            $ctrl.save = save;
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);
            $ctrl.DEMANDEUR_RECHERCHE_STATUTS = Dictionary.names.DEMANDEUR_RECHERCHE_STATUTS;
            $ctrl.DEMANDEUR_TYPES = Dictionary.names.DEMANDEUR_TYPES;
            $ctrl.NATURES = Dictionary.names.NATURES;
            $ctrl.TRANSACTION_TYPES = Dictionary.names.DEMANDEUR_RECHERCHE_TRANSACTION_TYPES;
            $ctrl.CODE_STATUTS_EN_RECHERCHE = Demandeur.statuts.EN_RECHERCHE;
            $ctrl.filter = (function () {
                var idx;
                var returnPendingStatus = false;
                var myFilter = angular.copy(filter);

                if (myFilter.queryParameters.statut.values.length !== 1) {
                    myFilter.queryParameters.statut.values = [null];
                }

                idx = myFilter.queryParameters.recherches.status.values.indexOf('pending_match');
                if (idx >= 0) {
                    myFilter.queryParameters.recherches.status.values.splice(idx, 1);
                    returnPendingStatus = true;
                }

                idx = myFilter.queryParameters.recherches.status.values.indexOf('pending_updated_match');
                if (idx >= 0) {
                    myFilter.queryParameters.recherches.status.values.splice(idx, 1);
                    returnPendingStatus = true;
                }

                if (!myFilter.queryParameters.nature) {
                    myFilter.queryParameters.nature = {values: [], condition: ConditionConst.IN};
                }

                if (returnPendingStatus) {
                    myFilter.queryParameters.recherches.status.values.push(DemandeurRecherche.activeStatuts.PENDING);
                }

                return myFilter;
            })();
            $ctrl.options = {
                statutsDemandeurs: dictionariesManager.createDictionaryFromNg2(ng2DictionaryFactory.getByName(Dictionary.names.DEMANDEUR_STATUTS))
                    .filter(dictionaryItem => dictionaryItem.code !== Demandeur.statuts.ARCHIVE)
                    .filter(dictionaryItem => dictionaryItem.code !== Demandeur.statuts.EN_SUSPENS),
                natures: {color: 'purple'},
            };
            $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];
            ng2UserService.last$.subscribe(currentUser => $ctrl.hasRoleLocation = currentUser.hasRoleGrpLocation());

            const responsableDossiers$: Observable<User>[] = ($ctrl.filter.queryParameters?.responsableDossier?.values || [])
                .filter(userId => !!userId)
                .map(userId => ng2UserFactory.get$(userId));

            if ($ctrl.filter.queryParameters?.recherches.status?.values?.length >= 0) {
                $ctrl.demandeursRecherchesStatuts = $ctrl.filter.queryParameters.recherches.status.values.map(statusCode => ng2DictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_RECHERCHE_STATUTS, statusCode));
            } else {
                $ctrl.demandeursRecherchesStatuts = [];
            }

            if ($ctrl.filter.queryParameters?.recherches.nature?.values?.length >= 0) {
                $ctrl.natures = $ctrl.filter.queryParameters.recherches.nature.values.map(dictionaryItem => ng2DictionaryItemService.getByCode(Dictionary.names.NATURES, dictionaryItem.code));
            } else {
                $ctrl.natures = [];
            }

            if ($ctrl.filter.queryParameters?.recherches.type?.values?.length >= 0) {
                $ctrl.transactionTypes = $ctrl.filter.queryParameters.recherches.type.values.map(dictionaryItemCode => ng2DictionaryItemService.getByCode(Dictionary.names.DEMANDEUR_RECHERCHE_TRANSACTION_TYPES, dictionaryItemCode));
            } else {
                $ctrl.transactionTypes = [];
            }

            if (responsableDossiers$?.length >= 0) {
                combineLatest(responsableDossiers$).pipe(take(1))
                    .subscribe(responsableDossiers => $ctrl.responsableDossiers = responsableDossiers);
            } else {
                $ctrl.responsableDossiers = [];
            }

            function onSelectDemandeursRecherchesStatuts(demandeursRecherchesStatuts: DictionaryItem[]) {
                $ctrl.demandeursRecherchesStatuts = demandeursRecherchesStatuts;
                $ctrl.filter.queryParameters.recherches.status.values = demandeursRecherchesStatuts.map(demandeursRecherchesStatut => demandeursRecherchesStatut.code);
            }

            function onSelectNatures(natures: DictionaryItem[]) {
                $ctrl.natures = natures;
                $ctrl.filter.queryParameters.recherches.nature.values = natures.map(nature => dictionariesManager.createFromNg2(nature));
            }

            function onSelectResponsables(users: User[]) {
                $ctrl.responsableDossiers = users;
                $ctrl.filter.queryParameters.responsableDossier.values = users.map(user => user.id);
            }

            function onSelectTransactionTypes(transactionTypes: DictionaryItem[]) {
                $ctrl.transactionTypes = transactionTypes;
                $ctrl.filter.queryParameters.recherches.type.values = transactionTypes.map(transactionType => transactionType.code);
            }

            /**
             * Remove filter and close modal
             */
            function remove() {
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: 'Supprimer',
                    question: $translate.instant('remove.confirm.MESSAGE', {ceData: 'ce filtre'}),
                    title: $translate.instant('remove.confirm.TITLE'),
                    status: ModalService.statuts.DANGER,
                }).pipe(
                    switchMap(response => {
                        if (!response) {
                            return of(null);
                        }

                        $ctrl.loading = 'REMOVE';

                        return ng2FilterFactory.delete$(ng2FilterFactory.ngCreate($ctrl.filter)).pipe(
                            tap(() => $uibModalInstance.close()),
                        );
                    }),
                    take(1),
                ).subscribe({complete: () => $ctrl.loading = undefined});
            }

            /**
             * Save filter and close modal
             */
            function save() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) {
                    soqSweetAlert.warningMessage($translate.instant("formulaire.invalid.TITLE"), $translate.instant("formulaire.invalid.MESSAGE"));
                    return;
                }

                if ($ctrl.filter.queryParameters.statut.values[0] !== Demandeur.statuts.EN_RECHERCHE) {
                    $ctrl.filter.queryParameters.recherches.status.values = [];
                }

                if (!$ctrl.hasRoleLocation && $ctrl.filter.queryParameters.recherches?.type?.values?.length > 0) {
                    $ctrl.filter.queryParameters.recherches.type.values = [];
                }

                $uibModalInstance.close(filter?.extend?.($ctrl.filter));
            }
        }
    })(angularJS);
}
