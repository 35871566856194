import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DocumentFactory} from '@models/documents/document/document.factory';
import {
    BonvisiteResumeActionsMainComponent
} from '@features/bonvisites/item/actions/resume/bonvisite-resume.actions-main.component';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {DocumentService} from '@core/models/documents/document/document.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import Document from '@models/documents/document/document.model';
import {ToasterService} from '@shared/toaster/toaster.service';
import {Router} from '@angular/router';
import {MediaResumeService} from '@models/medias/media/media-resume.service';
import {EmailEditService} from '@models/emails/email/email.edit.service';

@Component({
    selector: 'layout-bonvisite-resume',
    standalone: false,
    templateUrl: 'layout.bonvisite-resume.component.html',
})
export class AppLayoutBonvisiteResumeComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {RESUME_DISABLED: 'Le résumé de votre bon de visite n\'est pas disponible.'};
    private _bonvisiteService = inject(BonvisiteService);
    private _callToActionService = inject(CallToActionService);
    private _documentFactory = inject(DocumentFactory);
    private _documentService = inject(DocumentService);
    private _emailEditService = inject(EmailEditService);
    private _mediaResumeService = inject(MediaResumeService);
    private _procedureService = inject(ProcedureService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _bonvisiteSource = new BehaviorSubject<Bonvisite>(undefined!);
    private _bonvisite$ = this._bonvisiteSource.asObservable();
    private _documentPathSource = new BehaviorSubject<string>(undefined!);
    private _documentPath$ = this._documentPathSource.asObservable();
    private _documentSource = new ReplaySubject<Document>(1);
    private _document$ = this._documentSource.asObservable();
    private _dossierBiensSource = new BehaviorSubject<ADossierBien[]>(undefined!);
    private _dossierBiens$ = this._dossierBiensSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _procedureSource = new ReplaySubject<Procedure>(1);
    private _procedure$ = this._procedureSource.asObservable();

    get bonvisite$(): Observable<Bonvisite> {
        return this._bonvisite$;
    }

    get documentPath$(): Observable<string> {
        return this._documentPath$;
    }

    get dossierBiens$(): Observable<ADossierBien[]> {
        return this._dossierBiens$;
    }

    get procedure$(): Observable<Procedure> {
        return this._procedure$;
    }

    ngOnInit(): void {
        let fallbackPath = '/app/dashboard';

        if (!this._router.url.startsWith('/app/bons-visite')) {
            fallbackPath = this._router.url.split('/bons-visite')[0] + '/bons-visite/portefeuille';
        }

        this._bonvisiteService.current$.pipe(filter(bonvisite => !!bonvisite), take(1)).subscribe(bonvisite => {
            if (!bonvisite.isArchive()) {
                this._toasterService.warning(AppLayoutBonvisiteResumeComponent.errorMessages.RESUME_DISABLED);
                this._router.navigateByUrl(fallbackPath);
            }
        });
        this._bonvisiteService.current$.pipe(
            filter(bonvisite => !!bonvisite),
            takeUntil(this._onDestroy$),
        ).subscribe(bonvisite => this._bonvisiteSource.next(bonvisite));
        this.bonvisite$.pipe(
            filter(bonvisite => !!bonvisite),
            tap(bonvisite => {
                if (bonvisite.documentId) {
                    this._callToActionService.setDynamicComponentLoading({
                        component: BonvisiteResumeActionsMainComponent,
                        data: {bonvisite},
                    });
                }
            }),
            switchMap(bonvisite => {
                const data$: Observable<unknown>[] = [this._bonvisiteService.getDossierBiens$(bonvisite).pipe(tap(dossierBiens => this._dossierBiensSource.next(dossierBiens)))];

                if (bonvisite.linkDocument) {
                    data$.push(this._documentFactory.getByLink$(bonvisite.linkDocument).pipe(
                        tap(document => this._documentSource.next(document)),
                        switchMap(document => this._bonvisiteService.getProcedure$(bonvisite).pipe(
                            tap(procedure => this._procedureSource.next(procedure)),
                            switchMap(procedure =>
                                procedure?.linkMedia ? this._procedureService.getMedia$(procedure) : this._documentService.getMedia$(document)
                            ),
                            map(media => media.linkDownload),
                            tap(documentPath => this._documentPathSource.next(documentPath)),
                        )),
                    ));
                }

                return combineLatest(data$);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe();
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === BonvisiteResumeActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === BonvisiteResumeActionsMainComponent.actions.SEND) {
                    return this._callToActionService.actionExec$(this.send$());
                }

                return of(undefined);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    print$(): Observable<string> {
        return this.bonvisite$.pipe(
            take(1),
            switchMap(bonvisite => this._mediaResumeService.readFromBonvisite$(bonvisite)),
            map(_ => undefined!),
        );
    }

    send$(): Observable<string> {
        return this.bonvisite$.pipe(
            take(1),
            switchMap(bonvisite => combineLatest([
                this._document$,
                this._mediaResumeService.getFromBonvisite$(bonvisite).pipe(map(media => [media])),
                this._bonvisiteService.getDossiersCurrent$(),
            ])),
            switchMap(([document, attachments, dossiers]) => this.procedure$.pipe(
                switchMap(procedure => this._emailEditService.fromBonvisiteDocument$(document, attachments, dossiers, procedure))
            )),
            map(_ => undefined!),
        );
    }
}
