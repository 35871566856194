import {Component, inject, OnInit} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgDemandeurManager, NgDemandeurRechercheManager} from '@legacy/app/managers/managers';
import {NgDemandeurRecherche} from '@legacy/app/managers/ressources';
import {from, Observable, Subject} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
    selector: 'layout-demandeur-resultats',
    standalone: false,
    templateUrl: 'layout-demandeur-resultats.component.html',
})
export class AppLayoutDemandeurResultatsComponent implements OnInit {
    private _ngInjectorService = inject(NgInjectorService);
    private _searchSource = new Subject<NgDemandeurRecherche>();
    private _search$ = this._searchSource.asObservable();

    // Supprimer les injections des anciens manager
    private get ngDemandeurManager(): NgDemandeurManager {
        return this._ngInjectorService.getService('DemandeurManager');
    }

    // Supprimer les injections des anciens manager
    private get ngDemandeurRechercheManager(): NgDemandeurRechercheManager {
        return this._ngInjectorService.getService('DemandeurRechercheManager');
    }

    get search$(): Observable<NgDemandeurRecherche> {
        return this._search$;
    }

    ngOnInit(): void {
        from(this.ngDemandeurRechercheManager.getSearchForDemandeur(this.ngDemandeurManager.current)).pipe(
            take(1)
        ).subscribe(ngDemandeurRecherche => this._searchSource.next(ngDemandeurRecherche));
    }
}
