import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {NgForm} from '@angular/forms';
import {ModalService} from '@shared/modal/modal.service';
import Reference from '@models/references/reference/reference.model';
import Commune from '@core/models/communes/commune/commune.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import {IFormSelectDateOptions} from '@shared/form/form.interfaces';
import DateFormat from '@shared/date/date.format';

@Component({selector: 'dc-reference-edit', standalone: false, templateUrl: 'reference.edit.component.html'})
export class DCReferenceEditComponent implements IDynamicComponent {
    static readonly dateVenteOptions: IFormSelectDateOptions = {
        max: DateFormat.toISOString(),
        name: 'dateVente',
        required: true,
        toApi: true,
    };
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.',
        TITLE: 'Données incomplètes',
    };
    private _dictionaryItemService = inject(DictionaryItemService);
    private _modalService = inject(ModalService);
    private _toasterService = inject(ToasterService);
    private _reference!: Reference;
    private _superficieLabel = DictionaryItemService.superficieLabel.DEFAULT;

    set data(dynamicComponentData: IDynamicComponentData) {
        this._reference = dynamicComponentData.reference as Reference;
        this.updateSuperficieLabel();
    }

    get dateVenteOptions(): IFormSelectDateOptions {
        return DCReferenceEditComponent.dateVenteOptions;
    }

    get DICTIONARY_NAME_CONSTRUCTION_EPOQUES(): string {
        return Dictionary.names.CONSTRUCTION_EPOQUES;
    }

    get DICTIONARY_NAME_NATURES(): string {
        return Dictionary.names.NATURES;
    }

    get reference(): Reference {
        return this._reference;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    onSelectCommune(commune: Commune): void {
        this._reference.communeId = commune?.id;
        this._reference.linkCommune = commune?.linkSelf;
    }

    onSelectEpoque(epoque: DictionarySelected): void {
        this._reference.epoqueId = (epoque as DictionaryItem)?.id;
    }

    onSelectNature(nature: DictionarySelected): void {
        this._reference.natureId = (nature as DictionaryItem)?.id;
        this.updateSuperficieLabel();
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            this._toasterService.warning(DCReferenceEditComponent.errorMessages.TITLE, DCReferenceEditComponent.errorMessages.OTHER);

            return;
        }

        this._modalService.setResponse(this._reference);
    }

    updateSuperficieLabel(): void {
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabelById(this._reference.natureId);
    }
}
