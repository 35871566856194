import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {delay, Observable, Subject, switchMap} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {DossierService} from '@models/dossiers/dossier/dossier.service';

@Component({selector: 'layout-bonvisites', standalone: false, templateUrl: 'layout-bonvisites.component.html'})
export class AppLayoutBonvisitesComponent implements OnDestroy, OnInit {
    private _bonvisiteService = inject(BonvisiteService);
    private _dossierService = inject(DossierService);
    private _router = inject(Router);
    private readonly _onDestroy$ = new Subject<void>();
    private _redirectionRoute!: string;
    private _title$!: Observable<string>;

    get CALL_TO_ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }

    get redirectionRoute(): string {
        return this._redirectionRoute;
    }

    get title$(): Observable<string> {
        return this._title$;
    }

    ngOnInit(): void {
        this._title$ = this._router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            startWith(undefined!),
            switchMap(_ => this._bonvisiteService.current$),
            map(bonvisite => {
                const dossier = this._dossierService.getCurrentFromNg(this._router.url);
                const hasDossier = dossier && dossier.id;

                if (hasDossier) {
                    this._redirectionRoute = '/app/' + dossier.dossierType + 's/' + dossier.id.toString() + '/bons-visite/portefeuille';
                } else {
                    this._redirectionRoute = '/app/dashboard';
                }

                switch (this._router.url.split('/').splice(-1, 1)[0]) {
                    case 'edit' :
                        return bonvisite?.isNew() ? 'Création d\'un bon de visite' : 'Édition du bon de visite';
                    case 'redaction' :
                        return 'Rédaction du bon de visite';
                    case 'resume' :
                        return 'Résumé du bon de visite';
                    case 'signature' :
                        return 'Signature du bon de visite';
                }

                if (hasDossier) {
                    this._redirectionRoute = '/app/' + dossier.dossierType + 's/' + dossier.id.toString() + '/lifetime';
                } else {
                    this._redirectionRoute = '/app/dashboard';
                }

                return 'Bons de visite';
            }),
            delay(1),
            takeUntil(this._onDestroy$),
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
