import {Component, Input} from '@angular/core';
import DossierExport from '@models/dossiers/dossier/exports/export/dossier-export.model';

@Component({
    selector: 'app-dossier-inot-export-statut',
    standalone: false,
    templateUrl: 'dossier-inot-export.statut.component.html',
})
export class AppDossierInotExportStatutComponent {
    private _dossierExport!: DossierExport;

    get dossierExport(): DossierExport {
        return this._dossierExport;
    }

    @Input()
    set dossierExport(value: DossierExport) {
        this._dossierExport = value;
    }
}
