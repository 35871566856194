import {inject, Injectable} from '@angular/core';
import {BienPhotoFactory} from '@models/bien/photos/photo/bien-photo.factory';
import {IBienPhotoApi} from '@models/bien/photos/photo/bien-photo.interfaces';
import BienPhoto from '@models/bien/photos/photo/bien-photo.model';

@Injectable({providedIn: 'root'})
export class BienPhotosFactory {
    private _bienPhotoFactory = inject(BienPhotoFactory);

    create(bienPhotoApis: IBienPhotoApi[] = []): BienPhoto[] {
        return (bienPhotoApis || []).filter(bienPhotoApi => bienPhotoApi)
            .map(bienPhotoApi => this._bienPhotoFactory.create(bienPhotoApi));
    }
}
