import {Component, inject, Input, output, ViewChild} from '@angular/core';
import {map, take, tap} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {IFormSelectInputOptions} from '@shared/form/form.interfaces';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import User from '@models/users/user/user.model';
import {CUsersFactory} from '@models/users/collection/users.collection.factory';
import Etude from '@models/etudes/etude/etude.model';
import {ConditionConst, SortConst} from '@shared/constants';

@Component({
    exportAs: 'usersSelect',
    selector: 'app-users-select',
    standalone: false,
    templateUrl: 'users-select.component.html',
})
export class AppUsersSelectComponent {
    static readonly initUsersSelectOptions: IFormSelectInputOptions = {
        bindLabel: 'convivialName',
        bindLinkImage: 'linkPhotoThumbnail',
        customTemplate: AppFormSelectInputComponent.customTemplates.USER,
        initWithEmptySearch: true,
        loadingText: 'Chargement des utilisateurs',
        name: 'usersSelect',
        notFoundText: 'Aucun utilisateur trouvé',
        placeholder: 'Rechercher un utilisateur',
    };
    readonly selected = output<User>();
    private _cUsersFactory = inject(CUsersFactory);
    private _etudesId: number[] = [];
    private _formInput!: NgModel;
    private _loading = false;
    private _options: IFormSelectInputOptions = {...AppUsersSelectComponent.initUsersSelectOptions};
    private _roles: string[] = [];
    private _user!: User;
    private _users: User[] = [];

    @Input()
    set etudes(value: Etude[]) {
        this._etudesId = value.map(etude => etude.id);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IFormSelectInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectInputOptions) {
        this._options = {...AppUsersSelectComponent.initUsersSelectOptions, ...value};
    }

    @Input()
    set roles(value: string[]) {
        this._roles = value;
    }

    get user(): User {
        return this._user;
    }

    @Input()
    set user(value: User) {
        this._user = value;
    }

    get users(): User[] {
        return this._users;
    }

    @ViewChild('usersSelectInput')
    set usersSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    onSelect(user: unknown): void {
        this.selected.emit(user as User);
    }

    search(keywords: string): void {
        this._users = [];
        this._loading = true;
        this._cUsersFactory.get$({
            etude: {condition: ConditionConst.IN, values: this._etudesId},
            keywords,
            role: {condition: ConditionConst.CONTAINS_SOME, values: this._roles},
            tris: {sortableRank: SortConst.ASCENDING},
        }).pipe(
            map(cUsers => cUsers.results),
            tap(users => this._users = users),
            take(1),
        ).subscribe(_ => this._loading = false);
    }
}
