import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewLoiAlurService
} from '@features/dossiers/dossier/bien/overview/loi-alur/dossier-bien-overview.loi-alur.service';

@Component({
    selector: 'app-dossier-bien-overview-loi-alur',
    standalone: false,
    templateUrl: 'dossier-bien-overview.loi-alur.component.html',
})
export class AppDossierBienOverviewLoiAlurComponent {
    private _dossierBienOverviewLoiAlurService = inject(DossierBienOverviewLoiAlurService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isAdministrationSyndicLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isAdministrationSyndicLoiAlurVisible;
    }

    get isCommentsLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isCommentsLoiAlurVisible;
    }

    get isCoordonneesSyndicLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isCoordonneesSyndicLoiAlurVisible;
    }

    get isMontantQuotePartLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isMontantQuotePartLoiAlurVisible;
    }

    get isMontantTravauxLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isMontantTravauxLoiAlurVisible;
    }

    get isNombreCoproprietairesLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isNombreCoproprietairesLoiAlurVisible;
    }

    get isNombreLotsLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isNombreLotsLoiAlurVisible;
    }

    get isPlanSauvegardeLoiAlurVisible(): boolean {
        return this._dossierBienOverviewLoiAlurService.isPlanSauvegardeLoiAlurVisible;
    }
}
