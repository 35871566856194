import {Component, inject, Input, OnInit, output, ViewChild} from '@angular/core';
import {take, tap} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import InotUser from '@models/inot-users/user/inot-user.model';
import {CInotUsersFactory} from '@models/inot-users/collection/inot-users.collection.factory';
import {IInotUsersSelectOptions} from '@features/inot/inot.interfaces';
import Etude from '@models/etudes/etude/etude.model';

@Component({
    exportAs: 'inotUsersSelect',
    selector: 'app-inot-users-select',
    standalone: false,
    templateUrl: 'inot.users-select.component.html',
})
export class AppInotUsersSelectComponent implements OnInit {
    static readonly initInotUsersSelectOptions: IInotUsersSelectOptions = {
        bindLabel: 'convivialName',
        initWithEmptySearch: true,
        loadingText: 'Chargement des utilisateurs ' + Etude.enablableModules.INOT.label,
        name: 'inotUsersSelect',
        notFoundText: 'Aucun utilisateur ' + Etude.enablableModules.INOT.label + ' trouvé',
        placeholder: 'Rechercher un utilisateur ' + Etude.enablableModules.INOT.label,
        selectFirst: false,
    };
    readonly selected = output<InotUser>();
    private _cInotUsersFactory = inject(CInotUsersFactory);
    private _formInput!: NgModel;
    private _inotUser!: InotUser;
    private _inotUsers: InotUser[] = [];
    private _loading = false;
    private _options: IInotUsersSelectOptions = {...AppInotUsersSelectComponent.initInotUsersSelectOptions};

    get formInput(): NgModel {
        return this._formInput;
    }

    get inotUser(): InotUser {
        return this._inotUser;
    }

    @Input()
    set inotUser(value: InotUser) {
        this._inotUser = value;
    }

    get inotUsers(): InotUser[] {
        return this._inotUsers;
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IInotUsersSelectOptions {
        return this._options;
    }

    @Input()
    set options(value: IInotUsersSelectOptions) {
        this._options = {...AppInotUsersSelectComponent.initInotUsersSelectOptions, ...value};
    }

    @ViewChild('inotUsersSelectInput')
    set usersSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    ngOnInit(): void {
        this._cInotUsersFactory.get$().pipe(
            tap(cInotUsers => {
                if (this.options.selectFirst && cInotUsers.total > 0) {
                    this.inotUser = cInotUsers.results[0];
                    this.onSelect(this.inotUser);
                }
            }),
            take(1),
        ).subscribe({
            next: cInotUsers => this._inotUsers = cInotUsers.results,
            complete: () => this._loading = false,
        });
    }

    onSelect(inotUser: unknown): void {
        this.selected.emit(inotUser as InotUser);
    }
}
