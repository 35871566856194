import {Component, Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {Observable} from 'rxjs';

@Directive({selector: '[appNgEskUploadFilesInfo]'})
export class NgEskimmoUploadFilesInfoDirective extends UpgradeComponent {
    @Input() infoClientFilter!: unknown;
    @Input() typeFile!: string;
    @Input() updateTasks$!: Observable<void>;
    @Input() updateTasksErrors$!: Observable<void>;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskUploadFilesInfo', elementRef, injector);
    }
}

@Component({
    imports: [NgEskimmoUploadFilesInfoDirective],
    selector: 'esk-upload-files-info',
    template: '<div appNgEskUploadFilesInfo [typeFile]="typeFile" [infoClientFilter]="infoClientFilter" ' +
        '[updateTasks$]="updateTasks$" [updateTasksErrors$]="updateTasksErrors$"></div>',
})
export class NgEskimmoUploadFilesInfoComponent {
    @Input() infoClientFilter!: unknown;
    @Input() typeFile!: string;
    @Input() updateTasks$!: Observable<void>;
    @Input() updateTasksErrors$!: Observable<void>;
}
