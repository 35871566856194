import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgBienEditVirtualVisit]'})
export class NgBienEditVirtualVisitDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskBienEditVirtualVisitCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgBienEditVirtualVisitDirective],
    selector: 'app-ng-bien-edit-virtual-visit',
    template: '<div appNgBienEditVirtualVisit></div>',
})
export class NgBienEditVirtualVisitComponent {
}
