import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {UserService} from '@models/users/user/user.service';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {RoutesService} from '@shared/routes/routes.service';

// @todo À ranger dans core/auth
@Injectable({providedIn: 'root'})
export class StartGuard {
    private _routesService = inject(RoutesService);
    private _userService = inject(UserService);

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
        return this._userService.current$.pipe(
            switchMap(currentUser => currentUser ? of(true) : this._routesService.redirect$('/start', routerStateSnapshot.url)),
        );
    }
}
