import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {ActivatedRoute, Router, RouterLink} from '@angular/router';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import Estimation from '@models/estimations/estimation/estimation.model';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {EstimationRapportService} from '@features/estimations/estimation/rapport/estimation-rapport.service';
import {AsyncPipe, DatePipe} from '@angular/common';
import {LoadModule} from '@shared/load/load.module';
import Media from '@models/medias/media/media.model';
import {MediaFactory} from '@models/medias/media/media.factory';
import {
    EstimationRapportActionsMainComponent
} from '@features/estimations/estimation/rapport/actions/estimation-rapport.actions-main.component';
import {AppNoteoLogoNotestimComponent} from '@shared/noteo/logo/notestim/noteo-logo.notestim.component';
import {MediaService} from '@models/medias/media/media.service';
import {AppPdfComponent} from '@shared/pdf/pdf.component';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import {AppNoteoLogoNotyComponent} from '@shared/noteo/logo/noty/noteo-logo.noty.component';
import {ModalService} from '@shared/modal/modal.service';
import {
    EstimationRapportReadNotestimUpdateComponent
} from '@features/estimations/estimation/rapport/read/notestim-update/estimation-rapport.read-notestim-update.component';
import {IEstimationRapportReadNotestimUpdateDataObject} from '@features/estimations/estimations.interfaces';

@Component({
    imports: [
        AsyncPipe,
        AppNoteoLogoNotestimComponent,
        AppNoteoLogoNotyComponent,
        AppPdfComponent,
        DatePipe,
        LoadModule,
        RouterLink,
    ],
    selector: 'layout-estimation-rapport-read',
    templateUrl: 'layout-estimation.rapport-read.component.html',
})
export class AppLayoutEstimationRapportReadComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _callToActionService = inject(CallToActionService);
    private _emailEditService = inject(EmailEditService);
    private _estimationRapportService = inject(EstimationRapportService);
    private _estimationService = inject(EstimationService);
    private _mediaFactory = inject(MediaFactory);
    private _mediaService = inject(MediaService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _estimation$!: Observable<Estimation>;
    private _rapportMediaSource = new ReplaySubject<Media>(1);
    private _rapportMedia$ = this._rapportMediaSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();

    get estimation$(): Observable<Estimation> {
        return this._estimation$;
    }

    get estimationNotestim$(): Observable<EstimationNotestim> {
        return this._estimationRapportService.estimationNotestim$;
    }

    get rapportMedia$(): Observable<Media> {
        return this._rapportMedia$;
    }

    ngOnInit(): void {
        this._estimation$ = this._estimationService.getCurrentFromNg$();
        this._estimation$.pipe(
            switchMap(estimation => this._estimationRapportService.redirect$(estimation, this._router.url, this._activatedRoute)),
            switchMap(isRedirect => {
                if (isRedirect) {
                    return of(undefined);
                }

                return this._estimationRapportService.estimationNotestim$.pipe(
                    filter(estimationNotestim => !!estimationNotestim),
                    switchMap(estimationNotestim => this._mediaService.getOneByLink$(estimationNotestim.linkRapportMedia)),
                    tap(media => {
                        if (media.linkDownload) {
                            this._callToActionService.setDynamicComponentLoading({
                                component: EstimationRapportActionsMainComponent,
                                data: {
                                    options: {enabledActions: {generate: false, print: true, save: false, send: true}},
                                },
                            });
                        }
                    }),
                    map(media => this._rapportMediaSource.next(media)),
                );
            }),
            take(1),
        ).subscribe();
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === EstimationRapportActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === EstimationRapportActionsMainComponent.actions.SEND) {
                    return this._callToActionService.actionExec$(this.send$());
                }

                return of(undefined);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    print$(): Observable<string> {
        return this.rapportMedia$.pipe(
            switchMap(rapportMedia => this._mediaFactory.read$(rapportMedia)),
            switchMap(_ => this._estimation$),
            switchMap(estimation => this.remettreRapport$(estimation)),
            map(_ => undefined!),
        );
    }

    remettreRapport$(estimation: Estimation): Observable<boolean> {
        if (estimation.isArchived()) {
            return of(!!estimation.dateRemise);
        }

        return this._estimationService.remettreRapport$(estimation);
    }

    send$(): Observable<string> {
        return combineLatest([this._estimation$, this.rapportMedia$]).pipe(
            switchMap(([estimation, rapportMedia]) => this._emailEditService.fromEstimationRapport$(estimation, rapportMedia.title, [rapportMedia]).pipe(
                switchMap(isRemis => isRemis ? this.remettreRapport$(estimation) : of(undefined)),
            )),
            map(_ => undefined!),
        );
    }

    updateFromNotestim(): void {
        combineLatest([this.estimation$, this.estimationNotestim$]).pipe(
            take(1),
            map(([estimation, estimationNotestim]) => ({
                notestim: {valeurVenale: estimationNotestim.scanDataResalePrice},
                noty: {valeurVenale: estimation.valeurVenale},
            })),
            switchMap(data => this._modalService.open$<IEstimationRapportReadNotestimUpdateDataObject>(EstimationRapportReadNotestimUpdateComponent, data)),
            switchMap(data => {
                if (!data) {
                    return of(undefined!);
                }

                return this.estimation$.pipe(
                    take(1),
                    switchMap(estimation => this._estimationRapportService.updateFromNotestimData$(estimation, data)),
                );
            }),
            take(1),
        ).subscribe();
    }
}
