import {ErrorHandler, NgModule} from '@angular/core';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {ErrorHandlerService} from '@core/error/error.handler.service';
import {ErrorSentryService} from '@core/error/error.sentry.service';
import ABOUT from '@env/about';
import environment from '@env/environment';

@NgModule({
    providers: [
        ErrorSentryService,
        ErrorHandlerService,
        ErrorConfigurationService,
        {provide: ErrorHandler, useClass: ErrorHandlerService},
    ],
})
export class ErrorModule {
    static readonly ignoreErrors = [
        // @todo Supprimer ce qui se rapporte à twitter et vérifier pour l'ancienne box-news
        // Erreur provenant de l'utilisation de https://platform.twitter.com/widgets.js
        'sandbox not initialized',
        // https://support.google.com/tagmanager/thread/325457635/how-to-solve-error-can-t-find-variable-gmo-on-gtm-server-side?hl=en
        'Can\'t find variable: gmo',
    ];

    constructor(errorSentryService: ErrorSentryService, errorConfigurationService: ErrorConfigurationService) {
        if (environment.sentry.enabled) {
            errorSentryService.init({
                dsn: environment.sentry.dsn,
                environment: environment.sentry.environment,
                ignoreErrors: ErrorModule.ignoreErrors,
                normalizeDepth: 10,
                release: ABOUT.version,
            });
            errorConfigurationService.setUser();
        }
    }
}
