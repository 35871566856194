import {Component, Input} from '@angular/core';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';

@Component({selector: 'app-bonvisite-statut', standalone: false, templateUrl: 'bonvisite.statut.component.html'})
export class AppBonvisiteStatutComponent {
    private _bonvisite!: Bonvisite;

    get bonvisite(): Bonvisite {
        return this._bonvisite;
    }

    @Input()
    set bonvisite(value: Bonvisite) {
        this._bonvisite = value;
    }
}
