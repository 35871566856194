import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import {
    IBonvisiteActionsMainData, IBonvisiteEditForms, IBonvisiteEditOptions
} from '@features/bonvisites/bonvisites.interfaces';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Location from '@models/locations/location/location.model';
import Vente from '@models/ventes/vente/vente.model';
import {ModalService} from '@shared/modal/modal.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {
    BonvisiteEditActionsMainComponent
} from '@features/bonvisites/item/actions/edit/bonvisite-edit.actions-main.component';
import {Router} from '@angular/router';
import {ToasterService} from '@shared/toaster/toaster.service';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';

@Component({
    selector: 'layout-bonvisite-edit',
    standalone: false,
    templateUrl: 'layout.bonvisite-edit.component.html',
})
export class AppLayoutBonvisiteEditComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        DEMANDEUR: 'Le demandeur associé n\'est pas correctement saisi.',
        DOSSIERBIENS: 'Il n\'y a pas de biens associés.',
        EDIT_DISABLED: 'L\'édition de votre bon de visite n\'est pas disponible.',
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.',
        TITLE: 'Données incomplètes',
    };
    private _bonvisiteService = inject(BonvisiteService);
    private _callToActionService = inject(CallToActionService);
    private _modalService = inject(ModalService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _bonvisiteSource = new ReplaySubject<Bonvisite>(1);
    private _bonvisite$ = this._bonvisiteSource.asObservable();
    private _bonvisiteEditOptions!: IBonvisiteEditOptions;
    private _bonvisitesRoot!: string;
    private _demandeur!: Demandeur;
    private _formDemandeurSource = new ReplaySubject<void>(1);
    private _forms!: IBonvisiteEditForms;
    private _formVisiteSource = new ReplaySubject<void>(1);
    private _locations!: Location[];
    private readonly _onDestroy$ = new Subject<void>();
    private _ventes!: Vente[];

    get bonvisite$(): Observable<Bonvisite> {
        return this._bonvisite$;
    }

    get bonvisiteEditOptions(): IBonvisiteEditOptions {
        return this._bonvisiteEditOptions;
    }

    set demandeur(value: Demandeur) {
        this._demandeur = value;
    }

    set forms(value: IBonvisiteEditForms) {
        this._forms = value;
    }

    set locations(value: Location[]) {
        this._locations = value;
    }

    set ventes(value: Vente[]) {
        this._ventes = value;
    }

    ngOnInit(): void {
        let fallbackPath = '/app/dashboard';

        this._bonvisitesRoot = '/app/bons-visite';
        if (!this._router.url.startsWith('/app/bons-visite')) {
            this._bonvisitesRoot = this._router.url.split('/bons-visite')[0] + '/bons-visite';
            fallbackPath = this._bonvisitesRoot + '/portefeuille';
        }

        this._bonvisiteService.current$.pipe(filter(bonvisite => !!bonvisite), take(1)).subscribe(bonvisite => {
            if (!bonvisite.isEdit()) {
                this._toasterService.warning(AppLayoutBonvisiteEditComponent.errorMessages.EDIT_DISABLED);
                this._router.navigateByUrl(fallbackPath);
            }
        });
        this._bonvisiteService.current$.pipe(
            filter(bonvisite => !!bonvisite),
            tap(_ => this._bonvisiteEditOptions = {
                fixedDemandeur: this._router.url.includes(DossierTypesConst.DEMANDEUR),
                fixedDossierBiens: this._router.url.includes(DossierTypesConst.LOCATION) || this._router.url.includes(DossierTypesConst.VENTE),
            }),
            tap(bonvisite => {
                const data: IBonvisiteActionsMainData = {
                    bonvisite,
                    options: {
                        fromDossier: this._router.url.includes(DossierTypesConst.DEMANDEUR)
                            || this._router.url.includes(DossierTypesConst.LOCATION)
                            || this._router.url.includes(DossierTypesConst.VENTE),
                    },
                };

                this._callToActionService.setDynamicComponentLoading({
                    component: BonvisiteEditActionsMainComponent,
                    data,
                });
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(bonvisite => this._bonvisiteSource.next(bonvisite));
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === BonvisiteEditActionsMainComponent.actions.ARCHIVE) {
                    return this._callToActionService.actionExec$(this.archiveCurrent$());
                } else if (callToActionClicked.action === BonvisiteEditActionsMainComponent.actions.SAVE) {
                    return this._callToActionService.actionExec$(this.save$());
                } else if (callToActionClicked.action === BonvisiteEditActionsMainComponent.actions.WRITE) {
                    return this._callToActionService.actionExec$(this.write$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    archiveCurrent$(): Observable<string> {
        return this._bonvisiteService.archiveCurrent$().pipe(
            switchMap(isArchived => this.bonvisite$.pipe(
                take(1),
                map(bonvisite => isArchived ? this._bonvisitesRoot + '/' + bonvisite.uuid + '/resume' : undefined!))
            ),
        );
    }

    save$(): Observable<string> {
        let isBonvisiteNew = false;

        return this.bonvisite$.pipe(
            take(1),
            tap(bonvisite => isBonvisiteNew = bonvisite.isNew()),
            switchMap(_ => this.saveCurrent$()),
            map(bonvisite => isBonvisiteNew && bonvisite ? this._bonvisitesRoot + '/' + bonvisite.uuid + '/edit' : undefined!),
        );
    }

    saveCurrent$(): Observable<Bonvisite> {
        if (!this._forms.demandeurCreation) {
            this.saveDemandeur();
        } else if (this._forms.demandeurCreation && !this._forms.demandeurCreation.submitted) {
            setTimeout(() => document.querySelector<HTMLButtonElement>('[form=demandeurCreationForm]')!.click(), 1);
        }

        if (!this._forms.visite.submitted) {
            setTimeout(() => document.querySelector<HTMLButtonElement>('[form=visiteForm]')!.click(), 1);
        }

        return combineLatest([this._formDemandeurSource.asObservable(), this._formVisiteSource.asObservable()]).pipe(
            map(_ => {
                const errorComments: string[] = [];

                if (this._forms.demandeurCreation && !this._forms.demandeurCreation.valid) {
                    errorComments.push(AppLayoutBonvisiteEditComponent.errorMessages.DEMANDEUR);
                }

                if (this._locations.length <= 0 && this._ventes.length <= 0) {
                    errorComments.push(AppLayoutBonvisiteEditComponent.errorMessages.DOSSIERBIENS);
                }

                if (!this._forms.visite.valid) {
                    errorComments.push(AppLayoutBonvisiteEditComponent.errorMessages.OTHER);
                }

                return errorComments;
            }),
            switchMap(errorComments => {
                if (errorComments.length > 0) {
                    return this._modalService.openInformation$({
                        comments: errorComments.join('<br>'),
                        title: AppLayoutBonvisiteEditComponent.errorMessages.TITLE,
                        status: ModalService.statuts.WARNING,
                    }).pipe(map(_ => undefined!));
                }

                return this._bonvisiteService.saveCurrent$({
                    demandeur: this._demandeur,
                    locations: this._locations,
                    ventes: this._ventes,
                });
            }),
        );
    }

    saveDemandeur(): void {
        this._formDemandeurSource.next();
    }

    saveVisite(): void {
        this._formVisiteSource.next();
    }

    write$(): Observable<string> {
        return this.saveCurrent$().pipe(
            switchMap(bonvisite => bonvisite ? this._bonvisiteService.writeCurrent$() : of(undefined!)),
            map(bonvisite => bonvisite ? this._bonvisitesRoot + '/' + bonvisite.uuid + '/redaction' : undefined!),
        );
    }
}
