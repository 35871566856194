import {AfterViewInit, Component, HostListener, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgContactsGroupManager, NgEstimationManager} from '@legacy/app/managers/managers';
import {NgEstimation} from '@legacy/app/managers/ressources';
import {NgForm} from '@angular/forms';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {delay, firstValueFrom, Observable, of, Subject, switchMap} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {ToasterService} from '@shared/toaster/toaster.service';
import {EstimationService} from '@models/estimations/estimation/estimation.service';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {ContactsGroupFactory} from '@models/contacts-group/contacts-group.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import {ApiImpersonationService} from '@core/api/api-impersonation.service';
import {AppBienEditDescriptionComponent} from '@features/bien-edit/description/bien.edit-description.component';
import {DossierBienService} from '@models/dossiers/biens/bien/dossier-bien.service';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';

@Component({
    selector: 'layout-estimation-edit',
    standalone: false,
    templateUrl: 'layout-estimation-edit.component.html',
})
export class AppLayoutEstimationEditComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('editForm') editForm!: NgForm;

    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {OFFLINE: 'OFFLINE'};
    private _apiImpersonationService = inject(ApiImpersonationService);
    private _browserOnlineService = inject(BrowserOnlineService);
    private _contactsGroupFactory = inject(ContactsGroupFactory);
    private _dossierBienService = inject(DossierBienService);
    private _estimationService = inject(EstimationService);
    private _etudeService = inject(EtudeService);
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _ngEstimation!: NgEstimation;
    private _hasConstraintOnPhotos = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _saveIsDone = false;
    private _saving = false;
    private _showSaveButton = true;

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngContactsGroupManager(): NgContactsGroupManager {
        return this._ngInjectorService.getService('ContactsGroupManager');
    }

    // Supprimer les injections des anciens manager
    private get ngEstimationManager(): NgEstimationManager {
        return this._ngInjectorService.getService('EstimationManager');
    }

    // Supprimer les injections des anciens manager
    private get ngSoqSweetAlert(): NgSoqSweetAlert {
        return this._ngInjectorService.getService('SoqSweetAlert');
    }

    get currentEtude$(): Observable<Etude> {
        return this._etudeService.last$;
    }

    get ngEstimation(): NgEstimation {
        return this._ngEstimation;
    }

    get hasConstraintOnPhotos(): boolean {
        return this._hasConstraintOnPhotos;
    }

    get saving(): boolean {
        return this._saving;
    }

    get showSaveButton(): boolean {
        return this._showSaveButton;
    }

    get STATUTS_ARCHIVE(): string {
        return Estimation.statuts.ARCHIVE;
    }

    canDeactivate(): Observable<boolean> {
        if (this._apiImpersonationService.isImpersonate()) {
            return of(true);
        }

        if (this._saveIsDone) {
            return of(true);
        }

        if (![Estimation.statuts.BROUILLON, Estimation.statuts.ARCHIVE].includes(this.ngEstimation?.statut) && !this.editForm.valid) {
            this.editForm.onSubmit(undefined!);
            this._formEstimationNotValid();

            return of(false);
        }

        return this._browserOnlineService.checkAndPrevent$();
    }

    ngOnInit(): void {
        this.ngEstimationManager.current$.pipe(
            tap(_ => this._saveIsDone = false),
            tap(_ => this._hasConstraintOnPhotos = this.ngBienDossierCurrent.hasConstraints('photos')),
            takeUntil(this._onDestroy$),
        ).subscribe(ngEstimation => this._ngEstimation = ngEstimation);
        this._router.events.pipe(
            map(event => event as RouterEvent),
            filter(event => event instanceof NavigationEnd),
            map(event => !event.url.endsWith('/edit/photos') && !event.url.endsWith('/edit/virtual_visit')),
            takeUntil(this._onDestroy$),
        ).subscribe(showSaveButton => this._showSaveButton = showSaveButton);
    }

    ngAfterViewInit(): void {
        this._estimationService.needToBeSaved$
            .pipe(filter(_ => !!this.ngEstimation), takeUntil(this._onDestroy$))
            .subscribe(reason => this.save(true, reason));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        if (!this._apiImpersonationService.isImpersonate() && !this._saveIsDone && this.ngEstimation?.id) {
            this.save();
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    beforeUnloadHandler(event: BeforeUnloadEvent): void {
        event.preventDefault();
    }

    save(ignoreSubmitted = false, reason?: string): void {
        const isEstimationNew = !this.ngEstimation.id;

        if (this.ngEstimation.statut === Estimation.statuts.ARCHIVE || this._saving) {
            return;
        }

        // Nouveau mais bouton Enregistrer pas cliqué
        if (isEstimationNew && !this.editForm.submitted) {
            return;
        }

        // Pas nouveau, bouton Enregistrer cliqué mais pas valide
        if ((this.ngEstimation.statut !== Estimation.statuts.BROUILLON || (this.ngEstimation.type.code === Estimation.types.EVALUATION && !!this.ngEstimation.rapportId)) &&
            (this.editForm.submitted || ignoreSubmitted) && !this.editForm.valid) {
            this._formEstimationNotValid();
            return;
        }

        /*
                If (this.ngEstimation.statut === Estimation.statuts.BROUILLON && this.editForm.submitted && !this.editForm.valid) {
                    return this._formEstimationNotValid();
                }
        */

        this._saveIsDone = true;
        this._saving = true;
        firstValueFrom(this._browserOnlineService.checkAndPrevent$(false)).then(isOn => {
            if (!isOn) {
                return Promise.reject(AppLayoutEstimationEditComponent.errorMessages.OFFLINE);
            }

            // Nécessaire pour que la récupération du nom du CG soit réalisé correctement car il y a un "debounceTime(500)" sur la frappe
            return firstValueFrom(of(undefined).pipe(
                delay(600),
                switchMap(_ => this._contactsGroupFactory.save$(this._contactsGroupFactory.ngCreate(this.ngEstimation.bien.contactsGroup))),
            )).then(contactsGroupSaved => this.ngContactsGroupManager.createFromNg2(contactsGroupSaved))
                .then(contactsGroup => this.ngEstimation.bien.contactsGroup = contactsGroup)
                .then(_ => firstValueFrom(this._estimationService.ngSave$(this.ngEstimation).pipe(
                    switchMap(estimation => {
                        if (reason === AppBienEditDescriptionComponent.SET_TITRE_DESCRIPTIF) {
                            return this._dossierBienService.updateTitreDescriptif$(estimation);
                        }

                        return of(estimation);
                    }),
                )))
                .then(estimation => this.ngEstimationManager.getOneById(estimation.id))
                .then(ngEstimation => {
                    this.ngEstimationManager.emitCurrent(ngEstimation);
                    if (isEstimationNew) {
                        return this._router.navigateByUrl('/app/estimations/' + this.ngEstimation.id.toString(), {replaceUrl: true});
                    }

                    return;
                }, _ => this._toasterService.error('Oups !', 'Une erreur est survenue lors de la mise à jour de votre bien en estimation.'));
        }).finally(() => this._saving = false);
    }

    private _formEstimationNotValid(): void {
        this.ngSoqSweetAlert.warningMessage('Saisie des champs', 'Certains champs n\'ont pas de valeurs correctes.');

        return;
    }
}
