import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEstimationLifetime]'})
export class NgEstimationLifetimeDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationLifetimeCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgEstimationLifetimeDirective],
    selector: 'app-ng-estimation-lifetime',
    template: '<div appNgEstimationLifetime></div>',
})
export class NgEstimationLifetimeComponent {
}
