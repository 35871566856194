import {Component, Input, output} from '@angular/core';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';

// @todo Est-ce que l'utilisation direct de AppDictionaryRadioComponent ne simplifierait pas le code ?
@Component({
    selector: 'app-bien-edit-type-radio',
    standalone: false,
    templateUrl: 'bien-edit.type-radio.component.html',
})
export class AppBienEditTypeRadioComponent {
    readonly selected = output<DictionaryItem>();
    private _dictionaryName!: string;
    private _type!: DictionaryItem;

    get dictionaryName(): string {
        return this._dictionaryName;
    }

    @Input()
    set typeBien(value: string) {
        this._dictionaryName = 'types' + value[0].toUpperCase() + value.slice(1);
    }

    get type(): DictionaryItem {
        return this._type;
    }

    @Input()
    set type(value: DictionaryItem) {
        this._type = value;
    }

    onSelect(dictionaryItem: DictionaryItem): void {
        this._type = dictionaryItem;
        this.selected.emit(this._type);
    }
}
