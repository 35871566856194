import {
    AfterViewChecked, Component, ElementRef, HostListener, inject, Inject, Input, output, Renderer2, ViewChild
} from '@angular/core';
import {IImagePreviewOptions, IImagePercentageCoordinates} from '@shared/image/image.interfaces';
import ImageService from '@shared/image/image.service';
import {NgClass, NgOptimizedImage, NgStyle} from '@angular/common';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    imports: [FaIconComponent, NgClass, NgOptimizedImage, NgStyle],
    selector: 'app-image-preview',
    templateUrl: 'image-preview.component.html',
})
export class AppImagePreviewComponent implements AfterViewChecked {
    @ViewChild('noLinkContainerIconElementRef') noLinkContainerIconElementRef!: ElementRef<HTMLDivElement>;

    static readonly initImagePreviewOptions: IImagePreviewOptions = {
        aspectRatio: 'tw-aspect-cinema',
        noLinkContainerIcon: ['fal', 'camera'],
        priority: false,
    };
    readonly action = output<void>();
    private _imageService = inject(ImageService);
    private _renderer2 = inject(Renderer2);
    private _window: Window;
    private _coordinates: IImagePercentageCoordinates = {...ImageService.initImagePercentageCoordinates};
    private _link!: string;
    private _options: IImagePreviewOptions = {...AppImagePreviewComponent.initImagePreviewOptions};

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    get coordinates(): IImagePercentageCoordinates {
        return this._coordinates;
    }

    get link(): string {
        return this._link;
    }

    @Input()
    set link(value: string) {
        this._link = value;
        this._coordinates = {...ImageService.initImagePercentageCoordinates, ...this._imageService.getCoordinatesFromUrl(this._link)};
    }

    get options(): IImagePreviewOptions {
        return this._options;
    }

    @Input()
    set options(value: IImagePreviewOptions) {
        this._options = {...AppImagePreviewComponent.initImagePreviewOptions, ...value};
    }

    ngAfterViewChecked(): void {
        this.updateNoLinkContainerIconFontSize();
    }

    @HostListener('window:resize')
    onResize(): void {
        this.updateNoLinkContainerIconFontSize();
    }

    updateNoLinkContainerIconFontSize(): void {
        if (!this.noLinkContainerIconElementRef) {
            return;
        }

        const computedStyle = this._window.getComputedStyle(this.noLinkContainerIconElementRef.nativeElement);
        const fontSize = +(computedStyle.height.replace('px', '')) * 0.8;

        this._renderer2.setStyle(this.noLinkContainerIconElementRef.nativeElement, 'font-size', fontSize.toString() + 'px');
    }
}
