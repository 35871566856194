@if (currentEtude$ | async; as currentEtude) {
  <div class="container-fluid container-fullw tw-bg-white bien-edit">
    <esk-bien-edit-header [dossier]="ngEstimation"/>

    <div class="tabs-container">
      <ul class="nav nav-pills">
        <!-- @todo Voir pourquoi ce n'est pas le <a> qui supporte le routerLink -->
        <li routerLink="./general" class="nav-item" routerLinkActive="active"><a class="nav-link">Général</a></li>

        <li routerLink="./photos" class="nav-item" routerLinkActive="active">
          <a class="nav-link">Photos {{ hasConstraintOnPhotos ? '*' : '' }}</a>
        </li>

        <li routerLink="./construction" class="nav-item" routerLinkActive="active">
          <a class="nav-link">Construction</a>
        </li>

        <li routerLink="./formalites" class="nav-item" routerLinkActive="active">
          <a class="nav-link">Formalités et Coûts</a>
        </li>

        @if (currentEtude.previsite) {
          <li routerLink="./virtual_visit" class="nav-item" routerLinkActive="active">
            <a class="nav-link">Visite virtuelle</a>
          </li>
        }
      </ul>
    </div>

    <form (ngSubmit)="save()" #editForm="ngForm" class="estimation-edit">
      @if (ngEstimation.statut !== STATUTS_ARCHIVE && showSaveButton) {
        <esk-button-save [saving]="saving"/>
      }

      <router-outlet/>
    </form>
  </div>
} @else {
  <app-loader [classHeight]="'tw-h-40'" class="tw-block tw-mt-4"/>
}
