import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgDemandeurLifetime]'})
export class NgDemandeurLifetimeDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeurLifetimeCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgDemandeurLifetimeDirective],
    selector: 'app-ng-demandeur-lifetime',
    template: '<div appNgDemandeurLifetime></div>',
})
export class NgDemandeurLifetimeComponent {
}
