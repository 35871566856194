import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEstimationEvaluationReferencesCriteria]'})
export class NgEstimationEvaluationReferencesCriteriaDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationEvaluationReferencesCriteriaCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgEstimationEvaluationReferencesCriteriaDirective],
    selector: 'app-ng-estimation-evaluation-references-criteria',
    template: '<div appNgEstimationEvaluationReferencesCriteria></div>',
})
export class NgEstimationEvaluationReferencesCriteriaComponent {
}
