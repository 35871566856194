import {Component, Inject, inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, from, Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {UserService} from '@models/users/user/user.service';
import {NgVenteManager} from '@legacy/app/managers/managers';
import {NgBienDossierCurrent} from '@legacy/app/biens/biens';
import {NgVente} from '@legacy/app/managers/ressources';
import {Location} from '@angular/common';
import {IWindowHistoryState} from '@shared/routes/routes.interfaces';
import Vente from '@models/ventes/vente/vente.model';
import {VenteService} from '@models/ventes/vente/vente.service';
import {INavigationLateralMenu, INavigationLateralSection} from '@shared/navigation/navigation.interfaces';
import {CDossierFilesService} from '@models/dossiers/dossier/files/collection/dossier-files.collection.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {AppNavigationLateralComponent} from '@shared/navigation/lateral/navigation-lateral.component';

@Component({selector: 'layout-vente', standalone: false, templateUrl: 'layout-vente.component.html'})
export class AppLayoutVenteComponent implements OnDestroy, OnInit {
    static readonly COLOR_ICON_FILLED_GED = 'tw-text-green-800';
    static readonly menus: Record<string, INavigationLateralMenu> = {
        GED: {
            code: AppNavigationLateralComponent.menuCodes.GED,
            label: 'Documents',
            route: 'ged',
        },
        OVERVIEW: {
            code: AppNavigationLateralComponent.menuCodes.OVERVIEW,
            label: 'Vue d\'ensemble',
            route: 'overview',
        },
    };
    private _activatedRoute = inject(ActivatedRoute);
    private _cDossierFilesService = inject(CDossierFilesService);
    private _location = inject(Location);
    private _ngInjectorService = inject(NgInjectorService);
    private _userService = inject(UserService);
    private _venteService = inject(VenteService);
    private _window: Window;
    private _ngVente!: NgVente;
    private readonly _onDestroy$ = new Subject<void>();
    private _sectionsSource = new ReplaySubject<INavigationLateralSection[]>(1);
    private _sections$ = this._sectionsSource.asObservable();

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    // Supprimer les injections des anciens manager
    private get ngBienDossierCurrent(): NgBienDossierCurrent {
        return this._ngInjectorService.getService('BienDossierCurrent');
    }

    // Supprimer les injections des anciens manager
    private get ngVenteManager(): NgVenteManager {
        return this._ngInjectorService.getService('VenteManager');
    }

    get ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }

    get ngVente(): NgVente {
        return this._ngVente;
    }

    get sections$(): Observable<INavigationLateralSection[]> {
        return this._sections$;
    }

    get vente$(): Observable<Vente> {
        return this._venteService.current$;
    }

    ngOnInit(): void {
        combineLatest([this._activatedRoute.params, this._userService.last$]).pipe(
            switchMap(([params, currentUser]) => {
                const state = this._window.history.state as IWindowHistoryState;
                const fromUuid = params.venteId === 'item' && state?.extraParams?.uuid;

                this._venteService.initCurrent(fromUuid ? state.extraParams!.uuid! : +params.venteId);

                return this.vente$.pipe(
                    filter(vente => !!vente),
                    tap(vente => {
                        if (fromUuid) {
                            this._location.replaceState(this._location.path().replace('item', vente.id.toString()));
                        }
                    }),
                    // Code à supprimer quand le formulaire de saisie sera sous Camilla
                    switchMap(vente => from(this.ngVenteManager.initCurrent(vente.id, currentUser)).pipe(
                        switchMap(_ => this.ngVenteManager.current$),
                        tap(ngVente => this.ngBienDossierCurrent.set(ngVente)),
                        tap(ngVente => this._ngVente = ngVente),
                        map(_ => vente),
                    )),
                );
            }),
            switchMap(vente => this._cDossierFilesService.getCurrentTotal$().pipe(
                map(cDossierFilesTotal => ({cDossierFilesTotal, vente})),
            )),
            takeUntil(this._onDestroy$),
        ).subscribe(({cDossierFilesTotal, vente}) => {
            const menuGed = {...AppLayoutVenteComponent.menus.GED};
            const routePrefix = '/app/ventes/' + (vente.id || 'item').toString() + '/';
            const section: INavigationLateralSection = {menus: []};

            menuGed.colorIcon = cDossierFilesTotal > 0 ? AppLayoutVenteComponent.COLOR_ICON_FILLED_GED : undefined!;
            menuGed.label = cDossierFilesTotal > 1 ? (cDossierFilesTotal.toString() + ' Documents') : 'Document';
            section.menus.push({...AppLayoutVenteComponent.menus.OVERVIEW});
            section.menus.push(menuGed);

            section.menus.forEach(menu => menu.route = routePrefix + menu.route);
            this._sectionsSource.next([section]);
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
