import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgVenteCorrespondants]'})
export class NgVenteCorrespondantsDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVenteCorrespondantsCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgVenteCorrespondantsDirective],
    selector: 'app-ng-vente-correspondants',
    template: '<div appNgVenteCorrespondants></div>',
})
export class NgVenteCorrespondantsComponent {
}
