import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgAuthLogin]'})
export class NgAuthLoginDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskAuthLoginCtrl', elementRef, injector);
    }
}

@Component({imports: [NgAuthLoginDirective], selector: 'app-ng-auth-login',  template: '<div appNgAuthLogin></div>'})
export class NgAuthLoginComponent {
}
