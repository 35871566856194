import {inject, Injectable} from '@angular/core';
import {
    EtudeDossierQualifiedContactFactory
} from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.factory';
import {
    IEtudeDossierQualifiedContactApi
} from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.interfaces';
import EtudeDossierQualifiedContact
    from '@models/etude-dossiers/etude-dossier/qualified-contacts/qualified-contact/etude-dossier.qualified-contact.model';

// @todo Mettre à niveau comme ActualitesFactory
@Injectable({providedIn: 'root'})
export class EtudeDossierQualifiedContactsFactory {
    private _etudeDossierQualifiedContactFactory = inject(EtudeDossierQualifiedContactFactory);

    create(etudeDossierQualifiedContactsApi: IEtudeDossierQualifiedContactApi[]): EtudeDossierQualifiedContact[] {
        return etudeDossierQualifiedContactsApi.map(contactQualifiedApi => this._etudeDossierQualifiedContactFactory.create(contactQualifiedApi));
    }
}
