import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskimmoDossierSuiviReport]'})
export class NgEskimmoDossierSuiviReportDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDossierSuiviReportCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgEskimmoDossierSuiviReportDirective],
    selector: 'app-ng-eskimmo-dossier-suivi-report',
    template: '<div appNgEskimmoDossierSuiviReport></div>',
})
export class NgEskimmoDossierSuiviReportComponent {
}
