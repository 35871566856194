import {NgModule} from '@angular/core';
import {AppEmailStatutComponent} from '@features/emails/email/statut/email.statut.component';
import {
    AppEmailRecipientStatutComponent
} from '@features/emails/email/recipients/recipient/statut/email-recipient.statut.component';
import {AppEmailPopoverComponent} from '@features/emails/email/popover/email.popover.component';

@NgModule({
    exports: [AppEmailPopoverComponent, AppEmailRecipientStatutComponent, AppEmailStatutComponent],
    imports: [AppEmailPopoverComponent, AppEmailRecipientStatutComponent, AppEmailStatutComponent],
})
export class EmailsModule {
}
