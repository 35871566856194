import {Component} from '@angular/core';
import {AppPublicitesComponent} from '@features/publicites/publicites.component';

@Component({
    selector: 'layout-locations-publicites',
    standalone: false,
    templateUrl: 'layout-locations.publicites.component.html',
})
export class AppLayoutLocationsPublicitesComponent {
    get PUBLICITES_LOCATIONS(): string {
        return AppPublicitesComponent.modules.LOCATIONS;
    }
}
