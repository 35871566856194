import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {
    ICEstimationOnsaleReferencesApi
} from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.interfaces';
import {
    EstimationOnsaleReferenceFactory
} from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.factory';
import {
    EstimationOnsaleReferencesApiService
} from '@models/estimations/estimation/onsale-references/estimation-onsale-references.api.service';
import CEstimationOnsaleReferences
    from '@models/estimations/estimation/onsale-references/collection/estimation-onsale-references.collection.model';
import Estimation from '@models/estimations/estimation/estimation.model';

@Injectable({providedIn: 'root'})
export class CEstimationOnsaleReferencesFactory {
    private _estimationOnsaleReferenceFactory = inject(EstimationOnsaleReferenceFactory);
    private _estimationOnsaleReferencesApiService = inject(EstimationOnsaleReferencesApiService);

    create(cEstimationOnsaleReferencesApi: ICEstimationOnsaleReferencesApi): CEstimationOnsaleReferences {
        const cEstimationOnsaleReferences = new CEstimationOnsaleReferences();

        cEstimationOnsaleReferences.links = new Links(cEstimationOnsaleReferencesApi._links);
        cEstimationOnsaleReferences.page = cEstimationOnsaleReferencesApi.page;
        cEstimationOnsaleReferences.pages = cEstimationOnsaleReferencesApi.pages;
        cEstimationOnsaleReferences.perPage = cEstimationOnsaleReferencesApi.limit;
        cEstimationOnsaleReferences.total = cEstimationOnsaleReferencesApi.total;

        if (cEstimationOnsaleReferencesApi._embedded) {
            cEstimationOnsaleReferences.results = (cEstimationOnsaleReferencesApi._embedded.items || []).filter(estimationOnsaleReferenceApi => estimationOnsaleReferenceApi)
                .map(estimationOnsaleReferenceApi => this._estimationOnsaleReferenceFactory.create(estimationOnsaleReferenceApi));
        }

        return cEstimationOnsaleReferences;
    }

    get$(estimation: Estimation): Observable<CEstimationOnsaleReferences> {
        return this._estimationOnsaleReferencesApiService.getCollection$(estimation.id.toString()).pipe(map(cEstimationOnsaleReferencesApi => this.create(cEstimationOnsaleReferencesApi)));
    }

    getNext$(cEstimationOnsaleReferences: CEstimationOnsaleReferences): Observable<CEstimationOnsaleReferences> {
        return this._estimationOnsaleReferencesApiService.getCollectionByLink$(cEstimationOnsaleReferences.links.next)
            .pipe(map(cEstimationOnsaleReferencesApi => this.create(cEstimationOnsaleReferencesApi)));
    }
}
