import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {OffreachatDropdownComponent} from '@features/offreachats/item/dropdown/offreachat.dropdown.component';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';
import {IOffreachatActionsMainData, IOffreachatActionsMainDataOptions} from '@features/offreachats/offreachats.interfaces';

@Component({
    selector: 'offreachat-write-actions-main',
    standalone: false,
    templateUrl: 'offreachat-write.actions-main.component.html',
})
export class OffreachatWriteActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = OffreachatDropdownComponent.actions;
    static readonly initOffreachatActionsMainDataOptions: IOffreachatActionsMainDataOptions = {fromDossier: false};
    private _callToActionService = inject(CallToActionService);
    private _dropdownService = inject(DropdownService);
    private _userService = inject(UserService);
    private _offreachat!: Offreachat;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IOffreachatActionsMainDataOptions = {...OffreachatWriteActionsMainComponent.initOffreachatActionsMainDataOptions};

    get actions(): { SEND: string; SIGN: string } {
        return OffreachatWriteActionsMainComponent.actions;
    }

    set data(value: IOffreachatActionsMainData) {
        this._offreachat = value.offreachat;
        this._options = {...OffreachatWriteActionsMainComponent.initOffreachatActionsMainDataOptions, ...value.options};
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    get user$(): Observable<User> {
        return this._userService.last$;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this.user$.pipe(
            map(currentUser => ({
                enabledActions: {
                    archive: this._options.fromDossier,
                    print: true,
                    send: currentUser.hasRoleSignatureElectronique(),
                    save: this._options.fromDossier,
                },
            })),
            take(1),
        ).subscribe(options => this._dropdownService.open(htmlButtonElement, {
            component: OffreachatDropdownComponent,
            data: {offreachat: this._offreachat, options},
        }));
    }

    action(action: string): void {
        this._callToActionService.clicked(action, this._offreachat);
    }
}
