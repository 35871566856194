import {inject, Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {UrlService} from '@shared/texts/url/url.service';

// @todo Rassembler ici tous les appels à @Inject('Window') et @Inject(DOCUMENT)
@Injectable({providedIn: 'root'})
export class BrowserWindowService {
    static readonly NO_TITLE = '_blank';
    private _urlService = inject(UrlService);
    private _window: Window;

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
        this._window.addEventListener(type, listener, options);
    }

    getNavigator(): Navigator {
        return this._window.navigator;
    }

    open$(path: string, queryParams?: Record<string, unknown>, name = BrowserWindowService.NO_TITLE): Observable<Window> {
        const completePath = this._urlService.pathWithQueryParameters(path, queryParams);

        return of(this._window.open(completePath, name)!);
    }

    openFromOrigin$(path: string, queryParams?: Record<string, unknown>, name = BrowserWindowService.NO_TITLE): Observable<Window> {
        let formattedOrigin = this._window.origin;

        if (!path.startsWith('/')) {
            formattedOrigin += '/';
        }

        return this.open$(formattedOrigin + path, queryParams, name);
    }
}
