import {Component, inject, OnInit} from '@angular/core';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {Observable} from 'rxjs';
import EtudeSettingsNetwork from '@models/etudes/etude/settings/network/etude-settings-network.model';
import {map, take} from 'rxjs/operators';
import {EtudeSettingsNetworkService} from '@models/etudes/etude/settings/network/etude-settings-network.service';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';

@Component({
    selector: 'layout-configuration-network',
    standalone: false,
    templateUrl: 'layout-configuration-network.component.html',
})
export class AppLayoutConfigurationNetworkComponent implements OnInit {
    private _etudeService = inject(EtudeService);
    private _etudeSettingsNetworkService = inject(EtudeSettingsNetworkService);
    private _userService = inject(UserService);
    private _compromisActifLoading = false;
    private _etudeSettingsNetwork$!: Observable<EtudeSettingsNetwork>;
    private _hasRoleVente$!: Observable<boolean>;
    private _matchingDemandeursLoading = false;

    get compromisActifLoading(): boolean {
        return this._compromisActifLoading;
    }

    get etudeSettingsNetwork$(): Observable<EtudeSettingsNetwork> {
        return this._etudeSettingsNetwork$;
    }

    get hasRoleVente$(): Observable<boolean> {
        return this._hasRoleVente$;
    }

    get matchingDemandeursLoading(): boolean {
        return this._matchingDemandeursLoading;
    }

    ngOnInit(): void {
        this._etudeSettingsNetwork$ = this._etudeService.last$.pipe(map(currentEtude => currentEtude.settingsNetwork));
        this._hasRoleVente$ = this._userService.currentHasRole$(User.roles.GRP_VENTE);
    }

    updateCompromisActif(etudeSettingsNetwork: EtudeSettingsNetwork, compromisActif: boolean): void {
        this._compromisActifLoading = true;
        etudeSettingsNetwork.compromisActif = compromisActif;
        this._etudeSettingsNetworkService.save$(etudeSettingsNetwork).pipe(take(1)).subscribe({complete: () => this._compromisActifLoading = false});
    }

    updateMatchingDemandeurs(etudeSettingsNetwork: EtudeSettingsNetwork, matchingDemandeurs: boolean): void {
        this._matchingDemandeursLoading = true;
        etudeSettingsNetwork.matchingDemandeurs = matchingDemandeurs;
        this._etudeSettingsNetworkService.save$(etudeSettingsNetwork).pipe(take(1)).subscribe({complete: () => this._matchingDemandeursLoading = false});
    }
}
