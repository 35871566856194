import {Component, inject, Input} from '@angular/core';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {Observable, ReplaySubject} from 'rxjs';
import {take} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {EtudeSlideOverComponent} from '@features/etudes/etude/slide-over/etude.slide-over.component';
import {AsyncPipe} from '@angular/common';
import {AppLoadObsPipe} from '@shared/load/obs/load-obs.pipe';
import {LoadModule} from '@shared/load/load.module';

@Component({
    imports: [AppLoadObsPipe, AsyncPipe, LoadModule],
    selector: 'app-etude-cartouche',
    templateUrl: 'etude.cartouche.component.html',
})
export class AppEtudeCartoucheComponent {
    private _etudeFactory = inject(EtudeFactory);
    private _slideOverService = inject(SlideOverService);
    private _etudeSource = new ReplaySubject<Etude>(1);
    private _etude$ = this._etudeSource.asObservable();

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    @Input()
    set etude(value: Etude) {
        this._etudeSource.next(value);
    }

    @Input()
    set link(value: string) {
        if (value) {
            this._etudeFactory.getByLink$(value).pipe(take(1)).subscribe(user => this._etudeSource.next(user));
        } else {
            this._etudeSource.next(undefined!);
        }
    }

    openSlideOver(etude: Etude): void {
        this._slideOverService.open$(EtudeSlideOverComponent, {etude});
    }
}
