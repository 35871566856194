import {Component} from '@angular/core';
import {AppPublicitesComponent} from '@features/publicites/publicites.component';

@Component({
    selector: 'layout-demandeurs-publicites',
    standalone: false,
    templateUrl: 'layout-demandeurs.publicites.component.html',
})
export class AppLayoutDemandeursPublicitesComponent {
    get PUBLICITES_DEMANDEURS(): string {
        return AppPublicitesComponent.modules.DEMANDEURS;
    }
}
