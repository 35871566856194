import {Component, inject, OnInit, output} from '@angular/core';
import {PersonFactory} from '@models/contacts/person/person.factory';
import Person from '@models/contacts/person/person.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {ProvideParentFormDirective} from '@shared/form/provide-parent-form.directive';
import {
    AppDictionarySelectItemsComponent
} from '@features/dictionaries/dictionary/items/select/dictionary-select-items.component';
import {AppFormErrorDirective} from '@shared/form/error/form-error.directive';
import {FormsModule} from '@angular/forms';
import {FormEmailValidatorDirective} from '@shared/form/validators/form.email.validator';
import {FormPhoneValidatorDirective} from '@shared/form/validators/form.phone.validator';

@Component({
    imports: [
        AppDictionarySelectItemsComponent,
        FormEmailValidatorDirective,
        FormPhoneValidatorDirective,
        AppFormErrorDirective,
        FormsModule,
        ProvideParentFormDirective,
    ],
    selector: 'app-person-edit-simple',
    templateUrl: 'person.edit-simple.component.html',
})
export class AppPersonEditSimpleComponent implements OnInit {
    readonly edited = output<Person>();
    private _personFactory = inject(PersonFactory);
    private _person!: Person;

    get TITRES(): string {
        return Dictionary.names.TITRES;
    }

    get person(): Person {
        return this._person;
    }

    ngOnInit(): void {
        this._person = this._personFactory.createVirgin();
        this.edited.emit(this.person);
    }

    onBlur(): void {
        this.edited.emit(this.person);
    }

    onSelectTitre(titre: DictionarySelected): void {
        this._person.titre = titre as DictionaryItem;
        this.onBlur();
    }
}
