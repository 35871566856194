import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CanDeactivateComponent} from '@core/routing/guards/guards.interfaces';

// @todo À ranger dans core/routing directement
@Injectable({providedIn: 'root'})
export class CanDeactivateGuard {
    canDeactivate(component: CanDeactivateComponent): Observable<boolean> {
        return component?.canDeactivate ? component.canDeactivate() : of(true);
    }
}
