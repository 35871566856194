import BienPerformanceEnergetique from '@models/bien/performance-energetique/bien-performance-energetique.model';
import {IBienPerformanceEnergetiqueApi} from '@models/bien/performance-energetique/bien-performance-energetique.interfaces';
import {getMockDictionaryItem} from '@models/dictionaries/dictionary/items/dictionary-items.fixtures';
import {getMockDateString} from '@shared/date/date.format.fixtures';

export const getMockBienPerformanceEnergetique = (): BienPerformanceEnergetique => {
    const suffix = 'bien-performance-energetique';
    const bienPerformanceEnergetique = new BienPerformanceEnergetique();

    bienPerformanceEnergetique.comments = 'comments-' + suffix;
    bienPerformanceEnergetique.consommation = 4;
    bienPerformanceEnergetique.consommationClasse = getMockDictionaryItem();
    bienPerformanceEnergetique.coutMax = 3;
    bienPerformanceEnergetique.coutMin = 8;
    bienPerformanceEnergetique.date = getMockDateString();
    bienPerformanceEnergetique.dateReference = getMockDateString();
    bienPerformanceEnergetique.emissions = 9;
    bienPerformanceEnergetique.emissionsClasse = getMockDictionaryItem();
    bienPerformanceEnergetique.statutDpe = getMockDictionaryItem();

    return bienPerformanceEnergetique;
};

export const getMockBienPerformanceEnergetiqueApi = (suffix = 'bien-performance-energetique-api'): IBienPerformanceEnergetiqueApi => ({
    comments: 'comments-' + suffix,
    consommation: 4,
    consommationClasse: 'consommationClasse-' + suffix,
    coutMax: 3,
    coutMin: 8,
    date: getMockDateString(),
    dateReference: getMockDateString(),
    emissions: 9,
    emissionsClasse: 'emissionsClasse-' + suffix,
    statutDpe: 'statutDpe-' + suffix,
} as IBienPerformanceEnergetiqueApi);

export class MockBienPerformanceEnergetiqueFactory {
    create(): void {
    }

    forApi(): void {
    }

    ngCreate(): void {
    }
}

export class MockBienPerformanceEnergetiqueService {
    getConsommationClasse(): void {
    }

    getEmissionsClasse(): void {
    }

    isDiagV1(): void {
    }

    isDiagV2(): void {
    }
}
