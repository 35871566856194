import {Component, inject, Input} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {CVentePrixHistoriesFactory} from '@models/ventes/vente/prix-histories/collection/vente-prix-histories.collection.factory';
import {SortConst} from '@shared/constants';
import {Observable, of} from 'rxjs';
import CVentePrixHistories from '@models/ventes/vente/prix-histories/collection/vente-prix-histories.collection.model';

@Component({selector: 'app-vente-prix-history', standalone: false, templateUrl: 'vente-prix-history.component.html'})
export class AppVentePrixHistoryComponent {
    private _cVentePrixHistoriesFactory = inject(CVentePrixHistoriesFactory);
    private _cVentePrixHistories$!: Observable<CVentePrixHistories>;
    private _vente!: Vente;

    get cVentePrixHistories$(): Observable<CVentePrixHistories> {
        return this._cVentePrixHistories$;
    }

    get vente(): Vente {
        return this._vente;
    }

    @Input()
    set vente(value: Vente) {
        this._vente = value;

        if (this._vente.isNewOrBrouillon() || this._vente.isArchived()) {
            this._cVentePrixHistories$ = of(undefined as unknown as CVentePrixHistories);
        } else {
            this._cVentePrixHistories$ = this._cVentePrixHistoriesFactory.get$(this._vente, {tris: {createdAt: SortConst.DESCENDING}});
        }
    }
}
