import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {PhoneFactory} from '@models/phones/phone/phone.factory';
import {ICPhonesApi} from '@models/phones/collection/phones.collection.interfaces';
import CPhones from '@models/phones/collection/phones.collection.model';

@Injectable({providedIn: 'root'})
export class CPhonesFactory {
    private _phoneFactory = inject(PhoneFactory);

    create(cPhonesApi: ICPhonesApi): CPhones {
        const cPhones = new CPhones();

        cPhones.links = new Links(cPhonesApi._links);
        cPhones.page = cPhonesApi.page;
        cPhones.pages = cPhonesApi.pages;
        cPhones.perPage = cPhonesApi.limit;
        cPhones.total = cPhonesApi.total;
        if (cPhonesApi._embedded) {
            cPhones.results = (cPhonesApi._embedded.items || []).filter(phoneApi => phoneApi)
                .map(phoneApi => this._phoneFactory.create(phoneApi));
        }

        return cPhones;
    }
}
