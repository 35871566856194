import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {NgBrowserTools} from '@legacy/app/eskimmo/eskimmo';
import {from, Observable, of} from 'rxjs';

// @todo À ranger dans shared/browser
@Injectable({providedIn: 'root'})
export class BrowserGuard {
    private _ngInjectorService = inject(NgInjectorService);
    private _router = inject(Router);

    // Supprimer les injections des anciens manager
    private get ngEskBrowserTools(): NgBrowserTools {
        return this._ngInjectorService.getService('EskBrowserTools');
    }

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean> {
        if (routerStateSnapshot.url === '/browser') {
            return of(true);
        }

        if (!this.ngEskBrowserTools.browserIsOudated()) {
            return of(true);
        }

        return from(this._router.navigateByUrl('browser'));
    }
}
