import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {
    ICBonvisitesApi, ICBonvisitesQueryParameters
} from '@models/bonvisites/collection/bonvisites.collection.interfaces';
import CBonvisites from '@models/bonvisites/collection/bonvisites.collection.model';
import {BonvisiteFactory} from '@models/bonvisites/bonvisite/bonvisite.factory';
import {Observable} from 'rxjs';
import {BonvisitesApiService} from '@models/bonvisites/bonvisites.api.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CBonvisitesFactory {
    private _bonvisiteFactory = inject(BonvisiteFactory);
    private _bonvisitesApiService = inject(BonvisitesApiService);

    create(cBonvisitesApi: ICBonvisitesApi): CBonvisites {
        const cBonvisites = new CBonvisites();

        cBonvisites.links = new Links(cBonvisitesApi._links);
        cBonvisites.page = cBonvisitesApi.page;
        cBonvisites.pages = cBonvisitesApi.pages;
        cBonvisites.perPage = cBonvisitesApi.limit;
        cBonvisites.total = cBonvisitesApi.total;
        if (cBonvisitesApi._embedded) {
            cBonvisites.results = (cBonvisitesApi._embedded.items || []).filter(bonvisiteApi => bonvisiteApi)
                .map(bonvisiteApi => this._bonvisiteFactory.create(bonvisiteApi));
        }

        return cBonvisites;
    }

    get$(queryParameters?: ICBonvisitesQueryParameters): Observable<CBonvisites> {
        return this._bonvisitesApiService.getEtudeCollection$(queryParameters).pipe(map(cBonvisitesApi => this.create(cBonvisitesApi)));
    }

    getNext$(cBonvisites: CBonvisites): Observable<CBonvisites> {
        return this._bonvisitesApiService.getCollectionByLink$(cBonvisites.links.next).pipe(map(cBonvisitesApi => this.create(cBonvisitesApi)));
    }
}
