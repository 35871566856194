import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {takeUntil} from 'rxjs/operators';
import {
    EstimationRapportDropdownComponent
} from '@features/estimations/estimation/rapport/dropdown/estimation-rapport.dropdown.component';
import {
    IEstimationRapportActionsData, IEstimationRapportActionsDataOptions
} from '@features/estimations/estimations.interfaces';
import {AsyncPipe, NgClass} from '@angular/common';
import {LoadModule} from '@shared/load/load.module';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    imports: [AsyncPipe, FaIconComponent, LoadModule, NgClass],
    selector: 'estimation-rapport-actions-main',
    templateUrl: 'estimation-rapport.actions-main.component.html',
})
export class EstimationRapportActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = EstimationRapportDropdownComponent.actions;
    static readonly initEstimationRapportActions = EstimationRapportDropdownComponent.initEstimationRapportActions;
    private _callToActionService = inject(CallToActionService);
    private _dropdownService = inject(DropdownService);
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IEstimationRapportActionsDataOptions = {...{enabledActions: EstimationRapportActionsMainComponent.initEstimationRapportActions}};
    private _showDropdown = false;

    set data(value: IEstimationRapportActionsData) {
        this._options = {...{enabledActions: EstimationRapportActionsMainComponent.initEstimationRapportActions}, ...value.options};
        if (this._options.enabledActions) {
            let numberOptions = 0;

            if (this._options.enabledActions.generate) {
                numberOptions++;
            }

            if (this._options.enabledActions.print) {
                numberOptions++;
            }

            if (this._options.enabledActions.save) {
                numberOptions++;
            }

            if (this._options.enabledActions.send) {
                numberOptions++;
            }

            this._showDropdown = numberOptions > 1;
        }
    }

    get options(): IEstimationRapportActionsDataOptions {
        return this._options;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    get showDropdown(): boolean {
        return this._showDropdown;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        const enabledActions = {...this.options.enabledActions};

        if (this.options.enabledActions?.save) {
            enabledActions.save = false;
        } else if (this.options.enabledActions?.send) {
            enabledActions.send = false;
        } else if (this.options.enabledActions?.generate) {
            enabledActions.generate = false;
        }

        this._dropdownService.open(htmlButtonElement, {
            component: EstimationRapportDropdownComponent,
            data: {options: {enabledActions}},
        });
    }

    generate(): void {
        this._callToActionService.clicked(EstimationRapportActionsMainComponent.actions.GENERATE);
    }

    save(): void {
        this._callToActionService.clicked(EstimationRapportActionsMainComponent.actions.SAVE);
    }

    send(): void {
        this._callToActionService.clicked(EstimationRapportActionsMainComponent.actions.SEND);
    }
}
