import {inject, Injectable} from '@angular/core';
import {ArrondissementFactory} from '@models/arrondissements/arrondissement/arrondissement.factory';
import {IArrondissementApi} from '@models/arrondissements/arrondissement/arrondissement.interfaces';
import Arrondissement from '@models/arrondissements/arrondissement/arrondissement.model';

@Injectable({providedIn: 'root'})
export class ArrondissementsFactory {
    private _arrondissementFactory = inject(ArrondissementFactory);

    create(arrondissementApis: IArrondissementApi[] = []): Arrondissement[] {
        return (arrondissementApis || []).filter(arrondissementApi => arrondissementApi)
            .map(arrondissementApi => this._arrondissementFactory.create(arrondissementApi));
    }
}
