import {inject, Injectable} from '@angular/core';
import {VenteMatchingFactory} from '@models/ventes/vente/matchings/matching/vente-matching.factory';
import VenteMatching from '@models/ventes/vente/matchings/matching/vente-matching.model';
import {IVenteMatchingApi} from '@models/ventes/vente/matchings/matching/vente-matching.interfaces';

@Injectable({providedIn: 'root'})
export class VenteMatchingsFactory {
    private _venteMatchingFactory = inject(VenteMatchingFactory);

    create(venteMatchingApis: IVenteMatchingApi[] = []): VenteMatching[] {
        return (venteMatchingApis || []).filter(venteMatchingApi => venteMatchingApi)
            .map(venteMatchingApi => this._venteMatchingFactory.create(venteMatchingApi));
    }
}
