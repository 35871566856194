import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {NgForm} from '@angular/forms';
import {ModalService} from '@shared/modal/modal.service';
import Commune from '@core/models/communes/commune/commune.model';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import DictionaryItem from '@models/dictionaries/dictionary/items/item/dictionary-item.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {ToasterService} from '@shared/toaster/toaster.service';
import EstimationReference from '@models/estimations/estimation/references/reference/estimation-reference.model';

@Component({
    selector: 'dc-estimation-reference-edit',
    standalone: false,
    templateUrl: 'estimation.reference-edit.component.html',
})
export class DCEstimationReferenceEditComponent implements IDynamicComponent {
    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.',
        TITLE: 'Données incomplètes',
    };
    private _dictionaryItemService = inject(DictionaryItemService);
    private _modalService = inject(ModalService);
    private _toasterService = inject(ToasterService);
    private _estimationReference!: EstimationReference;
    private _superficieLabel = DictionaryItemService.superficieLabel.DEFAULT;

    set data(dynamicComponentData: IDynamicComponentData) {
        this._estimationReference = dynamicComponentData.estimationReference as EstimationReference;
        this.updateSuperficieLabel();
    }

    get DICTIONARY_NAME_CONSTRUCTION_EPOQUES(): string {
        return Dictionary.names.CONSTRUCTION_EPOQUES;
    }

    get DICTIONARY_NAME_NATURES(): string {
        return Dictionary.names.NATURES;
    }

    get estimationReference(): EstimationReference {
        return this._estimationReference;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    onSelectCommune(commune: Commune): void {
        this._estimationReference.communeId = commune?.id;
        this._estimationReference.linkCommune = commune?.linkSelf;
    }

    onSelectEpoque(epoque: DictionarySelected): void {
        this._estimationReference.epoqueId = (epoque as DictionaryItem)?.id;
    }

    onSelectNature(nature: DictionarySelected): void {
        this._estimationReference.natureId = (nature as DictionaryItem)?.id;
        this.updateSuperficieLabel();
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            this._toasterService.warning(DCEstimationReferenceEditComponent.errorMessages.TITLE, DCEstimationReferenceEditComponent.errorMessages.OTHER);

            return;
        }

        this._modalService.setResponse(this._estimationReference);
    }

    updateSuperficieLabel(): void {
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabelById(this._estimationReference.natureId);
    }
}
