import {Component, Input, output, ViewChild} from '@angular/core';
import {FormsModule, NgModel} from '@angular/forms';
import {IFormSelectDatetimeOptions} from '@shared/form/form.interfaces';
import DateFormat from '@shared/date/date.format';
import {ProvideParentFormDirective} from '@shared/form/provide-parent-form.directive';

@Component({
    imports: [FormsModule, ProvideParentFormDirective],
    exportAs: 'formSelectDatetime',
    selector: 'app-form-select-datetime',
    templateUrl: 'form.select-datetime.component.html',
})
export class AppFormSelectDatetimeComponent {
    static readonly initFormSelectDatetimeOptions: IFormSelectDatetimeOptions = {
        max: undefined!,
        name: 'formSelectDatetime',
        required: false,
    };
    readonly selected = output<string>();
    private _formInput!: NgModel;
    private _modelDatetime!: string;
    private _options: IFormSelectDatetimeOptions = {...AppFormSelectDatetimeComponent.initFormSelectDatetimeOptions};

    get formInput(): NgModel {
        return this._formInput;
    }

    @ViewChild('formDatetime')
    set formInput(value: NgModel) {
        setTimeout(_ => this._formInput = value, 1);
    }

    @Input()
    set model(value: string | Date) {
        const sanitizedDatetime = value instanceof Date ? (DateFormat.dateFromDate(value) + 'T' + value.toLocaleTimeString()) : value;

        // @todo Quand null que faire ?
        this._modelDatetime = sanitizedDatetime?.slice(0, 16);
    }

    get modelDatetime(): string {
        return this._modelDatetime;
    }

    set modelDatetime(value: string) {
        this._modelDatetime = value;
        this.selected.emit(DateFormat.toAPI(this._modelDatetime));
    }

    get options(): IFormSelectDatetimeOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectDatetimeOptions) {
        this._options = {...AppFormSelectDatetimeComponent.initFormSelectDatetimeOptions, ...value};
        this._options.max = DateFormat.dateFromDatetime(this._options.max);
    }
}
