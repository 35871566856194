import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import {NgBienDossier, NgBienPiece, NgEstimation} from '@legacy/app/managers/ressources';
import {from} from 'rxjs';
import {NgDictionariesManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import {DictionarySelected} from '@features/dictionaries/dictionaries.interfaces';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';
import Estimation from '@models/estimations/estimation/estimation.model';

@Component({selector: 'bien-edit-piece', standalone: false, templateUrl: 'bien.edit-piece.component.html'})
export class DCBienEditPieceComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _isEvaluation = false;
    private _ngBienDossier!: NgBienDossier;
    private _ngBienPiece!: NgBienPiece;

    // Supprimer les injections des anciens manager
    private get ngDictionariesManager(): NgDictionariesManager {
        return this._ngInjectorService.getService('DictionariesManager');
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._ngBienDossier = dynamicComponentData.ngBienDossier as NgBienDossier;
        this._ngBienPiece = dynamicComponentData.ngBienPiece as NgBienPiece;
        this._isEvaluation = this._ngBienDossier._esk.typeDossier === DossierBienTypesConst.ESTIMATION && (this._ngBienDossier as NgEstimation).type.code !== Estimation.types.AVIS_VALEUR;
    }

    get DICTIONARY_NAME_ETAGES(): string {
        return Dictionary.names.ETAGES;
    }

    get DICTIONARY_NAME_PIECE_TYPES(): string {
        return Dictionary.names.PIECE_TYPES;
    }

    get isEvaluation(): boolean {
        return this._isEvaluation;
    }

    get ngBienPiece(): NgBienPiece {
        return this._ngBienPiece;
    }

    onSelectEtage(etage: DictionarySelected): void {
        if (etage && !Array.isArray(etage)) {
            this._ngBienPiece.etage = this.ngDictionariesManager.createFromNg2(etage);
        } else {
            this._ngBienPiece.etage = undefined!;
        }
    }

    onSelectHabitable(habitable: boolean): void {
        this._ngBienPiece.habitable = habitable;
        this._ngBienPiece.setSurfacePonderee();
    }

    onSelectPiece(pieceType: DictionarySelected): void {
        if (pieceType && !Array.isArray(pieceType)) {
            this._ngBienPiece.piece = this.ngDictionariesManager.createFromNg2(pieceType);
        } else {
            this._ngBienPiece.piece = undefined!;
        }
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        from(this._ngBienDossier.bien.saveItem('pieces', this._ngBienPiece, true))
            .pipe(take(1)).subscribe(_ => this._modalService.setResponse(this._ngBienPiece));
    }
}
