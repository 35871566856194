import {Component, inject, Input, OnInit, output} from '@angular/core';
import {Observable} from 'rxjs';
import CBonvisites from '@models/bonvisites/collection/bonvisites.collection.model';
import {take} from 'rxjs/operators';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {CBonvisitesService} from '@models/bonvisites/collection/bonvisites.collection.service';
import {SortConst} from '@shared/constants';

@Component({selector: 'app-bonvisites-list', standalone: false, templateUrl: 'bonvisites.list.component.html'})
export class AppBonvisitesListComponent implements OnInit {
    readonly clicked = output<[HTMLButtonElement, Bonvisite]>();
    private _cBonvisitesService = inject(CBonvisitesService);
    private _demandeurUuids!: string[];
    private _locationUuids!: string[];
    private _pendingGetMore = false;
    private _venteUuids!: string[];

    get cBonvisites$(): Observable<CBonvisites> {
        return this._cBonvisitesService.current$;
    }

    @Input()
    set demandeurUuids(value: string[]) {
        this._demandeurUuids = value;
    }

    @Input()
    set locationUuids(value: string[]) {
        this._locationUuids = value;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    @Input()
    set venteUuids(value: string[]) {
        this._venteUuids = value;
    }

    ngOnInit(): void {
        this._cBonvisitesService.initCurrent({
            demandeurUuid: this._demandeurUuids,
            locationUuid: this._locationUuids,
            tris: {createdAt: SortConst.DESCENDING},
            venteUuid: this._venteUuids,
        });
    }

    click(htmlButtonElement: HTMLButtonElement, bonvisite: Bonvisite): void {
        this.clicked.emit([htmlButtonElement, bonvisite]);
    }

    getMore(): void {
        this._pendingGetMore = true;
        this._cBonvisitesService.addNextToCurrent$().pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }
}
