import {animate, animateChild, group, query, state, style, transition, trigger} from '@angular/animations';
import {Component, Inject, inject, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {combineLatest, fromEvent, Subject} from 'rxjs';
import {filter, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    animations: [
        trigger('openClose', [
            state('true', style({ opacity: '1' })),
            state('false', style({ opacity: '0' })),
            transition('false <=> true', [
                group([query('@openCloseSlideOver', animateChild()), animate('400ms ease-in-out')]),
            ]),
        ]),
        trigger('openCloseSlideOver', [
            state('true', style({ transform: 'translateX(0)' })),
            state('false', style({ transform: 'translateX(100%)' })),
            transition('false <=> true', animate('400ms ease-in-out')),
        ]),
    ],
    imports: [FaIconComponent],
    selector: 'app-slide-over',
    templateUrl: 'slide-over.component.html',
})
export class SlideOverComponent implements OnDestroy, OnInit {
    @ViewChild('slideOverContent', {read: ViewContainerRef, static: true}) slideOverContent!: ViewContainerRef;
    private _document: Document;
    private _router = inject(Router);
    private _slideOverService = inject(SlideOverService);
    private _isOpen = false;
    private readonly _onDestroy$ = new Subject<void>();

    constructor(@Inject(DOCUMENT) document: Document) {
        this._document = document;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    ngOnInit(): void {
        this._slideOverService.dynamicComponentLoading$.pipe(
            tap(_ => this.slideOverContent.clear()),
            takeUntil(this._onDestroy$),
        ).subscribe(dynamicComponentLoading => {
            const componentRef = this.slideOverContent.createComponent(dynamicComponentLoading.component);

            componentRef.instance.data = dynamicComponentLoading.data;
        });
        this._slideOverService.isOpen$.pipe(
            tap(isOpen => this._isOpen = isOpen),
            filter(isOpen => !isOpen),
            takeUntil(this._onDestroy$),
        ).subscribe(_ => this.slideOverContent.clear());
        combineLatest([
            this._router.events.pipe(
                map(event => event as RouterEvent),
                filter(event => event instanceof NavigationStart),
                startWith(undefined!),
            ),
            fromEvent(this._document, 'keyup', (data: KeyboardEvent): string => data.code).pipe(
                filter(keyCode => keyCode === 'Escape'),
                startWith(undefined!),
            ),
        ]).pipe(takeUntil(this._onDestroy$)).subscribe(_ => this.close());
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    close(): void {
        this._slideOverService.setResponse();
    }
}
