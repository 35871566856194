import {Component, Inject} from '@angular/core';

@Component({
    selector: 'layout-ventes-negociees',
    standalone: false,
    templateUrl: 'layout-ventes-negociees.component.html',
})
export class AppLayoutVentesNegocieesComponent {
    private _window: Window;

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    print(): void {
        this._window.print();
    }
}
