import {Component, inject, Input} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';

@Component({
    selector: 'app-estimation-actions-selection',
    standalone: false,
    templateUrl: 'estimation.actions-selection.component.html',
})
export class EstimationActionsSelectionComponent implements IDynamicComponent {
    static readonly actions = {LINK: 'estimation_link'};
    private _callToActionService = inject(CallToActionService);
    private _value!: IDynamicComponentData;

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    link(): void {
        this._callToActionService.clicked(EstimationActionsSelectionComponent.actions.LINK);
    }
}
