import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskimmoNegociationRevenus]'})
export class NgEskimmoNegociationRevenusDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskNegociationRevenusCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgEskimmoNegociationRevenusDirective],
    selector: 'app-ng-eskimmo-negociation-revenus',
    template: '<div appNgEskimmoNegociationRevenus></div>',
})
export class NgEskimmoNegociationRevenusComponent {
}
