import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UserService} from '@models/users/user/user.service';
import {IDossiersViewChoiceOptions} from '@features/dossiers/dossiers.interfaces';
import {Observable, ReplaySubject, Subject, switchMap} from 'rxjs';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {
    IUserClientMetadataConfigWalletRouteCode
} from '@models/users/user/client-metadata/user-client-metadata.interfaces';
import {DisplayMode} from '@shared/constants';
import {UserClientMetadataService} from '@models/users/user/client-metadata/user-client-metadata.service';

@Component({
    selector: 'app-dossiers-view-choice',
    standalone: false,
    templateUrl: 'dossiers-view-choice.component.html',
})
export class AppDossiersViewChoiceComponent implements OnDestroy, OnInit {
    @Output() readonly changed = new EventEmitter<string>();
    // Impossible de passer avec la ligne ci-dessous, une erreur dans la console apparait
    // Readonly changed = output<string>();

    static readonly initDossiersViewChoiceOptions: IDossiersViewChoiceOptions = {showTablePhoto: true};
    private _userClientMetadataService = inject(UserClientMetadataService);
    private _userService = inject(UserService);
    private _code!: string;
    private _dossiersWalletSource = new ReplaySubject<IUserClientMetadataConfigWalletRouteCode>(1);
    private _dossiersWallet$ = this._dossiersWalletSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IDossiersViewChoiceOptions = {...AppDossiersViewChoiceComponent.initDossiersViewChoiceOptions};
    private _route!: string;

    @Input({required: true})
    set code(value: string) {
        this._code = value;
    }

    get dossiersWallet$(): Observable<IUserClientMetadataConfigWalletRouteCode> {
        return this._dossiersWallet$;
    }

    get displayMode(): typeof DisplayMode {
        return DisplayMode;
    }

    get options(): IDossiersViewChoiceOptions {
        return this._options;
    }

    @Input()
    set options(value: IDossiersViewChoiceOptions) {
        this._options = {...AppDossiersViewChoiceComponent.initDossiersViewChoiceOptions, ...value};
    }

    @Input({required: true})
    set route(value: string) {
        this._route = value;
    }

    ngOnInit(): void {
        this._userService.last$.pipe(
            map(currentUser => currentUser.clientMetadata.getWallet(this._route, this._code)),
            tap(dossiersWallet => this.changed.emit(dossiersWallet.view)),
            takeUntil(this._onDestroy$),
        ).subscribe(dossiersWallet => this._dossiersWalletSource.next(dossiersWallet));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    updateView(view: DisplayMode): void {
        this._userService.last$.pipe(
            tap(currentUser => currentUser.clientMetadata.setWallet(this._route, this._code, {view})),
            switchMap(currentUser => this._userClientMetadataService.saveFromUser$(currentUser)),
            switchMap(_ => this._dossiersWallet$),
            tap(dossiersWallet => dossiersWallet.view = view),
            take(1),
        ).subscribe(() => this.changed.emit(view));
    }
}
