import {Component, Input, output} from '@angular/core';
import {IFormButtonInput} from '@shared/form/form.interfaces';
import {NgClass} from '@angular/common';

@Component({imports: [NgClass], selector: 'app-form-select-button', templateUrl: 'select-button.component.html'})
export class AppFormSelectButtonComponent {
    readonly selected = output<string>();
    private _list: IFormButtonInput[] = [];
    private _model!: string;

    get list(): IFormButtonInput[] {
        return this._list;
    }

    @Input()
    set list(value: IFormButtonInput[]) {
        this._list = value;
    }

    get model(): string {
        return this._model;
    }

    @Input()
    set model(value: string) {
        this._model = value;
    }

    onSelect(selectedButton: IFormButtonInput):  void {
        this._model = selectedButton.code;
        this.selected.emit(this.model);
    }
}
