import {Component, Input} from '@angular/core';
import Transfer from '@models/transfers/transfer/transfer.model';
import {ILoadProgressBarOptions} from '@shared/load/load.interfaces';

@Component({selector: 'app-transfer-progress', standalone: false, templateUrl: 'transfer.progress.component.html'})
export class AppTransferProgressComponent {
    static readonly initLoadProgressBarOptions: ILoadProgressBarOptions = {waitingLabel: 'Lancement du transfert'};
    private _options: ILoadProgressBarOptions = {...AppTransferProgressComponent.initLoadProgressBarOptions};
    private _transfer!: Transfer<unknown>;

    get options(): ILoadProgressBarOptions {
        return this._options;
    }

    @Input()
    set options(value: ILoadProgressBarOptions) {
        this._options = {...AppTransferProgressComponent.initLoadProgressBarOptions, ...value};
    }

    get transfer(): Transfer<unknown> {
        return this._transfer;
    }

    @Input()
    set transfer(value: Transfer<unknown>) {
        this._transfer = value;
    }
}
