import {NgModule} from '@angular/core';
import {NgAuthLoginComponent} from '@shared/angularJS/up-ng/auth/auth.login.component';
import {NgAuthLogoutComponent} from '@shared/angularJS/up-ng/auth/auth.logout.component';
import {NgAuthResetComponent} from '@shared/angularJS/up-ng/auth/auth.reset.component';
import {NgAuthStartComponent} from '@shared/angularJS/up-ng/auth/auth.start.component';
import {RouterModule} from '@angular/router';

@NgModule({
    exports: [NgAuthLoginComponent, NgAuthLogoutComponent, NgAuthResetComponent, NgAuthStartComponent, RouterModule],
    imports: [NgAuthLoginComponent, NgAuthLogoutComponent, NgAuthResetComponent, NgAuthStartComponent, RouterModule],
})
export class AuthComponentsModule {
}
