import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewHabitationService
} from '@features/dossiers/dossier/bien/overview/habitation/dossier-bien-overview.habitation.service';

@Component({
    selector: 'app-dossier-bien-overview-habitation',
    standalone: false,
    templateUrl: 'dossier-bien-overview.habitation.component.html',
})
export class AppDossierBienOverviewHabitationComponent {
    private _dossierBienOverviewHabitationService = inject(DossierBienOverviewHabitationService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isInfosLocativesVisible(): boolean {
        return this._dossierBienOverviewHabitationService.isInfosLocativesVisible;
    }

    get isResidenceVisible(): boolean {
        return this._dossierBienOverviewHabitationService.isResidenceVisible;
    }

    get isUsageVisible(): boolean {
        return this._dossierBienOverviewHabitationService.isUsageVisible;
    }
}
