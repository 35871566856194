import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    IProcedureSignataireActions, IProcedureSignataireDropdownData, IProcedureSignataireDropdownDataOptions
} from '@features/procedures/procedures.interfaces';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';

@Component({
    selector: 'dc-procedure-signataire-dropdown',
    standalone: false,
    templateUrl: 'procedure-signataire.dropdown.component.html',
})
export class ProcedureSignataireDropdownComponent implements IDynamicComponent {
    static readonly actions = {
        COPY_LINK_INTERFACE: 'copyLinkInterface',
        REMOVE: 'remove',
        SEND_LINK_INTERFACE: 'sendLinkInterface',
    };
    static readonly initProcedureSignataireActions: IProcedureSignataireActions = {
        copyLinkInterface: false,
        remove: false,
        sendLinkInterface: false,
    };
    private _dropdownService = inject(DropdownService);
    private _cProcedureSignataires!: CProcedureSignataires;
    private _options: IProcedureSignataireDropdownDataOptions = {...{enabledActions: ProcedureSignataireDropdownComponent.initProcedureSignataireActions}};
    private _procedureSignataire!: ProcedureSignataire;

    @Input()
    set data(value: IProcedureSignataireDropdownData) {
        this._cProcedureSignataires = value.cProcedureSignataires;
        this._options = {...{enabledActions: ProcedureSignataireDropdownComponent.initProcedureSignataireActions}, ...value.options};
        this._procedureSignataire = value.procedureSignataire;
    }

    get options(): IProcedureSignataireDropdownDataOptions {
        return this._options;
    }

    copyLinkInterface(): void {
        this._dropdownService.clicked(ProcedureSignataireDropdownComponent.actions.COPY_LINK_INTERFACE, {
            cProcedureSignataires: this._cProcedureSignataires,
            procedureSignataire: this._procedureSignataire,
        });
    }

    remove(): void {
        this._dropdownService.clicked(ProcedureSignataireDropdownComponent.actions.REMOVE, {
            cProcedureSignataires: this._cProcedureSignataires,
            procedureSignataire: this._procedureSignataire,
        });
    }

    sendLinkInterface(): void {
        this._dropdownService.clicked(ProcedureSignataireDropdownComponent.actions.SEND_LINK_INTERFACE, {
            cProcedureSignataires: this._cProcedureSignataires,
            procedureSignataire: this._procedureSignataire,
        });
    }
}
