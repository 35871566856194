import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDCEstimationOnsaleReferenceData} from '@features/estimations/estimations.interfaces';
import EstimationOnsaleReference
    from '@models/estimations/estimation/onsale-references/onsale-reference/estimation-onsale-reference.model';

@Component({
    selector: 'estimation-onsale-reference-dropdown',
    standalone: false,
    templateUrl: 'estimation.onsale-reference.dropdown.component.html',
})
export class EstimationOnsaleReferenceDropdownComponent implements IDynamicComponent {
    static readonly actions = {SEE: 'estimation-onsale-reference_see', UN_LINK: 'estimation-onsale-reference_unlink'};
    private _dropdownService = inject(DropdownService);
    private _estimationOnsaleReference!: EstimationOnsaleReference;

    @Input()
    set data(value: IDCEstimationOnsaleReferenceData) {
        this._estimationOnsaleReference = value.estimationOnsaleReference!;
    }

    see(): void {
        this._dropdownService.clicked(EstimationOnsaleReferenceDropdownComponent.actions.SEE, {estimationOnsaleReference: this._estimationOnsaleReference});
    }

    unLink(): void {
        this._dropdownService.clicked(EstimationOnsaleReferenceDropdownComponent.actions.UN_LINK, {estimationOnsaleReference: this._estimationOnsaleReference});
    }
}
