import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {of, Subject, switchMap} from 'rxjs';
import {BonvisiteService} from '@models/bonvisites/bonvisite/bonvisite.service';
import {map, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {IBonvisiteDropdownDataOptions} from '@features/bonvisites/bonvisites.interfaces';
import {BonvisiteDropdownComponent} from '@features/bonvisites/item/dropdown/bonvisite.dropdown.component';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import {MediaResumeService} from '@models/medias/media/media-resume.service';

@Component({
    selector: 'layout-bonvisites-wallet',
    standalone: false,
    templateUrl: 'layout-bonvisites-wallet.component.html',
})
export class AppLayoutBonvisitesWalletComponent implements OnDestroy, OnInit {
    private _bonvisiteService = inject(BonvisiteService);
    private _dossierService = inject(DossierService);
    private _dropdownService = inject(DropdownService);
    private _mediaResumeService = inject(MediaResumeService);
    private _router = inject(Router);
    private _demandeurUuids: string[] = [];
    private _locationUuids: string[] = [];
    private readonly _onDestroy$ = new Subject<void>();
    private _venteUuids: string[] = [];

    get demandeurUuids(): string[] {
        return this._demandeurUuids;
    }

    get locationUuids(): string[] {
        return this._locationUuids;
    }

    get venteUuids(): string[] {
        return this._venteUuids;
    }

    ngOnInit(): void {
        const currentDossier = this._dossierService.getCurrentFromNg(this._router.url);

        if (currentDossier?.isDemandeur()) {
            this._demandeurUuids = [currentDossier.uuid];
        } else if (currentDossier?.isLocation()) {
            this._locationUuids = [currentDossier.uuid];
        } else if (currentDossier?.isVente()) {
            this._venteUuids = [currentDossier.uuid];
        } else {
            throw new Error('"currentDossier.typeDossier" inconnu : ' + currentDossier?.dossierType);
        }

        this._dropdownService.clicked$.pipe(
            switchMap(dropdownClicked => {
                const bonvisite = dropdownClicked.value as Bonvisite;

                if (dropdownClicked.action === BonvisiteDropdownComponent.actions.ARCHIVE) {
                    return this._bonvisiteService.archive$(bonvisite).pipe(switchMap(isArchived => {
                        if (isArchived) {
                            return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/bons-visite/portefeuille');
                        }

                        return of(undefined);
                    }));
                } else if (dropdownClicked.action === BonvisiteDropdownComponent.actions.EDIT) {
                    return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/bons-visite/' + bonvisite.uuid + '/edit');
                } else if (dropdownClicked.action === BonvisiteDropdownComponent.actions.PRINT) {
                    return this._mediaResumeService.readFromBonvisite$(bonvisite).pipe(map(_ => undefined));
                } else if (dropdownClicked.action === BonvisiteDropdownComponent.actions.RESUME) {
                    return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/bons-visite/' + bonvisite.uuid + '/resume');
                } else if (dropdownClicked.action === BonvisiteDropdownComponent.actions.SIGN) {
                    return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/bons-visite/' + bonvisite.uuid + '/signature');
                } else if (dropdownClicked.action === BonvisiteDropdownComponent.actions.WRITE) {
                    return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/bons-visite/' + bonvisite.uuid + '/redaction');
                }

                return of(undefined);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    onListClick([htmlButtonElement, bonvisite]: [HTMLButtonElement, Bonvisite]): void {
        const options: IBonvisiteDropdownDataOptions = {
            enabledActions: {
                archive: !bonvisite.isArchive(),
                edit: bonvisite.isEdit(),
                print: bonvisite.isWrite() || bonvisite.isSign() || bonvisite.isArchive(),
                resume: bonvisite.isArchive(),
                sign: bonvisite.isSign(),
                write: bonvisite.isWrite(),
            },
        };

        this._dropdownService.open(htmlButtonElement, {
            component: BonvisiteDropdownComponent,
            data: {bonvisite, options},
        });
    }
}
