import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import CNotestimBiens from '@models/notestim/biens/collection/notestim-biens.collection.model';
import {CNotestimBiensFactory} from '@models/notestim/biens/collection/notestim-biens.collection.factory';
import {ModalService} from '@shared/modal/modal.service';
import NotestimBien from '@models/notestim/biens/bien/notestim-bien.model';
import {ToasterService} from '@shared/toaster/toaster.service';

@Component({
    selector: 'dc-notestim-biens-select',
    standalone: false,
    templateUrl: 'notestim-biens-select.component.html',
})
export class DCNotestimBiensSelectComponent implements IDynamicComponent, OnDestroy, OnInit {
    private _cNotestimBiensFactory = inject(CNotestimBiensFactory);
    private _modalService = inject(ModalService);
    private _toasterService = inject(ToasterService);
    private _cNotestimBiensSource = new ReplaySubject<CNotestimBiens>(1);
    private _cNotestimBiens$ = this._cNotestimBiensSource.asObservable();
    private _displaySearchLoader = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _searchText!: string;
    private _searchSource = new ReplaySubject<string>(1);
    private _search$ = this._searchSource.asObservable();

    get cNotestimBiens$(): Observable<CNotestimBiens> {
        return this._cNotestimBiens$;
    }

    set data(dynamicComponentData: IDynamicComponentData) {
    }

    get displaySearchLoader(): boolean {
        return this._displaySearchLoader;
    }

    get searchText(): string {
        return this._searchText;
    }

    ngOnInit(): void {
        this._search$.pipe(
            tap(_ => this._displaySearchLoader = true),
            tap(_ => this._cNotestimBiensSource.next(undefined!)),
            filter(search => !!search),
            switchMap(search => this._cNotestimBiensFactory.get$({ref: search})),
            tap(cNotestimBiens => this._cNotestimBiensSource.next(cNotestimBiens)),
            takeUntil(this._onDestroy$)
        ).subscribe({
            next: _ => this._displaySearchLoader = false,
            error: _ => {
                this._modalService.setResponse();
                this._toasterService.error('Oups !', 'Une erreur est survenue lors de l\'import. Veuillez contacter le service client.');
            },
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    referenceToImport(notestimBien: NotestimBien): void {
        this._modalService.setResponse(notestimBien);
    }

    searchReferences(search: string): void {
        this._searchSource.next(search);
        this._searchText = search;
    }
}
