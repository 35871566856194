import {NgModule, Type} from '@angular/core';
import {
    NgSoqPopoverTemplateComponent, NgSoqPopoverTextComponent
} from '@shared/angularJS/up-ng/soqrate/soqrate.popover.components';

const components: Type<unknown>[] = [NgSoqPopoverTemplateComponent, NgSoqPopoverTextComponent];

@NgModule({exports: components, imports: components})
export class NoteoComponentsModule {
}
