import {inject, Injectable} from '@angular/core';
import {UserFactory} from '@models/users/user/user.factory';
import {IUserApi} from '@models/users/user/user.interfaces';
import User from '@models/users/user/user.model';

@Injectable({providedIn: 'root'})
export class UsersFactory {
    private _userFactory = inject(UserFactory);

    create(userApis: IUserApi[] = []): User[] {
        return (userApis || []).filter(userApi => userApi)
            .map(userApi => this._userFactory.create(userApi));
    }
}
