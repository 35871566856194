import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgDemandeurEditCriteria]'})
export class NgDemandeurEditCriteriaDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDemandeurEditCriteriaCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgDemandeurEditCriteriaDirective],
    selector: 'app-ng-demandeur-edit-criteria',
    template: '<div appNgDemandeurEditCriteria></div>',
})
export class NgDemandeurEditCriteriaComponent {
}
