import {Component, Inject, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import Procedure from '@models/procedures/procedure/procedure.model';
import {ProcedureFactory} from '@models/procedures/procedure/procedure.factory';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import {
    CProcedureSignatairesFactory
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.factory';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import {ModalService} from '@shared/modal/modal.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {
    ProcedureSignataireSignActionsMainComponent
} from '@features/procedures/procedure/signataires/signataire/sign/actions/procedure-signataire.sign-actions-main.component';
import {ToasterService} from '@shared/toaster/toaster.service';
import {IWindowHistoryState} from '@shared/routes/routes.interfaces';

@Component({selector: 'layout-procedure', standalone: false, templateUrl: 'layout-procedure.component.html'})
export class AppLayoutProcedureComponent implements OnDestroy, OnInit {
    readonly ACTION_MAIN = CallToActionService.MAIN;
    private _activatedRoute = inject(ActivatedRoute);
    private _callToActionService = inject(CallToActionService);
    private _cProcedureSignatairesFactory = inject(CProcedureSignatairesFactory);
    private _modalService = inject(ModalService);
    private _procedureFactory = inject(ProcedureFactory);
    private _procedureService = inject(ProcedureService);
    private _router = inject(Router);
    private _toasterService = inject(ToasterService);
    private _window: Window;
    private _cProcedureSignatairesSubject = new Subject<CProcedureSignataires>();
    private _cProcedureSignataires$ = this._cProcedureSignatairesSubject.asObservable();
    private readonly _onDestroy$ = new Subject<void>();
    private _procedureSubject = new ReplaySubject<Procedure>();
    private _procedure$ = this._procedureSubject.asObservable();
    private _routeBack!: string;

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    get cProcedureSignataires$(): Observable<CProcedureSignataires> {
        return this._cProcedureSignataires$;
    }

    get procedure$(): Observable<Procedure> {
        return this._procedure$;
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(
            tap(params => this._routeBack = (params.routeBack || (this._window.history.state as IWindowHistoryState).extraParams?.routeBack) as string),
            map(params => params.procedureUuid as string),
            switchMap(procedureUuid => this._procedureFactory.get$(procedureUuid)),
            switchMap(procedure => {
                if (!procedure.isPresentielEditable()) {
                    this.redirect();
                    this._toasterService.info('Procédure terminée', 'La procédure que vous essayez d\'afficher ne peut pas être signée en présentiel.');

                    return of(undefined!);
                }

                this._procedureSubject.next(procedure);

                return this._cProcedureSignatairesFactory.get$(procedure.uuid)
                    .pipe(tap(cProcedureSignataires => this._cProcedureSignatairesSubject.next(cProcedureSignataires)));
            }),
            takeUntil(this._onDestroy$),
        ).subscribe();
        this._callToActionService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(clicked => {
            switch (clicked.action) {
                case ProcedureSignataireSignActionsMainComponent.CANCEL:
                    this.cancelProcedure();
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    cancelProcedure(): void {
        this._callToActionService.actionExec$(this.procedure$.pipe(
            take(1),
            switchMap(procedure => this._procedureService.cancel$(procedure).pipe(
                tap(_ => this.hasToRedirect(procedure)),
            )),
        )).pipe(take(1)).subscribe();
    }

    hasToRedirect(procedure: Procedure): void {
        if (!procedure.isClosed()) {
            return;
        }

        let beforeRedirect$: Observable<void> = of(undefined);

        if (procedure.isFinished()) {
            let comments = 'Toutes les signatures ont été effectuées, la procédure est terminée.';

            if (this._routeBack) {
                comments += '<br>Vous allez être redirigé vers le document signé.';
            }

            beforeRedirect$ = this._modalService.openInformation$({
                comments,
                title: 'Procédure terminée',
                status: ModalService.statuts.SUCCESS
            });
        }

        beforeRedirect$.pipe(take(1)).subscribe(_ => this.redirect());
    }

    redirect(): void {
        this._router.navigateByUrl(this._routeBack || '/app/procedures');
    }
}
