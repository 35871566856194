import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgLocationGestion]'})
export class NgLocationGestionDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskLocationGestionCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgLocationGestionDirective],
    selector: 'app-ng-location-gestion',
    template: '<div appNgLocationGestion></div>',
})
export class NgLocationGestionComponent {
}
