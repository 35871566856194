import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import Procedure from '@models/procedures/procedure/procedure.model';
import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {
    OffreachatSignActionsMainComponent
} from '@features/offreachats/item/actions/sign/offreachat-sign.actions-main.component';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import {IProcedureSignatairesEditOptions} from '@features/procedures/procedures.interfaces';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import {ProcedureSignatairesService} from '@core/models/procedures/procedure/signataires/procedure-signataires.service';

@Component({
    selector: 'app-offreachat-signature-in-progress',
    standalone: false,
    templateUrl: 'offreachat.signature-in-progress.component.html',
})
export class AppOffreachatSignatureInProgressComponent implements OnDestroy, OnInit {
    readonly procedureClosed = output<void>();
    private _callToActionService = inject(CallToActionService);
    private _procedureService = inject(ProcedureService);
    private _procedureSignatairesService = inject(ProcedureSignatairesService);
    private _cProcedureSignataires$!: Observable<CProcedureSignataires>;
    private _currentDossier!: ADossier;
    private _documentPath$!: Observable<string>;
    private _offreachat!: Offreachat;
    private readonly _onDestroy$ = new Subject<void>();
    private _procedure$!: Observable<Procedure>;
    private _procedureAcquereur$!: Observable<Procedure>;
    private _procedureSignatairesEditOptions!: IProcedureSignatairesEditOptions;
    private _procedureVendeur$!: Observable<Procedure>;

    get cProcedureSignataires$(): Observable<CProcedureSignataires> {
        return this._cProcedureSignataires$;
    }

    get currentDossier(): ADossier {
        return this._currentDossier;
    }

    @Input({required: true})
    set currentDossier(value: ADossier) {
        this._currentDossier = value;
    }

    get documentPath$(): Observable<string> {
        return this._documentPath$;
    }

    get offreachat(): Offreachat {
        return this._offreachat;
    }

    @Input({required: true})
    set offreachat(value: Offreachat) {
        this._offreachat = value;
    }

    get procedure$(): Observable<Procedure> {
        return this._procedure$;
    }

    @Input({required: true})
    set procedureAcquereur$(value: Observable<Procedure>) {
        this._procedureAcquereur$ = value.pipe(filter(procedure => !!procedure));
    }

    get procedureSignatairesEditOptions(): IProcedureSignatairesEditOptions {
        return this._procedureSignatairesEditOptions;
    }

    @Input({required: true})
    set procedureVendeur$(value: Observable<Procedure>) {
        this._procedureVendeur$ = value.pipe(filter(procedure => !!procedure));
    }

    ngOnInit(): void {
        if (this.offreachat.isSignatureAcquereur()) {
            this._procedure$ = this._procedureAcquereur$;
        } else {
            this._procedure$ = this._procedureVendeur$;
        }

        this.procedure$.pipe(
            filter(procedure => !!procedure),
            tap(procedure => this._procedureSignatairesEditOptions = {
                enabledActions: {
                    copyLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                    remove: procedure.isNewOrDraft(),
                    sendLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                },
                enabledEmail: procedure.isDistanciel(),
                enabledPhone: procedure.isDistanciel(),
                enabledSignatairesAdding: procedure.isNewOrDraft(),
                enabledStatut: !procedure.isNewOrDraft(),
            }),
            tap(procedure => this._cProcedureSignataires$ = this._procedureService.getCProcedureSignataires$(procedure)),
            tap(procedure => this._documentPath$ = this._procedureService.getMedia$(procedure).pipe(map(media => media.linkDownload))),
            takeUntil(this._onDestroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    editSignataires(procedure: Procedure, signataires: ProcedureSignataire[]): void {
        this._procedureSignatairesService.update$(procedure, [], signataires).pipe(take(1)).subscribe();
    }

    onLaunch(): void {
        this._callToActionService.clicked(OffreachatSignActionsMainComponent.actions.SIGN);
    }

    signed(procedure: Procedure): void {
        if (procedure.isClosed()) {
            this.procedureClosed.emit();
        }
    }
}
