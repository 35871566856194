import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getDossiersWalletSearchCriteriaCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskDossierBiensWalletSearchCriteriaCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/controllers/wallet/search/criteria.html',
        });

        /**
         * Controller of criteria for search ventes
         *
         * @param Ng2RouterStateForNgService
         */
        Controller.$inject = ['Ng2RouterStateForNgService'];
        function Controller(this: any, ng2RouterStateForNgService: RouterStateForNgService) {
            const $ctrl = this;

            // @todo Utiliser DossierService.getTypeFromUrl()
            if (ng2RouterStateForNgService.current.name!.startsWith('/app/locations')) {
                $ctrl.typeDossier = DossierBienTypesConst.LOCATION;
            } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/ventes')) {
                $ctrl.typeDossier = DossierBienTypesConst.VENTE;
            } else {
                throw new Error('Impossible de déterminer le "typeDossier" pour ' + ng2RouterStateForNgService.current.name);
            }

            $ctrl.launch = launch;
            if (!angular.isObject(ng2RouterStateForNgService.params.search)) {
                ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/search');
            }

            $ctrl.search = angular.extend({type: $ctrl.typeDossier}, ng2RouterStateForNgService.params.search);

            /**
             * Launch request
             */
            function launch() {
                if (!angular.isFunction($ctrl.updateSearchLocalisations)) {
                    return;
                }

                $ctrl.updateSearchLocalisations($ctrl.search).then(() => {
                    ng2RouterStateForNgService.navigateByUrl('/app/' + $ctrl.typeDossier + 's/search', {search: $ctrl.search});
                });
            }
        }
    })(angularJS);
}
