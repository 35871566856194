import {Component, inject, Input} from '@angular/core';
import Site from '@models/sites/site/site.model';
import Etude from '@models/etudes/etude/etude.model';
import {take} from 'rxjs/operators';
import {EmailEditService} from '@models/emails/email/email.edit.service';
import {ISiteCardOptions} from '@features/sites/sites.interfaces';

@Component({selector: 'app-site-card', standalone: false, templateUrl: 'site.card.component.html'})
export class AppSiteCardComponent {
    static readonly initSiteCardOptions: ISiteCardOptions = {enabledActions: true};
    private _emailEditService = inject(EmailEditService);
    private _etude!: Etude;
    private _options: ISiteCardOptions = {...AppSiteCardComponent.initSiteCardOptions};
    private _site!: Site;

    get etude(): Etude {
        return this._etude;
    }

    @Input()
    set etude(value: Etude) {
        this._etude = value;
    }

    get options(): ISiteCardOptions {
        return this._options;
    }

    @Input()
    set options(value: ISiteCardOptions) {
        this._options = {...AppSiteCardComponent.initSiteCardOptions, ...value};
    }

    get site(): Site {
        return this._site;
    }

    @Input()
    set site(value: Site) {
        this._site = value;
    }

    sendMail(): void {
        this._emailEditService.fromEtudeSite$(this.etude, this.site).pipe(take(1)).subscribe();
    }
}
