import {inject, NgModule} from '@angular/core';
import {
    NgDemandeurEditGeneralComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-edit-general.component';
import {
    NgDemandeurEditCriteriaComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-edit-criteria.component';
import {
    NgDemandeurResultsHistoricalComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results-historical.component';
import {
    NgDemandeurResultsDetailsComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results-details.component';
import {NgDemandeurLifetimeComponent} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-lifetime.component';
import {
    NgDemandeursWalletCurrentComponent
} from '@shared/angularJS/up-ng/demandeurs/demandeurs-wallet-current.component';
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {AppLayoutDemandeursComponent} from '@core/routing/layout/demandeurs/layout-demandeurs.component';
import {AppLayoutDemandeurComponent} from '@core/routing/layout/demandeurs/demandeur/layout-demandeur.component';
import {CommonModule} from '@angular/common';
import {EskimmoModule} from '@shared/angularJS/eskimmo.module';
import {SharedModule} from '@shared/shared.module';
import {
    AppLayoutDemandeurEditComponent
} from '@core/routing/layout/demandeurs/demandeur/edit/layout-demandeur-edit.component';
import {CanDeactivateGuard} from '@core/routing/guards/can-deactivate/can-deactivate-guard.service';
import {
    AppLayoutDemandeurResultatsComponent
} from '@core/routing/layout/demandeurs/demandeur/resultats/layout-demandeur-resultats.component';
import {NgDemandeurResultsComponent} from '@shared/angularJS/up-ng/demandeurs/demandeurs-item-results.component';
import {
    NgEskimmoDossierSuiviReportComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-report.component';
import {
    NgEskimmoDossierSuiviEmailComponent
} from '@shared/angularJS/up-ng/eskimmo/eskimmo-dossier-suivi-email.component';
import {AppLayoutDossierSuiviComponent} from '@core/routing/layout/suivi/layout-dossier-suivi.component';
import {BonvisitesActionsMainComponent} from '@features/bonvisites/list/actions/bonvisites.actions-main.component';
import {OffreachatsActionsMainComponent} from '@features/offreachats/list/actions/offreachats.actions-main.component';
import {
    AppLayoutDossierFilesComponent
} from '@core/routing/layout/dossiers/dossier/files/layout-dossier-files.component';
import {
    DossierFilesActionsMainComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-main.component';
import {
    DossierFilesActionsSelectionComponent
} from '@features/dossiers/dossier/files/actions/dossier-files.actions-selection.component';
import {AppLayoutBonvisitesComponent} from '@core/routing/layout/bonvisites/layout-bonvisites.component';
import {AppLayoutOffreachatsComponent} from '@core/routing/layout/offreachats/layout-offreachats.component';
import {AppLayoutBonvisiteComponent} from '@core/routing/layout/bonvisites/bonvisite/layout-bonvisite.component';
import {AppLayoutOffreachatComponent} from '@core/routing/layout/offreachats/offreachat/layout-offreachat.component';
import {
    AppLayoutBonvisitesWalletComponent
} from '@core/routing/layout/bonvisites/wallet/layout-bonvisites-wallet.component';
import {
    AppLayoutOffreachatsWalletComponent
} from '@core/routing/layout/offreachats/wallet/layout-offreachats-wallet.component';
import {
    AppLayoutBonvisiteEditComponent
} from '@core/routing/layout/bonvisites/bonvisite/edit/layout.bonvisite-edit.component';
import {
    AppLayoutBonvisiteWriteComponent
} from '@core/routing/layout/bonvisites/bonvisite/write/layout.bonvisite-write.component';
import {
    AppLayoutBonvisiteResumeComponent
} from '@core/routing/layout/bonvisites/bonvisite/resume/layout.bonvisite-resume.component';
import {
    AppLayoutBonvisiteSignComponent
} from '@core/routing/layout/bonvisites/bonvisite/sign/layout.bonvisite-sign.component';
import {
    AppLayoutOffreachatEditComponent
} from '@core/routing/layout/offreachats/offreachat/edit/layout.offreachat-edit.component';
import {
    AppLayoutOffreachatWriteComponent
} from '@core/routing/layout/offreachats/offreachat/write/layout.offreachat-write.component';
import {
    AppLayoutOffreachatResumeComponent
} from '@core/routing/layout/offreachats/offreachat/resume/layout.offreachat-resume.component';
import {
    AppLayoutOffreachatSignComponent
} from '@core/routing/layout/offreachats/offreachat/sign/layout.offreachat-sign.component';
import {
    AppLayoutDemandeursPublicitesComponent
} from '@core/routing/layout/demandeurs/publicites/layout-demandeurs.publicites.component';
import {FeaturesModule} from '@features/features.module';
import {HasRolesGuard} from '@core/routing/guards/has-roles/has-roles-guard.service';
import User from '@models/users/user/user.model';
import {GuardsService} from '@core/routing/guards/guards.service';
import {CanDeactivateComponent} from '@core/routing/guards/guards.interfaces';
import {ActualitesService} from '@models/actualites/actualites.service';
import {
    AppLayoutOffreachatSignatureComponent
} from '@core/routing/layout/offreachats/offreachat/signature/layout.offreachat-signature.component';
import {DossierAccessGuardService} from '@features/dossiers/dossier/new-guard/dossier.access-guard.service';

const routes: Routes = [
    {component: AppLayoutDemandeursPublicitesComponent, path: 'informations'},
    {
        component: AppLayoutDemandeursComponent,
        canActivate: [
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => inject(HasRolesGuard).canActivate(activatedRouteSnapshot),
        ],
        data: {[GuardsService.REDIRECTION_ROUTE_DATA]: '/app/demandeurs/informations', roles: User.cRoles.DEMANDEUR},
        path: '',
        children: [
            {
                component: NgDemandeursWalletCurrentComponent,
                path: 'portefeuille',
                resolve: {news: () => inject(ActualitesService).show$()},
            },
            {
                component: AppLayoutDemandeurComponent,
                canActivate: [
                    (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot) =>
                        inject(DossierAccessGuardService).canActivate(activatedRouteSnapshot, routerStateSnapshot),
                ],
                path: ':demandeurId',
                children: [
                    {
                        component: AppLayoutBonvisitesComponent,
                        path: 'bons-visite',
                        children: [
                            {
                                component: AppLayoutBonvisitesWalletComponent,
                                data: {actions: {main: {component: BonvisitesActionsMainComponent}}},
                                path: 'portefeuille',
                            },
                            {
                                component: AppLayoutBonvisiteComponent,
                                path: ':bonvisiteUuid',
                                children: [
                                    {component: AppLayoutBonvisiteEditComponent, path: 'edit'},
                                    {component: AppLayoutBonvisiteWriteComponent, path: 'redaction'},
                                    {component: AppLayoutBonvisiteResumeComponent, path: 'resume'},
                                    {component: AppLayoutBonvisiteSignComponent, path: 'signature'},
                                ],
                            },
                            // Wildcard pour /app/demandeurs/:demandeurId/bons-visite/*
                            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
                        ],
                    },
                    {
                        component: AppLayoutDossierFilesComponent,
                        data: {
                            actions: {
                                main: {component: DossierFilesActionsMainComponent},
                                selection: {component: DossierFilesActionsSelectionComponent},
                            },
                        },
                        path: 'documents',
                    },
                    {
                        canDeactivate: [
                            (component: CanDeactivateComponent) => inject(CanDeactivateGuard).canDeactivate(component),
                        ],
                        component: AppLayoutDemandeurEditComponent,
                        path: 'edit',
                        children: [
                            {component: NgDemandeurEditGeneralComponent, path: 'general'},
                            {
                                path: 'criteres',
                                children: [
                                    {component: NgDemandeurEditCriteriaComponent, path: 'general'},
                                    {component: NgDemandeurEditCriteriaComponent, path: 'secondaire'},
                                    // Wildcard pour /app/demandeurs/:demandeurId/edit/criteres/*
                                    {path: '**', pathMatch: 'full', redirectTo: 'general'},
                                ],
                            },
                            // Wildcard pour /app/demandeurs/:demandeurId/edit/*
                            {path: '**', pathMatch: 'full', redirectTo: 'general'},
                        ],
                    },
                    {component: NgDemandeurLifetimeComponent, path: 'lifetime'},
                    {
                        component: AppLayoutOffreachatsComponent,
                        path: 'offres-achat',
                        children: [
                            {
                                component: AppLayoutOffreachatsWalletComponent,
                                data: {actions: {main: {component: OffreachatsActionsMainComponent}}},
                                path: 'portefeuille',
                            },
                            {
                                component: AppLayoutOffreachatComponent,
                                path: ':offreachatUuid',
                                children: [
                                    {component: AppLayoutOffreachatEditComponent, path: 'edit'},
                                    {component: AppLayoutOffreachatWriteComponent, path: 'redaction'},
                                    {component: AppLayoutOffreachatResumeComponent, path: 'resume'},
                                    {component: AppLayoutOffreachatSignComponent, path: 'sign'},
                                    {component: AppLayoutOffreachatSignatureComponent, path: 'signature'},
                                ],
                            },
                            // Wildcard pour /app/demandeurs/:demandeurId/offres-achat/*
                            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
                        ],
                    },
                    {
                        component: AppLayoutDemandeurResultatsComponent,
                        path: 'resultats',
                        children: [
                            {component: NgDemandeurResultsHistoricalComponent, path: 'historique'},
                            {component: NgDemandeurResultsDetailsComponent, path: ':resultId'},
                            {component: NgDemandeurResultsComponent, path: ''},
                            // Wildcard pour /app/demandeurs/:demandeurId/resultats/*
                            {path: '**', pathMatch: 'full', redirectTo: ''},
                        ],
                    },
                    {
                        component: AppLayoutDossierSuiviComponent,
                        path: 'suivi',
                        children: [
                            {component: NgEskimmoDossierSuiviEmailComponent, path: 'emails/:emailId'},
                            {component: NgEskimmoDossierSuiviReportComponent, path: 'reports/:reportId'},
                            // Wildcard pour /app/demandeurs/:demandeurId/suivi/*
                            {path: '**', pathMatch: 'full', redirectTo: ''},
                        ],
                    },
                    // Wildcard pour /app/demandeurs/:demandeurId/*
                    {path: '**', pathMatch: 'full', redirectTo: 'edit/general'},
                ],
            },
            // Wildcard pour /app/demandeurs/*
            {path: '**', pathMatch: 'full', redirectTo: 'portefeuille'},
        ],
    },
];

@NgModule({
    declarations: [
        AppLayoutDemandeurComponent,
        AppLayoutDemandeurEditComponent,
        AppLayoutDemandeurResultatsComponent,
        AppLayoutDemandeursComponent,
        AppLayoutDemandeursPublicitesComponent,
        AppLayoutDossierSuiviComponent,
    ],
    imports: [CommonModule, EskimmoModule, FeaturesModule, RouterModule.forChild(routes), SharedModule],
})
export class DemandeursRoutingModule {
}
