import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {SiteFactory} from '@models/sites/site/site.factory';
import {ICSitesApi, ICSitesQueryParameters} from '@models/sites/collection/sites.collection.interfaces';
import CSites from '@models/sites/collection/sites.collection.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SitesApiService} from '@models/sites/sites.api.service';
import {ConditionConst} from '@shared/constants';
import {IFilterQueryParametersApi} from '@models/filters/filter/filter.interfaces';

@Injectable({providedIn: 'root'})
export class CSitesFactory {
    private _siteFactory = inject(SiteFactory);
    private _sitesApiService = inject(SitesApiService);

    create(cSitesApi: ICSitesApi): CSites {
        const cSites = new CSites();

        cSites.links = new Links(cSitesApi._links);
        cSites.page = cSitesApi.page;
        cSites.pages = cSitesApi.pages;
        cSites.perPage = cSitesApi.limit;
        cSites.total = cSitesApi.total;
        if (cSitesApi._embedded) {
            cSites.results = (cSitesApi._embedded.items || []).filter(siteApi => siteApi)
                .map(siteApi => this._siteFactory.create(siteApi));
        }

        return cSites;
    }

    createQueryParameters(filterQueryParametersApi: IFilterQueryParametersApi): ICSitesQueryParameters {
        const queryParameters = this.createVirginQueryParameters();

        queryParameters.coordonnees = filterQueryParametersApi.coordonnees ?? queryParameters.coordonnees;
        // Pas fait pour les autres attributs de ICSitesQueryParameters car ce n'est utile que dans Noty

        return queryParameters;
    }

    createVirginQueryParameters(): ICSitesQueryParameters {
        return {
            commune: {condition: ConditionConst.IN, values: []},
            coordonnees: {ne: {lat: undefined!, lon: undefined!}, sw: {lat: undefined!, lon: undefined!}},
            tris: {distance: undefined!}
        };
    }

    get$(queryParameters?: ICSitesQueryParameters): Observable<CSites> {
        return this._sitesApiService.getCollection$(queryParameters).pipe(map(cSitesApi => this.create(cSitesApi)));
    }

    getByLink$(link: string): Observable<CSites> {
        return this._sitesApiService.getCollectionByLink$(link).pipe(map(cSitesApi => this.create(cSitesApi)));
    }

    getNext$(cSites: CSites): Observable<CSites> {
        return this._sitesApiService.getCollectionByLink$(cSites.links.next).pipe(map(cSitesApi => this.create(cSitesApi)));
    }
}
