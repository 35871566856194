import {Component, ElementRef, inject, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, switchMap} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {
    EtudeSettingsDocumentsFooterService
} from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.service';
import EtudeSettingsDocumentsFooter
    from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.model';
import CTemplateFooters from '@models/templates/footers/collection/template-footers.collection.model';
import {CTemplateFootersService} from '@models/templates/footers/collection/template-footers.collection.service';
import {IFormSelectImageInput} from '@shared/form/form.interfaces';
import {ModalConfirmationComponent} from '@shared/modal/confirmation/modal.confirmation.component';
import {ModalService} from '@shared/modal/modal.service';
import TemplateFooter from '@models/templates/footers/footer/template-footer.model';
import {TemplateFooterService} from '@models/templates/footers/footer/template-footer.service';
import {ConfigurationActionsMainComponent} from '@features/configuration/actions/configuration-actions-main.component';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {TemplateFooterFactory} from '@models/templates/footers/footer/template-footer.factory';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import Template from '@models/templates/template/template.model';
import {IIframeOptions} from '@shared/iframe/iframe.interfaces';
import {EditorOptions} from 'tinymce';
import {
    EtudeSettingsDocumentsFooterApiService
} from '@models/etudes/etude/settings/documents/footer/etude-settings-documents-footer.api.service';
import {AppNoteoIconTextNotyComponent} from '@shared/noteo/icon-text/noty/noteo-icon-text.noty.component';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'layout-configuration-documents-footer',
    standalone: false,
    styleUrls: ['layout-configuration-documents-footer.component.scss'],
    templateUrl: 'layout-configuration-documents-footer.component.html',
})
export class AppLayoutConfigurationDocumentsFooterComponent implements OnDestroy, OnInit {
    // @todo Faire un tour pour factoriser les messages
    static readonly messages = {
        FOOTER_CODE_VALIDATION: {
            buttonConfirmationLabel: 'Personnaliser',
            comments: 'En personnalisant le document, vous ne pourrez plus bénéficier des évolutions proposées par ' +
                AppNoteoIconTextNotyComponent.label + '.',
            question: 'Voulez-vous vraiment personnaliser votre document ?',
            title: 'Personnalisation d\'un document',
        },
    };
    private _callToActionService = inject(CallToActionService);
    private _cTemplateFootersService = inject(CTemplateFootersService);
    private _etudeSettingsDocumentsFooterService = inject(EtudeSettingsDocumentsFooterService);
    private _modalService = inject(ModalService);
    private _renderer2 = inject(Renderer2);
    private _templateFooterFactory = inject(TemplateFooterFactory);
    private _templateFooterService = inject(TemplateFooterService);
    private _tinymceService = inject(TinymceService);
    private _cTemplateFootersSubject = new ReplaySubject<CTemplateFooters>();
    private _cTemplateFooters$ = this._cTemplateFootersSubject.asObservable();
    private _editorOptions!: EditorOptions;
    private _footer!: EtudeSettingsDocumentsFooter;
    private _footer422Error = false;
    private _footerLoading = true;
    private _footerPreview$!: Observable<string>;
    private _footerPreviewOptions: IIframeOptions = {sandbox: 'allow-same-origin'};
    private _footerSubject = new ReplaySubject<EtudeSettingsDocumentsFooter>();
    private _heightInputSubject = new Subject<number>();
    private _heightTemplateSubject = new Subject<[number, string]>();
    private readonly _onDestroy$ = new Subject<void>();
    private _templateInputSubject = new Subject<string>();

    get cTemplateFooters$(): Observable<CTemplateFooters> {
        return this._cTemplateFooters$;
    }

    get editorOptions(): EditorOptions {
        return this._editorOptions;
    }

    get footer(): EtudeSettingsDocumentsFooter {
        return this._footer;
    }

    get footer422Error(): boolean {
        return this._footer422Error;
    }

    get footerLoading(): boolean {
        return this._footerLoading;
    }

    get footerPreview$(): Observable<string> {
        return this._footerPreview$;
    }

    get footerPreviewOptions(): IIframeOptions {
        return this._footerPreviewOptions;
    }

    @ViewChild('preview')
    set preview(previewElement: ElementRef<HTMLDivElement>) {
        if (!previewElement) {
            return;
        }

        const heightPx = +this.footerPreviewOptions.height! + TinymceService.DOCUMENT_MARGIN * Template.convertMmToPx * 2 + 2;

        this._renderer2.setStyle(previewElement.nativeElement.firstChild, 'margin', TinymceService.DOCUMENT_MARGIN.toString() + 'mm');
        this._renderer2.setStyle(previewElement.nativeElement, 'width', '210mm');
        this._renderer2.setStyle(previewElement.nativeElement.parentElement, 'height', heightPx.toString() + 'px');
    }

    ngOnInit(): void {
        this._editorOptions = this._tinymceService.getOptions('complete', {
            content_css: [EtudeSettingsDocumentsFooterApiService.CSS_FILE],
            plugins: [
                TinymceService.plugins.AUTO_RESIZE,
                TinymceService.plugins.BALISES,
            ],
            pluginsOptions: {
                [TinymceService.pluginOptions.balisesModels.KEY]: [
                    TinymceService.pluginOptions.balisesModels.value.etude.CODE,
                    TinymceService.pluginOptions.balisesModels.value.page.CODE,
                    TinymceService.pluginOptions.balisesModels.value.sitePrincipal.CODE,
                    TinymceService.pluginOptions.balisesModels.value.currentUser.CODE,
                ],
            },
        } as unknown as EditorOptions);

        // Saisie des templates-footers
        this._cTemplateFootersService.getWithCustom$().pipe(take(1))
            .subscribe(cTemplateFooters => this._cTemplateFootersSubject.next(cTemplateFooters));

        // Au clic sur le CTA
        this._callToActionService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(clicked => {
            switch (clicked.action) {
                case ConfigurationActionsMainComponent.actions.SAVE:
                    this.saveFooter();
                    break;
            }
        });

        // Listen Height and Template to Preview
        this._footerPreview$ = combineLatest([
            this._heightInputSubject.asObservable(),
            this._templateInputSubject.asObservable(),
        ]).pipe(
            debounceTime(500),
            distinctUntilChanged(),
            tap(([footerHeightMm,]) => this._footerPreviewOptions.height = (footerHeightMm * Template.convertMmToPx).toString()),
            switchMap(([footerHeightMm, template]) => {
                const footerPreviewSubject = new BehaviorSubject<string>(undefined!);

                this._footer422Error = false;
                this._templateFooterFactory.getPreviewLink$(footerHeightMm, template, {headers: {handledStatusErrors: [422]}}).pipe(take(1))
                    .subscribe({
                        next: footerPreview => footerPreviewSubject.next(footerPreview),
                        error: (error: HttpErrorResponse) => {
                            if (error.status === 422) {
                                this._footer422Error = true;
                            }
                        },
                    });

                return footerPreviewSubject.asObservable();
            }),
        );

        // Manage Height
        this._heightTemplateSubject.asObservable().pipe(
            map(([height,]) => height),
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this._onDestroy$),
        ).subscribe(height => this._heightInputSubject.next(height));

        // Manage Template
        this._heightTemplateSubject.asObservable().pipe(
            map(([, template]) => template),
            debounceTime(500),
            distinctUntilChanged(),
            takeUntil(this._onDestroy$),
        ).subscribe(template => this._templateInputSubject.next(template));

        // Get Height and Template from footer
        this._footerSubject.asObservable().pipe(
            switchMap(footer => this._templateFooterService.getHeightTemplate$(footer, this.cTemplateFooters$)),
            takeUntil(this._onDestroy$),
        ).subscribe(heightTemplate => this._heightTemplateSubject.next(heightTemplate));

        // Init Footer
        this._etudeSettingsDocumentsFooterService.initCurrent();
        this._etudeSettingsDocumentsFooterService.current$.pipe(
            filter(etudeSettingsDocumentsFooter => !!etudeSettingsDocumentsFooter),
            tap(etudeSettingsDocumentsFooter => this._footer = etudeSettingsDocumentsFooter),
            tap(etudeSettingsDocumentsFooter => this._footerSubject.next(etudeSettingsDocumentsFooter)),
            take(1),
        ).subscribe(_ => this._footerLoading = false);
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    footerCodeValidation$(formSelectImageInput: IFormSelectImageInput): Observable<boolean> {
        const selectTemplateFooterCode = formSelectImageInput?.code;
        let isSelectionAccepted: Observable<boolean> = of(true);

        if (this._footer.code !== selectTemplateFooterCode && selectTemplateFooterCode === TemplateFooter.codes.CUSTOM) {
            isSelectionAccepted = this._modalService.open$(ModalConfirmationComponent, {
                buttonConfirmationLabel: AppLayoutConfigurationDocumentsFooterComponent.messages.FOOTER_CODE_VALIDATION.buttonConfirmationLabel,
                checkComments: false,
                comments: AppLayoutConfigurationDocumentsFooterComponent.messages.FOOTER_CODE_VALIDATION.comments,
                question: AppLayoutConfigurationDocumentsFooterComponent.messages.FOOTER_CODE_VALIDATION.question,
                title: AppLayoutConfigurationDocumentsFooterComponent.messages.FOOTER_CODE_VALIDATION.title,
                status: ModalService.statuts.WARNING,
            });
        }

        return isSelectionAccepted;
    }

    saveFooter(): void {
        this._callToActionService.actionExec$(this._etudeSettingsDocumentsFooterService.save$(this._footer)).pipe(
            take(1),
        ).subscribe(_ => this.updateFooter());
    }

    selectTemplateFooter(selectTemplateFooterCode: string, fromCurrentTemplateFooter = false): void {
        if (this._footer.code === selectTemplateFooterCode) {
            return;
        }

        let currentTemplateFooter: TemplateFooter;

        this.cTemplateFooters$.pipe(
            tap(cTemplateFooters => currentTemplateFooter = cTemplateFooters.findByCode(this._footer.code)),
            map(cTemplateFooters => cTemplateFooters.findByCode(selectTemplateFooterCode)),
            tap(selectedTemplateFooter => {
                this._footer.code = selectedTemplateFooter.code;
                if (fromCurrentTemplateFooter) {
                    this._footer.customHeight = currentTemplateFooter.height;
                    this._footer.customTemplate = currentTemplateFooter.template;
                } else {
                    this._footer.customHeight = selectedTemplateFooter.height;
                    this._footer.customTemplate = selectedTemplateFooter.template;
                }
            }),
            take(1),
        ).subscribe(_ => this.updateFooter());
    }

    selectTemplateFooterCustom(): void {
        const formSelectImageInput = {code: TemplateFooter.codes.CUSTOM} as IFormSelectImageInput;

        this.footerCodeValidation$(formSelectImageInput).pipe(take(1)).subscribe(isAccepted => {
            if (!isAccepted) {
                return;
            }

            this.selectTemplateFooter(TemplateFooter.codes.CUSTOM, true);
        });
    }

    updateFooter(): void {
        this._footer.customHeight = +this._footer.customHeight || 0;
        this._footerSubject.next(this._footer);
    }
}
