import {Component, inject, Input, OnDestroy, OnInit, output} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {CVentesService} from '@models/ventes/collection/ventes.collection.service';
import {IVentesListOptions} from '@features/ventes/ventes.interfaces';
import {DisplayMode, SortDefinition} from '@shared/constants';
import Vente from '@models/ventes/vente/vente.model';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {filter, map, switchMap, take, takeUntil} from 'rxjs/operators';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {
    VenteDropdownComponent
} from '@features/ventes/vente/dropdown/vente.dropdown.component';

@Component({selector: 'app-ventes-list', standalone: false, templateUrl: 'ventes-list.component.html'})
export class AppVentesListComponent implements OnDestroy, OnInit {
    static readonly initVentesListOptions: IVentesListOptions = {
        enabledColumnAdresse: false,
        enabledColumnCommune: false,
        enabledColumnLocalisation: true,
        mode: DisplayMode.Table,
        nameSelection: 'ventes-list-selection',
        tris: {},
    };
    readonly changedTri = output<[string, SortDefinition]>();
    readonly clicked = output<IDropdownClicked>();
    readonly ventesSelected = output<Vente[]>();
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _cVentesService = inject(CVentesService);
    private _dictionaryItemService = inject(DictionaryItemService);
    private _dropdownService = inject(DropdownService);
    private _cVentes$!: Observable<CVentes>;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IVentesListOptions = {...AppVentesListComponent.initVentesListOptions};
    private _pendingGetMore = false;
    private _superficieLabel!: string;

    get CVENTES_TRIS(): Record<string, string> {
        return CVentes.tris;
    }

    get cVentes$(): Observable<CVentes> {
        return this._cVentes$;
    }

    @Input({required: true})
    set cVentes$(value$: Observable<CVentes>) {
        this._cVentes$ = value$;
    }

    get options(): IVentesListOptions {
        return this._options;
    }

    @Input()
    set options(value: IVentesListOptions) {
        this._options = {...AppVentesListComponent.initVentesListOptions, ...value};
        this._options.tris = value.tris ?? AppVentesListComponent.initVentesListOptions.tris;
    }

    get pendingGetMore(): boolean {
        return this._pendingGetMore;
    }

    get superficieLabel(): string {
        return this._superficieLabel;
    }

    ngOnInit(): void {
        this._superficieLabel = this._dictionaryItemService.getOneSuperficieLabel(this._options.mainNature!);
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked => this.clicked.emit(dropdownClicked));
        this._collectionSelectionService.clear(this._options.nameSelection!);
        this._collectionSelectionService.isInit$(this._options.nameSelection!).pipe(
            filter(isInit => isInit),
            switchMap(_ => this._collectionSelectionService.getListSelected$(this._options.nameSelection!)),
            map(listSelected => listSelected as Set<Vente>),
            map(listSelected => Array.from(listSelected)),
            takeUntil(this._onDestroy$),
        ).subscribe(listSelected => this.ventesSelected.emit(listSelected));
    }

    ngOnDestroy(): void {
        this._collectionSelectionService.delete(this._options.nameSelection!);
        this._onDestroy$.next();
    }

    clickOnVente(htmlButtonElement: HTMLButtonElement, vente: Vente): void {
        this._dropdownService.open(htmlButtonElement, {
            component: VenteDropdownComponent,
            data: {options: {enabledActions: {link: true, see: true}}, vente},
        });
    }

    getMore(cVentes: CVentes): void {
        this._pendingGetMore = true;
        this._cVentesService.addNext$(cVentes).pipe(take(1)).subscribe({
            complete: () => this._pendingGetMore = false,
        });
    }

    isEnabled(vente: Vente): boolean {
        if (!this.options.filterEnabled) {
            return true;
        }

        return this.options.filterEnabled(vente);
    }
}
