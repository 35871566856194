import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    CLocationPasserellesFactory
} from '@models/locations/location/passerelles/collection/location-passerelles.collection.factory';
import {EtudePasserelleFactory} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.factory';
import {ModalService} from '@shared/modal/modal.service';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {LocationFactory} from '@models/locations/location/location.factory';
import {LocationPasserelleFactory} from '@models/locations/location/passerelles/passerelle/location-passerelle.factory';
import {combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import CLocationPasserelles
    from '@models/locations/location/passerelles/collection/location-passerelles.collection.model';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {map, startWith, take, takeUntil, tap} from 'rxjs/operators';
import {IDropdownClicked} from '@shared/dropdown/dropdown.interfaces';
import {IDCLocationPasserelleData} from '@features/locations/locations.interfaces';
import {
    LocationPasserelleDropdownComponent
} from '@features/locations/location/passerelles/passerelle/dropdown/location-passerelle.dropdown.component';
import {DCLocationSlideOverComponent} from '@features/locations/location/slide-over/location.slide-over.component';
import LocationPasserelle from '@models/locations/location/passerelles/passerelle/location-passerelle.model';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {
    LocationPasserellesActionsSelectionComponent
} from '@features/locations/location/passerelles/actions/location-passerelles.actions-selection.component';
import {
    AppLocationPasserellesListComponent
} from '@features/locations/location/passerelles/list/location-passerelles-list.component';

@Component({
    selector: 'layout-diffusions-passerelle-locations',
    standalone: false,
    templateUrl: 'layout-diffusions-passerelle-locations.component.html',
})
export class AppLayoutDiffusionsPasserelleLocationsComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _callToActionService = inject(CallToActionService);
    private _cLocationPasserellesFactory = inject(CLocationPasserellesFactory);
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _etudePasserelleFactory = inject(EtudePasserelleFactory);
    private _locationFactory = inject(LocationFactory);
    private _locationPasserelleFactory = inject(LocationPasserelleFactory);
    private _modalService = inject(ModalService);
    private _slideOverService = inject(SlideOverService);
    private _cLocationPasserelles$!: Observable<CLocationPasserelles>;
    private _etudePasserelle!: EtudePasserelle;
    private _updateCLocationPasserellesSource = new Subject<void>();
    private readonly _onDestroy$ = new Subject<void>();

    get cLocationPasserelles$(): Observable<CLocationPasserelles> {
        return this._cLocationPasserelles$;
    }

    ngOnInit(): void {
        this._cLocationPasserelles$ = this._updateCLocationPasserellesSource.pipe(
            startWith(undefined!),
            switchMap(_ => this._activatedRoute.params),
            map(params => (params as { passerelleId: string }).passerelleId),
            switchMap(passerelleId => this._etudePasserelleFactory.get$(passerelleId)),
            tap(etudePasserelle => this._etudePasserelle = etudePasserelle),
            switchMap(etudePasserelle => this._cLocationPasserellesFactory.getFromPasserelle$(etudePasserelle.passerelle)),
        );

        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === LocationPasserellesActionsSelectionComponent.actions.REMOVE) {
                    const action$ = (locationPasserelles: Set<LocationPasserelle>) => this.removeSelected$(Array.from(locationPasserelles));

                    return this._callToActionService.actionExec$(
                        this._collectionSelectionService.operateListSelected$(AppLocationPasserellesListComponent.initLocationPasserellesListOptions.nameSelection!, action$)
                    );
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$)).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clicked(dropdownClicked: IDropdownClicked): void {
        const locationPasserelle = (dropdownClicked.value as IDCLocationPasserelleData).locationPasserelle!;

        if (dropdownClicked.action === LocationPasserelleDropdownComponent.actions.REMOVE) {
            this.remove(locationPasserelle);
        } else if (dropdownClicked.action === LocationPasserelleDropdownComponent.actions.SEE_LOCATION) {
            this._slideOverService.open$(DCLocationSlideOverComponent, {link: locationPasserelle.linkLocation}).pipe(take(1)).subscribe();
        }
    }

    remove(locationPasserelle: LocationPasserelle): void {
        this._locationFactory.getByLink$(locationPasserelle.linkLocation).pipe(
            switchMap(location => this._modalService.openConfirmation$({
                    buttonConfirmationLabel: 'Retirer',
                    question: 'Voulez-vous vraiment retirer le bien en location "' + location.reference + '" de ' + this._etudePasserelle.passerelle.name + ' ?',
                    title: 'Retirer de ' + this._etudePasserelle.passerelle.name,
                    status: ModalService.statuts.DANGER,
                }
            )),
            switchMap(isAccepted => {
                if (!isAccepted) {
                    return of(false);
                }

                return this._locationPasserelleFactory.retirer$(locationPasserelle).pipe(
                    tap(_ => this._updateCLocationPasserellesSource.next())
                );
            }),
            take(1),
        ).subscribe();
    }

    removeSelected$(locationPasserellesToremove: LocationPasserelle[] = []): Observable<boolean> {
        if (locationPasserellesToremove.length <= 0) {
            return of(false);
        }

        return this._modalService.openConfirmation$({
            buttonConfirmationLabel: 'Retirer',
            question: 'Voulez-vous vraiment retirer ' + (locationPasserellesToremove.length > 1 ? ('ces ' + locationPasserellesToremove.length.toString() + ' biens') : 'ce bien') + ' en location ?',
            title: 'Retirer de ' + this._etudePasserelle.passerelle.name,
            status: ModalService.statuts.DANGER,
        }).pipe(switchMap(isAccepted => {
            if (!isAccepted) {
                return of(false);
            }

            return combineLatest(locationPasserellesToremove.map(locationPasserelleToRemove => this._locationPasserelleFactory.retirer$(locationPasserelleToRemove))).pipe(
                take(1),
                tap(_ => this._updateCLocationPasserellesSource.next()),
                map(_ => true),
            );
        }));
    }
}
