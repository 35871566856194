import {NgModule} from '@angular/core';
import {AppActualitesListComponent} from '@features/actualites/list/actualites-list.component';
import {ActualiteDropdownComponent} from '@features/actualites/actualite/dropdown/actualite.dropdown.component';
import {DCActualiteViewComponent} from '@features/actualites/actualite/view/actualite-view.component';

@NgModule({
    exports: [AppActualitesListComponent],
    imports: [ActualiteDropdownComponent, AppActualitesListComponent, DCActualiteViewComponent],
})
export class ActualitesModule {
}
