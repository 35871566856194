import {Component, inject, Input, output, ViewChild} from '@angular/core';
import Commune from '@models/communes/commune/commune.model';
import {map, take, tap} from 'rxjs/operators';
import {NgModel} from '@angular/forms';
import CommuneVoie from '@models/communes/commune/voies/voie/commune-voie.model';
import {CCommuneVoiesService} from '@models/communes/commune/voies/collection/commune-voies.collection.service';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import {IFormSelectInputOptions} from '@shared/form/form.interfaces';

// @todo À passer dans le répertoire commune
@Component({
    exportAs: 'communeVoiesSelect',
    imports: [AppFormSelectInputComponent],
    selector: 'app-commune-voies-select',
    templateUrl: 'commune.voies-select.component.html',
})
export class AppCommuneVoiesSelectComponent {
    static readonly initCommuneVoiesSelectOptions: IFormSelectInputOptions = {
        bindLabel: 'nom',
        loadingText: 'Chargement des voies',
        name: 'communeVoiesSelect',
        notFoundText: 'Aucune voie trouvée',
        placeholder: 'Rechercher une voie par son nom',
    };
    readonly selected = output<CommuneVoie>();
    private _cCommuneVoiesService = inject(CCommuneVoiesService);
    private _formInput!: NgModel;
    private _loading = false;
    private _options: IFormSelectInputOptions = {...AppCommuneVoiesSelectComponent.initCommuneVoiesSelectOptions};
    private _commune!: Commune;
    private _communeVoie!: CommuneVoie;
    private _communeVoies: CommuneVoie[] = [];

    @Input()
    set commune(value: Commune) {
        this._commune = value;
    }

    get communeVoie(): CommuneVoie {
        return this._communeVoie;
    }

    @Input()
    set communeVoie(value: CommuneVoie) {
        this._communeVoie = value;
    }

    get communeVoies(): CommuneVoie[] {
        return this._communeVoies;
    }

    @ViewChild('communeVoiesSelectInput')
    set communeVoiesSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): IFormSelectInputOptions {
        return this._options;
    }

    @Input()
    set options(value: IFormSelectInputOptions) {
        this._options = {...AppCommuneVoiesSelectComponent.initCommuneVoiesSelectOptions, ...value};
    }

    onSelect(communeVoie: unknown): void {
        this.selected.emit(communeVoie as CommuneVoie);
    }

    search(search: string): void {
        this._communeVoies = [];
        this._loading = true;
        this._cCommuneVoiesService.search$(this._commune.uuid, search).pipe(
            map(cCommuneVoies => cCommuneVoies.results),
            tap(communeVoies => this._communeVoies = communeVoies),
            take(1),
        ).subscribe(_ => this._loading = false);
    }
}
