import {inject, Injectable} from '@angular/core';
import VenteContrat from '@models/ventes/vente/contrats/contrat/vente-contrat.model';
import {Observable, of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {CProceduresService} from '@models/procedures/collection/procedures.collection.service';
import {UserService} from '@models/users/user/user.service';

@Injectable({providedIn: 'root'})
export class VenteContratService {
    private _cProceduresService = inject(CProceduresService);
    private _userService = inject(UserService);

    isCancelable$(venteContrat: VenteContrat): Observable<boolean> {
        if (venteContrat.isMandat() && !venteContrat.isCanceled()) {
            return this._userService.last$.pipe(switchMap(currentUser => {
                if (currentUser.hasRoleSignatureElectronique()) {
                    return this._cProceduresService.getFirst$(venteContrat.linkContratProcedures).pipe(map(procedure => {
                        if (!procedure) {
                            return true;
                        }

                        return procedure.isCanceled() || procedure.isExpired() || procedure.isNewOrDraft();
                    }));
                }

                return of(false);
            }));
        }

        return of(false);
    }
}
