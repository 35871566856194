import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskEstimationEvaluation]'})
export class NgEstimationEvaluationDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskEstimationEvaluation', elementRef, injector);
    }
}

@Component({
    imports: [NgEstimationEvaluationDirective],
    selector: 'app-ng-estimation-evaluation',
    template: '<div appNgEskEstimationEvaluation></div>',
})
export class NgEstimationEvaluationComponent {
}
