import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {NgEskUtils} from '@legacy/app/eskimmo/eskimmo';
import {RouterStateForNgService} from '@shared/angularJS/down-ng2/router-state-for-ng.service';
import {NgBienDossier} from '@legacy/app/managers/ressources';
import {DossierBienType, DossierBienTypesConst} from '@models/dossiers/biens/dossier-biens.constants';

export default function getBienDetailsCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.component('eskBienDetailsCtrl', {
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/biens/controllers/item/details.html',
        });

        /**
         * Controller for Dossiers view
         *
         * @param Ng2RouterStateForNgService
         * @param EskUtils
         */
        Controller.$inject = ['Ng2RouterStateForNgService', 'EskUtils'];
        function Controller(this: any,
                            ng2RouterStateForNgService: RouterStateForNgService,
                            eskUtils: NgEskUtils) {
            const $ctrl = this;
            const dataFromParentRoute = eskUtils.getDataFromParentRoute(ng2RouterStateForNgService.current.name!);
            let typeDossierBien: DossierBienType;

            // @todo Utiliser DossierService.getTypeFromUrl()
            if (ng2RouterStateForNgService.current.name!.startsWith('/app/estimations/references/details/')) {
                $ctrl.currentDossierId = ng2RouterStateForNgService.params.estimationReferenceId;
                typeDossierBien = DossierBienTypesConst.VENTE;
            } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/estimations')) {
                $ctrl.currentDossierId = ng2RouterStateForNgService.params.estimationId;
                typeDossierBien = DossierBienTypesConst.ESTIMATION;
            } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/locations')) {
                $ctrl.currentDossierId = ng2RouterStateForNgService.params.locationId;
                typeDossierBien = DossierBienTypesConst.LOCATION;
            } else if (ng2RouterStateForNgService.current.name!.startsWith('/app/ventes')) {
                $ctrl.currentDossierId = ng2RouterStateForNgService.params.venteId;
                typeDossierBien = DossierBienTypesConst.VENTE;
            } else {
                throw new Error('Impossible de déterminer le "typeDossierBien" pour ' + ng2RouterStateForNgService.current.name);
            }

            $ctrl.replaceLocation = replaceLocation;
            $ctrl.loader = {updating: false};
            if (angular.isObject(dataFromParentRoute) && angular.isObject(dataFromParentRoute.dossiers)) {
                $ctrl.dossiers = dataFromParentRoute.dossiers;
            } else {
                $ctrl.dossiers = {
                    totalItems: 1,
                    collection: [{_esk: {typeDossier: typeDossierBien}, id: $ctrl.currentDossierId}]
                };
            }

            if (angular.isObject(dataFromParentRoute) && angular.isObject(dataFromParentRoute.routeBack)) {
                $ctrl.backToList = backToList;
            }

            /**
             * Replace location with current
             *
             * @param dossier
             */
            function replaceLocation(dossier: NgBienDossier) {
                const split = ng2RouterStateForNgService.current.name.split('/details/')

                split[1] = dossier.id.toString();
                ng2RouterStateForNgService.navigateByUrl(split.join('/details/'), {[typeDossierBien + 'Id']: dossier.id});
            }

            /**
             * Back to list
             */
            function backToList() {
                ng2RouterStateForNgService.navigateByUrl(dataFromParentRoute.routeBack.name, dataFromParentRoute.routeBack.params as any);
            }
        }
    })(angularJS);
}
