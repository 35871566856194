import {inject, Injectable} from '@angular/core';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';
import {ActivatedRouteSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BrowserOnlineGuardService {
    private _browserOnlineService = inject(BrowserOnlineService);

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
        const data = activatedRouteSnapshot.data as { useOffLine: boolean };

        if (data.useOffLine) {
            return of(true);
        }

        return this._browserOnlineService.checkAndPrevent$();
    }

    canActivateChild(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
        return this.canActivate(activatedRouteSnapshot);
    }
}
