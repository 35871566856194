<form (ngSubmit)="response(confirmationForm, true)" #confirmationForm="ngForm">
  <div class="sm:tw-flex sm:tw-items-start">
    <app-modal-status-icon [status]="status"/>

    <div class="tw-mt-3 tw-text-left sm:tw-mt-0 sm:tw-mx-4">
      <h3 class="tw-text-lg tw-text-center tw-leading-6 tw-font-medium tw-text-gray-900 sm:tw-text-left">
        {{ title }}
      </h3>

      <div class="tw-mt-2"><p class="tw-text-sm tw-text-gray-600" [innerHTML]="question"></p></div>

      @if (comments) {
        <div class="tw-mt-2">
          <div class="tw-flex tw-items-start">
            @if (checkComments) {
              <div class="tw-flex tw-items-center tw-h-5 tw-mr-3">
                <input class="tw-input-checkbox" id="checkComments" name="checkComments" type="checkbox"
                       [(ngModel)]="isCheckComments" required/>
              </div>
            }

            <div class="tw-text-sm">
              <label for="checkComments" class="tw-font-medium tw-text-gray-400 tw-cursor-pointer">
                {{ comments }}
              </label>
            </div>
          </div>

          @if (confirmationForm.submitted && !confirmationForm.controls.checkComments.valid) {
            <p class="tw-mt-2 tw-text-xs tw-text-red-600">
              Veuillez prendre connaissance des informations et cocher la case avant de pouvoir confirmer.
            </p>
          }
        </div>
      }
    </div>
  </div>

  <div class="sm:tw-flex sm:tw-flex-row-reverse tw-gap-4 tw-mt-5 sm:tw-mt-4">
    <button [ngClass]="{'tw-btn-primary-danger': status === ModalStatus.DANGER,
                        'tw-btn-primary-info': status === ModalStatus.INFO,
                        'tw-btn-primary-success': status === ModalStatus.SUCCESS,
                        'tw-btn-primary-warning': status === ModalStatus.WARNING}"
            type="submit">{{ buttonConfirmationLabel }}</button>

    <button type="button" class="tw-btn-secondary-default" (click)="response(confirmationForm, false)">
      {{ buttonCancelLabel }}
    </button>
  </div>
</form>
