import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {delay, Observable, ReplaySubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({selector: 'layout-dossier-suivi', standalone: false, templateUrl: 'layout-dossier-suivi.component.html'})
export class AppLayoutDossierSuiviComponent implements OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _destroyRef = inject(DestroyRef);
    private _router = inject(Router);
    private _isOnSuiviItemSource = new ReplaySubject<boolean>();
    private _isOnSuiviItem$ = this._isOnSuiviItemSource.asObservable();

    get isOnSuiviItem$(): Observable<boolean> {
        return this._isOnSuiviItem$;
    }

    ngOnInit(): void {
        this._activatedRoute.url.pipe(
            tap(_ => this._isOnSuiviItemSource.next(undefined!)),
            delay(1),
            map(_ => this._router.url),
            map(url => {
                const splitUrl = url.split('/');
                const lastSplitUrl = splitUrl.at(-1)!;

                return lastSplitUrl === (+lastSplitUrl).toString();
            }),
            takeUntilDestroyed(this._destroyRef),
        ).subscribe(isOnSuiviItem => this._isOnSuiviItemSource.next(isOnSuiviItem));
    }
}
