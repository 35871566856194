import {Component, Input} from '@angular/core';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';

@Component({selector: 'app-offreachat-statut', standalone: false, templateUrl: 'offreachat.statut.component.html'})
export class AppOffreachatStatutComponent {
    private _offreachat!: Offreachat;

    get offreachat(): Offreachat {
        return this._offreachat;
    }

    @Input()
    set offreachat(value: Offreachat) {
        this._offreachat = value;
    }
}
