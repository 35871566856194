import {Component, inject, Input} from '@angular/core';
import Email from '@models/emails/email/email.model';
import {Observable} from 'rxjs';
import CEmailDossiers from '@models/emails/email/dossiers/collection/email-dossiers.collection.model';
import {CEmailDossiersFactory} from '@models/emails/email/dossiers/collection/email-dossiers.collection.factory';
import {AsyncPipe, NgPlural, NgPluralCase, TitleCasePipe} from '@angular/common';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [AppLoaderComponent, AsyncPipe, NgPlural, NgPluralCase, TitleCasePipe],
    selector: 'app-email-popover',
    templateUrl: 'email.popover.component.html',
})
export class AppEmailPopoverComponent {
    private _cEmailDossiersFactory = inject(CEmailDossiersFactory);
    private _cEmailDossiers$!: Observable<CEmailDossiers>;

    get cEmailDossiers$(): Observable<CEmailDossiers> {
        return this._cEmailDossiers$;
    }

    @Input()
    set email(value: Email) {
        this._cEmailDossiers$ = this._cEmailDossiersFactory.get$(value);
    }
}
