import {Component, inject, Input} from '@angular/core';
import {ConsommationV1ColorsConst, ConsommationV2ColorsConst} from '@shared/diagnostic/diagnostic.constants';
import Bien from '@models/bien/bien.model';
import {
    BienPerformanceEnergetiqueService
} from '@models/bien/performance-energetique/bien-performance-energetique.service';
import {NgStyle, UpperCasePipe} from '@angular/common';

@Component({
    imports: [NgStyle, UpperCasePipe],
    selector: 'app-diagnostic-letter-consommation',
    templateUrl: 'diagnostic-letter-consommation.component.html',
})
export class AppDiagnosticLetterConsommationComponent {
    private _bienPerformanceEnergetiqueService = inject(BienPerformanceEnergetiqueService);
    private _backgroundColor!: string;
    private _letter!: string;

    get backgroundColor(): string {
        return this._backgroundColor;
    }

    get letter(): string {
        return this._letter;
    }

    @Input()
    set bien(value: Bien) {
        const consommationClasse = value.performanceEnergetique.consommationClasse!;

        this._letter = consommationClasse?.code ?? this._bienPerformanceEnergetiqueService.getConsommationClasse(value);
        this._backgroundColor = value.performanceEnergetique.isV1ByDate() ? ConsommationV1ColorsConst[this._letter] : ConsommationV2ColorsConst[this._letter];
    }
}
