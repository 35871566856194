import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {InotUsersApiService} from '@models/inot-users/inot-users.api.service';
import CInotUsers from '@models/inot-users/collection/inot-users.collection.model';
import {ICInotUsersApi} from '@models/inot-users/collection/inot-users.collection.interfaces';
import {InotUserFactory} from '@models/inot-users/user/inot-user.factory';

@Injectable({providedIn: 'root'})
export class CInotUsersFactory {
    private _inotUserFactory = inject(InotUserFactory);
    private _inotUsersApiService = inject(InotUsersApiService);

    create(cInotUsersApi: ICInotUsersApi): CInotUsers {
        const cInotUsers = new CInotUsers();

        cInotUsers.links = new Links(cInotUsersApi._links);
        cInotUsers.page = cInotUsersApi.page;
        cInotUsers.pages = cInotUsersApi.pages;
        cInotUsers.perPage = cInotUsersApi.limit;
        cInotUsers.total = cInotUsersApi.total;
        if (cInotUsersApi._embedded) {
            cInotUsers.results = (cInotUsersApi._embedded.items || []).filter(inotUserApi => inotUserApi)
                .map(inotUserApi => this._inotUserFactory.create(inotUserApi));
        }

        return cInotUsers;
    }

    get$(): Observable<CInotUsers> {
        return this._inotUsersApiService.getCollection$().pipe(map(cInotUsersApi => this.create(cInotUsersApi)));
    }

}
