import {inject, Injectable} from '@angular/core';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DemandeurRecherchesApiService} from '@models/demandeurs/demandeur/recherches/demandeur-recherches.api.service';
import {DemandeurRechercheFactory} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.factory';
import {ICDemandeurRecherchesApi} from '@models/demandeurs/demandeur/recherches/collection/demandeur-recherches.collection.interfaces';
import CDemandeurRecherches
    from '@models/demandeurs/demandeur/recherches/collection/demandeur-recherches.collection.model';

@Injectable({providedIn: 'root'})
export class CDemandeurRecherchesFactory {
    private _demandeurRecherchesApiService = inject(DemandeurRecherchesApiService);
    private _demandeurRechercheFactory = inject(DemandeurRechercheFactory);

    create(cDemandeurRecherchesApi: ICDemandeurRecherchesApi): CDemandeurRecherches {
        const cDemandeurRecherches = new CDemandeurRecherches();

        cDemandeurRecherches.links = new Links(cDemandeurRecherchesApi._links);
        cDemandeurRecherches.page = cDemandeurRecherchesApi.page;
        cDemandeurRecherches.pages = cDemandeurRecherchesApi.pages;
        cDemandeurRecherches.perPage = cDemandeurRecherchesApi.limit;
        cDemandeurRecherches.total = cDemandeurRecherchesApi.total;
        if (cDemandeurRecherchesApi._embedded) {
            cDemandeurRecherches.results = (cDemandeurRecherchesApi._embedded.items || []).filter(demandeurRechercheApi => demandeurRechercheApi)
                .map(demandeurRechercheApi => this._demandeurRechercheFactory.create(demandeurRechercheApi));
        }

        return cDemandeurRecherches;
    }

    getByLink$(link: string): Observable<CDemandeurRecherches> {
        return this._demandeurRecherchesApiService.getCollectionByLink$(link).pipe(map(cDemandeurRecherchesApi => this.create(cDemandeurRecherchesApi)));
    }
}
