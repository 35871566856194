import {Component, inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {
    CEtudePasserellesFactory
} from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.factory';
import CEtudePasserelles from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.model';
import {Router} from '@angular/router';
import {IEtudePasserellesListAction} from '@features/etudes/etudes.interfaces';
import {
    AppEtudePasserellesListComponent
} from '@features/etudes/etude/passerelles/list/etude-passerelles-list.component';

@Component({
    selector: 'layout-diffusions-passerelles',
    standalone: false,
    templateUrl: 'layout-diffusions-passerelles.component.html',
})
export class AppLayoutDiffusionsPasserellesComponent implements OnInit {
    private _cEtudePasserellesFactory = inject(CEtudePasserellesFactory);
    private _router = inject(Router);
    private _cEtudePasserelles$!: Observable<CEtudePasserelles>;

    get cEtudePasserelles$(): Observable<CEtudePasserelles> {
        return this._cEtudePasserelles$;
    }

    ngOnInit(): void {
        this._cEtudePasserelles$ = this._cEtudePasserellesFactory.get$();
    }

    listClicked(etudePasserellesListAction: IEtudePasserellesListAction): void {
        if (etudePasserellesListAction.action === AppEtudePasserellesListComponent.actions.SEE_LOCATIONS) {
            this._router.navigateByUrl('/app/diffusions/passerelles/' + etudePasserellesListAction.etudePasserelle.passerelle.id.toString() + '/locations');
        } else if (etudePasserellesListAction.action === AppEtudePasserellesListComponent.actions.SEE_VENTES) {
            this._router.navigateByUrl('/app/diffusions/passerelles/' + etudePasserellesListAction.etudePasserelle.passerelle.id.toString() + '/ventes');
        }
    }
}
