import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskTaskRunnerComp]'})
export class NgEskimmoTaskRunnerCompDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskTaskRunnerComp', elementRef, injector);
    }
}

@Component({
    imports: [NgEskimmoTaskRunnerCompDirective],
    selector: 'esk-task-runner',
    template: '<div appNgEskTaskRunnerComp class="tw-h-full tw-w-full"></div>',
})
export class NgEskimmoTaskRunnerCompComponent {
}
