import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    IBonvisiteActions, IBonvisiteDropdownData, IBonvisiteDropdownDataOptions
} from '@features/bonvisites/bonvisites.interfaces';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';

@Component({selector: 'bonvisite-dropdown', standalone: false, templateUrl: 'bonvisite.dropdown.component.html'})
export class BonvisiteDropdownComponent implements IDynamicComponent {
    static readonly actions = {
        ARCHIVE: 'archive',
        CANCEL_SIGN: 'cancelSign',
        EDIT: 'edit',
        PRINT: 'print',
        RESUME: 'resume',
        SAVE: 'save',
        SEND: 'send',
        SIGN: 'sign',
        WRITE: 'write',
    };
    static readonly initBonvisiteActions: IBonvisiteActions = {
        archive: false,
        cancelSign: false,
        edit: false,
        print: false,
        resume: false,
        save: false,
        send: false,
        sign: false,
        write: false,
    };
    private _dropdownService = inject(DropdownService);
    private _bonvisite!: Bonvisite;
    private _options: IBonvisiteDropdownDataOptions = {...{enabledActions: BonvisiteDropdownComponent.initBonvisiteActions}};

    @Input()
    set data(value: IBonvisiteDropdownData) {
        this._bonvisite = value.bonvisite ;
        this._options = {...{enabledActions: BonvisiteDropdownComponent.initBonvisiteActions}, ...value.options};
    }

    get options(): IBonvisiteDropdownDataOptions {
        return this._options;
    }

    archive(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.ARCHIVE, this._bonvisite);
    }

    cancelSign(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.CANCEL_SIGN, this._bonvisite);
    }

    edit(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.EDIT, this._bonvisite);
    }

    print(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.PRINT, this._bonvisite);
    }

    resume(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.RESUME, this._bonvisite);
    }

    save(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.SAVE, this._bonvisite);
    }

    send(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.SEND, this._bonvisite);
    }

    sign(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.SIGN, this._bonvisite);
    }

    write(): void {
        this._dropdownService.clicked(BonvisiteDropdownComponent.actions.WRITE, this._bonvisite);
    }
}
