import {NgModule, Type} from '@angular/core';
import {NgEstimationsReferencesComponent} from '@shared/angularJS/up-ng/estimations/estimations-references.component';
import {
    NgEstimationEvaluationReferencesCriteriaComponent
} from '@shared/angularJS/up-ng/estimations/estimations-item-evaluation-references-criteria.component';
import {NgEstimationLifetimeComponent} from '@shared/angularJS/up-ng/estimations/estimations-item-lifetime.component';
import {
    NgEstimationsReferencesCriteriaComponent
} from '@shared/angularJS/up-ng/estimations/estimations-references-criteria.component';
import {
    NgEstimationEvaluationOnsaleReferencesCriteriaComponent
} from '@shared/angularJS/up-ng/estimations/estimations-item-evaluation-onsale-references-criteria.component';

const components: Type<unknown>[] = [
    NgEstimationEvaluationOnsaleReferencesCriteriaComponent,
    NgEstimationEvaluationReferencesCriteriaComponent,
    NgEstimationLifetimeComponent,
    NgEstimationsReferencesComponent,
    NgEstimationsReferencesCriteriaComponent,
];

@NgModule({exports: components, imports: components})
export class EstimationsComponentsModule {
}
