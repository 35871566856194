import {inject, Injectable} from '@angular/core';
import {ActualitesApiService} from '@models/actualites/actualites.api.service';
import CActualites from '@models/actualites/collection/actualites.collection.model';
import {Observable} from 'rxjs';
import {
    ICActualitesQueryParameters, ICActualitesApi
} from '@models/actualites/collection/actualites.collection.interfaces';
import Links from '@models/links/links.model';
import {map} from 'rxjs/operators';
import {ActualitesFactory} from '@models/actualites/actualites.factory';

@Injectable({providedIn: 'root'})
export class CActualitesFactory {
    private _actualitesFactory = inject(ActualitesFactory);
    private _actualitesApiService = inject(ActualitesApiService);

    create(cActualitesApi: ICActualitesApi): CActualites {
        const cActualites = this.createVirgin();

        cActualites.links = new Links(cActualitesApi._links);
        cActualites.page = cActualitesApi.page;
        cActualites.pages = cActualitesApi.pages;
        cActualites.perPage = cActualitesApi.limit;
        cActualites.total = cActualitesApi.total;
        if (cActualitesApi._embedded) {
            cActualites.results = this._actualitesFactory.create(cActualitesApi._embedded.items);
        }

        return cActualites;
    }

    createVirgin(): CActualites {
        const cActualites = new CActualites();

        cActualites.links = new Links();

        return cActualites;
    }

    get$(queryParameters?: ICActualitesQueryParameters): Observable<CActualites> {
        return this._actualitesApiService.getCollection$(queryParameters).pipe(map(cActualitesApi => this.create(cActualitesApi)));
    }

    getNext$(cActualites: CActualites): Observable<CActualites> {
        return this._actualitesApiService.getCollectionByLink$(cActualites.links.next).pipe(map(cActualitesApi => this.create(cActualitesApi)));
    }
}
