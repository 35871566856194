import {Component, inject} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {ModalService} from '@shared/modal/modal.service';
import {IEstimationRapportErrorCityscanLocationData} from '@features/estimations/estimations.interfaces';
import Estimation from '@models/estimations/estimation/estimation.model';

@Component({
    selector: 'dc-estimation-rapport-error-cityscan-location',
    standalone: false,
    templateUrl: 'estimation-rapport.error-cityscan-location.component.html',
})
export class DCEstimationRapportErrorCityscanLocationComponent implements IDynamicComponent {
    private _modalService = inject(ModalService);
    private _estimation!: Estimation;

    set data(value: IEstimationRapportErrorCityscanLocationData) {
        this._estimation = value.estimation;
    }

    get estimation(): Estimation {
        return this._estimation;
    }

    get STATUS_WARNING(): string {
        return ModalService.statuts.WARNING;
    }

    redirection(redirect: boolean): void {
        this._modalService.setResponse(redirect);
    }
}
