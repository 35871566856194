import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {IDynamicComponent} from '@shared/shared.interfaces';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {map, take, takeUntil} from 'rxjs/operators';
import {BonvisiteDropdownComponent} from '@features/bonvisites/item/dropdown/bonvisite.dropdown.component';
import {UserService} from '@models/users/user/user.service';
import User from '@models/users/user/user.model';
import {IBonvisiteActionsMainData, IBonvisiteActionsMainDataOptions} from '@features/bonvisites/bonvisites.interfaces';

@Component({
    selector: 'bonvisite-write-actions-main',
    standalone: false,
    templateUrl: 'bonvisite-write.actions-main.component.html',
})
export class BonvisiteWriteActionsMainComponent implements IDynamicComponent, OnDestroy, OnInit {
    static readonly actions = BonvisiteDropdownComponent.actions;
    static readonly initBonvisiteActionsMainDataOptions: IBonvisiteActionsMainDataOptions = {fromDossier: false};
    private _callToActionService = inject(CallToActionService);
    private _dropdownService = inject(DropdownService);
    private _userService = inject(UserService);
    private _bonvisite!: Bonvisite;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IBonvisiteActionsMainDataOptions = {...BonvisiteWriteActionsMainComponent.initBonvisiteActionsMainDataOptions};

    get actions(): { SEND: string; SIGN: string } {
        return BonvisiteWriteActionsMainComponent.actions;
    }

    set data(value: IBonvisiteActionsMainData) {
        this._bonvisite = value.bonvisite;
        this._options = {...BonvisiteWriteActionsMainComponent.initBonvisiteActionsMainDataOptions, ...value.options};
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    get user$(): Observable<User> {
        return this._userService.last$;
    }

    ngOnInit(): void {
        this._dropdownService.clicked$.pipe(takeUntil(this._onDestroy$)).subscribe(dropdownClicked =>
            this._callToActionService.clicked(dropdownClicked.action, dropdownClicked.value)
        );
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clickDropdown(htmlButtonElement: HTMLButtonElement): void {
        this.user$.pipe(
            map(currentUser => ({
                enabledActions: {
                    archive: this._options.fromDossier,
                    print: true,
                    send: currentUser.hasRoleSignatureElectronique(),
                    save: this._options.fromDossier,
                },
            })),
            take(1),
        ).subscribe(options => this._dropdownService.open(htmlButtonElement, {
            component: BonvisiteDropdownComponent,
            data: {bonvisite: this._bonvisite, options},
        }));
    }

    action(action: string): void {
        this._callToActionService.clicked(action, this._bonvisite);
    }
}
