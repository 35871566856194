import {Component, Input} from '@angular/core';
import Contact from '@models/contacts/contact/contact.model';
import Person from '@models/contacts/person/person.model';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    imports: [FaIconComponent],
    selector: 'app-contact-initiales',
    templateUrl: 'contact.initiales.component.html',
})
export class AppContactInitialesComponent {
    private _contact!: Contact;

    get contact(): Contact {
        return this._contact;
    }

    @Input()
    set contact(value: Contact) {
        this._contact = value;
    }

    get person(): Person {
        return this._contact as Person;
    }
}
