import {Component, Input} from '@angular/core';
import ProcedureSignataire from '@core/models/procedures/procedure/signataires/signataire/procedure-signataire.model';

@Component({
    selector: 'app-procedure-signataire-statut',
    standalone: false,
    templateUrl: 'procedure-signataire.statut.component.html',
})
export class AppProcedureSignataireStatutComponent {
    private _signataire!: ProcedureSignataire;

    get signataire(): ProcedureSignataire {
        return this._signataire;
    }

    @Input()
    set signataire(value: ProcedureSignataire) {
        this._signataire = value;
    }
}
