import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import Bonvisite from '@models/bonvisites/bonvisite/bonvisite.model';
import {BonvisiteFactory} from '@models/bonvisites/bonvisite/bonvisite.factory';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {map, take, tap} from 'rxjs/operators';
import {DemandeurService} from '@models/demandeurs/demandeur/demandeur.service';
import {IBonvisiteAddedInformation} from '@models/bonvisites/bonvisite/bonvisite.interfaces';
import {BonvisiteVentesService} from '@models/bonvisites/bonvisite/ventes/bonvisite-ventes.service';
import {BonvisiteLocationsService} from '@models/bonvisites/bonvisite/locations/bonvisite-locations.service';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBiensService} from '@core/models/dossiers/biens/dossier-biens.service';
import {
    CBonvisiteVentesService
} from '@models/bonvisites/bonvisite/ventes/collection/bonvisite-ventes.collection.service';
import {
    CBonvisiteLocationsService
} from '@models/bonvisites/bonvisite/locations/collection/bonvisite-locations.collection.service';
import {ModalService} from '@shared/modal/modal.service';
import {CTemplatesService} from '@models/templates/collection/templates.collection.service';
import Procedure from '@models/procedures/procedure/procedure.model';
import {CProceduresService} from '@models/procedures/collection/procedures.collection.service';
import ADossier from '@core/models/dossiers/dossier/dossier.model.abstract';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import TemplateCategory from '@models/templates/template/category/template-category.model';

@Injectable({providedIn: 'root'})
export class BonvisiteService {
    static readonly messages = {
        archive: {
            CONFIRMATION: 'Voulez-vous vraiment archiver ce bon de visite ?',
            PROCEDURE_IN_PROGRESS: 'Une procédure de signature est en cours.<br>Attendez le retour des signataires ou annulez-la.',
            TITLE: 'Archivage du bon de visite',
        }
    };
    private _bonvisiteFactory = inject(BonvisiteFactory);
    private _bonvisiteLocationsService = inject(BonvisiteLocationsService);
    private _bonvisiteVentesService = inject(BonvisiteVentesService);
    private _cBonvisiteLocationsService = inject(CBonvisiteLocationsService);
    private _cBonvisiteVentesService = inject(CBonvisiteVentesService);
    private _cProceduresService = inject(CProceduresService);
    private _cTemplatesService = inject(CTemplatesService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _demandeurService = inject(DemandeurService);
    private _dossierBiensService = inject(DossierBiensService);
    private _modalService = inject(ModalService);
    private _currentSource = new BehaviorSubject<Bonvisite>(undefined!);
    private _current$ = this._currentSource.asObservable();
    private _lastEditedSubject = new Subject<Bonvisite>();

    get current$(): Observable<Bonvisite> {
        return this._current$;
    }

    get lastEdited$(): Observable<Bonvisite> {
        return this._lastEditedSubject.asObservable();
    }

    archive$(bonvisite: Bonvisite): Observable<boolean> {
        return this.getProcedure$(bonvisite).pipe(
            switchMap(procedure => {
                if (!procedure || procedure.isNewOrDraft() || procedure.isClosed()) {
                    return this._modalService.openConfirmation$({
                        buttonConfirmationLabel: 'Archiver',
                        question: BonvisiteService.messages.archive.CONFIRMATION,
                        title: BonvisiteService.messages.archive.TITLE,
                        status: ModalService.statuts.WARNING,
                    }).pipe(switchMap(confirmation => {
                        if (confirmation) {
                            return this._bonvisiteFactory.archive$(bonvisite).pipe(
                                tap(_ => this._lastEditedSubject.next(bonvisite)),
                                map(_ => true),
                            );
                        }

                        return of(false);
                    }));
                }

                return this._modalService.openInformation$({
                    comments: BonvisiteService.messages.archive.PROCEDURE_IN_PROGRESS,
                    title: BonvisiteService.messages.archive.TITLE,
                    status: ModalService.statuts.WARNING,
                }).pipe(map(_ => false));
            }),
        );
    }

    archiveCurrent$(): Observable<boolean> {
        return this.current$.pipe(
            take(1),
            switchMap(bonvisite => this.archive$(bonvisite)),
            switchMap(isArchived => this.updateCurrent$().pipe(map(_ => isArchived))),
        );
    }

    getDossierBiens$(bonvisite: Bonvisite): Observable<ADossierBien[]> {
        const dossierBiens$ = [this._dossierBiensService.getByLinks$([...bonvisite.tmpLinkLocations, ...bonvisite.tmpLinkVentes])];

        if (bonvisite.linkBVLocations) {
            dossierBiens$.push(this._cBonvisiteLocationsService.getLocationsByLink$(bonvisite.linkBVLocations));
        }

        if (bonvisite.linkBVVentes) {
            dossierBiens$.push(this._cBonvisiteVentesService.getVentesByLink$(bonvisite.linkBVVentes));
        }

        return combineLatest(dossierBiens$).pipe(
            map(dossierBienss =>
                dossierBienss.filter(dossierBiens => dossierBiens?.length > 0)
                    .reduce((previousDossierBiens, dossierBiens) => previousDossierBiens.concat(dossierBiens), [])
            ),
        );
    }

    getDossiers$(bonvisite: Bonvisite): Observable<ADossier[]> {
        const dossiers$: Observable<ADossier[]>[] = [this.getDossierBiens$(bonvisite)];

        if (bonvisite.linkDemandeur) {
            dossiers$.push(this._demandeurFactory.getByLink$(bonvisite.linkDemandeur).pipe(map(demandeur => [demandeur])));
        }

        return combineLatest(dossiers$).pipe(
            map(dossierss =>
                dossierss.filter(dossiers => dossiers?.length > 0)
                    .reduce((previousDossiers, dossiers) => previousDossiers.concat(dossiers), [])
            ),
        );
    }

    getDossiersCurrent$(): Observable<ADossier[]> {
        return this.current$.pipe(take(1), switchMap(bonvisite => this.getDossiers$(bonvisite)));
    }

    getDossiersFromUuid$(uuid: string): Observable<ADossier[]> {
        return this._bonvisiteFactory.get$(uuid).pipe(
            take(1),
            switchMap(bonvisite => this.getDossiers$(bonvisite)),
        );
    }

    getOne$(uuid: string, addedInformation: IBonvisiteAddedInformation = {}): Observable<Bonvisite> {
        if (uuid === Bonvisite.statuts.NEW) {
            const bonvisite = this._bonvisiteFactory.createVirgin(Bonvisite.statuts.NEW);

            bonvisite.tmpLinkLocations = addedInformation.locations?.map(location => location.linkSelf) ?? [];
            bonvisite.tmpLinkVentes = addedInformation.ventes?.map(vente => vente.linkSelf) ?? [];
            if (addedInformation.demandeur) {
                bonvisite.demandeurId = addedInformation.demandeur.id;
                bonvisite.linkDemandeur = addedInformation.demandeur.linkSelf;
            }

            return of(bonvisite);
        }

        return this._bonvisiteFactory.get$(uuid);
    }

    getProcedure$(bonvisite: Bonvisite): Observable<Procedure> {
        return bonvisite.linkProcedures ? this._cProceduresService.getFirst$(bonvisite.linkProcedures) : of(undefined as unknown as Procedure);
    }

    initCurrent(uuid: string, addedInformation: IBonvisiteAddedInformation = {}): void {
        this.razCurrent();
        this.getOne$(uuid, addedInformation).pipe(take(1)).subscribe(bonvisite => this._currentSource.next(bonvisite));
    }

    razCurrent(): void {
        this._currentSource.next(undefined!);
    }

    saveCurrent$(informationToSave: IBonvisiteAddedInformation = {}): Observable<Bonvisite> {
        return this.current$.pipe(
            take(1),
            switchMap(bonvisite => {
                let demandeur$ = of(informationToSave.demandeur);

                if (informationToSave.demandeur?.uuid === Demandeur.statuts.NEW) {
                    demandeur$ = this._demandeurService.save$(informationToSave.demandeur);
                }

                return demandeur$.pipe(
                    tap(demandeur => {
                        if (demandeur) {
                            bonvisite.demandeurId = demandeur.id;
                            bonvisite.linkDemandeur = demandeur.linkSelf;
                        }
                    }),
                    map(_ => bonvisite),
                );
            }),
            switchMap(bonvisite => this._bonvisiteFactory.save$(bonvisite)),
            switchMap(bonvisite => combineLatest([
                this._bonvisiteLocationsService.persist$(bonvisite, informationToSave.locations),
                this._bonvisiteVentesService.persist$(bonvisite, informationToSave.ventes),
            ]).pipe(map(_ => bonvisite))),
            tap(bonvisite => this._currentSource.next(bonvisite)),
        );
    }

    signCurrent$(provider: string): Observable<Bonvisite> {
        return this.current$.pipe(
            take(1),
            switchMap(bonvisite => this._bonvisiteFactory.sign$(bonvisite, provider)),
            switchMap(_ => this.updateCurrent$()),
        );
    }

    updateCurrent$(): Observable<Bonvisite> {
        return this.current$.pipe(
            take(1),
            switchMap(bonvisite => this._bonvisiteFactory.get$(bonvisite.uuid)),
            tap(bonvisite => this._currentSource.next(bonvisite)),
        );
    }

    writeCurrent$(): Observable<Bonvisite> {
        return this._cTemplatesService.getWithDefaultFirst$([TemplateCategory.codes.BONVISITE]).pipe(
            switchMap(cTemplates => combineLatest([this.current$, of(cTemplates.results[0])])),
            take(1),
            switchMap(([bonvisite, template]) => this._bonvisiteFactory.write$(bonvisite, template)),
            switchMap(_ => this.updateCurrent$()),
        );
    }
}
