import {inject, Injectable} from '@angular/core';
import VenteCreditRentier from '@models/ventes/vente/credit-rentier/vente-credit-rentier.model';
import {IVenteCreditRentierApi} from '@models/ventes/vente/credit-rentier/vente-credit-rentier.interfaces';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';
import Person from '@models/contacts/person/person.model';

@Injectable({providedIn: 'root'})
export class VenteCreditRentierFactory {
    private _dictionaryItemService = inject(DictionaryItemService);

    create(venteCreditRentierApi: IVenteCreditRentierApi): VenteCreditRentier {
        const venteCreditRentier: VenteCreditRentier = new VenteCreditRentier(venteCreditRentierApi.uuid);

        venteCreditRentier.age = venteCreditRentierApi.age;
        venteCreditRentier.sexe = this._dictionaryItemService.getByCode(Dictionary.names.SEXES, venteCreditRentierApi.sexe ?? Person.sexes.FEMME);

        return venteCreditRentier;
    }

    createDefault(): VenteCreditRentier {
        return this.create({sexe: Person.sexes.FEMME} as IVenteCreditRentierApi);
    }

    forApi(venteCreditRentier: VenteCreditRentier): IVenteCreditRentierApi {
        return {age: venteCreditRentier.age, sexe: venteCreditRentier.sexe.code} as IVenteCreditRentierApi;
    }
}
