import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {EtudeService} from '@models/etudes/etude/etude.service';

// @todo À ranger dans core/models/users/user
@Injectable({providedIn: 'root'})
export class IsEnablableModulesGuard {
    private _etudeService = inject(EtudeService);
    private _router = inject(Router);

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        const enablableModules = activatedRouteSnapshot.data?.enablableModules as string[];

        return this.isEnabled$(enablableModules);
    }

    // @todo Utilité d'une méthode ?
    isEnabled$(enablableModules: string[], redirect = '/app/dashboard'): Observable<boolean | UrlTree> {
        if (!enablableModules) {
            return of(this._router.parseUrl(redirect));
        }

        if (enablableModules.length <= 0) {
            return of(true);
        }

        return this._etudeService.last$.pipe(
            map(etude => etude.isEnablabledModules(enablableModules)),
            // @todo Utiliser GuardsService.redirect()
            map(isEnabled => isEnabled ? true : this._router.parseUrl(redirect)),
        );
    }
}
