import {inject, Injectable} from '@angular/core';
import {VentePasserelleFactory} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.factory';
import VentePasserelle from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.model';
import {IVentePasserelleApi} from '@models/ventes/vente/passerelles/passerelle/vente-passerelle.interfaces';

@Injectable({providedIn: 'root'})
export class VentePasserellesFactory {
    private _ventePasserelleFactory = inject(VentePasserelleFactory);

    create(ventePasserelleApis: IVentePasserelleApi[] = []): VentePasserelle[] {
        return (ventePasserelleApis || []).filter(ventePasserelleApi => ventePasserelleApi)
            .map(ventePasserelleApi => this._ventePasserelleFactory.create(ventePasserelleApi));
    }
}
