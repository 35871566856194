import angularJS from '@shared/angularJS/global.ng';
import {IModule} from 'angular';
import {delay, Observable, of, Subject} from 'rxjs';
import ContactsGroup from '@models/contacts-group/contacts-group.model';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import ContactsGroupMember from '@models/contacts-group/members/member/contacts-group-member.model';
import {
    DossierContactsGroupEditMemberDropdownComponent
} from '@features/dossiers/dossier/contacts-group/edit/member-dropdown/dossier-contacts-group-edit.member-dropdown.component';
import Contact from '@models/contacts/contact/contact.model';
import {NgItemDictionary} from '@legacy/app/managers/ressources';
import {ModalService} from '@shared/modal/modal.service';
import {ContactsGroupMemberFactory} from '@models/contacts-group/members/member/contacts-group-member.factory';
import {NgContactsGroupManager, NgDictionariesManager} from '@legacy/app/managers/managers';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {
    AppDossierContactsGroupEditComponent
} from '@features/dossiers/dossier/contacts-group/edit/dossier-contacts-group-edit.component';
import {ContactsGroupMemberService} from '@models/contacts-group/members/member/contacts-group-member.service';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';
import {IDCContactSelectResponse} from '@features/contacts/contacts.interfaces';
import {ToasterService} from '@shared/toaster/toaster.service';
import {PersonFactory} from '@models/contacts/person/person.factory';
import {
    DCDossierMembresSelectComponent
} from '@features/dossiers/dossier/membres/select/dynamic-components/dossier-membres-select.component';
import Dictionary from '@models/dictionaries/dictionary/dictionary.model';

export default function getEskDossierContactsGroupEdit(module: IModule): void {
    (function (angular) {
        'use strict';

        /**
         * @example <esk-dossier-contacts-group-edit></esk-dossier-contacts-group-edit>
         */
        module.component('eskDossierContactsGroupEdit', {
            bindings: {editForm: '=', changed: '=?'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/eskimmo/components/dossier/contacts-group.edit.html'
        });

        /**
         * Controller to manage dossier contacts-group edition
         *
         * @param Ng2ContactsGroupMemberFactory
         * @param Ng2ModalService
         * @param Ng2DropdownService
         * @param Ng2ContactsGroupMemberService
         * @param DictionariesManager
         * @param Ng2DictionaryItemFactory
         * @param Ng2ToasterService
         * @param Ng2PersonFactory
         * @param ContactsGroupManager
         */
        Controller.$inject = ['Ng2ContactsGroupMemberFactory', 'Ng2ModalService', 'Ng2DropdownService', 'Ng2ContactsGroupMemberService', 'DictionariesManager', 'Ng2DictionaryItemFactory', 'Ng2ToasterService', 'Ng2PersonFactory', 'ContactsGroupManager'];
        function Controller(this: any,
                            ng2ContactsGroupMemberFactory: ContactsGroupMemberFactory,
                            ng2ModalService: ModalService,
                            ng2DropdownService: DropdownService,
                            ng2ContactsGroupMemberService: ContactsGroupMemberService,
                            dictionariesManager: NgDictionariesManager,
                            ng2DictionaryItemFactory: DictionaryItemFactory,
                            ng2ToasterService: ToasterService,
                            ng2PersonFactory: PersonFactory,
                            contactsGroupManager: NgContactsGroupManager) {
            const $ctrl = this;
            const _onDestroy$ = new Subject<void>();
            const contactsGroupNomChangeSource = new Subject<ContactsGroup>();
            let searchModalProcessing = false;

            $ctrl.$onDestroy = () => _onDestroy$.next();
            $ctrl.$onInit = $onInit;
            $ctrl.actionsMember = actionsMember;
            $ctrl.addFromContact$ = addFromContact$;
            $ctrl.createContactFromSearch$ = createContactFromSearch$;
            $ctrl.editContact = editContact;
            $ctrl.getSavedContactsGroup = getSavedContactsGroup;
            $ctrl.importFailedFromInot$ = importFailedFromInot$;
            $ctrl.onChangeNom = onChangeNom;
            $ctrl.onSelectTypeGroup = onSelectTypeGroup;
            $ctrl.openContactModal$ = openContactModal$;
            $ctrl.openSearchModal = openSearchModal;
            $ctrl.removeMember = removeMember;
            $ctrl.CONTACTS_GROUP_TYPES = Dictionary.names.CONTACTS_GROUP_TYPES;
            $ctrl.uiSortableOptions = {
                handle: '.drag-handler',
                placeholder: 'placeholder',
                update: function (e, ui: ng.ui.UISortableUIParams<any>) {
                    if (!angular.isObject(ui) || !angular.isObject(ui.item) || !angular.isObject(ui.item.sortable) || !angular.isObject(ui.item.sortable.model)) {
                        return;
                    }

                    ng2ContactsGroupMemberFactory.updateRank$($ctrl.contactsGroup, ui.item.sortable.model, ui.item.sortable.dropindex + 1)
                        .pipe(take(1)).subscribe();
                }
            };

            /**
             * Initialization method
             */
            function $onInit() {
                contactsGroupNomChangeSource.asObservable().pipe(
                    filter(contactsGroup => !!contactsGroup),
                    map(contactsGroup => ({contactsGroup, nom: contactsGroup.nom})),
                    debounceTime(500),
                    distinctUntilChanged((previousContactsGroup, currentContactsGroup) => previousContactsGroup.nom === currentContactsGroup.nom),
                    takeUntil(_onDestroy$),
                ).subscribe(({contactsGroup}) => $ctrl.changed(contactsGroup));
                contactsGroupManager.currentSource.asObservable().pipe(
                    filter(contactsGroup => !!contactsGroup),
                    tap(contactsGroup => $ctrl.typeGroup = dictionariesManager.createFromNg2(contactsGroup.typeGroup)),
                    takeUntil(_onDestroy$),
                ).subscribe(contactsGroup => $ctrl.contactsGroup = contactsGroup);
                ng2DropdownService.clicked$.pipe(takeUntil(_onDestroy$)).subscribe(dropdownClicked => {
                    const {contactsGroupMember} = dropdownClicked.value as { contactsGroupMember: ContactsGroupMember };

                    if (dropdownClicked.action === DossierContactsGroupEditMemberDropdownComponent.actions.EDIT) {
                        $ctrl.editContact(contactsGroupMember.contact);
                    } else if (dropdownClicked.action === DossierContactsGroupEditMemberDropdownComponent.actions.REMOVE) {
                        $ctrl.removeMember(contactsGroupMember);
                    }
                });
            }

            function actionsMember($event: Event, contactsGroupMember: ContactsGroupMember): void {
                const htmlButtonElement = $event.target as HTMLButtonElement;

                ng2DropdownService.open(htmlButtonElement, {
                    component: DossierContactsGroupEditMemberDropdownComponent,
                    data: {contactsGroup: $ctrl.contactsGroup, contactsGroupMember},
                });
            }

            function addFromContact$(contact: Contact, notificationType: string): Observable<ContactsGroupMember> {
                return ng2ContactsGroupMemberService.addFromContact$($ctrl.contactsGroup, contact).pipe(tap(contactsGroupMember =>
                    ng2ToasterService.success(AppDossierContactsGroupEditComponent.notifications[notificationType].TITLE, contactsGroupMember.contact.convivialName + ' fait partie du dossier.')
                ));
            }

            function createContactFromSearch$(search: string): Observable<ContactsGroupMember> {
                const person = ng2PersonFactory.createVirgin();

                person.nom = search;

                return $ctrl.openContactModal$(person).pipe(
                    switchMap(contact => {
                        if (!contact) {
                            return of(undefined as unknown as ContactsGroupMember);
                        }

                        return $ctrl.addFromContact$(contact, DCDossierMembresSelectComponent.actions.CREATE);
                    }),
                );
            }

            function editContact(contactToEdit: Contact): void {
                openContactModal$(contactToEdit).pipe(
                    switchMap(contact => {
                        if (!contact) {
                            return of(undefined);
                        }

                        const idx = $ctrl.contactsGroup.members.findIndex(contactsGroupMember => contactsGroupMember.id === contact.id);

                        return of([contact, idx] as [Contact, number]).pipe(
                            tap(([, idx]) => $ctrl.contactsGroup.members[idx]!.contact = undefined!),
                            delay(1),
                            tap(([contact, idx]) => $ctrl.contactsGroup.members[idx]!.contact = contact),
                            map(_ => $ctrl.changed($ctrl.contactsGroup)),
                        );
                    }),
                    take(1),
                ).subscribe();
            }

            function getSavedContactsGroup(): Observable<ContactsGroup> {
                if (!$ctrl.contactsGroup.id) {
                    const contactsGroupSource = new Subject<ContactsGroup>();
                    const interval = setInterval(() => {
                        if ($ctrl.contactsGroup.id) {
                            contactsGroupSource.next($ctrl.contactsGroup);
                            clearInterval(interval);
                        }
                    }, 100);

                    $ctrl.changed($ctrl.contactsGroup);

                    return contactsGroupSource.asObservable();
                }

                return of($ctrl.contactsGroup);
            }

            function importFailedFromInot$(contact: Contact): Observable<ContactsGroupMember> {
                return $ctrl.openContactModal$(contact, true).pipe(
                    switchMap(contactSaved => {
                        if (!contactSaved) {
                            return of(undefined as unknown as ContactsGroupMember);
                        }

                        return $ctrl.addFromContact$(contactSaved, AppDossierContactsGroupEditComponent.actions.IMPORT);
                    }),
                );
            }

            function onChangeNom(): void {
                contactsGroupNomChangeSource.next($ctrl.contactsGroup);
            }

            function onSelectTypeGroup(typeGroup: NgItemDictionary): void {
                $ctrl.typeGroup = typeGroup;
                $ctrl.contactsGroup.typeGroup = $ctrl.typeGroup ? ng2DictionaryItemFactory.ngCreate($ctrl.typeGroup) : undefined;
                $ctrl.changed($ctrl.contactsGroup);
            }

            function openContactModal$(contact: Contact, forceSubmit = false): Observable<Contact> {
                return ng2ModalService.ngOpen$<Contact>('ContactModalEdit', {resolve: {contact, forceSubmit}});
            }

            function openSearchModal(): void {
                if (searchModalProcessing) {
                    return;
                }

                searchModalProcessing = true;
                $ctrl.getSavedContactsGroup().pipe(
                    switchMap((contactsGroup: ContactsGroup) => ng2ModalService.open$<IDCContactSelectResponse>(
                        DCDossierMembresSelectComponent,
                        {contactsGroup},
                        {align: 'top', button: 'ok', withPadding: false},
                    ).pipe(
                        switchMap(contactSelectResponse => {
                            $ctrl.contactsGroup.members = contactsGroup.members;
                            if (!contactSelectResponse) {
                                return of(undefined!);
                            }

                            if (contactSelectResponse.action === AppDossierContactsGroupEditComponent.actions.CREATE) {
                                return $ctrl.createContactFromSearch$(contactSelectResponse.search!);
                            } else if (contactSelectResponse.action === AppDossierContactsGroupEditComponent.actions.IMPORT_FAILED) {
                                return $ctrl.importFailedFromInot$(contactSelectResponse.contact!);
                            }

                            return of(undefined);
                        }),
                    )),
                    switchMap(_ => $ctrl.getSavedContactsGroup()),
                    take(1),
                ).subscribe(contactsGroup => {
                    if (!contactsGroup.hasNom() && contactsGroup.members.length > 0) {
                        contactsGroup.nom = contactsGroup.members[0].contact.convivialName;
                        contactsGroupManager.currentSource.next(contactsGroup);
                    }

                    $ctrl.changed(contactsGroup);
                    searchModalProcessing = false;
                });
            }

            function removeMember(contactsGroupMember: ContactsGroupMember): void {
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: 'Retirer',
                    question: AppDossierContactsGroupEditComponent.messages.delete.CONFIRMATION,
                    title: AppDossierContactsGroupEditComponent.messages.delete.TITLE,
                    status: ModalService.statuts.WARNING,
                }).pipe(
                    switchMap(confirmation => {
                        if (!confirmation) {
                            return of(undefined);
                        }

                        return ng2ContactsGroupMemberService.delete$($ctrl.contactsGroup, contactsGroupMember);
                    }),
                    take(1),
                ).subscribe(_ => $ctrl.changed($ctrl.contactsGroup));
            }
        }
    })(angularJS);
}
