import {Component, inject, Input} from '@angular/core';
import Vente from '@models/ventes/vente/vente.model';
import {VenteCompromisFactory} from '@models/ventes/vente/compromis/vente-compromis.factory';
import VenteCompromis from '@models/ventes/vente/compromis/vente-compromis.model';
import {Observable, of} from 'rxjs';
import Etude from '@models/etudes/etude/etude.model';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {tap} from 'rxjs/operators';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';

@Component({
    selector: 'app-dossier-bien-overview-compromis',
    standalone: false,
    templateUrl: 'dossier-bien-overview.compromis.component.html',
})
export class AppDossierBienOverviewCompromisComponent {
    private _demandeurFactory = inject(DemandeurFactory);
    private _etudeFactory = inject(EtudeFactory);
    private _venteCompromisFactory = inject(VenteCompromisFactory);
    private _demandeur$!: Observable<Demandeur>;
    private _etude$!: Observable<Etude>;
    private _vente!: Vente;
    private _venteCompromis$!: Observable<VenteCompromis>;

    get demandeur$(): Observable<Demandeur> {
        return this._demandeur$;
    }

    get etude$(): Observable<Etude> {
        return this._etude$;
    }

    @Input({required: true})
    set vente(value: Vente) {
        this._vente = value;
        this._venteCompromis$ = this._venteCompromisFactory.getByLink$(this._vente.linkCompromisActif).pipe(
            tap(venteCompromis => this._demandeur$ = venteCompromis.linkDemandeur ? this._demandeurFactory.getByLink$(venteCompromis.linkDemandeur) : of(undefined as unknown as Demandeur)),
            tap(venteCompromis => this._etude$ = venteCompromis.linkEtudeAcquereur ? this._etudeFactory.getByLink$(venteCompromis.linkEtudeAcquereur) : of(undefined as unknown as Etude)),
        );
    }

    get venteCompromis$(): Observable<VenteCompromis> {
        return this._venteCompromis$;
    }
}
