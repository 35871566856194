import {Component, Input, output} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import Contact from '@models/contacts/contact/contact.model';
import {IContactCardOptions, IContactConvivialNameOptions} from '@features/contacts/contacts.interfaces';
import {AppContactInitialesComponent} from '@features/contacts/contact/initiales/contact.initiales.component';
import {AsyncPipe} from '@angular/common';
import {
    AppContactConvivialNameComponent
} from '@features/contacts/contact/convivial-name/contact.convivial-name.component';
import {TextPhonesComponent} from '@shared/texts/phones/text-phones.component';
import {SearchHighlightDirective} from '@shared/search/highlight/search.highlight.directive';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';

@Component({
    imports: [
        AppContactConvivialNameComponent,
        AppContactInitialesComponent,
        AppLoaderComponent,
        AsyncPipe,
        SearchHighlightDirective,
        TextPhonesComponent,
    ],
    selector: 'app-contact-card',
    templateUrl: 'contact.card.component.html',
})
export class AppContactCardComponent {
    readonly clicked = output<Contact>();
    static readonly initContactCardOptions: IContactCardOptions = {enabledClick: true};
    private _contactConvivialNameOptions: IContactConvivialNameOptions = {};
    private _contactSource = new BehaviorSubject<Contact>(undefined!);
    private _contact$ = this._contactSource.asObservable();
    private _options: IContactCardOptions = {...AppContactCardComponent.initContactCardOptions};


    get contact$(): Observable<Contact> {
        return this._contact$;
    }

    @Input()
    set contact(value: Contact) {
        this._contactSource.next(value);
    }

    get contactConvivialNameOptions(): IContactConvivialNameOptions {
        return this._contactConvivialNameOptions;
    }

    get options(): IContactCardOptions {
        return this._options;
    }

    @Input()
    set options(value: IContactCardOptions) {
        this._options = {...AppContactCardComponent.initContactCardOptions, ...value};
        this._contactConvivialNameOptions.highlight = this._options.highlight;
        this._contactConvivialNameOptions.withExternalSource = this._options.withExternalSource;
    }

    onClick(contact: Contact): void {
        if (!this._options.enabledClick) {
            return;
        }

        this.clicked.emit(contact);
    }
}
