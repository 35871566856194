import {Component, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import BienSurface from '@models/bien/surfaces/surface/bien-surface.model';

@Component({
    selector: 'app-dossier-bien-overview-surfaces',
    standalone: false,
    templateUrl: 'dossier-bien-overview.surfaces.component.html',
})
export class AppDossierBienOverviewSurfacesComponent {
    private _bienSurfaces!: BienSurface[];

    get bienSurfaces(): BienSurface[] {
        return this._bienSurfaces;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._bienSurfaces = value.bien.surfaces;
    }
}
