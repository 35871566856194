import {Component, inject, Input} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import Vente from '@models/ventes/vente/vente.model';
import {take} from 'rxjs/operators';

@Component({selector: 'app-vente-view', standalone: false, templateUrl: 'vente.view.component.html'})
export class AppVenteViewComponent {
    private _venteFactory = inject(VenteFactory);
    private _venteSource = new Subject<Vente>();
    private _vente$ = this._venteSource.asObservable();

    get vente$(): Observable<Vente> {
        return this._vente$;
    }

    @Input()
    set link(value: string) {
        this._venteFactory.getByLink$(value).pipe(take(1)).subscribe(vente => this._venteSource.next(vente));
    }
}
