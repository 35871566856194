import {Component} from '@angular/core';

// @todo Utilité ? Voir ce qui est fait pour les Ventes
@Component({
    selector: 'layout-locations-search',
    standalone: false,
    templateUrl: 'layout-locations-search.component.html',
})
export class AppLayoutLocationsSearchComponent {
}
