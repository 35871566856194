import {inject, Injectable} from '@angular/core';
import VenteCreditRentier from '@models/ventes/vente/credit-rentier/vente-credit-rentier.model';
import {IVenteCreditRentierApi} from '@models/ventes/vente/credit-rentier/vente-credit-rentier.interfaces';
import {VenteCreditRentierFactory} from '@models/ventes/vente/credit-rentier/vente-credit-rentier.factory';

@Injectable({providedIn: 'root'})
export class VenteCreditRentiersFactory {
    private _venteCreditRentierFactory = inject(VenteCreditRentierFactory);

    create(venteCreditRentierApis: IVenteCreditRentierApi[] = []): VenteCreditRentier[] {
        return (venteCreditRentierApis || []).filter(viagerTeteApi => viagerTeteApi)
            .map(viagerTeteApi => this._venteCreditRentierFactory.create(viagerTeteApi));
    }

    forApi(venteCreditRentiers: VenteCreditRentier[] = []): IVenteCreditRentierApi[] {
        return venteCreditRentiers.filter(viagerTete => viagerTete.isValid())
            .map(viagerTete => this._venteCreditRentierFactory.forApi(viagerTete));
    }
}
