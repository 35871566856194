import {Component, Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';
import {NgBienDossier} from '@legacy/app/managers/ressources';

@Directive({selector: '[appNgEskBiensSearch]'})
export class NgBiensSearchDirective extends UpgradeComponent {
    @Input() dossier!: NgBienDossier;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDossierBiensWalletSearch', elementRef, injector);
    }
}

@Component({
    imports: [NgBiensSearchDirective],
    selector: 'app-ng-biens-search',
    template: '<div appNgEskBiensSearch [dossier]="dossier"></div>',
})
export class NgBiensSearchComponent {
    @Input() dossier!: NgBienDossier;
}
