import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DemandeurRecherchesApiService} from '@models/demandeurs/demandeur/recherches/demandeur-recherches.api.service';
import DemandeurRecherche from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.model';
import {IDemandeurRechercheApi} from '@models/demandeurs/demandeur/recherches/recherche/demandeur-recherche.interfaces';
import {DictionaryItemFactory} from '@models/dictionaries/dictionary/items/item/dictionary-item.factory';

@Injectable({providedIn: 'root'})
export class DemandeurRechercheFactory {
    private _demandeurRecherchesApiService = inject(DemandeurRecherchesApiService);
    private _dictionaryItemFactory = inject(DictionaryItemFactory);

    create(demandeurRechercheApi: IDemandeurRechercheApi): DemandeurRecherche {
        const demandeurRecherche: DemandeurRecherche = this.createVirgin(demandeurRechercheApi.id.toString(), demandeurRechercheApi.id);

        demandeurRecherche.localizationSummary = demandeurRechercheApi.localizationSummary;
        demandeurRecherche.type = demandeurRechercheApi.type;
        if (demandeurRechercheApi._embedded) {
            demandeurRecherche.natures = (demandeurRechercheApi._embedded.natures || []).filter(nature => nature)
                .map(nature => this._dictionaryItemFactory.create(nature));
        }

        if (demandeurRechercheApi._links) {
            if (demandeurRechercheApi._links.demandeur) {
                demandeurRecherche.linkDemandeur = demandeurRechercheApi._links.demandeur.href;
            }
        }

        return demandeurRecherche;
    }

    createVirgin(uuid?: string, id?: number): DemandeurRecherche {
        return new DemandeurRecherche(uuid ?? DemandeurRecherche.statuts.NEW, id);
    }

    getByLink$(link: string): Observable<DemandeurRecherche> {
        return this._demandeurRecherchesApiService.getByLink$(link).pipe(map(demandeurRechercheApi => this.create(demandeurRechercheApi)));
    }
}
