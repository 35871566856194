import {inject, Injectable} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {DossierBienFieldsService} from '@models/dossiers/biens/bien/dossier-bien.fields.service';
import {DossierBienFieldsConst} from '@models/dossiers/biens/dossier-biens.constants';

@Injectable({providedIn: 'root'})
export class DossierBienOverviewBatimentService {
    private _dossierBienFieldsService = inject(DossierBienFieldsService);
    private _isCharpentesVisible!: boolean;
    private _isChauffageVisible!: boolean;
    private _isCommentsBatimentVisible!: boolean;
    private _isDateConstructionEtatVisible!: boolean;
    private _isExpositionVisible!: boolean;
    private _isHuisseriesVisible!: boolean;
    private _isIsolationsVisible!: boolean;
    private _isMateriauxConstructionVisible!: boolean;
    private _isMateriauxCouvertureVisible!: boolean;
    private _isMitoyenneteVisible!: boolean;
    private _isNombreEtagesBatimentVisible!: boolean;
    private _isPartVisible!: boolean;
    private _isSurfaceCarrezVisible!: boolean;
    private _isVoletsVisible!: boolean;

    get isCharpentesVisible(): boolean {
        return this._isCharpentesVisible;
    }

    get isChauffageVisible(): boolean {
        return this._isChauffageVisible;
    }

    get isCommentsBatimentVisible(): boolean {
        return this._isCommentsBatimentVisible;
    }

    get isDateConstructionEtatVisible(): boolean {
        return this._isDateConstructionEtatVisible;
    }

    get isExpositionVisible(): boolean {
        return this._isExpositionVisible;
    }

    get isHuisseriesVisible(): boolean {
        return this._isHuisseriesVisible;
    }

    get isIsolationsVisible(): boolean {
        return this._isIsolationsVisible;
    }

    get isMateriauxConstructionVisible(): boolean {
        return this._isMateriauxConstructionVisible;
    }

    get isMateriauxCouvertureVisible(): boolean {
        return this._isMateriauxCouvertureVisible;
    }

    get isMitoyenneteVisible(): boolean {
        return this._isMitoyenneteVisible;
    }

    get isNombreEtagesBatimentVisible(): boolean {
        return this._isNombreEtagesBatimentVisible;
    }

    get isPartVisible(): boolean {
        return this._isPartVisible;
    }

    get isSurfaceCarrezVisible(): boolean {
        return this._isSurfaceCarrezVisible;
    }

    get isVoletsVisible(): boolean {
        return this._isVoletsVisible;
    }

    init(dossierBien: ADossierBien): void {
        this._isCharpentesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.charpentes);
        this._isChauffageVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.aucunChauffage)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.chauffageCollectif)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.chauffages);
        this._isCommentsBatimentVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.commentsBatiment);
        this._isDateConstructionEtatVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.anneeConstruction)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.epoqueConstruction)
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.etat);
        this._isExpositionVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.exposition);
        this._isHuisseriesVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.huisseries);
        this._isIsolationsVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.isolations);
        this._isMateriauxConstructionVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.materiauxConstruction);
        this._isMateriauxCouvertureVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.materiauxCouverture);
        this._isMitoyenneteVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.mitoyennete);
        this._isNombreEtagesBatimentVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.nombreEtagesBatiment);
        this._isSurfaceCarrezVisible = dossierBien.bien.isAppartement()
            || this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.surfaceCarrez);
        this._isVoletsVisible = this._dossierBienFieldsService.isResumeVisible(dossierBien, DossierBienFieldsConst.volets);

        this._isPartVisible = this.isCharpentesVisible || this.isChauffageVisible || this.isCommentsBatimentVisible
            || this.isDateConstructionEtatVisible || this.isExpositionVisible || this.isHuisseriesVisible
            || this.isIsolationsVisible || this.isMateriauxConstructionVisible || this.isMateriauxCouvertureVisible
            || this.isMitoyenneteVisible || this.isNombreEtagesBatimentVisible || this.isSurfaceCarrezVisible
            || this.isVoletsVisible;
    }
}
