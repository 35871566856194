import {Component, inject, Input} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {Observable} from 'rxjs';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';

@Component({
    selector: 'dossier-files-actions-main',
    standalone: false,
    templateUrl: 'dossier-files.actions-main.component.html',
})
export class DossierFilesActionsMainComponent implements IDynamicComponent {
    static readonly actions = {IMPORT: 'dossier_files_import'};
    private _callToActionService = inject(CallToActionService);
    private _value!: IDynamicComponentData;

    get CONTENT_ALLOWED_TYPES(): string {
        return DossierFile.CONTENT_ALLOWED_TYPES;
    }

    get pending$(): Observable<boolean> {
        return this._callToActionService.pending$;
    }

    // Inutile pour le moment mais obligatoire pour IDynamicComponent
    @Input()
    set data(value: IDynamicComponentData) {
        this._value = value;
    }

    importFiles(file: File): void {
        this._callToActionService.clicked(DossierFilesActionsMainComponent.actions.IMPORT, file);
    }
}
