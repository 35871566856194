import {Component, Inject, inject, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, of, Subject, switchMap} from 'rxjs';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {map, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import Vente from '@models/ventes/vente/vente.model';
import {IOffreachatAddedInformation} from '@models/offreachats/offreachat/offreachat.interfaces';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {IWindowHistoryState} from '@shared/routes/routes.interfaces';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';

@Component({selector: 'layout-offreachat', standalone: false, templateUrl: 'layout-offreachat.component.html'})
export class AppLayoutOffreachatComponent implements OnDestroy, OnInit {
    private _activatedRoute = inject(ActivatedRoute);
    private _demandeurFactory = inject(DemandeurFactory);
    private _dossierService = inject(DossierService);
    private _offreachatService = inject(OffreachatService);
    private _router = inject(Router);
    private _venteFactory = inject(VenteFactory);
    private _window: Window;
    private readonly _onDestroy$ = new Subject<void>();

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    ngOnInit(): void {
        this._activatedRoute.params.pipe(
            map(params => (params as { offreachatUuid: string }).offreachatUuid),
            switchMap(offreachatUuid => {
                const addedInformation: IOffreachatAddedInformation = {};
                const currentDossier = this._dossierService.getCurrentFromNg(this._router.url);
                const dossiers$: Observable<ADossier>[] = [];
                const state = this._window.history.state as IWindowHistoryState;

                if (currentDossier?.isDemandeur()) {
                    addedInformation.demandeur = currentDossier as Demandeur;
                    if (state.extraParams?.linkVente) {
                        dossiers$.push(this._venteFactory.getByLink$(state.extraParams.linkVente as string).pipe(tap(vente => addedInformation.vente = vente)));
                    }
                } else if (currentDossier?.isVente()) {
                    addedInformation.vente = currentDossier as Vente;
                } else if (state.extraParams) {
                    if (state.extraParams.linkDemandeur) {
                        dossiers$.push(this._demandeurFactory.getByLink$(state.extraParams.linkDemandeur as string).pipe(tap(demandeur => addedInformation.demandeur = demandeur)));
                    }

                    if (state.extraParams.linkVente) {
                        dossiers$.push(this._venteFactory.getByLink$(state.extraParams.linkVente as string).pipe(tap(vente => addedInformation.vente = vente)));
                    }
                }

                return (dossiers$.length > 0 ? combineLatest(dossiers$) : of([] as ADossier[])).pipe(
                    map(_ => ({addedInformation, offreachatUuid})),
                );
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(({addedInformation, offreachatUuid}) =>
            this._offreachatService.initCurrent(offreachatUuid, addedInformation));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._offreachatService.razCurrent();
    }
}
