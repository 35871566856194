import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Vente from '@models/ventes/vente/vente.model';
import {Observable, of} from 'rxjs';
import Demandeur from '@models/demandeurs/demandeur/demandeur.model';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import Etude from '@models/etudes/etude/etude.model';

@Component({
    selector: 'app-dossier-bien-overview-archive',
    standalone: false,
    templateUrl: 'dossier-bien-overview.archive.component.html',
})
export class AppDossierBienOverviewArchiveComponent {
    private _demandeurFactory = inject(DemandeurFactory);
    private _etudeFactory = inject(EtudeFactory);
    private _archiveDemandeur$ = of(undefined) as unknown as Observable<Demandeur>;
    private _archiveEtude$ = of(undefined) as unknown as Observable<Etude>;
    private _dossierBien!: ADossierBien;

    get archiveDemandeur$(): Observable<Demandeur> {
        return this._archiveDemandeur$;
    }

    get archiveEtude$(): Observable<Etude> {
        return this._archiveEtude$;
    }

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
        if (this.dossierBien.isVente()) {
            if (this.vente.linkArchiveDemandeur) {
                this._archiveDemandeur$ = this._demandeurFactory.getByLink$(this.vente.linkArchiveDemandeur);
            }

            if (this.vente.linkArchiveEtudeAcquereur) {
                this._archiveEtude$ = this._etudeFactory.getByLink$(this.vente.linkArchiveEtudeAcquereur);
            }
        }
    }

    get vente(): Vente {
        return this._dossierBien as Vente;
    }
}
