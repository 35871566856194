import angularJS from '@shared/angularJS/global.ng';
import {findIndex} from 'lodash';
import {IModule} from 'angular';
import {NgLocationLocataireEtatLieuxManager, NgMediaManager} from '@legacy/app/managers/managers';
import User from '@models/users/user/user.model';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {NgLocationLocataire, NgLocationLocataireEtatLieux, NgMedia} from '@legacy/app/managers/ressources';
import {MediaFactory} from '@models/medias/media/media.factory';
import {take, tap} from 'rxjs/operators';
import DateFormat from '@shared/date/date.format';
import {ModalService} from '@shared/modal/modal.service';
import {of, switchMap} from 'rxjs';
import {ToasterService} from '@shared/toaster/toaster.service';
import {LocationLocataireFactory} from '@models/locations/location/locataires/locataire/location-locataire.factory';
import {
    LocationLocataireConstatFactory
} from '@models/locations/location/locataires/locataire/constats/constat/location-locataire-constat.factory';
import {LocationService} from '@models/locations/location/location.service';

export default function getLocationModalEditEtatsLieuxCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('LocationModalEditEtatsLieuxCtrl', Controller);

        /**
         * Controller for EtatsLieux modal
         *
         * @param locataire
         * @param Ng2EtudeService
         * @param LocationLocataireEtatLieuxManager
         * @param $translate
         * @param MediaManager
         * @param Ng2MediaFactory
         * @param Ng2ToasterService
         * @param Ng2ModalService
         * @param Ng2LocationLocataireFactory
         * @param Ng2LocationLocataireConstatFactory
         * @param Ng2LocationService
         */
        Controller.$inject = ['locataire', 'Ng2EtudeService', 'LocationLocataireEtatLieuxManager', '$translate', 'MediaManager', 'Ng2MediaFactory', 'Ng2ToasterService', 'Ng2ModalService', 'Ng2LocationLocataireFactory', 'Ng2LocationLocataireConstatFactory', 'Ng2LocationService'];
        function Controller(this: any,
                            locataire: NgLocationLocataire,
                            ng2EtudeService: EtudeService,
                            locationLocataireEtatLieuxManager: NgLocationLocataireEtatLieuxManager,
                            $translate: angular.translate.ITranslateService,
                            mediaManager: NgMediaManager,
                            ng2MediaFactory: MediaFactory,
                            ng2ToasterService: ToasterService,
                            ng2ModalService: ModalService,
                            ng2LocationLocataireFactory: LocationLocataireFactory,
                            ng2LocationLocataireConstatFactory: LocationLocataireConstatFactory,
                            ng2LocationService: LocationService) {
            const $ctrl = this;

            $ctrl.setCurrent = setCurrent;
            $ctrl.save = save;
            $ctrl.remove = remove;
            $ctrl.upload = upload;
            $ctrl.downloadMedia = downloadMedia;
            ng2EtudeService.last$.subscribe(currentEtude => $ctrl.currentEtude = currentEtude);
            $ctrl.loading = true;
            $ctrl.usersRoles = [User.roles.NEGOCIATEUR, User.roles.NOTAIRE];
            locationLocataireEtatLieuxManager.getAll(locataire._esk.defaultRouteParams!.locationId!, locataire._esk.defaultRouteParams!.id!).then(function (etatsLieux) {
                $ctrl.etatsLieux = etatsLieux.collection;
                $ctrl.setCurrent();
            }).finally(() => $ctrl.loading = false);

            /**
             * Change the current etatLieux
             *
             * @param etatLieux
             */
            function setCurrent(etatLieux: NgLocationLocataireEtatLieux) {
                $ctrl.current = null;
                if (etatLieux === null) {
                    return;
                }

                if (angular.isDefined($ctrl.editForm)) {
                    $ctrl.editForm.$setPristine();
                }

                if (!angular.isObject(etatLieux)) {
                    etatLieux = locationLocataireEtatLieuxManager.create({date: DateFormat.toDate()}, locataire._esk.defaultRouteParams!.locationId!, locataire._esk.defaultRouteParams!.id!);
                }

                $ctrl.current = angular.copy(etatLieux);
            }

            /**
             * Save the current etatLieux
             *
             * @param keepCurrent
             */
            function save(keepCurrent: boolean) {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid) return;

                if (!$ctrl.current.media) {
                    ng2ToasterService.warning('Insérer un document d\'état des lieux afin de pouvoir enregistrer');

                    return;
                }

                $ctrl.sending = true;
                $ctrl.current.save().then(function () {
                    var idx = findIndex($ctrl.etatsLieux, function (etatLieux) {
                        // @ts-ignore
                        return etatLieux._esk.uuid === $ctrl.current._esk.uuid;
                    });

                    if (idx === -1) {
                        $ctrl.etatsLieux.unshift($ctrl.current);
                    } else {
                        $ctrl.etatsLieux[idx] = $ctrl.current;
                    }
                }).finally(function () {
                    if (!keepCurrent) {
                        $ctrl.setCurrent(null);
                    }
                    $ctrl.sending = false;
                });
            }

            /**
             * Remove the current etatLieux
             */
            function remove() {
                $ctrl.removing = true;
                ng2ModalService.openConfirmation$({
                    buttonConfirmationLabel: 'Supprimer',
                    question: $translate.instant('remove.confirm.MESSAGE', {ceData: 'cet état des lieux'}),
                    title: $translate.instant('remove.confirm.TITLE'),
                    status: ModalService.statuts.DANGER,
                }).pipe(
                    switchMap(response => {
                        if (!response) {
                            return of(null);
                        }

                        const location = ng2LocationService.getCurrentFromNg();
                        const locationLocataire = ng2LocationLocataireFactory.ngCreate(locataire);
                        const locationLocataireConstat = ng2LocationLocataireConstatFactory.ngCreate($ctrl.current);

                        return ng2LocationLocataireConstatFactory.delete$(location, locationLocataire, locationLocataireConstat).pipe(
                            tap(() => $ctrl.etatsLieux = ($ctrl.etatsLieux as NgLocationLocataireEtatLieux[]).filter(ngEL => ngEL._esk.uuid !== $ctrl.current._esk.uuid)),
                        );
                    }),
                    take(1),
                ).subscribe({next: () => $ctrl.setCurrent(null), complete: () => $ctrl.removing = false});
            }

            /**
             * Upload the given media
             *
             * @param files
             */
            function upload(files: File[]) {
                $ctrl.editForm.$submitted = true;
                $ctrl.mediaAttached = mediaManager.create({file: files[0]});
                $ctrl.mediaAttached.upload().then(function () {
                    $ctrl.current.media = angular.copy($ctrl.mediaAttached);
                    $ctrl.mediaAttached = null;
                    $ctrl.save(true);
                });
            }

            /**
             * Download ngMedia
             *
             * @param ngMedia
             */
            function downloadMedia(ngMedia: NgMedia) {
                ng2MediaFactory.read$(ng2MediaFactory.ngCreate(ngMedia)).pipe(take(1)).subscribe();
            }
        }
    })(angularJS);
}
