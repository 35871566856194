import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgBienEditPhotos]'})
export class NgBienEditPhotosDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskBienEditPhotosCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgBienEditPhotosDirective],
    selector: 'app-ng-bien-edit-photos',
    template: '<div appNgBienEditPhotos></div>',
})
export class NgBienEditPhotosComponent {
}
