import {Component, ElementRef, inject, Input, OnDestroy, OnInit, output, ViewChild} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewInfosConfidentiellesService
} from '@features/dossiers/dossier/bien/overview/infos-confidentielles/dossier-bien-overview.infos-confidentielles.service';
import {
    DossierBienOverviewProprietairesService
} from '@features/dossiers/dossier/bien/overview/proprietaires/dossier-bien-overview.proprietaires.service';
import {
    DossierBienOverviewLocalisationService
} from '@features/dossiers/dossier/bien/overview/localisation/dossier-bien-overview.localisation.service';
import {
    DossierBienOverviewDescriptifsService
} from '@features/dossiers/dossier/bien/overview/descriptifs/dossier-bien-overview.descriptifs.service';
import {
    DossierBienOverviewArchiveService
} from '@features/dossiers/dossier/bien/overview/archive/dossier-bien-overview.archive.service';
import {Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {
    DossierBienOverviewCompromisService
} from '@features/dossiers/dossier/bien/overview/compromis/dossier-bien-overview.compromis.service';
import Vente from '@models/ventes/vente/vente.model';

@Component({
    selector: 'app-dossier-bien-overview-infos-confidentielles',
    standalone: false,
    templateUrl: 'dossier-bien-overview.infos-confidentielles.component.html',
})
export class AppDossierBienOverviewInfosConfidentiellesComponent implements OnDestroy, OnInit {
    @ViewChild('seeInfosConfidentialVisibleLink') seeInfosConfidentialVisibleLink!: ElementRef<HTMLAnchorElement>;
    readonly isInfosConfidential = output<boolean>();
    private _dossierBienOverviewArchiveService = inject(DossierBienOverviewArchiveService);
    private _dossierBienOverviewCompromisService = inject(DossierBienOverviewCompromisService);
    private _dossierBienOverviewDescriptifsService = inject(DossierBienOverviewDescriptifsService);
    private _dossierBienOverviewInfosConfidentiellesService = inject(DossierBienOverviewInfosConfidentiellesService);
    private _dossierBienOverviewLocalisationService = inject(DossierBienOverviewLocalisationService);
    private _dossierBienOverviewProprietairesService = inject(DossierBienOverviewProprietairesService);
    private _dossierBien!: ADossierBien;
    private _infosConfidentialWanted$!: Observable<void>;
    private _isInfosConfidentialVisible = false;
    private readonly _onDestroy$ = new Subject<void>();

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    @Input()
    set infosConfidentialWanted$(value: Observable<void>) {
        this._infosConfidentialWanted$ = value;
    }

    get isCommentsConfidentielVisible(): boolean {
        return this._dossierBienOverviewInfosConfidentiellesService.isCommentsConfidentielVisible;
    }

    get isCommentsConfreresVisible(): boolean {
        return this._dossierBienOverviewInfosConfidentiellesService.isCommentsConfreresVisible;
    }

    get isInfosConfidentialVisible(): boolean {
        return this._isInfosConfidentialVisible;
    }

    set isInfosConfidentialVisible(value: boolean) {
        this._isInfosConfidentialVisible = value;
        this.isInfosConfidential.emit(this.isInfosConfidentialVisible);
    }

    get isPartArchiveVisible(): boolean {
        return this._dossierBienOverviewArchiveService.isPartVisible;
    }

    get isPartCompromisVisible(): boolean {
        return this._dossierBienOverviewCompromisService.isPartVisible;
    }

    get isPartDescriptifsVisible(): boolean {
        return this._dossierBienOverviewDescriptifsService.isPartVisible;
    }

    get isPartLocalisationVisible(): boolean {
        return this._dossierBienOverviewLocalisationService.isPartVisible;
    }

    get isPartProprietairesVisible(): boolean {
        return this._dossierBienOverviewProprietairesService.isPartVisible;
    }

    get vente(): Vente {
        return this._dossierBien as Vente;
    }

    ngOnInit(): void {
        if (this._infosConfidentialWanted$) {
            this._infosConfidentialWanted$
                .pipe(filter(_ => !!this.seeInfosConfidentialVisibleLink), takeUntil(this._onDestroy$))
                .subscribe(() => this.seeInfosConfidentialVisibleLink.nativeElement.click());
        }
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }
}
