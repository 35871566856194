import {inject, Injectable} from '@angular/core';
import {
    ProcedureSignatairesApiService
} from '@models/procedures/procedure/signataires/procedure-signataires.api.service';
import {
    ProcedureSignataireFactory
} from '@models/procedures/procedure/signataires/signataire/procedure-signataire.factory';
import {
    ICProcedureSignatairesApi
} from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.interfaces';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import Links from '@models/links/links.model';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CProcedureSignatairesFactory {
    private _procedureSignataireFactory = inject(ProcedureSignataireFactory);
    private _procedureSignatairesApiService = inject(ProcedureSignatairesApiService);

    create(cProcedureSignatairesApi: ICProcedureSignatairesApi): CProcedureSignataires {
        const cProcedureSignataires = this.createVirgin();

        cProcedureSignataires.links = new Links(cProcedureSignatairesApi._links);
        cProcedureSignataires.page = cProcedureSignatairesApi.page;
        cProcedureSignataires.pages = cProcedureSignatairesApi.pages;
        cProcedureSignataires.perPage = cProcedureSignatairesApi.limit;
        cProcedureSignataires.total = cProcedureSignatairesApi.total;
        if (cProcedureSignatairesApi._embedded) {
            cProcedureSignataires.results = (cProcedureSignatairesApi._embedded.items || []).filter(procedureSignataireApi => procedureSignataireApi)
                .map(procedureSignataireApi => this._procedureSignataireFactory.create(procedureSignataireApi));
        }

        return cProcedureSignataires;
    }

    createVirgin(): CProcedureSignataires {
        const cProcedureSignataires = new CProcedureSignataires();

        cProcedureSignataires.links = new Links();

        return cProcedureSignataires;
    }

    get$(procedureUuid: string): Observable<CProcedureSignataires> {
        return this._procedureSignatairesApiService.getCollection$(procedureUuid)
            .pipe(map((cProcedureSignatairesApi: ICProcedureSignatairesApi): CProcedureSignataires => this.create(cProcedureSignatairesApi)));
    }

    getNext$(cProcedureSignataires: CProcedureSignataires): Observable<CProcedureSignataires> {
        return this._procedureSignatairesApiService.getCollectionByLink$(cProcedureSignataires.links.next)
            .pipe(map((cProcedureSignatairesApi: ICProcedureSignatairesApi): CProcedureSignataires => this.create(cProcedureSignatairesApi)));
    }
}
