import {Component, inject, Input} from '@angular/core';
import Media from '@core/models/medias/media/media.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {MediaService} from '@models/medias/media/media.service';
import {take} from 'rxjs/operators';
import {IMediaConvivialNameOptions} from '@features/medias/medias.interfaces';

@Component({
    selector: 'app-media-convivial-name',
    standalone: false,
    templateUrl: 'media.convivial-name.component.html',
})
export class AppMediaConvivialNameComponent {
    static readonly initMediaConvivialNameOptions: IMediaConvivialNameOptions = {enabledIcon: true};
    private _mediaService = inject(MediaService);
    private _mediaSource = new BehaviorSubject<Media>(undefined!);
    private _media$ = this._mediaSource.asObservable();
    private _options: IMediaConvivialNameOptions = {...AppMediaConvivialNameComponent.initMediaConvivialNameOptions};

    @Input()
    set link(value: string) {
        this._mediaService.getOneByLink$(value).pipe(take(1)).subscribe(media => this._mediaSource.next(media));
    }

    get media$(): Observable<Media> {
        return this._media$;
    }

    @Input()
    set media(value: Media) {
        this._mediaSource.next(value);
    }

    get options(): IMediaConvivialNameOptions {
        return this._options;
    }

    @Input()
    set options(value: IMediaConvivialNameOptions) {
        this._options = {...AppMediaConvivialNameComponent.initMediaConvivialNameOptions, ...value};
    }
}
