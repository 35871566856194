import angularJS from '@shared/angularJS/global.ng';
import {filter, find, findIndex, remove} from 'lodash';
import {IModule, IPromise, IQService} from 'angular';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgSoqSweetAlert} from '@legacy/app/soqrate/soqrate';
import {NgEmailAttachmentManager, NgEmailDossierManager, NgMediaManager} from '@legacy/app/managers/managers';
import {TinymceService} from '@shared/tinymce/tinymce.service';
import {
    NgBienDossier, NgDemandeur, NgEmail, NgEmailAttachment, NgEmailDossier, NgEmailRecipient
} from '@legacy/app/managers/ressources';
import DossierFile from '@models/dossiers/dossier/files/file/dossier-file.model';
import EmailRecipient from '@models/emails/email/recipients/recipient/email-recipient.model';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {EmailFactory} from '@models/emails/email/email.factory';
import {EmailAttachmentFactory} from '@models/emails/email/attachments/attachment/email-attachment.factory';
import {EmailDossierFactory} from '@models/emails/email/dossiers/dossier/email-dossier.factory';
import {EmailRecipientFactory} from '@models/emails/email/recipients/recipient/email-recipient.factory';

export default function getEskModalEmailCtrl(module: IModule): void {
    (function (angular) {
        'use strict';

        module.controller('EskModalEmailCtrl', Controller);

        /**
         * Controller to edit email
         *
         * @param $uibModalInstance
         * @param $q
         * @param email
         * @param EmailDossierManager
         * @param EmailAttachmentManager
         * @param SoqSweetAlert
         * @param currentDossier
         * @param $translate
         * @param MediaManager
         * @param Ng2ToasterService
         * @param Ng2TinymceService
         * @param Ng2EmailFactory
         * @param Ng2EmailAttachmentFactory
         * @param Ng2EmailDossierFactory
         * @param Ng2EmailRecipientFactory
         * @constructor
         */
        Controller.$inject = ['$uibModalInstance', '$q', 'email', 'EmailDossierManager', 'EmailAttachmentManager', 'SoqSweetAlert', 'currentDossier', '$translate', 'MediaManager', 'Ng2ToasterService', 'Ng2TinymceService', 'Ng2EmailFactory', 'Ng2EmailAttachmentFactory', 'Ng2EmailDossierFactory', 'Ng2EmailRecipientFactory'];
        function Controller(this: any,
                            $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
                            $q: IQService,
                            email: NgEmail,
                            emailDossierManager: NgEmailDossierManager,
                            emailAttachmentManager: NgEmailAttachmentManager,
                            soqSweetAlert: NgSoqSweetAlert,
                            currentDossier: unknown,
                            $translate: angular.translate.ITranslateService,
                            mediaManager: NgMediaManager,
                            ng2ToasterService: ToasterService,
                            ng2TinymceService: TinymceService,
                            ng2EmailFactory: EmailFactory,
                            ng2EmailAttachmentFactory: EmailAttachmentFactory,
                            ng2EmailDossierFactory: EmailDossierFactory,
                            ng2EmailRecipientFactory: EmailRecipientFactory) {
            const $ctrl = this;

            $ctrl.send = send;
            $ctrl.addAttachmentDocument = addAttachmentDocument;
            $ctrl.addDossier = addDossier;
            $ctrl.addContacts = addContacts;
            $ctrl.addAttachment = addAttachment;
            $ctrl.removeAttachment = removeAttachment;
            $ctrl.removeDossier = removeDossier;
            $ctrl.removeRecipient = removeRecipient;
            $ctrl.tinymceOptions = ng2TinymceService.getOptions('simple', {
                plugins: [TinymceService.plugins.LISTS],
            });
            $ctrl.sending = false;
            $ctrl.attachmentsMaxSize = 10485760;
            $ctrl.email = email;
            $ctrl.tabs = [
                {code: 'edition', label: "Rédaction"},
                {code: 'dossiers', label: "Dossiers"},
                {code: 'attachments', label: "Pièces jointes"}
            ];
            $ctrl.currentTab = $ctrl.tabs[0].code;
            $ctrl.currentDossier = currentDossier;
            toggleMoreRecipients();
            updateAttachmentsSize();

            /**
             * Send email
             */
            function send() {
                if (!$ctrl.editForm.$submitted || !$ctrl.editForm.$valid || !$ctrl.email.message) {
                    soqSweetAlert.warningMessage($translate.instant('formulaire.invalid.TITLE'), $translate.instant('formulaire.invalid.MESSAGE'));
                    return;
                }

                if ($ctrl.email.recipients.length <= 0) {
                    soqSweetAlert.warningMessage($translate.instant('mail.recipients_edition.TITLE'), $translate.instant('mail.recipients_edition.message.NOT_ENOUGH'));
                    return;
                }

                if ($ctrl.email.recipients.length > 50) {
                    soqSweetAlert.warningMessage($translate.instant('mail.recipients_edition.TITLE'), $translate.instant('mail.recipients_edition.message.TOO_MUCH', {nbDestinataires: $ctrl.email.recipients.length}));
                    return;
                }

                $ctrl.sending = true;
                $ctrl.email.send().then(function () {
                    $uibModalInstance.close(true);
                    ng2ToasterService.success($translate.instant('mail.TITLE'), $translate.instant('mail.MESSAGE'));
                }, (error: { data: { recipients: NgEmailRecipient[] }, status: number }) => {
                    const textRecipientsInvalid: unknown[] = [];

                    if (error.status === 403) {
                        angular.forEach(error.data.recipients, (recipient) => textRecipientsInvalid.push(recipient.name + " : " + recipient.emailAddress));

                        return soqSweetAlert.warningConfirmation(
                            $translate.instant('mail.invalid_recipient.TITLE', {nbDestinataires: error.data.recipients.length}),
                            $translate.instant('mail.invalid_recipient.MESSAGE', {
                                nbDestinataires: error.data.recipients.length,
                                message: textRecipientsInvalid.join('<br>- ')
                            }),
                            {confirmButtonText: $translate.instant('mail.invalid_recipient.CONFIRMATION', {nbDestinataires: error.data.recipients.length})}
                        ).then(() => {
                            const promises: IPromise<unknown>[] = [];

                            angular.forEach($ctrl.email.recipients, (recipient: NgEmailRecipient) => {
                                if (findIndex(error.data.recipients, {id: recipient.id}) >= 0) {
                                    promises.push($ctrl.removeRecipient(recipient));
                                }
                            });

                            return $q.all(promises);
                        });
                    }

                    return;
                }).finally(() => $ctrl.sending = false);
            }

            /**
             * Add attachment document to the email
             *
             * @param dossierFile
             */
            function addAttachmentDocument(dossierFile: DossierFile) {
                $ctrl.email.attachments.push(emailAttachmentManager.create({media: mediaManager.createFromNg2(dossierFile.media)}));
                updateAttachmentsSize();
            }

            /**
             * Adds a dossier to the email
             *
             * @param dossier
             */
            function addDossier(dossier: NgEmailDossier) {
                if (!dossier) {
                    return;
                }

                $ctrl.email.dossiers.push(emailDossierManager.create({
                    typeId: dossier.id,
                    type: dossier._esk.typeDossier,
                    dossier: dossier
                }));
            }

            /**
             * Adds contacts from the dossier to the email
             * @param dossier
             */
            function addContacts(dossier: NgEmailDossier) {
                const message: string[] = [];
                let recipients: { emailAddress: string, name: string, type: string }[];

                if (dossier.type === DossierTypesConst.DEMANDEUR) {
                    recipients = (dossier.dossier as NgDemandeur).contactsGroup.getRecipients();
                } else {
                    recipients = (dossier.dossier as NgBienDossier).bien.contactsGroup.getRecipients();
                }

                if (recipients.length > 0) {
                    recipients.map(function (recipient) {
                        if (find($ctrl.email.recipients, {emailAddress: recipient.emailAddress})) {
                            message.push(recipient.name);
                        } else {
                            $ctrl.email.addRecipient(recipient.emailAddress, recipient.type, recipient.name);
                        }
                    });

                    if (message.length > 0) {
                        ng2ToasterService.info($translate.instant("Ajout de destinataires"),
                            $translate.instant("Les contacts suivant sont déjà présents dans l'email")
                            + " : " + message.join(', ') + ".");
                    } else {
                        ng2ToasterService.success($translate.instant("Ajout de destinataires"), $translate.instant('Les contacts ont bien été importés.'));
                    }

                    toggleMoreRecipients();
                } else {
                    ng2ToasterService.info($translate.instant("Ajout de destinataires"), $translate.instant("Aucun contact ne peut être importé."));
                }
            }

            /**
             * Add attachment to mail
             *
             * @param file
             */
            function addAttachment(file: File) {
                const emailAttachment = emailAttachmentManager.create({media: mediaManager.create({file})}) as NgEmailAttachment;

                $ctrl.uploading = true;
                $ctrl.email.attachments.push(emailAttachment);
                emailAttachment.media.upload().then(() => {
                    emailAttachment.mediaId = emailAttachment.media.id;
                }, () => {
                    ng2ToasterService.error($translate.instant("mail.TITLE"), $translate.instant('mail.attachment_incorrect.MESSAGE', {fileName: emailAttachment.media.file.name}));
                    remove($ctrl.email.attachments, emailAttachment);
                }).finally(() => {
                    let uploading = false;

                    updateAttachmentsSize();
                    angular.forEach($ctrl.email.attachments, emailAttachment => {
                        if (angular.isObject(emailAttachment.media.file) && emailAttachment.media.file._esk.uploadXhr) uploading = true;
                    });
                    $ctrl.uploading = uploading;
                });
            }

            function removeAttachment(ngEmailAttachment: NgEmailAttachment) {
                const index = ($ctrl.email.attachments as NgEmailAttachment[]).findIndex(a => a._esk.uuid === ngEmailAttachment._esk.uuid);

                if ($ctrl.email.attachments[index].id) {
                    ng2EmailAttachmentFactory.delete$(ng2EmailFactory.ngCreate($ctrl.email), ng2EmailAttachmentFactory.ngCreate(ngEmailAttachment))
                        .subscribe(() => {
                            $ctrl.email.attachments.splice(index, 1);
                            updateAttachmentsSize();
                        });
                } else {
                    $ctrl.email.attachments.splice(index, 1);
                    updateAttachmentsSize();
                }
            }

            function removeDossier(ngEmailDossier: NgEmailDossier) {
                const index = ($ctrl.email.dossiers as NgEmailDossier[]).findIndex(d => d._esk.uuid === ngEmailDossier._esk.uuid);

                if ($ctrl.email.dossiers[index].id) {
                    ng2EmailDossierFactory.delete$(ng2EmailFactory.ngCreate($ctrl.email), ng2EmailDossierFactory.ngCreate(ngEmailDossier))
                        .subscribe(() => $ctrl.email.dossiers.splice(index, 1));
                } else {
                    $ctrl.email.dossiers.splice(index, 1);
                }
            }

            function removeRecipient(ngEmailRecipient: NgEmailRecipient) {
                const index = ($ctrl.email.recipients as NgEmailRecipient[]).findIndex(p => p._esk.uuid === ngEmailRecipient._esk.uuid);

                if ($ctrl.email.recipients[index].id) {
                    const email = {...$ctrl.email};

                    // Pas besoin pour l'opération ci-dessous et ça amenait des erreurs d'id non saisi
                    email.recipients = [];

                    ng2EmailRecipientFactory.delete$(ng2EmailFactory.ngCreate(email), ng2EmailRecipientFactory.ngCreate(ngEmailRecipient))
                        .subscribe(() => $ctrl.email.recipients.splice(index, 1));
                } else {
                    $ctrl.email.recipients.splice(index, 1);
                }
            }

            /**
             * toggle moreRecipients
             */
            function toggleMoreRecipients() {
                $ctrl.moreRecipients = (filter($ctrl.email.recipients, {type: EmailRecipient.types.CC}).length +
                    filter($ctrl.email.recipients, {type: EmailRecipient.types.BCC}).length) > 0;
            }

            /**
             * Update size attachments
             */
            function updateAttachmentsSize() {
                $ctrl.attachmentsSize = 0;
                angular.forEach($ctrl.email.attachments, attachment => $ctrl.attachmentsSize += attachment.media.totalBytes);
            }
        }
    })(angularJS);
}
