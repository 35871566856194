import {Component, inject, Input} from '@angular/core';
import Estimation from '@models/estimations/estimation/estimation.model';
import {RouterLink} from '@angular/router';
import {AppNoteoLogoNotestimComponent} from '@shared/noteo/logo/notestim/noteo-logo.notestim.component';
import {DictionaryItemService} from '@models/dictionaries/dictionary/items/item/dictionary-item.service';
import {LowerCasePipe} from '@angular/common';

@Component({
    imports: [AppNoteoLogoNotestimComponent, LowerCasePipe, RouterLink],
    selector: 'app-estimation-notestim-missing-data',
    templateUrl: 'estimation-notestim.missing-data.component.html',
})
export class EstimationNotestimMissingDataComponent {
    private _dictionaryItemService = inject(DictionaryItemService);
    private _estimation!: Estimation;
    private _labelSuperficie!: string;

    get estimation(): Estimation {
        return this._estimation;
    }

    @Input()
    set estimation(value: Estimation) {
        this._estimation = value;
        this._labelSuperficie = this._dictionaryItemService.getOneSuperficieLabel(this._estimation.bien.nature);
    }

    get labelSuperficie(): string {
        return this._labelSuperficie;
    }
}
