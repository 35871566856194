import {Component, Input} from '@angular/core';
import {INoteoIconTextOptions} from '@shared/noteo/noteo.interfaces';
import {NgClass, NgOptimizedImage} from '@angular/common';

// @todo Utiliser, comme pour Notestim, un composant AppNoteoLogoComponent
@Component({
    imports: [NgClass, NgOptimizedImage],
    selector: 'app-noteo-icon-text',
    templateUrl: 'noteo-icon-text.component.html',
})
export class AppNoteoIconTextComponent {
    static readonly initNoteoIconTextOptions: INoteoIconTextOptions = {
        loading: 'lazy',
        size: 20,
        verticalAlignClass: 'tw-align-baseline',
    };
    private _label!: string;
    private _options: INoteoIconTextOptions = {...AppNoteoIconTextComponent.initNoteoIconTextOptions};
    private _srcImage!: string;

    get label(): string {
        return this._label;
    }

    @Input()
    set label(value: string) {
        this._label = value;
    }

    get options(): INoteoIconTextOptions {
        return this._options;
    }

    @Input()
    set options(value: INoteoIconTextOptions) {
        this._options = {...AppNoteoIconTextComponent.initNoteoIconTextOptions, ...value};
    }

    get srcImage(): string {
        return this._srcImage;
    }

    @Input()
    set srcImage(value: string) {
        this._srcImage = value;
    }
}
