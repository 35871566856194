import {Component, inject, Input} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {SiteFactory} from '@models/sites/site/site.factory';
import Site from '@models/sites/site/site.model';

@Component({selector: 'app-site-convivial-name', standalone: false, templateUrl: 'site.convivial-name.component.html'})
export class AppSiteConvivialNameComponent {
    private _siteFactory = inject(SiteFactory);
    private _siteSource = new BehaviorSubject<Site>(undefined!);
    private _site$ = this._siteSource.asObservable();

    get site$(): Observable<Site> {
        return this._site$;
    }

    @Input()
    set linkSite(value: string) {
        this._siteFactory.getByLink$(value).pipe(take(1)).subscribe(site => this._siteSource.next(site));
    }

    @Input()
    set site(value: Site) {
        this._siteSource.next(value);
    }
}
