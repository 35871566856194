@if (collection$ | async; as collection) {
  @if (collection.total === 0) {
    <span class="tw-flex tw-items-center tw-h-full">Aucun {{ options.labelSingular }}</span>
  }

  @if (collection.total > 0) {
    <div class="tw-flex tw-space-x-2 tw-items-center tw-h-full">
      <div class="tw-flex tw--space-x-2 tw-h-full tw-overflow-hidden">
        @for (item of collection.results.slice(0, options.quantity); track item) {
          <div class="tw-relative tw-overflow-hidden tw-aspect-square tw-rounded-full tw-h-full tw-ring-2 tw-ring-white tw-bg-gray-100">
            @if (item[options.linkImageAttribute!] || options.linkDefault) {
              <img [ngSrc]="item[options.linkImageAttribute!] || options.linkDefault" class="tw-object-fill" fill>
            } @else {
              <div class="tw-flex tw-items-center tw-justify-center tw-h-full tw-w-full">
                <fa-icon [icon]="['fat', 'image']"/>
              </div>
            }
          </div>
        }
      </div>

      @if (remainingNumber(collection) > 0) {
        <span [ngPlural]="remainingNumber(collection)" class="tw-flex-1">
          <ng-template ngPluralCase="=1">+1 {{ options.labelSingular }}</ng-template>
          <ng-template ngPluralCase="other">+ {{ remainingNumber(collection) }} {{ options.labelPlural }}</ng-template>
        </span>
      }
    </div>
  }
} @else {
  <app-loader class="tw-block tw-h-full" [classHeight]="'tw-h-full'"/>
}
