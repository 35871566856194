import {inject, Injectable} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {CActualitesFactory} from '@models/actualites/collection/actualites.collection.factory';
import {map} from 'rxjs/operators';
import {DCActualiteViewComponent} from '@features/actualites/actualite/view/actualite-view.component';
import {ModalService} from '@shared/modal/modal.service';
import {ActualitesApiService} from '@models/actualites/actualites.api.service';
import DateFormat from '@shared/date/date.format';
import environment from '@env/environment';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';

@Injectable({providedIn: 'root'})
export class ActualitesService {
    private _actualitesApiService = inject(ActualitesApiService);
    private _browserOnlineService = inject(BrowserOnlineService);
    private _cActualitesFactory = inject(CActualitesFactory);
    private _modalService = inject(ModalService);

    show$(): Observable<boolean> {
        return this._browserOnlineService.isOn$.pipe(switchMap(isOn => {
            if (!isOn) {
                return of(false);
            }

            const popupDatetime = this._actualitesApiService.getPopupDatetime();

            if (popupDatetime && !DateFormat.isNowOlder(popupDatetime)) {
                return of(false);
            }

            return this._cActualitesFactory.get$({read: false}).pipe(
                map(cActualites => {
                    this.savePopupDatetime();
                    if (cActualites.total <= 0) {
                        return false;
                    }

                    // "setTimeout()" pour laisser le DOM se créer la première fois et avoir accès à AppModalComponent
                    setTimeout(() => this._modalService.open(DCActualiteViewComponent, {collection: cActualites}));

                    return true;
                }),
            );
        }));
    }

    savePopupDatetime(): void {
        const datetime = DateFormat.toJSON(new Date()).slice(0, -1);
        const popupDatetime = environment.sentry.environment === 'prod' ? DateFormat.addHoursToDateString(datetime, 5) : DateFormat.addSecondsToDateString(datetime, 20);

        this._actualitesApiService.savePopupDatetime(popupDatetime.slice(0, -1));
    }
}
