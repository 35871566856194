import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgAuthStart]'})
export class NgAuthStartDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskAuthStartCtrl', elementRef, injector);
    }
}

@Component({imports: [NgAuthStartDirective], selector: 'app-ng-auth-start', template: '<div appNgAuthStart></div>'})
export class NgAuthStartComponent {
}
