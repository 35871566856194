import {Component, inject} from '@angular/core';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';
import {Observable} from 'rxjs';
import {AppLoadObsPipe} from '@shared/load/obs/load-obs.pipe';
import {AsyncPipe} from '@angular/common';

@Component({
    imports: [AppLoadObsPipe, AsyncPipe],
    selector: 'app-browser-online-banner',
    templateUrl: 'browser.online-banner.component.html',
})
export class BrowserOnlineBannerComponent {
    private _browserOnlineService = inject(BrowserOnlineService);

    get isOn$(): Observable<boolean> {
        return this._browserOnlineService.isOn$;
    }

    openMoreInfos(): void {
        this._browserOnlineService.openInfosOffLine();
    }
}
