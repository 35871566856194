import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewAccessibiliteEnvironnementService
} from '@features/dossiers/dossier/bien/overview/accessibilite-environnement/dossier-bien-overview.accessibilite-environnement.service';

@Component({
    selector: 'app-dossier-bien-overview-accessibilite-environnement',
    standalone: false,
    templateUrl: 'dossier-bien-overview.accessibilite-environnement.component.html',
})
export class AppDossierBienOverviewAccessibiliteEnvironnementComponent {
    private _dossierBienOverviewAccessibiliteEnvironnementService = inject(DossierBienOverviewAccessibiliteEnvironnementService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isAccesHandicapeVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isAccesHandicapeVisible;
    }

    get isChambreRdcVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isChambreRdcVisible;
    }

    get isCommentsAccessibiliteEtEnvironnementVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isCommentsAccessibiliteEtEnvironnementVisible;
    }

    get isCommoditesVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isCommoditesVisible;
    }

    get isPieceEauRdcVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isPieceEauRdcVisible;
    }

    get isPlainPiedVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isPlainPiedVisible;
    }

    get isVuesVisible(): boolean {
        return this._dossierBienOverviewAccessibiliteEnvironnementService.isVuesVisible;
    }
}
