import {Component, inject, ViewChild} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {EtudeService} from '@models/etudes/etude/etude.service';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import {
    IDCTemplatesSelectData, IDCTemplatesSelectOptions, IDCTemplatesSelectResponse
} from '@features/templates/templates.interfaces';
import Template from '@models/templates/template/template.model';
import {map} from 'rxjs/operators';
import TemplateCategory from '@models/templates/template/category/template-category.model';
import {AppNoteoContactComponent} from '@shared/noteo/contact/noteo-contact.component';

@Component({selector: 'dc-templates-select', standalone: false, templateUrl: 'templates-select.component.html'})
export class DCTemplatesSelectComponent implements IDynamicComponent {
    @ViewChild('templatesForm') templatesForm!: NgForm;

    static readonly initDCTemplatesSelectOptions: IDCTemplatesSelectOptions = {
        buttonCreateLabel: 'Créer le document',
        inputDocumentLabel: 'Document',
        titre: 'Création d\'un document',
    };
    readonly codeCategories = TemplateCategory.codes;
    private _etudeService = inject(EtudeService);
    private _modalService = inject(ModalService);
    private _advertising!: string;
    private _courrierTemplate!: Template;
    private _courrierCategories: string[] = [];
    private _documentTemplate!: Template;
    private _documentCategories: string[] = [];
    private _options: IDCTemplatesSelectOptions = {...DCTemplatesSelectComponent.initDCTemplatesSelectOptions};

    get advertising(): string {
        return this._advertising;
    }

    set courrierTemplate(value: Template) {
        this._courrierTemplate = value;
    }

    get courrierCategories(): string[] {
        return this._courrierCategories;
    }

    set data(templatesSelectData: IDCTemplatesSelectData) {
        this._courrierCategories = templatesSelectData.courrierCategories ?? [];
        this._documentCategories = templatesSelectData.documentCategories ?? [];
        this._options = {...DCTemplatesSelectComponent.initDCTemplatesSelectOptions, ...templatesSelectData.options};
    }

    get documentCategories(): string[] {
        return this._documentCategories;
    }

    get moduleCityscan(): string {
        return AppNoteoContactComponent.modules.CITYSCAN;
    }

    get options(): IDCTemplatesSelectOptions {
        return this._options;
    }

    createDocument(): void {
        if (!this.templatesForm.valid) {
            return;
        }

        const response = {} as IDCTemplatesSelectResponse;

        response.courrierTemplate = this._courrierTemplate;
        response.documentTemplate = this._documentTemplate;

        this._modalService.setResponse(response);
    }

    selectDocumentTemplate(documentTemplate: Template): void {
        this._documentTemplate = documentTemplate;
        this._etudeService.last$.pipe(
            map(etude => this._documentTemplate.needAd(etude) ? this._documentTemplate.category.code : undefined!),
        ).subscribe(advertising => this._advertising = advertising);
    }
}
