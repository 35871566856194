import {NgModule, Type} from '@angular/core';
import {NgBienHeaderComponent} from '@shared/angularJS/up-ng/biens/bien-header.component';
import {NgBienEditHeaderComponent} from '@shared/angularJS/up-ng/biens/bien-edit-header.component';
import {NgBiensSearchComponent} from '@shared/angularJS/up-ng/biens/biens.search.component';
import {NgBienEditPhotosComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-photos.component';
import {NgBienEditVirtualVisitComponent} from '@shared/angularJS/up-ng/biens/biens-item-edit-virtual_visit.component';
import {NgBiensLastCriteriaComponent} from '@shared/angularJS/up-ng/biens/biens-last-criteria.component';
import {NgBiensWalletCurrentComponent} from '@shared/angularJS/up-ng/biens/biens-wallet-current.component';
import {NgBiensWalletDiffusionComponent} from '@shared/angularJS/up-ng/biens/biens-wallet-diffusion.component';
import {
    NgBiensWalletSearchCriteriaComponent
} from '@shared/angularJS/up-ng/biens/biens-wallet-search-criteria.component';
import {NgBienDetailsComponent} from '@shared/angularJS/up-ng/biens/biens-item-details.component';

const components: Type<unknown>[] = [
    NgBienEditHeaderComponent,
    NgBienEditPhotosComponent,
    NgBienEditVirtualVisitComponent,
    NgBienDetailsComponent,
    NgBienHeaderComponent,
    NgBiensLastCriteriaComponent,
    NgBiensSearchComponent,
    NgBiensWalletCurrentComponent,
    NgBiensWalletDiffusionComponent,
    NgBiensWalletSearchCriteriaComponent,
];

@NgModule({exports: components, imports: components})
export class BiensComponentsModule {
}
