import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {DossierTypesConst} from '@models/dossiers/dossiers.constants';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import {DossierType} from '@models/dossiers/dossiers.interfaces';
import {BrowserOnlineService} from '@shared/browser/online/browser.online.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DossierAccessGuardService {
    private _browserOnlineService = inject(BrowserOnlineService);
    private _dossierService = inject(DossierService);
    private _router = inject(Router);

    canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean | UrlTree> {
        const dossierType = this._dossierService.getTypeFromUrl(routerStateSnapshot.url);
        const dossierId = this.getDossierId(activatedRouteSnapshot, dossierType);

        if (dossierId === 'item') {
            return of(true);
        }

        return this._browserOnlineService.checkAndPrevent$().pipe(
            map(isOn => {
                if (!isOn) {
                    return false;
                }

                if (+dossierId > 0) {
                    return true;
                }

                return this._router.parseUrl(`/app/${dossierType}s/item`);
            })
        );
    }

    getDossierId(activatedRouteSnapshot: ActivatedRouteSnapshot, dossierType: DossierType): string {
        if (dossierType === DossierTypesConst.DEMANDEUR) {
            return activatedRouteSnapshot.params.demandeurId;
        } else if (dossierType === DossierTypesConst.ESTIMATION) {
            return activatedRouteSnapshot.params.estimationId;
        } else if (dossierType === DossierTypesConst.LOCATION) {
            return activatedRouteSnapshot.params.locationId;
        } else if (dossierType === DossierTypesConst.VENTE) {
            return activatedRouteSnapshot.params.venteId;
        }

        return null!;
    }
}
