import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {VenteService} from '@models/ventes/vente/vente.service';
import Vente from '@models/ventes/vente/vente.model';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {filter, map, takeUntil} from 'rxjs/operators';
import {
    VenteOverviewActionsMainComponent
} from '@features/ventes/vente/actions/overview/vente-overview.actions-main.component';
import {VenteOverviewService} from '@models/ventes/vente/vente-overview.service';
import {Router} from '@angular/router';
import {IRoutingNavigateByUrl} from '@shared/routes/routes.interfaces';

@Component({
    selector: 'layout-search-vente-overview',
    standalone: false,
    templateUrl: 'layout-search-vente-overview.component.html',
})
export class AppLayoutSearchVenteOverviewComponent implements OnDestroy, OnInit {
    private _callToActionService = inject(CallToActionService);
    private _router = inject(Router);
    private _venteOverviewService = inject(VenteOverviewService);
    private _venteService = inject(VenteService);
    private _infosConfidentialWantedSource = new Subject<void>();
    private _infosConfidentialWanted$ = this._infosConfidentialWantedSource.asObservable();
    private readonly _onDestroy$ = new Subject<void>();

    get infosConfidentialWanted$(): Observable<void> {
        return this._infosConfidentialWanted$;
    }

    get vente$(): Observable<Vente> {
        return this._venteService.current$.pipe(filter(vente => !!vente));
    }

    ngOnInit(): void {
        this._callToActionService.clicked$.pipe(
            switchMap(callToActionClicked => {
                if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.ADVANCED_PRINT) {
                    return this._callToActionService.actionExec$(this.advancedPrint$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.CREATE_BON_VISITE) {
                    return this._callToActionService.actionExec$(this.createBonvisite$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.CREATE_OFFRE_ACHAT) {
                    return this._callToActionService.actionExec$(this.createOffreachat$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.MANAGE_DIFFUSIONS) {
                    return this._callToActionService.actionExec$(this.manageDiffusions$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.PRINT) {
                    return this._callToActionService.actionExec$(this.print$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.PRINT_AFFICHETTES) {
                    return this._callToActionService.actionExec$(this.printAffichettes$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.SEE_MATCHED_DEMANDEURS) {
                    return this._callToActionService.actionExec$(this.seeMatchedDemandeurs$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.SEND_FICHE) {
                    return this._callToActionService.actionExec$(this.sendFiche$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.SEND_TO_CONFRERE) {
                    return this._callToActionService.actionExec$(this.sendToConfrere$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.UNLOCK) {
                    return this._callToActionService.actionExec$(this.unlock$());
                } else if (callToActionClicked.action === VenteOverviewActionsMainComponent.actions.UPDATE) {
                    return this._callToActionService.actionExec$(this.update$());
                }

                return of(undefined!);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(routing => {
            if (routing) {
                this._router.navigateByUrl(routing.url, routing.extras);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    advancedPrint$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.advancedPrint$(vente)));
    }

    createBonvisite$(): Observable<IRoutingNavigateByUrl> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.createBonvisite$(vente)));
    }

    createOffreachat$(): Observable<IRoutingNavigateByUrl> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.createOffreachat$(vente)));
    }

    manageDiffusions$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.manageDiffusions$(vente)));
    }

    print$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.print$(vente)));
    }

    printAffichettes$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.printAffichettes$(vente)));
    }

    seeMatchedDemandeurs$(): Observable<IRoutingNavigateByUrl> {
        return this.vente$.pipe(
            switchMap(vente => this._venteOverviewService.seeMatchedDemandeurs$(vente)),
            map(url => ({url})),
        );
    }

    sendFiche$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.sendFiche$(vente)));
    }

    sendToConfrere$(): Observable<void> {
        return this.vente$.pipe(switchMap(vente => this._venteOverviewService.sendToConfrere$(vente)));
    }

    unlock$(): Observable<void> {
        this._infosConfidentialWantedSource.next();

        return of(undefined);
    }

    update$(): Observable<IRoutingNavigateByUrl> {
        return this.vente$.pipe(
            switchMap(vente => this._venteOverviewService.update$(vente)),
            map(url => ({url})),
        );
    }
}
