import {Component, inject, Input, output} from '@angular/core';
import Etude from '@models/etudes/etude/etude.model';
import {INoteoContactOptions} from '@shared/noteo/noteo.interfaces';
import {UserFactory} from '@models/users/user/user.factory';
import {take, tap} from 'rxjs/operators';
import {ToasterService} from '@shared/toaster/toaster.service';
import {NgOptimizedImage} from '@angular/common';

@Component({
    imports: [NgOptimizedImage],
    selector: 'app-noteo-contact',
    styleUrl: 'noteo-contact.component.scss',
    templateUrl: 'noteo-contact.component.html',
})
export class AppNoteoContactComponent {
    static readonly initNoteoContactOptions: INoteoContactOptions = {withModuleInactif: true};
    static readonly modules = {
        CITYSCAN: Etude.enablableModules.CITYSCAN.code,
        INOT: Etude.enablableModules.INOT.code,
        NOTESTIM: Etude.enablableModules.NOTESTIM.code,
    };
    readonly contacted = output<void>();
    private _toasterService = inject(ToasterService);
    // @todo Cette injection n'est pas normale, ce qu'il y a dans `src/shared` ne doit pas utiliser du code de `src/core`
    private _userFactory = inject(UserFactory);
    private _module!: string;
    private _labelModule!: string;
    private _options: INoteoContactOptions = {...AppNoteoContactComponent.initNoteoContactOptions};

    get labelModule(): string {
        return this._labelModule;
    }

    @Input({required: true})
    set module(value: string) {
        this._module = value;
        if (this._module === AppNoteoContactComponent.modules.CITYSCAN) {
            this._labelModule = Etude.enablableModules.CITYSCAN.label;
        } else if (this._module === AppNoteoContactComponent.modules.INOT) {
            this._labelModule = Etude.enablableModules.INOT.label;
        } else if (this._module === AppNoteoContactComponent.modules.NOTESTIM) {
            this._labelModule = Etude.enablableModules.NOTESTIM.label;
        } else {
            this._labelModule = undefined!;
        }
    }

    get options(): INoteoContactOptions {
        return this._options;
    }

    @Input()
    set options(value: INoteoContactOptions) {
        this._options = {...AppNoteoContactComponent.initNoteoContactOptions, ...value};
    }

    askToBeContacted(): void {
        this._userFactory.askInfosModule$(this._labelModule).pipe(tap(_ => this.contacted.emit()), take(1))
            .subscribe(() => this._toasterService.success('Demande d\'informations', 'Votre demande d\'informations sur le module "' + this._labelModule + '" est envoyée à Noteo.'));
    }
}
