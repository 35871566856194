import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import Vente from '@models/ventes/vente/vente.model';
import {
    DossierBienOverviewMainInfosService
} from '@features/dossiers/dossier/bien/overview/main-infos/dossier-bien-overview.main-infos.service';
import {
    IDossierBienOverviewMainInfo, IDossierBienOverviewMainInfosOptions
} from '@features/dossiers/dossier/bien/overview/dossier-bien-overview.interfaces';
import Location from '@models/locations/location/location.model';

@Component({
    selector: 'app-dossier-bien-overview-main-infos',
    standalone: false,
    templateUrl: 'dossier-bien-overview.main-infos.component.html',
})
export class AppDossierBienOverviewMainInfosComponent {
    static readonly initDossierBienOverviewMainInfosOptions: IDossierBienOverviewMainInfosOptions = {
        showReference: true,
    };
    private _dossierBienOverviewMainInfosService = inject(DossierBienOverviewMainInfosService);
    private _dossierBien!: ADossierBien;
    private _isInfosConfidentialVisible = false;
    private _options: IDossierBienOverviewMainInfosOptions = {...AppDossierBienOverviewMainInfosComponent.initDossierBienOverviewMainInfosOptions};

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isInfosConfidentialVisible(): boolean {
        return this._isInfosConfidentialVisible;
    }

    @Input({required: true})
    set isInfosConfidentialVisible(value: boolean) {
        this._isInfosConfidentialVisible = value;
    }

    get FIELDS(): Record<string, string> {
        return DossierBienOverviewMainInfosService.fields;
    }

    get location(): Location {
        return this._dossierBien as Location;
    }

    get mainInfos(): IDossierBienOverviewMainInfo[] {
        return this._dossierBienOverviewMainInfosService.mainInfos;
    }

    get options(): IDossierBienOverviewMainInfosOptions {
        return this._options;
    }

    @Input()
    set options(value: IDossierBienOverviewMainInfosOptions) {
        this._options = {...AppDossierBienOverviewMainInfosComponent.initDossierBienOverviewMainInfosOptions, ...value};
    }

    get vente(): Vente {
        return this._dossierBien as Vente;
    }
}
