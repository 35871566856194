import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {of, Subject, switchMap} from 'rxjs';
import {OffreachatService} from '@models/offreachats/offreachat/offreachat.service';
import {map, takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import {IOffreachatDropdownDataOptions} from '@features/offreachats/offreachats.interfaces';
import {OffreachatDropdownComponent} from '@features/offreachats/item/dropdown/offreachat.dropdown.component';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {DossierService} from '@models/dossiers/dossier/dossier.service';
import { MediaResumeService } from '@core/models/medias/media/media-resume.service';

@Component({
    selector: 'layout-offreachats-wallet',
    standalone: false,
    templateUrl: 'layout-offreachats-wallet.component.html',
})
export class AppLayoutOffreachatsWalletComponent implements OnDestroy, OnInit {
    private _dossierService = inject(DossierService);
    private _dropdownService = inject(DropdownService);
    private _offreachatService = inject(OffreachatService);
    private _mediaResumeService = inject(MediaResumeService);
    private _router = inject(Router);
    private _demandeurUuids: string[] = [];
    private readonly _onDestroy$ = new Subject<void>();
    private _venteUuids: string[] = [];

    get demandeurUuids(): string[] {
        return this._demandeurUuids;
    }

    get venteUuids(): string[] {
        return this._venteUuids;
    }

    ngOnInit(): void {
        const currentDossier = this._dossierService.getCurrentFromNg(this._router.url);

        if (currentDossier?.isDemandeur()) {
            this._demandeurUuids = [currentDossier.uuid];
        } else if (currentDossier?.isVente()) {
            this._venteUuids = [currentDossier.uuid];
        } else {
            throw new Error('"currentDossier.dossierType" inconnu : ' + currentDossier?.dossierType);
        }

        this._dropdownService.clicked$.pipe(
            switchMap(dropdownClicked => {
                const offreachat = dropdownClicked.value as Offreachat;

                if (dropdownClicked.action === OffreachatDropdownComponent.actions.ARCHIVE) {
                    return this._offreachatService.archive$(offreachat).pipe(switchMap(isArchived => {
                        if (isArchived) {
                            return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/offres-achat/portefeuille');
                        }

                        return of(undefined);
                    }));
                } else if (dropdownClicked.action === OffreachatDropdownComponent.actions.EDIT) {
                    return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/offres-achat/' + offreachat.uuid + '/edit');
                } else if (dropdownClicked.action === OffreachatDropdownComponent.actions.PRINT) {
                    return this._mediaResumeService.readFromOffreachat$(offreachat).pipe(map(_ => undefined));
                } else if (dropdownClicked.action === OffreachatDropdownComponent.actions.RESUME) {
                    return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/offres-achat/' + offreachat.uuid + '/resume');
                } else if (dropdownClicked.action === OffreachatDropdownComponent.actions.SIGN) {
                    if (offreachat.isSign()) {
                        return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/offres-achat/' + offreachat.uuid + '/sign');
                    } else {
                        return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/offres-achat/' + offreachat.uuid + '/signature');
                    }
                } else if (dropdownClicked.action === OffreachatDropdownComponent.actions.WRITE) {
                    return of('/app/' + currentDossier.dossierType + 's/' + currentDossier.id.toString() + '/offres-achat/' + offreachat.uuid + '/redaction');
                }

                return of(undefined);
            }),
            takeUntil(this._onDestroy$),
        ).subscribe(url => {
            if (url) {
                this._router.navigateByUrl(url);
            }
        });
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    onListClick([htmlButtonElement, offreachat]: [HTMLButtonElement, Offreachat]): void {
        const options: IOffreachatDropdownDataOptions = {
            enabledActions: {
                archive: !offreachat.isArchive(),
                edit: offreachat.isEdit(),
                print: offreachat.isWrite() || offreachat.isSign() || offreachat.isSignature() || offreachat.isArchive(),
                resume: offreachat.isArchive(),
                sign: offreachat.isSign() || offreachat.isSignature(),
                write: offreachat.isWrite(),
            },
        };

        this._dropdownService.open(htmlButtonElement, {
            component: OffreachatDropdownComponent,
            data: {offreachat, options},
        });
    }
}
