import {Component, inject, Input, output, ViewChild} from '@angular/core';
import Commune from '@models/communes/commune/commune.model';
import {map, take, tap} from 'rxjs/operators';
import {CCommunesService} from '@models/communes/collection/communes.collection.service';
import {NgModel} from '@angular/forms';
import {AppFormSelectInputComponent} from '@shared/form/select/input/form.select-input.component';
import {Observable, ReplaySubject} from 'rxjs';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {ICommuneSelectOptions} from '@features/communes/communes.interfaces';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {AsyncPipe} from '@angular/common';
import {AppLoadObsPipe} from '@shared/load/obs/load-obs.pipe';

@Component({
    exportAs: 'communeSelect',
    imports: [AppFormSelectInputComponent, AppLoaderComponent, AppLoadObsPipe, AsyncPipe],
    selector: 'app-commune-select',
    templateUrl: 'commune-select.component.html',
})
export class AppCommuneSelectComponent {
    static readonly initCommuneSelectOptions: ICommuneSelectOptions = {
        bindLabel: 'convivialName',
        excludedCodeInsees: [],
        loadingText: 'Chargement des communes',
        name: 'communeSelect',
        notFoundText: 'Aucune commune trouvée',
        placeholder: 'Rechercher une commune par son nom ou son code postal',
    };
    readonly selected = output<Commune>();
    private _cCommunesService = inject(CCommunesService);
    private _communeFactory = inject(CommuneFactory);
    private _formInput!: NgModel;
    private _loading = false;
    private _options: ICommuneSelectOptions = {...AppCommuneSelectComponent.initCommuneSelectOptions};
    private _communeSource = new ReplaySubject<Commune>();
    private _commune$ = this._communeSource.asObservable();
    private _communes: Commune[] = [];

    get commune$(): Observable<Commune> {
        return this._commune$;
    }

    @Input()
    set commune(value: Commune) {
        this._communeSource.next(value);
    }

    get communes(): Commune[] {
        return this._communes;
    }

    @ViewChild('communeSelectInput')
    set communesSelectInput(value: AppFormSelectInputComponent) {
        setTimeout(_ => this._formInput = value?.formInput, 1);
    }

    get formInput(): NgModel {
        return this._formInput;
    }

    @Input()
    set id(value: number) {
        if (value) {
            this._communeFactory.get$(value).pipe(take(1)).subscribe(commune => this._communeSource.next(commune));
        } else {
            this._communeSource.next(undefined as unknown as Commune);
        }
    }

    @Input()
    set link(value: string) {
        if (value) {
            this._communeFactory.getByLink$(value).pipe(take(1)).subscribe(commune => this._communeSource.next(commune));
        } else {
            this._communeSource.next(undefined!);
        }
    }

    get loading(): boolean {
        return this._loading;
    }

    get options(): ICommuneSelectOptions {
        return this._options;
    }

    @Input()
    set options(value: ICommuneSelectOptions) {
        this._options = {...AppCommuneSelectComponent.initCommuneSelectOptions, ...value};
    }

    onSelect(commune: unknown): void {
        this.selected.emit(commune as Commune);
    }

    search(search: string): void {
        this._communes = [];
        this._loading = true;
        this._cCommunesService.search$(search).pipe(
            map(cCommunes => cCommunes.results),
            tap(communes => this._communes = communes.filter(commune => !this._options.excludedCodeInsees?.includes(commune.codeInsee))),
            take(1),
        ).subscribe(_ => this._loading = false);
    }
}
