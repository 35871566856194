import {NgModule, Type} from '@angular/core';
import {NgVenteCorrespondantsComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-correspondants.component';
import {NgVenteDiffusionComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-diffusion.component';
import {NgVenteLifetimeComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-lifetime.component';
import {NgVenteMandatsComponent} from '@shared/angularJS/up-ng/ventes/ventes-item-mandats.component';
import {NgVentesNegocieesEtudesComponent} from '@shared/angularJS/up-ng/ventes/ventes-negociees-etudes.component';
import {NgVentesNegocieesNaturesComponent} from '@shared/angularJS/up-ng/ventes/ventes-negociees-natures.component';
import {
    NgVentesNetworkUpdatedCriteriaComponent
} from '@shared/angularJS/up-ng/ventes/ventes-network-updated-criteria.component';

const components: Type<unknown>[] = [
    NgVenteCorrespondantsComponent,
    NgVenteDiffusionComponent,
    NgVenteLifetimeComponent,
    NgVenteMandatsComponent,
    NgVentesNegocieesEtudesComponent,
    NgVentesNegocieesNaturesComponent,
    NgVentesNetworkUpdatedCriteriaComponent,
];

@NgModule({exports: components, imports: components})
export class VentesComponentsModule {
}
