import {Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import SignaturePad from 'signature_pad';
import {Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {ISignatureOptions} from '@shared/signature/signature.interfaces';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {NgClass} from '@angular/common';

@Component({
    imports: [FaIconComponent, NgClass],
    selector: 'app-signature',
    styleUrls: ['signature.component.scss'],
    templateUrl: 'signature.component.html',
})
export class AppSignatureComponent implements OnDestroy, OnInit {
    @Output() readonly signature = new EventEmitter<string>();
    // Impossible de passer avec la ligne ci-dessous, une erreur ci-dessous apparait
    // Readonly signature = output<string>();

    static readonly initSignatureOptions: ISignatureOptions = {withDate: true};
    static readonly DRAWING = 'drawing';
    static readonly NONE = 'none';
    private _window: Window;
    private _addedText!: string;
    private _canErase = false;
    private _canvasElement!: HTMLCanvasElement;
    private _hasSignature = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: ISignatureOptions = {...AppSignatureComponent.initSignatureOptions};
    private _signaturePad!: SignaturePad;

    constructor(@Inject('Window') window: Window) {
        this._window = window;
    }

    @Input()
    set addedText(value: string) {
        this._addedText = value;
        this.clear();
    }

    get canErase(): boolean {
        return this._canErase;
    }

    get hasSignature(): boolean {
        return this._hasSignature;
    }

    get options(): ISignatureOptions {
        return this._options;
    }

    @Input()
    set options(value: ISignatureOptions) {
        this._options = {...AppSignatureComponent.initSignatureOptions, ...value};
    }

    @ViewChild('signatureCanvas')
    set signatureCanvas(elementRef: ElementRef<HTMLCanvasElement>) {
        this._canvasElement = elementRef.nativeElement;
        this._canvasElement.width = this._canvasElement.offsetWidth;
        this._canvasElement.height = this._canvasElement.offsetHeight;
        this._signaturePad = new SignaturePad(this._canvasElement);
        this._signaturePad.addEventListener('beginStroke', _ => this.signature.emit(AppSignatureComponent.DRAWING));
        this._signaturePad.addEventListener('endStroke', _ => this.signature.emit(this._signaturePad.toDataURL()));
        this.clear();
    }

    ngOnInit(): void {
        this.signature.pipe(
            tap(signature => this._canErase = ![AppSignatureComponent.NONE, AppSignatureComponent.DRAWING].includes(signature)),
            tap(signature => this._hasSignature = signature !== AppSignatureComponent.NONE),
            takeUntil(this._onDestroy$),
        ).subscribe();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    clear(): void {
        if (!this._signaturePad) {
            return;
        }

        this._signaturePad.clear();
        this.printAddedText();
        this.signature.emit(AppSignatureComponent.NONE);
    }

    printAddedText(): void {
        const canvasContext = this._canvasElement.getContext('2d')!;
        const fontSizeInitial = 20;
        const xInitial = 5;
        const yInitial = fontSizeInitial + 2;
        const fitWidth = this._canvasElement.width - 2 * xInitial;
        const lineHeight = yInitial + 2;
        let currentLine = 0;
        let idx = 1;
        let words = this._addedText?.split(' ') || [];

        canvasContext.font = fontSizeInitial.toString() + 'px Arial';
        canvasContext.fillStyle = this._window.getComputedStyle(this._canvasElement).color;
        while (idx <= words.length) {
            const str = words.slice(0, idx).join(' ');
            const width = canvasContext.measureText(str).width;

            if (width > fitWidth) {
                if (idx === 1) {
                    idx = 2;
                }

                canvasContext.fillText(words.slice(0, idx - 1).join(' '), xInitial, yInitial + (lineHeight * currentLine));
                currentLine++;
                words = words.splice(idx - 1);
                idx = 1;
            } else {
                idx++;
            }
        }

        if (idx > 0) {
            canvasContext.fillText(words.join(' '), xInitial, yInitial + (lineHeight * currentLine));
            currentLine++;
        }

        if (this._options.withDate) {
            // @todo Utilisation de DateFormat ?
            canvasContext.fillText('Le ' + (new Date()).toLocaleDateString(), xInitial, yInitial + (lineHeight * currentLine));
        }
    }
}
