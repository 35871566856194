import {Component} from '@angular/core';
import {AppPublicitesComponent} from '@features/publicites/publicites.component';

@Component({
    selector: 'layout-ventes-publicites',
    standalone: false,
    templateUrl: 'layout-ventes.publicites.component.html',
})
export class AppLayoutVentesPublicitesComponent {
    get PUBLICITES_VENTES(): string {
        return AppPublicitesComponent.modules.VENTES;
    }
}
