import {NgModule} from '@angular/core';
import {
    AppContactsGroupConvivialNameComponent
} from '@features/contacts-group/convivial-name/contacts-group.convivial-name.component';
import {
    AppContactsGroupNumberContactsComponent
} from '@features/contacts-group/number-contacts/contacts-group.number-contacts.component';

@NgModule({
    exports: [AppContactsGroupConvivialNameComponent, AppContactsGroupNumberContactsComponent],
    imports: [AppContactsGroupConvivialNameComponent, AppContactsGroupNumberContactsComponent],
})
export class ContactsGroupModule {
}
