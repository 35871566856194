import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskimmoDashboard]'})
export class NgEskimmoDashboardDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDashboardCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgEskimmoDashboardDirective],
    selector: 'app-ng-eskimmo-dashboard',
    template: '<div appNgEskimmoDashboard></div>',
})
export class NgEskimmoDashboardComponent {
}
