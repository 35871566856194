import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgVenteLifetime]'})
export class NgVenteLifetimeDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskVenteLifetimeCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgVenteLifetimeDirective],
    selector: 'app-ng-vente-lifetime',
    template: '<div appNgVenteLifetime></div>',
})
export class NgVenteLifetimeComponent {
}
