import {Component, Directive, ElementRef, Injector, Input} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskButtonSave]'})
export class NgEskimmoButtonSaveDirective extends UpgradeComponent {
    @Input() saving!: boolean;

    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskButtonSave', elementRef, injector);
    }
}

@Component({
    imports: [NgEskimmoButtonSaveDirective],
    selector: 'esk-button-save',
    template: '<div appNgEskButtonSave [saving]="saving"></div>',
})
export class NgEskimmoButtonSaveComponent {
    @Input() saving!: boolean;
}
