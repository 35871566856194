import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable} from 'rxjs';
import {EtudeFactory} from '@models/etudes/etude/etude.factory';
import {filter, map, switchMap, take, tap} from 'rxjs/operators';
import Etude from '@models/etudes/etude/etude.model';
import {
    CEtudeConstraintsFactory
} from '@models/etudes/etude/constraints/collection/etude-constraints.collection.factory';
import {CEtudeNetworksService} from '@models/etudes/etude/networks/collection/etude-networks.collection.service';
import {
    CEtudePasserellesFactory
} from '@models/etudes/etude/passerelles/collection/etude-passerelles.collection.factory';
import {EtudeSettingsNetworkFactory} from '@models/etudes/etude/settings/network/etude-settings-network.factory';
import {UserService} from '@models/users/user/user.service';
import {ErrorConfigurationService} from '@core/error/error.configuration.service';
import {EtudeSettingsGenapiFactory} from '@models/etudes/etude/settings/genapi/etude-settings-genapi.factory';
import {EtudeSettingsCityscanFactory} from '@models/etudes/etude/settings/cityscan/etude-settings-cityscan.factory';
import {TemplatesService} from '@models/templates/templates.service';
import {EtudeSettingsBannerService} from '@models/etudes/etude/settings/banner/etude-settings-banner.service';
import Transfer from '@models/transfers/transfer/transfer.model';
import {EtudeSettingsBannerFactory} from '@models/etudes/etude/settings/banner/etude-settings-banner.factory';
import {IEtudeSettingsBannerPositionApi} from '@models/etudes/etude/settings/banner/etude-settings-banner.interfaces';
import {EtudeSettingsLogoFactory} from '@models/etudes/etude/settings/logo/etude-settings-logo.factory';
import {EtudeSettingsLogoService} from '@models/etudes/etude/settings/logo/etude-settings-logo.service';
import {CSitesService} from '@models/sites/collection/sites.collection.service';

@Injectable({providedIn: 'root'})
export class EtudeService {
    private _cEtudeConstraintsFactory = inject(CEtudeConstraintsFactory);
    private _cEtudeNetworksService = inject(CEtudeNetworksService);
    private _cEtudePasserellesFactory = inject(CEtudePasserellesFactory);
    private _cSitesService = inject(CSitesService);
    private _errorConfigurationService = inject(ErrorConfigurationService);
    private _etudeFactory = inject(EtudeFactory);
    private _etudeSettingsBannerFactory = inject(EtudeSettingsBannerFactory);
    private _etudeSettingsBannerService = inject(EtudeSettingsBannerService);
    private _etudeSettingsCityscanFactory = inject(EtudeSettingsCityscanFactory);
    private _etudeSettingsGenapiFactory = inject(EtudeSettingsGenapiFactory);
    private _etudeSettingsLogoFactory = inject(EtudeSettingsLogoFactory);
    private _etudeSettingsLogoService = inject(EtudeSettingsLogoService);
    private _etudeSettingsNetworkFactory = inject(EtudeSettingsNetworkFactory);
    private _templatesService = inject(TemplatesService);
    private _userService = inject(UserService);
    private _currentSource = new BehaviorSubject<Etude>(undefined!);

    get current$(): Observable<Etude> {
        return this._currentSource.asObservable();
    }

    get last$(): Observable<Etude> {
        return this.current$.pipe(filter(currentEtude => !!currentEtude), take(1));
    }

    deleteBannerCurrent$(): Observable<void> {
        return this.last$.pipe(switchMap(currentEtude => this._etudeSettingsBannerFactory.delete$(currentEtude).pipe(tap(_ => currentEtude.linkBanner = null!))));
    }

    deleteLogoCurrent$(): Observable<void> {
        return this.last$.pipe(switchMap(currentEtude => this._etudeSettingsLogoFactory.delete$(currentEtude).pipe(tap(_ => currentEtude.linkLogo = null!))));
    }

    initCurrent(): void {
        this._currentSource.next(undefined!);
        this._etudeFactory.getCurrent$().pipe(
            switchMap(etude => {
                this._cSitesService.initCurrent(etude.linkSites);

                return this._cSitesService.current$.pipe(filter(cSites => !!cSites), map(_ => etude));
            }),
            switchMap(etude => {
                // Voir si pas mieux de conserver la notion de collection
                return forkJoin([
                    this._cEtudeConstraintsFactory.get$(etude).pipe(map(cEtudeConstraints => etude.constraints = cEtudeConstraints.results)),
                    this._cEtudeNetworksService.get$().pipe(map(cEtudeNetworks => etude.networks = cEtudeNetworks.results)),
                    this._cEtudePasserellesFactory.get$().pipe(map(cEtudePasserelles => etude.passerelles = cEtudePasserelles.results)),
                    this._etudeSettingsCityscanFactory.get$(etude).pipe(map(etudeSettingsCityscan => etude.settingsCityscan = etudeSettingsCityscan)),
                    this._etudeSettingsGenapiFactory.get$(etude).pipe(map(etudeSettingsGenapi => etude.settingsGenapi = etudeSettingsGenapi)),
                    this._etudeSettingsNetworkFactory.get$(etude).pipe(map(etudeSettingsNetwork => etude.settingsNetwork = etudeSettingsNetwork)),
                    this._templatesService.update$(etude.defaultTemplates),
                ]).pipe(map(_ => etude));
            }),
            switchMap(currentEtude => this._userService.last$.pipe(
                tap(currentUser => this._errorConfigurationService.setUser(currentUser, currentEtude)),
                map(_ => currentEtude),
            )),
            take(1),
        ).subscribe(currentEtude => this._currentSource.next(currentEtude));
    }

    logout(): void {
        this._currentSource.next(undefined!);
    }

    updateBannerPositionCurrent$(etudeSettingsBannerPositionApi: IEtudeSettingsBannerPositionApi): Observable<void> {
        return this.last$.pipe(
            switchMap(currentEtude => this._etudeSettingsBannerFactory.updatePosition$(currentEtude, etudeSettingsBannerPositionApi)),
            switchMap(_ => this.updateLinksCurrent$()),
        );
    }

    updateLinksCurrent$(): Observable<void> {
        return this.last$.pipe(
            switchMap(currentEtude => this._etudeFactory.getCurrent$().pipe(
                tap(currentEtudeUpdated => currentEtude.linkBanner = currentEtudeUpdated.linkBanner),
                tap(currentEtudeUpdated => currentEtude.linkLogo = currentEtudeUpdated.linkRawLogo),
            )),
            map(_ => undefined),
        );
    }

    uploadBannerCurrent$(file: File): Observable<Transfer<void>> {
        return this.last$.pipe(
            switchMap(currentEtude => this._etudeSettingsBannerService.upload$(currentEtude, file)),
            switchMap(_ => this._etudeSettingsBannerService.currentTransfer$.pipe(
                tap(bannerTransfer => {
                    bannerTransfer.race$.subscribe({complete: () => this.updateLinksCurrent$().pipe(take(1)).subscribe()});
                }),
            )),
        );
    }

    uploadLogoCurrent$(file: File): Observable<Transfer<void>> {
        return this.last$.pipe(
            switchMap(currentEtude => this._etudeSettingsLogoService.upload$(currentEtude, file)),
            switchMap(_ => this._etudeSettingsLogoService.currentTransfer$.pipe(
                tap(logoTransfer => {
                    logoTransfer.race$.subscribe({complete: () => this.updateLinksCurrent$().pipe(take(1)).subscribe()});
                }),
            )),
        );
    }
}
