import angularJS from '@shared/angularJS/global.ng';
import {IModule, IQService} from 'angular';
import {IImageLoadError} from '@shared/image/image.interfaces';
import {ToasterService} from '@shared/toaster/toaster.service';

export default function getSoqInputPhotos(module: IModule): void {
    (angular => {
        'use strict';

        /**
         * @example <soq-input-photos></soq-input-photos>
         */
        module.component('soqInputPhotos', {
            bindings: {getInputPhoto: '=', options: '=?'},
            controller: Controller,
            templateUrl: 'src/app/legacy/templates/soqrate/components/input-photos.html'
        });

        /**
         * Get selected photos
         *
         * @param $q
         * @param Ng2ToasterService
         */
        Controller.$inject = ['$q', 'Ng2ToasterService'];
        function Controller(this: any, $q: IQService, ng2ToasterService: ToasterService) {
            const $ctrl = this;

            $ctrl.onChange = onChange;

            /**
             * Get photos
             */
            function onChange(files: File[]) {
                const images: any[] = [{promise: $q.resolve()}];

                files.forEach(file => {
                    const deferred = $q.defer();
                    const image = {file: file, reader: new FileReader(), promise: deferred.promise};

                    image.reader.onload = evt => {
                        const objImg = new Image();

                        // Se référer au code présent dans ImageService.load$ pour ce "onerror"
                        objImg.onerror = errorEvent => {
                            const error: IImageLoadError = {event: errorEvent as Event, file, message: `L'image "${file.name}" n'est pas compatible avec votre navigateur.`};

                            ng2ToasterService.error('Sélection d\'une image', error.message);
                            deferred.reject(error);
                        };
                        objImg.onload = () => {
                            $ctrl.getInputPhoto({
                                desktopFile: file,
                                width: objImg.width,
                                height: objImg.height,
                            });
                            deferred.resolve();
                        };
                        objImg.src = (evt as {target: {result: string}}).target.result;
                    };
                    images[images.length - 1].promise.finally(() => image.reader.readAsDataURL(image.file));
                    images.push(image);
                });
            }
        }
    })(angularJS);
}
