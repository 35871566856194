export default `
<div class="list-contacts">
  <div ng-if="$ctrl.contacts.totalItems > 0 && !!$ctrl.options.nameDossiers"
       class="padding-bottom-10 list-infos hidden-print text-justify">
    {{$ctrl.options.nameDossiers | translate:'{NB: ' + $ctrl.contacts.totalItems + '}'}}
  </div>

  <ng-switch on="$ctrl.options.loader.updating">
    <p class="tw-text-center text-extra-large" ng-switch-when="true">
      <fa-icon [icon]="'spinner'" animation="spin"></fa-icon> Mise à jour de vos contacts
    </p>

    <div ng-switch-default ng-if="$ctrl.contacts.totalItems > 0">
      <div class="table-responsive">
        <table class="table table-hover table-contacts !tw-mb-12">
          <thead>
          <tr>
            <th ng-if="$ctrl.options.visibleFields.select">
              <ng-include src="'src/app/legacy/templates/eskimmo/components/list/select.html'" class="select-all">
              </ng-include>
            </th>

            <th></th>

            <th class="tw-text-center">{{"Nom / Raison sociale" | translate}}</th>

            <th class="tw-text-center">{{"Téléphone / Mobile" | translate}}</th>

            <th class="tw-text-center">{{"Email" | translate}}</th>

            <th ng-if="$ctrl.options.visibleFields.edition"></th>
          </tr>
          </thead>

          <tr class="line" ng-repeat="contact in $ctrl.contacts.collection">
            <td ng-if="$ctrl.options.visibleFields.select" class="col-select hidden-print">
              <div class="checkbox clip-check check-primary">
                <input type="checkbox" id="table-contacts-select-{{contact.id}}" ng-model="contact._esk.select"
                       ng-change="$ctrl.onSelect(contact)">

                <label for="table-contacts-select-{{contact.id}}"></label>
              </div>
            </td>

            <td class="col-initiales tw-text-center" ng-click="$ctrl.onClick(contact)">
              <esk-contact-initiales contact="contact"></esk-contact-initiales>
            </td>

            <td class="col-nom">
              <div class="ellipsis">
<!--
                <esk-contact-convivial-name contact="contact"></esk-contact-convivial-name>
-->

                <app-contact-convivial-name [link-contact]="'/contacts/' + contact.id" class="tw-block tw-truncate tw-w-full"></app-contact-convivial-name>
              </div>
            </td>

            <td class="col-phone tw-text-center">
              <a ng-if="contact[contact.type].telephone"
                 ng-href="{{contact[contact.type].getRfc3966(contact[contact.type].telephone)}}">
                <fa-icon [icon]="'phone'"></fa-icon> {{contact[contact.type].telephone}}
              </a>

              <a ng-if="contact[contact.type].mobile"
                 ng-href="{{contact[contact.type].getRfc3966(contact[contact.type].mobile)}}">
                <fa-icon [icon]="'mobile-alt'"></fa-icon> {{contact[contact.type].mobile}}
              </a>
            </td>

            <td class="col-email tw-text-center">
              <button type="button" class="btn send" ng-click="$ctrl.sendMail(contact)"
                      title="Cliquez ici pour envoyer un email.">
                {{contact[contact.type].emailAddress}}
              </button>
            </td>

            <td ng-if="$ctrl.options.visibleFields.edition" class="col-actions tw-text-center">
              <div class="btn-group">
                <button type="button" class="btn btn-o btn-null dropdown-toggle" data-toggle="dropdown">
                  <fa-icon [icon]="'ellipsis-v'" size="lg"></fa-icon>
                </button>

                <div class="dropdown-menu">
                  <ul class="list-unstyled">
                    <li>
                      <button type="button" class="btn" ng-click="$ctrl.onEdit(contact)">
                        <fa-icon [icon]="'edit'"></fa-icon> Éditer
                      </button>
                    </li>
                    <li>
                      <button type="button" class="btn" ng-click="$ctrl.onDelete(contact)">
                        <fa-icon [icon]="'trash-alt'"></fa-icon> Supprimer
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </ng-switch>
</div>
`;
