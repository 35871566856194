import {animate, animateChild, group, query, state, style, transition, trigger} from '@angular/animations';
import {Component, inject, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ModalService} from '@shared/modal/modal.service';
import {fromEvent, Subject} from 'rxjs';
import {filter, takeUntil, tap} from 'rxjs/operators';
import {DOCUMENT, NgClass} from '@angular/common';
import {IModalOptions} from '@shared/modal/modal.interfaces';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';

@Component({
    animations: [
        trigger('openClose', [
            state('true', style({ opacity: '1' })),
            state('false', style({ opacity: '0' })),
            transition('false <=> true', [
                group([query('@openCloseModal', animateChild()), animate('300ms ease-out')]),
            ]),
        ]),
        trigger('openCloseModal', [
            state('false', style({ transform: 'translateY(50px) scale(1.02)' })),
            transition('false <=> true', animate('200ms ease-out')),
        ]),
    ],
    imports: [FaIconComponent, NgClass],
    selector: 'app-modal',
    templateUrl: 'modal.component.html',
})
export class ModalComponent implements OnDestroy, OnInit {
    @ViewChild('modalContent', {read: ViewContainerRef, static: true}) modalContent!: ViewContainerRef;
    static readonly initModalOptions: IModalOptions = {
        align: 'middle',
        allowClose: true,
        button: 'cross',
        withPadding: true,
    };
    private _document: Document;
    private _modalService = inject(ModalService);
    private _isOpen = false;
    private readonly _onDestroy$ = new Subject<void>();
    private _options: IModalOptions = {...ModalComponent.initModalOptions};

    constructor(@Inject(DOCUMENT) document: Document) {
        this._document = document;
    }

    get isOpen(): boolean {
        return this._isOpen;
    }

    get options(): IModalOptions {
        return this._options;
    }

    ngOnInit(): void {
        this._modalService.dynamicComponentLoading$.pipe(
            tap(_ => this.modalContent.clear()),
            takeUntil(this._onDestroy$),
        ).subscribe(dynamicComponentLoading => {
            const componentRef = this.modalContent.createComponent(dynamicComponentLoading.component);

            componentRef.instance.data = dynamicComponentLoading.data;

            this._options = {...ModalComponent.initModalOptions, ...dynamicComponentLoading.options};
        });
        this._modalService.isOpen$.pipe(
            tap(isOpen => this._isOpen = isOpen),
            filter(isOpen => !isOpen),
            takeUntil(this._onDestroy$),
        ).subscribe(_ => this.modalContent.clear());
        fromEvent(this._document, 'keyup', (data: KeyboardEvent): string => data.code).pipe(
            filter(keyCode => keyCode === 'Escape'),
            takeUntil(this._onDestroy$),
        ).subscribe(_ => this.close());
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    close(): void {
        if (this._options.allowClose) {
            this._modalService.setResponse();
        }
    }
}
