import {Component, inject, Input} from '@angular/core';
import {IDynamicComponent} from '@shared/shared.interfaces';
import {DropdownService} from '@shared/dropdown/dropdown.service';
import {IDossierContactsGroupEditMemberDropdownData} from '@features/dossiers/dossiers.interfaces';

@Component({
    selector: 'app-dossier-contacts-group-edit-member-dropdown',
    standalone: false,
    templateUrl: 'dossier-contacts-group-edit.member-dropdown.component.html',
})
export class DossierContactsGroupEditMemberDropdownComponent implements IDynamicComponent {
    static readonly actions = {EDIT: 'edit', REMOVE: 'remove'};
    private _dropdownService = inject(DropdownService);
    private _dossierContactsGroupEditMemberDropdownData!: IDossierContactsGroupEditMemberDropdownData;

    @Input()
    set data(value: IDossierContactsGroupEditMemberDropdownData) {
        this._dossierContactsGroupEditMemberDropdownData = value;
    }

    edit(): void {
        this._dropdownService.clicked(DossierContactsGroupEditMemberDropdownComponent.actions.EDIT, this._dossierContactsGroupEditMemberDropdownData);
    }

    remove(): void {
        this._dropdownService.clicked(DossierContactsGroupEditMemberDropdownComponent.actions.REMOVE, this._dossierContactsGroupEditMemberDropdownData);
    }
}
