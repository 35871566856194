import {NgModule} from '@angular/core';
import {AppBienEditLocalisationComponent} from '@features/bien-edit/localisation/bien.edit-localisation.component';
import {CommonModule} from '@angular/common';
import {EskimmoComponentsModule} from '@shared/angularJS/up-ng/eskimmo-components.module';
import {SharedModule} from '@shared/shared.module';
import {CommunesModule} from '@features/communes/communes.module';
import {AppBienEditConstraintsDirective} from '@features/bien-edit/constraints/bien-edit.constraints.directive';
import {DCBienEditCadastreComponent} from '@features/bien-edit/dynamic-components/cadastre/bien.edit-cadastre.component';
import {AppBienEditDescriptionComponent} from '@features/bien-edit/description/bien.edit-description.component';
import {DictionariesModule} from '@features/dictionaries/dictionaries.module';
import {AppBienEditDossierComponent} from '@features/bien-edit/dossier/bien.edit-dossier.component';
import {UsersModule} from '@features/users/users.module';
import {DCBienEditPieceComponent} from '@features/bien-edit/dynamic-components/piece/bien.edit-piece.component';
import {
    DCBienEditLogementComponent
} from '@features/bien-edit/dynamic-components/logement/bien.edit-logement.component';
import {
    DCBienEditSanitairesComponent
} from '@features/bien-edit/dynamic-components/sanitaires/bien.edit-sanitaires.component';
import {
    AppBienEditViabilisationAssainissementComponent
} from '@features/bien-edit/viabilisation-assainissement/bien.edit-viabilisation-assainissement.component';
import {
    DCBienEditCommoditeComponent
} from '@features/bien-edit/dynamic-components/commodite/bien.edit-commodite.component';
import {
    DCBienEditDiagnosticComponent
} from '@features/bien-edit/dynamic-components/diagnostic/bien.edit-diagnostic.component';
import {
    DCBienEditChargeComponent
} from '@features/bien-edit/dynamic-components/charge/bien.edit-charge.component';
import {AppBienEditTypeRadioComponent} from '@features/bien-edit/type-radio/bien-edit.type-radio.component';
import {BrowserOnlineIndicatorComponent} from '@shared/browser/online/indicator/browser.online-indicator.component';

@NgModule({
    declarations: [
        AppBienEditConstraintsDirective,
        AppBienEditDescriptionComponent,
        AppBienEditDossierComponent,
        AppBienEditLocalisationComponent,
        AppBienEditViabilisationAssainissementComponent,
        AppBienEditTypeRadioComponent,
        DCBienEditCadastreComponent,
        DCBienEditChargeComponent,
        DCBienEditCommoditeComponent,
        DCBienEditDiagnosticComponent,
        DCBienEditLogementComponent,
        DCBienEditPieceComponent,
        DCBienEditSanitairesComponent,
    ],
    exports: [
        AppBienEditConstraintsDirective,
        AppBienEditDescriptionComponent,
        AppBienEditDossierComponent,
        AppBienEditLocalisationComponent,
        AppBienEditViabilisationAssainissementComponent,
    ],
    imports: [
        BrowserOnlineIndicatorComponent,
        CommonModule,
        CommunesModule,
        DictionariesModule,
        EskimmoComponentsModule,
        SharedModule,
        UsersModule,
    ],
})
export class BienEditModule {
}
