import {Component, inject, Input} from '@angular/core';
import User from '@models/users/user/user.model';
import {SlideOverService} from '@shared/slide-over/slide-over.service';
import {DCUserSlideOverComponent} from '@features/dynamic-components/user-slide-over/dc-user.slide-over.component';

@Component({selector: 'app-user-card', standalone: false, templateUrl: 'user.card.component.html'})
export class AppUserCardComponent {
    private _slideOverService = inject(SlideOverService);
    private _user!: User;

    get user(): User {
        return this._user;
    }

    @Input()
    set user(value: User) {
        this._user = value;
    }

    openSlideOver(): void {
        this._slideOverService.open$(DCUserSlideOverComponent, {user: this._user});
    }
}
