import {Component, inject, Input} from '@angular/core';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {
    DossierBienOverviewAgencementExterieurService
} from '@features/dossiers/dossier/bien/overview/agencement-exterieur/dossier-bien-overview.agencement-exterieur.service';

@Component({
    selector: 'app-dossier-bien-overview-agencement-exterieur',
    standalone: false,
    templateUrl: 'dossier-bien-overview.agencement-exterieur.component.html',
})
export class AppDossierBienOverviewAgencementExterieurComponent {
    private _dossierBienOverviewAgencementExterieurService = inject(DossierBienOverviewAgencementExterieurService);
    private _dossierBien!: ADossierBien;

    get dossierBien(): ADossierBien {
        return this._dossierBien;
    }

    @Input({required: true})
    set dossierBien(value: ADossierBien) {
        this._dossierBien = value;
    }

    get isAnnexeVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isAnnexeVisible;
    }

    get isBalconVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isBalconVisible;
    }

    get isCiterneGazLocationVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCiterneGazLocationVisible;
    }

    get isCiterneGazPleineProprieteVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCiterneGazPleineProprieteVisible;
    }

    get isCommentsAgencementExterieurVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCommentsAgencementExterieurVisible;
    }

    get isCourVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCourVisible;
    }

    get isCuveFuelAerienneVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCuveFuelAerienneVisible;
    }

    get isCuveFuelEnterreeVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCuveFuelEnterreeVisible;
    }

    get isCuveInactiveDegazeeVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCuveInactiveDegazeeVisible;
    }

    get isCuveInactiveNeutralizeeVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isCuveInactiveNeutralizeeVisible;
    }

    get isGarageVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isGarageVisible;
    }

    get isNombrePlacesParkingVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isNombrePlacesParkingVisible;
    }

    get isPiscineVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isPiscineVisible;
    }

    get isTerrasseVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isTerrasseVisible;
    }

    get isVerandaVisible(): boolean {
        return this._dossierBienOverviewAgencementExterieurService.isVerandaVisible;
    }
}
