import {Component, inject, Input} from '@angular/core';
import {EmissionsV1ColorsConst, EmissionsV2ColorsConst} from '@shared/diagnostic/diagnostic.constants';
import Bien from '@models/bien/bien.model';
import {
    BienPerformanceEnergetiqueService
} from '@models/bien/performance-energetique/bien-performance-energetique.service';
import {NgStyle, UpperCasePipe} from '@angular/common';

@Component({
    imports: [NgStyle, UpperCasePipe],
    selector: 'app-diagnostic-letter-emissions',
    templateUrl: 'diagnostic-letter-emissions.component.html',
})
export class AppDiagnosticLetterEmissionsComponent {
    private _bienPerformanceEnergetiqueService = inject(BienPerformanceEnergetiqueService);
    private _backgroundColor!: string;
    private _letter!: string;

    get backgroundColor(): string {
        return this._backgroundColor;
    }

    get letter(): string {
        return this._letter;
    }

    @Input()
    set bien(value: Bien) {
        const emissionsClasse = value.performanceEnergetique.emissionsClasse!;

        this._letter = emissionsClasse?.code ?? this._bienPerformanceEnergetiqueService.getEmissionsClasse(value);
        this._backgroundColor = value.performanceEnergetique.isV1ByDate() ? EmissionsV1ColorsConst[this._letter] : EmissionsV2ColorsConst[this._letter];
    }
}
