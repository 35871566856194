import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, switchMap} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {EtudePasserelleFactory} from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.factory';
import EtudePasserelle from '@models/etudes/etude/passerelles/passerelle/etude-passerelle.model';
import {CollectionSelectionService} from '@shared/collection/selection/collection-selection.service';
import {
    AppLocationPasserellesListComponent
} from '@features/locations/location/passerelles/list/location-passerelles-list.component';
import environment from '@env/environment';
import Etude from '@models/etudes/etude/etude.model';
import {AppVentesPasserellesListComponent} from '@features/ventes/passerelles/list/ventes-passerelles-list.component';

@Component({
    selector: 'layout-diffusions-passerelle',
    standalone: false,
    templateUrl: 'layout-diffusions-passerelle.component.html',
})
export class AppLayoutDiffusionsPasserelleComponent implements OnInit {
    static readonly linkMarianne = environment.api.baseUrl + Etude.linkMarianne.PLURAL;
    private _activatedRoute = inject(ActivatedRoute);
    private _collectionSelectionService = inject(CollectionSelectionService);
    private _etudePasserelleFactory = inject(EtudePasserelleFactory);
    private _router = inject(Router);
    private _etudePasserelle$!: Observable<EtudePasserelle>;
    private _nameSelection!: string;
    private _title!: string;

    get etudePasserelle$(): Observable<EtudePasserelle> {
        return this._etudePasserelle$;
    }

    get hasSelectedItems(): boolean {
        return this._collectionSelectionService.hasValues(this._nameSelection);
    }

    get linkMarianne(): string {
        return AppLayoutDiffusionsPasserelleComponent.linkMarianne;
    }

    get nameSelection(): string {
        return this._nameSelection;
    }

    get title(): string {
        return this._title;
    }

    ngOnInit(): void {
        this._etudePasserelle$ = this._activatedRoute.params.pipe(
            map(params => (params as { passerelleId: string }).passerelleId),
            switchMap(passerelleId => this._etudePasserelleFactory.get$(passerelleId)),
            tap(_ => {
                if (this._router.url.includes('ventes')) {
                    this._nameSelection = AppVentesPasserellesListComponent.initVentePasserellesListOptions.nameSelection!;
                    this._title = 'Ventes';
                } else if (this._router.url.includes('locations')) {
                    this._nameSelection = AppLocationPasserellesListComponent.initLocationPasserellesListOptions.nameSelection!;
                    this._title = 'Locations';
                }
            })
        );
    }
}
