import {NgModule} from '@angular/core';
import {AppNavigationLateralComponent} from '@shared/navigation/lateral/navigation-lateral.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FaModule} from '@shared/fontawesome/fa.module';
import {AppNavigationToggleComponent} from '@shared/navigation/toggle/navigation-toggle.component';
import {LoadModule} from '@shared/load/load.module';

@NgModule({
    exports: [AppNavigationLateralComponent, AppNavigationToggleComponent],
    imports: [
        AppNavigationLateralComponent,
        AppNavigationToggleComponent,
        CommonModule,
        FaModule,
        LoadModule,
        NgOptimizedImage,
        RouterModule,
    ],
})
export class NavigationModule {
}
