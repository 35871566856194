import {Component, inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {CUsersService} from '@models/users/collection/users.collection.service';
import CUsers from '@models/users/collection/users.collection.model';
import Commune from '@models/communes/commune/commune.model';
import Filter from '@models/filters/filter/filter.model';
import {FilterService} from '@models/filters/filter/filter.service';
import {take, tap} from 'rxjs/operators';
import {ICUsersQueryParameters} from '@models/users/collection/users.collection.interfaces';

@Component({selector: 'layout-annuaire-users', standalone: false, templateUrl: 'layout-annuaire-users.component.html'})
export class AppLayoutAnnuaireUsersComponent implements OnInit {
    private _cUsersService = inject(CUsersService);
    private _filterService = inject(FilterService);
    private _filter!: Filter<ICUsersQueryParameters>;

    get filter(): Filter<ICUsersQueryParameters> {
        return this._filter;
    }

    get cUsers$(): Observable<CUsers> {
        return this._cUsersService.current$;
    }

    ngOnInit(): void {
        this._filterService.getOneByRouteCode$<ICUsersQueryParameters>(Filter.routes.GET_ANNUAIRES, Filter.codes.USERS).pipe(
            tap(filter => this._filter = filter),
            take(1),
        ).subscribe(() => this.updateList());
    }

    communesSelected(communes: Commune[]): void {
        this._filter.queryParameters.commune!.values = communes?.map(commune => commune.id);
        this.updateList();
    }

    searchedText(keywords: string): void {
        this._filter.queryParameters.keywords = keywords;
        this.updateList();
    }

    updateList(): void {
        this._cUsersService.initCurrent(this._filter.queryParameters);
        this._filterService.save$(this._filter).pipe(take(1)).subscribe();
    }
}
