import {Component, ElementRef, EventEmitter, inject, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';
import {DemandeurFactory} from '@models/demandeurs/demandeur/demandeur.factory';
import {ModalService} from '@shared/modal/modal.service';
import {ProcedureService} from '@models/procedures/procedure/procedure.service';
import {VenteFactory} from '@models/ventes/vente/vente.factory';
import {combineLatest, delay, Observable, of, ReplaySubject, Subject} from 'rxjs';
import CProcedureSignataires
    from '@models/procedures/procedure/signataires/collection/procedure-signataires.collection.model';
import ADossier from '@models/dossiers/dossier/dossier.model.abstract';
import ProcedureSignataire from '@models/procedures/procedure/signataires/signataire/procedure-signataire.model';
import Offreachat from '@models/offreachats/offreachat/offreachat.model';
import Procedure from '@models/procedures/procedure/procedure.model';
import {IProcedureSignatairesEditOptions} from '@features/procedures/procedures.interfaces';
import {filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {
    OffreachatSignActionsMainComponent
} from '@features/offreachats/item/actions/sign/offreachat-sign.actions-main.component';
import {ProcedureSignatairesService} from '@models/procedures/procedure/signataires/procedure-signataires.service';

@Component({
    selector: 'app-offreachat-signature-edit',
    standalone: false,
    templateUrl: 'offreachat.signature-edit.component.html',
})
export class AppOffreachatSignatureEditComponent implements OnDestroy, OnInit {
    @Output() readonly saved = new EventEmitter<boolean>();
    // Impossible de passer avec la ligne ci-dessous, ça crée une erreur dans les tests
    // Readonly saved = output<boolean>();
    @ViewChild('signatairesForm') signatairesForm!: NgForm;
    @ViewChild('signatairesButtonSubmit') signatairesButtonSubmit!: ElementRef<HTMLButtonElement>;

    // @todo Faire un tour pour factoriser les messages
    static readonly errorMessages = {
        OTHER: 'Certains champs n\'ont pas de valeurs correctes.',
        TITLE: 'Données incomplètes',
    };
    private _callToActionService = inject(CallToActionService);
    private _demandeurFactory = inject(DemandeurFactory);
    private _modalService = inject(ModalService);
    private _procedureService = inject(ProcedureService);
    private _procedureSignatairesService = inject(ProcedureSignatairesService);
    private _venteFactory = inject(VenteFactory);
    private _cProcedureSignatairesAcquereursSource = new ReplaySubject<CProcedureSignataires>(1);
    private _cProcedureSignatairesAcquereurs$ = this._cProcedureSignatairesAcquereursSource.asObservable();
    private _cProcedureSignatairesVendeursSource = new ReplaySubject<CProcedureSignataires>(1);
    private _cProcedureSignatairesVendeurs$ = this._cProcedureSignatairesVendeursSource.asObservable();
    private _currentDossier!: ADossier;
    private _deletedSignatairesAcquereur!: ProcedureSignataire[];
    private _deletedSignatairesVendeur!: ProcedureSignataire[];
    private _editedSignatairesAcquereur!: ProcedureSignataire[];
    private _editedSignatairesVendeur!: ProcedureSignataire[];
    private _fromSaveCurrent = false;
    private _hasSignatairesVendeurs = true;
    private _offreachat!: Offreachat;
    private readonly _onDestroy$ = new Subject<void>();
    private _procedureAcquereur$!: Observable<Procedure>;
    private _procedureSignatairesAcquereurEditOptions!: IProcedureSignatairesEditOptions;
    private _procedureSignatairesVendeurEditOptions!: IProcedureSignatairesEditOptions;
    private _procedureVendeur$!: Observable<Procedure>;
    private _signatairesFormSource = new ReplaySubject<void>(1);

    get cProcedureSignatairesAcquereurs$(): Observable<CProcedureSignataires> {
        return this._cProcedureSignatairesAcquereurs$;
    }

    get cProcedureSignatairesVendeurs$(): Observable<CProcedureSignataires> {
        return this._cProcedureSignatairesVendeurs$;
    }

    get currentDossier(): ADossier {
        return this._currentDossier;
    }

    @Input({required: true})
    set currentDossier(value: ADossier) {
        this._currentDossier = value;
    }

    set deletedSignatairesAcquereur(value: ProcedureSignataire[]) {
        this._deletedSignatairesAcquereur = value;
    }

    set deletedSignatairesVendeur(value: ProcedureSignataire[]) {
        this._deletedSignatairesVendeur = value;
    }

    set editedSignatairesAcquereur(value: ProcedureSignataire[]) {
        this._editedSignatairesAcquereur = value;
    }

    set editedSignatairesVendeur(value: ProcedureSignataire[]) {
        this._editedSignatairesVendeur = value;
    }

    get hasSignatairesVendeurs(): boolean {
        return this._hasSignatairesVendeurs;
    }

    set hasSignatairesVendeurs(value: boolean) {
        this._hasSignatairesVendeurs = value;
    }

    get offreachat(): Offreachat {
        return this._offreachat;
    }

    @Input({required: true})
    set offreachat(value: Offreachat) {
        this._offreachat = value;
    }

    get procedureAcquereur$(): Observable<Procedure> {
        return this._procedureAcquereur$;
    }

    @Input({required: true})
    set procedureAcquereur$(value: Observable<Procedure>) {
        this._procedureAcquereur$ = value.pipe(
            filter(procedure => !!procedure),
            tap(procedure => this._procedureSignatairesAcquereurEditOptions = {
                enabledActions: {
                    copyLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                    remove: procedure.isNewOrDraft(),
                    sendLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                },
                enabledEmail: procedure.isDistanciel(),
                enabledPhone: procedure.isDistanciel(),
                enabledSignatairesAdding: procedure.isNewOrDraft(),
                enabledStatut: !procedure.isNewOrDraft(),
                tableId: 'signataires-acquereurs',
            }),
        );
    }

    get procedureSignatairesAcquereurEditOptions(): IProcedureSignatairesEditOptions {
        return this._procedureSignatairesAcquereurEditOptions;
    }

    get procedureSignatairesVendeurEditOptions(): IProcedureSignatairesEditOptions {
        return this._procedureSignatairesVendeurEditOptions;
    }

    get procedureVendeur$(): Observable<Procedure> {
        return this._procedureVendeur$;
    }

    @Input({required: true})
    set procedureVendeur$(value: Observable<Procedure>) {
        this._procedureVendeur$ = value.pipe(
            filter(procedure => !!procedure),
            tap(procedure => this._procedureSignatairesVendeurEditOptions = {
                enabledActions: {
                    copyLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                    remove: procedure.isNewOrDraft(),
                    sendLinkInterface: procedure.isDistanciel() && procedure.isInProgress(),
                },
                enabledEmail: procedure.isDistanciel(),
                enabledPhone: procedure.isDistanciel(),
                enabledSignatairesAdding: procedure.isNewOrDraft(),
                enabledStatut: !procedure.isNewOrDraft(),
                tableId: 'signataires-vendeurs',
            }),
        );
    }

    @Input()
    set requestSaving$(value: Observable<void>) {
        value.pipe(switchMap(() => this.save$()), takeUntil(this._onDestroy$)).subscribe();
    }

    ngOnInit(): void {
        this.procedureAcquereur$.pipe(
            switchMap(procedure => this._demandeurFactory.getByLink$(this._offreachat.linkDemandeur).pipe(
                switchMap(demandeur => this._procedureService.getCProcedureSignataires$(procedure, [demandeur], {withResponsableDossier: false})),
            )),
            takeUntil(this._onDestroy$),
        ).subscribe(cProcedureSignataires => this._cProcedureSignatairesAcquereursSource.next(cProcedureSignataires));
        this.procedureVendeur$.pipe(
            switchMap(procedure => this._venteFactory.getByLink$(this._offreachat.linkVente).pipe(
                switchMap(vente => this._procedureService.getCProcedureSignataires$(procedure, [vente], {withResponsableDossier: false})),
            )),
            takeUntil(this._onDestroy$),
        ).subscribe(cProcedureSignataires => this._cProcedureSignatairesVendeursSource.next(cProcedureSignataires));
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
    }

    save(): void {
        this.signatairesButtonSubmit.nativeElement.focus({preventScroll: true});
        this._signatairesFormSource.next();
        if (!this._fromSaveCurrent) {
            this._callToActionService.clicked(OffreachatSignActionsMainComponent.actions.SAVE);
        }
    }

    save$(): Observable<void> {
        if (!this.signatairesForm.submitted) {
            this._fromSaveCurrent = true;
            setTimeout(() => this.signatairesButtonSubmit.nativeElement.click(), 1);
        }

        return this._signatairesFormSource.asObservable().pipe(
            switchMap(_ => {
                if (!this.signatairesForm.valid) {
                    this.saved.emit(false);

                    return this._modalService.openInformation$({
                        comments: AppOffreachatSignatureEditComponent.errorMessages.OTHER,
                        title: AppOffreachatSignatureEditComponent.errorMessages.TITLE,
                        status: ModalService.statuts.WARNING,
                    });
                }

                return combineLatest([
                    this.procedureAcquereur$.pipe(
                        take(1),
                        switchMap(procedure => this._procedureSignatairesService.update$(procedure, this._deletedSignatairesAcquereur, this._editedSignatairesAcquereur)),
                    ),
                    this.procedureVendeur$.pipe(
                        take(1),
                        switchMap(procedure => {
                            if (this._hasSignatairesVendeurs) {
                                return of(procedure);
                            }

                            return this._procedureService.getCProcedureSignataires$(procedure).pipe(
                                tap(cProcedureSignataires => {
                                    this._deletedSignatairesVendeur = cProcedureSignataires.results;
                                    this._editedSignatairesVendeur = [];
                                }),
                                map(() => procedure),
                            );
                        }),
                        switchMap(procedure => this._procedureSignatairesService.update$(procedure, this._deletedSignatairesVendeur, this._editedSignatairesVendeur)),
                    ),
                ]).pipe(delay(1), tap(() => this.saved.emit(true)));
            }),
            map(_ => undefined),
        );
    }
}
