import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgBiensWalletSearchCriteria]'})
export class NgBiensWalletSearchCriteriaDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskDossierBiensWalletSearchCriteriaCtrl', elementRef, injector);
    }
}

@Component({
    imports: [NgBiensWalletSearchCriteriaDirective],
    selector: 'app-ng-biens-wallet-search-criteria',
    template: '<div appNgBiensWalletSearchCriteria></div>',
})
export class NgBiensWalletSearchCriteriaComponent {
}
