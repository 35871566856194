import {Component, Directive, ElementRef, Injector} from '@angular/core';
import {UpgradeComponent} from '@angular/upgrade/static';

@Directive({selector: '[appNgEskFooter]'})
export class NgEskimmoFooterDirective extends UpgradeComponent {
    constructor(elementRef: ElementRef<HTMLElement>, injector: Injector) {
        super('eskFooter', elementRef, injector);
    }
}

@Component({imports: [NgEskimmoFooterDirective], selector: 'esk-footer',  template: '<div appNgEskFooter></div>'})
export class NgEskimmoFooterComponent {
}
