import {inject, Injectable} from '@angular/core';
import CVentes from '@models/ventes/collection/ventes.collection.model';
import {Observable, of, switchMap} from 'rxjs';
import {CVentesFactory} from '@models/ventes/collection/ventes.collection.factory';
import {
    ICVentesAddedSearchCriteria, ICVentesQueryParameters
} from '@models/ventes/collection/ventes.collection.interfaces';
import {DossierBienSource, DossierBiensSourcesConst} from '@models/dossiers/biens/dossier-biens.constants';
import {map, tap} from 'rxjs/operators';
import SearchCriteria from '@models/search/criteria/search-criteria.model';

@Injectable({providedIn: 'root'})
export class CVentesService {
    private _cVentesFactory = inject(CVentesFactory);

    addNext$(cVentes: CVentes): Observable<CVentes> {
        return this._cVentesFactory.getNext$(cVentes).pipe(
            tap(newCCVentes => cVentes.updateNext(newCCVentes)),
            map(_ => cVentes),
        );
    }

    getBySearchCriteria$(searchCriteria: SearchCriteria, addedSearchCriteria?: ICVentesAddedSearchCriteria): Observable<CVentes> {
        return this._cVentesFactory.getQueryParametersFromSearchCriteria$(searchCriteria, addedSearchCriteria).pipe(
            switchMap(queryParameters => this._cVentesFactory.get$(queryParameters)),
        );
    }

    getBySource$(source: DossierBienSource, queryParameters: ICVentesQueryParameters = {}): Observable<CVentes> {
        if (source === DossierBiensSourcesConst.WALLET) {
            return this._cVentesFactory.getEtude$(queryParameters);
        }

        return this._cVentesFactory.get$(queryParameters);
    }

    getOneByLink$(link?: string): Observable<CVentes> {
        if (!link) {
            return of(this._cVentesFactory.createVirgin());
        }

        return this._cVentesFactory.getByLink$(link);
    }

    search$(keyword?: string): Observable<CVentes> {
        return this._cVentesFactory.get$({keywords: keyword});
    }
}
