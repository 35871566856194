import {Component, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {NotestimFactory} from '@models/notestim/notestim.factory';
import {AsyncPipe} from '@angular/common';
import {AppLoaderComponent} from '@shared/load/loader/loader.component';
import {AppIframeComponent} from '@shared/iframe/iframe.component';

@Component({
    imports: [AppLoaderComponent, AsyncPipe, AppIframeComponent],
    selector: 'layout-configuration-notestim',
    templateUrl: 'layout-configuration-notestim.component.html',
})
export class AppLayoutConfigurationNotestimComponent {
    private _notestimFactory = inject(NotestimFactory);
    private _iframeUrl$ = this._notestimFactory.getUrlSettings$();

    get iframeUrl$(): Observable<string> {
        return this._iframeUrl$;
    }
}
