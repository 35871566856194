import {Component} from '@angular/core';
import {CallToActionService} from '@shared/call-to-action/call-to-action.service';

@Component({
    selector: 'layout-estimation-rapport',
    standalone: false,
    templateUrl: 'layout-estimation.rapport.component.html',
})
export class AppLayoutEstimationRapportComponent {
    get ACTION_MAIN(): string {
        return CallToActionService.MAIN;
    }
}
