import {Component, inject} from '@angular/core';
import {IDynamicComponent, IDynamicComponentData} from '@shared/shared.interfaces';
import {NgBien, NgBienCadastre} from '@legacy/app/managers/ressources';
import {take} from 'rxjs/operators';
import {ModalService} from '@shared/modal/modal.service';
import {NgForm} from '@angular/forms';
import Commune from '@models/communes/commune/commune.model';
import {CommuneFactory} from '@models/communes/commune/commune.factory';
import {NgCommuneManager} from '@legacy/app/managers/managers';
import {NgInjectorService} from '@shared/angularJS/injector.ng.service';
import ADossierBien from '@models/dossiers/biens/bien/dossier-bien.model.abstract';
import {BienCadastreService} from '@models/bien/cadastres/cadastre/bien-cadastre.service';

@Component({selector: 'bien-edit-cadastre', standalone: false, templateUrl: 'bien.edit-cadastre.component.html'})
export class DCBienEditCadastreComponent implements IDynamicComponent {
    private _bienCadastreService = inject(BienCadastreService);
    private _communeFactory = inject(CommuneFactory);
    private _modalService = inject(ModalService);
    private _ngInjectorService = inject(NgInjectorService);
    private _commune!: Commune;
    private _dossierBien!: ADossierBien;
    private _ngBien!: NgBien;
    private _ngBienCadastre!: NgBienCadastre;

    // Supprimer les injections des anciens manager
    private get ngCommuneManager(): NgCommuneManager {
        return this._ngInjectorService.getService('CommuneManager');
    }

    get commune(): Commune {
        return this._commune;
    }

    set data(dynamicComponentData: IDynamicComponentData) {
        this._dossierBien = dynamicComponentData.dossierBien as ADossierBien;
        this._ngBien = dynamicComponentData.ngBien as NgBien;
        this._ngBienCadastre = dynamicComponentData.ngBienCadastre as NgBienCadastre;
        if (this._ngBienCadastre.commune) {
            this._commune = this._communeFactory.ngCreate(this._ngBienCadastre.commune);
        } else {
            this._commune = undefined!;
        }
    }

    get ngBienCadastre(): NgBienCadastre {
        return this._ngBienCadastre;
    }

    communeSelected(commune: Commune): void {
        this._commune = commune;
        if (this._commune) {
            this._ngBienCadastre.commune = this.ngCommuneManager.createFromNg2(this._commune);
        } else {
            this._ngBienCadastre.commune = undefined!;
        }
    }

    save(editForm: NgForm): void {
        if (!editForm.submitted || !editForm.valid) {
            return;
        }

        this._bienCadastreService.ngSave$(this._dossierBien, this._ngBien, this._ngBienCadastre).pipe(take(1)).subscribe(ngBienCadastre => {
            if (ngBienCadastre) {
                this._modalService.setResponse(ngBienCadastre);
            }
        });
    }
}
